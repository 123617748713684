import { ChartOptions, COLORS, getOptionTotal } from '../../../helpers/relay';
import { JsonGroup, JsonPoll, JsonPollPollTypeEnum, JsonPollResults, JsonRelayResponse } from '../../../generated-api';
import { Fragment } from 'react';

const RelayHorizontalBarChart = (props: { group?: JsonGroup, poll: JsonPoll, results: JsonPollResults, responses: JsonRelayResponse[], options?: ChartOptions }) => {
    const {poll, results} = props;

    const max = results?.optionTotals
        ? poll.options?.reduce((max: number, option) => {
            let current = (results?.optionTotals && results.optionTotals[option.optionId as number]) || 0;
            return current > max ? current : max;
        } , 0) : 0;

    const sortedOptions = (poll.options || []).map(option => option);

    const chartHeight = props.options?.height || 20;
    const chartFontSize = props.options?.labelFontSize || '0.8rem';
    const percentPadding = props.options?.padding || '45px';
    const marginTop = props.options?.marginTop || '+4px';
    const marginBottom = props.options?.marginBottom || '-6px';
    const contentMarginTop = props.options?.contentMarginTop || '10px';
    const colors = props.options?.colors || COLORS;

    /*
    // we dont want to sort options any more
    if (!props.options?.noSortOptions) {
        sortedOptions.sort((a, b) => {
            let aResult = (results?.optionTotals && results.optionTotals[a.optionId as number]) || 0;
            let bResult = (results?.optionTotals && results.optionTotals[b.optionId as number]) || 0;
            return aResult > bResult ? -1 : 1;
        });
    }
     */

    return <table style={{width: "100%", tableLayout: "fixed", fontFamily: "Roboto,Helvetica,Arial,sans-serif", marginTop: contentMarginTop }}>
                <tbody>
                        {sortedOptions?.map((option, i) => {
                            const optionTotal = getOptionTotal(option, results);
                            const percent =  results?.userCount ? Math.round(optionTotal / results.userCount * 1000) / 10 : 0;
                            const showPercent = poll.pollType !== JsonPollPollTypeEnum.Ranking;
                            const barWidth = max ? 100 * (optionTotal / max) + '%' : '0px';
                            return (
                                <Fragment key={i}>
                                    <tr key={'title' + i}>
                                        <td style={{ verticalAlign: "top" }}>
                                            <div style={{ marginTop, marginBottom, display: "flex", alignItems: "flex-start", justifyContent: "flex-start", fontSize: chartFontSize, textAlign: "left" }}>
                                                <strong>{option.title}</strong>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr key={'bar' + i}>
                                        <td style={{verticalAlign: "center", textAlign: "center", fontSize: chartFontSize}}>
                                            <div style={{width: "100%", display: "flex", alignItems: 'center'}}>
                                            { showPercent && <div style={{ width: percentPadding }}><span style={{display: "block", fontWeight: "400", padding: "10px 0"}}>{percent}&nbsp;%</span></div> }
                                                <div style={{ width: showPercent ? 'calc(100% - ' + percentPadding + ')' : '100%' }}>
                                                    <div style={{ height: chartHeight, width: barWidth, background: colors[i % colors.length]}}></div>
                                                </div>
                                            </div>
                                        </td>
                                        
                                        { /* 
                                        <td><span style={{display: "block", padding: "10px 0"}}>{optionTotal}</span></td> 
                                        <td>{ JSON.stringify({  optionTotal, max, width: max ? 100 * (optionTotal / max) + '%' : '-' }) }</td>
                                        */ }
                                    </tr>
                                </Fragment>
                            );
                        })}
                </tbody>
           </table>
};

export default RelayHorizontalBarChart;
