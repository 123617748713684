import {useEffect} from "react";
import {useParams} from "react-router-dom";
import LiveLayout from "../../components/layout/LiveLayout";
import {JsonEpisode} from "../../generated-api";
import {Alert} from "@mui/material";
import {useLiveContext} from "../../hooks/useLiveContext";

const LiveReportPage = () => {

    const [context] = useLiveContext(true);

    const { episodeId } = useParams();

    useEffect(() => {
        console.log("Setup parent frame message listener")

        if (!context) {
            return;
        }

        /*
        const authToken = 'eyJhbGciOiAiUlMyNTYiLCAidHlwIjogIkpXVCIsICJraWQiOiAiMTQzY2Q5ZjItY2QyNy00ZTM5LWI0NmYtOTM1MTU1ZDA2YjYzIn0.eyJzdWIiOiAiZDg5MWMwZjEtZTE1Yi00NDUwLThkYzctNDc2ZTY4MTdjNjkzIiwgIm5hbWUiOiAiSm9obiBEb2UiLCAiaWF0IjogMTY5ODE1OTU1NSwgImV4cCI6IDE2OTg3NjQzNTUsICJqdGkiOiAiOGJiYjZhYzMtYzU5OS00M2Y3LTlkODMtYTBkZGNkODE5ODNhIn0.MtchtxJs0vaCpYVSQvjCqyk8gs9z2VOhJjkv9ealJMwQeUc1uEHkccCnU86vzIu1JN733xGpSBg5GuUh_0YxHi59xfKWWTjQK5ATI8Zj4OtIO-FGyY5jUD-Rr1vIVgad36AGf8RcDXIzVuvyrxw8ymE6YrWllojW8-JDkaqOb_uObINmsy5ddQwygFaB0KEOhja1BMRWTxa81VXdbvo9bALMmgFwnCkJrYlFbp6dI-O-fdDJtNXytDou52xdTSyfb4osDQ7eTaVtMgx3fCDLA-gcH9c3qIXzFbrKml9FKyM-OPnUC1EqL8td2BiCH4WJKTdJLD2rBGzyb4jouFxtCA'; // Replace with your authentication token
        const authToken1 = 'eyJhbGciOiAiUlMyNTYiLCAidHlwIjogIkpXVCIsICJraWQiOiAiODYzZDc3YjEtYTYwMi00YTNjLWE3ZjctMDkzODA0OWUxMGM1In0.eyJzdWIiOiAiMzNjNWNkM2ItZGMyMS00MTY3LTg0MzctNjA3YTdlNjM1YWFmIiwgIm5hbWUiOiAiSm9obiBEb2UiLCAiaWF0IjogMTY5ODMyOTg5MiwgImV4cCI6IDE2OTg5MzQ2OTIsICJqdGkiOiAiZjJjNTMxYzYtMWNiOC00YmRkLWFhNDktN2RjN2NlNjJmNzNhIn0.sBEwoBv3MjLGyFa4-FGiOHKHTeK8nXaEPCl81T3GCbOEHYGOc6RNnvGi7s2WrL9k3smh7lA2tf0CSMP4jIfZAtVRMefcT3tkrRJj3WYCwPTR4JJ_j0-8QURCZ-ZHPjzGmBb2NZ0HPOqgdmIbMDlCKyySfPePXMAEqXDlBxsNzzVJpGLShLMmrnh96UMWR08p3DbcIdjlLcEwPFIfEkeT6YET-jD7Q4woQGxdZgFasCVU_iNTa2dVwqw1--lGV3uQLisc0_F8AZYDb9z74rUFX3h7xonrCeykePSTSqw1n2R0EvQuzAbVA9Rp_moN-gkbHJ3-LSgK5BGcJP-E2JNrQw';
        const authToken2 = 'eyJhbGciOiAiUlMyNTYiLCAidHlwIjogIkpXVCIsICJraWQiOiAiMWI1OGQxZGYtYjI0OS00YTNlLTgyYWUtNmJiMTE1MjkzYTNlIn0.eyJzdWIiOiAiNTk5M2M4OGEtMGIxZi00MzRiLWFjMzYtNDhkMWIwNTg4MDY5IiwgIm5hbWUiOiAiSm9obiBEb2UiLCAiaWF0IjogMTY5ODMzMDAyNSwgImV4cCI6IDE2OTg5MzQ4MjUsICJqdGkiOiAiMjA2YzgzOGYtMDRjNi00YjA1LWI3MTMtMjNjNTBlNDRkNjY4In0.rEYSclwPd1n83OH9napCfSaqTlJi6mI0kyq9WMavqlX9GX5EiqyaKWLRWfuwsL7hvBsdsbRzGWQQb7fFeojN7i7ZpxW1VQm7zpMQxvuYYvXBydm8-MIGudrIFc2pj3ycVBbYgTbH9_KF15BdbbgPGf0rRhBDFrc7hkugBykM1MXx8HONHUZ3oEGbRIammrMrZr4QNoyKpbF-wRB53L0th1lw4o2yNjreRmRg2Y3SkzwE_zHF7ddhV__ynL1Ak7kxM1VPiOyuV63l6qx3SkkdbHcxwC9XXraTaLIUzo_hdO9zE2plptHVImVzQRqVDb7fNSmABHYEsx5QapQvSXcr5g';
        */
        const accessToken = context?.context?.dashboardToken;

        const handleListen = (event: any) => {
            console.log("Post message received", event);
            console.log("Event name - " + event.data.gdc?.event.name);

            if (event.data.gdc?.event.name === "listeningForApiToken") {
                const postMessageStructure = {
                    gdc: {
                        product: "dashboard",
                        event: {
                            name: "setApiToken",
                            data: {
                                token: accessToken,
                                type: "jwt",
                                secondsBeforeTokenExpirationToEmitReminder: 60
                            }
                        }
                    }
                };
                console.log("Sending token to embedded window");

                const origin = "*";
                const iframe = (document.getElementById("embedded-app-frame") as any)?.contentWindow;
                console.log({ iframe });
                iframe.postMessage(postMessageStructure, origin);
            }
        }

        window.addEventListener(
            "message",
            handleListen,
            false
        );

        return function cleanupListener() {
            window.removeEventListener('message', handleListen)
        }
    }, [context])

    const episodeIndex = (context?.context?.episodes?.findIndex((episode: JsonEpisode) => episode.episodeId === +(episodeId||0)) || 0) + 1;
    const episode = context?.context?.episodes?.find((episode: JsonEpisode) => episode.episodeId === +(episodeId||0));
    const title = episode ? 'Epizoda ' + episodeIndex + '. ' + episode.title : '';
    const dashboardUrl = episode?.dashboardUrl;
    const accessToken = context?.context?.dashboardToken;

    console.log({ episodeId, context, title });

    return (<LiveLayout
                title="Datové výstupy"
                subtitle={title}
                backUrl="/reports"
                backButton
            >
            { context && !dashboardUrl && <Alert severity="error">Není definován dashboard pro tuto epizodu</Alert> }
            { context && !accessToken && <Alert severity="error">Nemáte nastavena oprávnění pro přístup k dashboardu</Alert> }
            { dashboardUrl && accessToken &&
            <iframe
                title="Embeded GoodData Atairu Reports"
                id="embedded-app-frame"
                src={ `${dashboardUrl}?apiTokenAuthentication=true&hideControl=[topBar,deleteButton,saveAsButton,widgetsCatalogue]&showEmbedButton=false` }
                width="1170"
                height="800"
            />
            }
        </LiveLayout>
    );
}

// https://atairu.partners.cloud.gooddata.com/dashboards/embedded/#/workspace/2df1b066359144e49f9001cf58ba0d81/dashboard/f8681d56-06fe-4cac-9df6-50ee8369fe87

export default LiveReportPage;
