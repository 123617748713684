/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonErrorInfo,
    JsonErrorInfoFromJSON,
    JsonErrorInfoFromJSONTyped,
    JsonErrorInfoToJSON,
} from './JsonErrorInfo';

/**
 * 
 * @export
 * @interface JsonSerieUser
 */
export interface JsonSerieUser {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerieUser
     */
    allowedActions?: Array<JsonSerieUserAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonSerieUser
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUser
     */
    clientGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    companyBranchId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    companyDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    companyDurationId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    companyPositionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUser
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    departmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    durationId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    positionId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    rowNo?: number;
    /**
     * 
     * @type {Array<JsonErrorInfo>}
     * @memberof JsonSerieUser
     */
    saveErrors?: Array<JsonErrorInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    sectorId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    serieId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    serieUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUser
     */
    status?: JsonSerieUserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUser
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUser
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonSerieUserAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieUserStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonSerieUserFromJSON(json: any): JsonSerieUser {
    return JsonSerieUserFromJSONTyped(json, false);
}

export function JsonSerieUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'clientGuid': !exists(json, 'clientGuid') ? undefined : json['clientGuid'],
        'companyBranchId': !exists(json, 'companyBranchId') ? undefined : json['companyBranchId'],
        'companyDepartmentId': !exists(json, 'companyDepartmentId') ? undefined : json['companyDepartmentId'],
        'companyDurationId': !exists(json, 'companyDurationId') ? undefined : json['companyDurationId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyPositionId': !exists(json, 'companyPositionId') ? undefined : json['companyPositionId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'durationId': !exists(json, 'durationId') ? undefined : json['durationId'],
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'rowNo': !exists(json, 'rowNo') ? undefined : json['rowNo'],
        'saveErrors': !exists(json, 'saveErrors') ? undefined : ((json['saveErrors'] as Array<any>).map(JsonErrorInfoFromJSON)),
        'sectorId': !exists(json, 'sectorId') ? undefined : json['sectorId'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'serieUserId': !exists(json, 'serieUserId') ? undefined : json['serieUserId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonSerieUserToJSON(value?: JsonSerieUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'clientGuid': value.clientGuid,
        'companyBranchId': value.companyBranchId,
        'companyDepartmentId': value.companyDepartmentId,
        'companyDurationId': value.companyDurationId,
        'companyId': value.companyId,
        'companyPositionId': value.companyPositionId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'departmentId': value.departmentId,
        'durationId': value.durationId,
        'positionId': value.positionId,
        'rowNo': value.rowNo,
        'saveErrors': value.saveErrors === undefined ? undefined : ((value.saveErrors as Array<any>).map(JsonErrorInfoToJSON)),
        'sectorId': value.sectorId,
        'serieId': value.serieId,
        'serieUserId': value.serieUserId,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

