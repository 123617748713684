import {Card, CardActions, CardContent, Grid} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {processLogin} from '../../store/auth';
import {Formik, FormikErrors} from 'formik';
import TextFormfield, {whiteStyle} from '../../components/form/TextFormfield';
import {JsonLoginRequest} from '../../generated-api';
import {isEmpty} from '../../helpers/object';
import {addMessage} from '../../store/localApp';
import whiteLogo from '../../assets/images/atairu_logo_white.png';
import stratairuLogo from '../../assets/images/stratairu/stratairu-logo.svg';
import labLogo from '../../assets/images/lab/atairulab_logo_dark.svg';
import {isEmailValid} from '../../helpers/validators';
import {ActionLink} from "../../components/layout/ActionLink";
import {useNavigate} from "react-router";
import {Button} from '../../components/layout/Button';
import {AtairuEnv, getEnvironment} from "../../helpers/environment";

const LoginPage = () => {

	const t = useAppTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const env = getEnvironment();

	const getLogo = useCallback(() => {
		if (env === AtairuEnv.Tv) {
			return whiteLogo;
		}
		if (env === AtairuEnv.Stratairu) {
			return stratairuLogo;
		}
		if (env === AtairuEnv.Lab) {
			return labLogo;
		}
		return whiteLogo;
	}, [env]);

	const getLogoHeight = useCallback(() => {
		if (env === AtairuEnv.Tv) {
			return '140px';
		}
		if (env === AtairuEnv.Stratairu) {
			return '140px';
		}
		if (env === AtairuEnv.Lab) {
			return '40px';
		}
		return whiteLogo;
	}, [env]);

	const validate = useCallback((values: JsonLoginRequest) => {
		const errors = {} as FormikErrors<JsonLoginRequest>;
		if (!values.email) {
			errors['email'] = t('Zadejte přihlašovací email');
		} else if (!isEmailValid(values.email)) {
			errors['email'] = t('Neplatný přihlašovací email');
		}
		if (!values.password) {
			errors['password'] = t('Zadejte heslo');
		}
		return isEmpty(errors) ? undefined : errors;
	}, [t]);

	const handleLogin = useCallback(async (values: JsonLoginRequest) => {
		const result = await dispatch(processLogin(values));
		if ('error' in result) {
			dispatch(addMessage({
			  severity: 'error',
			  title: t(result.error.message + ''),
			}));
			return;
		}
	}, [dispatch, t])

	// https://mui.com/material-ui/getting-started/usage/
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				rememberMe: true
				}}
			validate={validate}
			onSubmit={handleLogin}
			>
			{props => (
			<form onSubmit={props.handleSubmit}>
				<div style={{ paddingTop: '15%' }}>
					<Card sx={{ width: '100%', maxWidth: 400, margin: 'auto', background: 'transparent' }} elevation={0}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
										<img alt={'Atairu'} src={getLogo()} style={{ height: getLogoHeight() }}/>
									</div>
								</Grid>
								<Grid item xs={12}>
									<TextFormfield name="email" label={t('E-mail')} sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
								</Grid>
								<Grid item xs={12}>
									<TextFormfield name="password" label={t('Heslo')} type="password" sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button variant="contained" color="primary" type="submit" fullWidth>{t('Přihlásit')}</Button>
						</CardActions>
						<CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
							<ActionLink onClick={() => navigate('/forgotten-password')} sx={{ color: env === AtairuEnv.Tv ? 'white' : undefined }}>{t('Zapomněli jste heslo?')}</ActionLink>
							<div style={{ marginTop: '50px', fontSize: '12px', color: '#aaa' }}>&copy; 2023 ATAIRU</div>
						</CardContent>
					</Card>
				</div>
			</form>
			)}
		</Formik>
	);
}

export default LoginPage;
