/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonRelay,
    ApiResultOfJsonRelayFromJSON,
    ApiResultOfJsonRelayToJSON,
    ApiResultOfJsonRelayInfo,
    ApiResultOfJsonRelayInfoFromJSON,
    ApiResultOfJsonRelayInfoToJSON,
    ApiResultOfJsonRelayResetResponse,
    ApiResultOfJsonRelayResetResponseFromJSON,
    ApiResultOfJsonRelayResetResponseToJSON,
    ApiResultOfListOfJsonRelayInfo,
    ApiResultOfListOfJsonRelayInfoFromJSON,
    ApiResultOfListOfJsonRelayInfoToJSON,
    ApiResultOfListOfJsonRelayItem,
    ApiResultOfListOfJsonRelayItemFromJSON,
    ApiResultOfListOfJsonRelayItemToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonRelay,
    JsonRelayFromJSON,
    JsonRelayToJSON,
    JsonRelayResetRequest,
    JsonRelayResetRequestFromJSON,
    JsonRelayResetRequestToJSON,
} from '../models';

export interface GetRelayCountUsingGETRequest {
    episodeId?: number;
    relayStatus?: GetRelayCountUsingGETRelayStatusEnum;
    search?: string;
}

export interface GetRelayInfoUsingGETRequest {
    relayId: number;
}

export interface GetRelayItemListUsingGETRequest {
    relayId: number;
}

export interface GetRelayListUsingGETRequest {
    episodeId?: number;
    relayStatus?: GetRelayListUsingGETRelayStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetRelayUsingGETRequest {
    relayId: number;
}

export interface ResetRelayUsingPOSTRequest {
    relayId: number;
    resetRequest: JsonRelayResetRequest;
    testOnly?: boolean;
}

export interface SaveRelayUsingPOSTRequest {
    json: JsonRelay;
    testOnly?: boolean;
}

export interface StartRelayItemGroupUsingPOSTRequest {
    relayId: number;
    itemType: StartRelayItemGroupUsingPOSTItemTypeEnum;
    groupId: number;
}

export interface StartRelayItemUsingPOSTRequest {
    relayId: number;
    itemType: StartRelayItemUsingPOSTItemTypeEnum;
    componentId: number;
}

export interface StopRelayItemGroupUsingPOSTRequest {
    relayId: number;
    itemType: StopRelayItemGroupUsingPOSTItemTypeEnum;
    groupId: number;
}

export interface StopRelayItemUsingPOSTRequest {
    relayId: number;
    itemId: number;
}

/**
 * 
 */
export class RelayControllerApi extends runtime.BaseAPI {

    /**
     * getRelayCount
     */
    async getRelayCountUsingGETRaw(requestParameters: GetRelayCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.episodeId !== undefined) {
            queryParameters['episodeId'] = requestParameters.episodeId;
        }

        if (requestParameters.relayStatus !== undefined) {
            queryParameters['relayStatus'] = requestParameters.relayStatus;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getRelayCount
     */
    async getRelayCountUsingGET(requestParameters: GetRelayCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getRelayCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRelayInfo
     */
    async getRelayInfoUsingGETRaw(requestParameters: GetRelayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRelayInfo>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling getRelayInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/info/{relayId}`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRelayInfoFromJSON(jsonValue));
    }

    /**
     * getRelayInfo
     */
    async getRelayInfoUsingGET(requestParameters: GetRelayInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRelayInfo> {
        const response = await this.getRelayInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRelayItemList
     */
    async getRelayItemListUsingGETRaw(requestParameters: GetRelayItemListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonRelayItem>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling getRelayItemListUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/{relayId}/item/list`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonRelayItemFromJSON(jsonValue));
    }

    /**
     * getRelayItemList
     */
    async getRelayItemListUsingGET(requestParameters: GetRelayItemListUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonRelayItem> {
        const response = await this.getRelayItemListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRelayList
     */
    async getRelayListUsingGETRaw(requestParameters: GetRelayListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonRelayInfo>> {
        const queryParameters: any = {};

        if (requestParameters.episodeId !== undefined) {
            queryParameters['episodeId'] = requestParameters.episodeId;
        }

        if (requestParameters.relayStatus !== undefined) {
            queryParameters['relayStatus'] = requestParameters.relayStatus;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonRelayInfoFromJSON(jsonValue));
    }

    /**
     * getRelayList
     */
    async getRelayListUsingGET(requestParameters: GetRelayListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonRelayInfo> {
        const response = await this.getRelayListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getRelay
     */
    async getRelayUsingGETRaw(requestParameters: GetRelayUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRelay>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling getRelayUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/{relayId}`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRelayFromJSON(jsonValue));
    }

    /**
     * getRelay
     */
    async getRelayUsingGET(requestParameters: GetRelayUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRelay> {
        const response = await this.getRelayUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * resetRelay
     */
    async resetRelayUsingPOSTRaw(requestParameters: ResetRelayUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRelayResetResponse>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling resetRelayUsingPOST.');
        }

        if (requestParameters.resetRequest === null || requestParameters.resetRequest === undefined) {
            throw new runtime.RequiredError('resetRequest','Required parameter requestParameters.resetRequest was null or undefined when calling resetRelayUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/relay/{relayId}/reset`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonRelayResetRequestToJSON(requestParameters.resetRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRelayResetResponseFromJSON(jsonValue));
    }

    /**
     * resetRelay
     */
    async resetRelayUsingPOST(requestParameters: ResetRelayUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRelayResetResponse> {
        const response = await this.resetRelayUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveRelay
     */
    async saveRelayUsingPOSTRaw(requestParameters: SaveRelayUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRelay>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveRelayUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/relay`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonRelayToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRelayFromJSON(jsonValue));
    }

    /**
     * saveRelay
     */
    async saveRelayUsingPOST(requestParameters: SaveRelayUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRelay> {
        const response = await this.saveRelayUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * startRelayItemGroup
     */
    async startRelayItemGroupUsingPOSTRaw(requestParameters: StartRelayItemGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonRelayItem>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling startRelayItemGroupUsingPOST.');
        }

        if (requestParameters.itemType === null || requestParameters.itemType === undefined) {
            throw new runtime.RequiredError('itemType','Required parameter requestParameters.itemType was null or undefined when calling startRelayItemGroupUsingPOST.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling startRelayItemGroupUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/{relayId}/group/{itemType}/{groupId}/start`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))).replace(`{${"itemType"}}`, encodeURIComponent(String(requestParameters.itemType))).replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonRelayItemFromJSON(jsonValue));
    }

    /**
     * startRelayItemGroup
     */
    async startRelayItemGroupUsingPOST(requestParameters: StartRelayItemGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonRelayItem> {
        const response = await this.startRelayItemGroupUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * startRelayItem
     */
    async startRelayItemUsingPOSTRaw(requestParameters: StartRelayItemUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonRelayItem>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling startRelayItemUsingPOST.');
        }

        if (requestParameters.itemType === null || requestParameters.itemType === undefined) {
            throw new runtime.RequiredError('itemType','Required parameter requestParameters.itemType was null or undefined when calling startRelayItemUsingPOST.');
        }

        if (requestParameters.componentId === null || requestParameters.componentId === undefined) {
            throw new runtime.RequiredError('componentId','Required parameter requestParameters.componentId was null or undefined when calling startRelayItemUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/{relayId}/item/{itemType}/{componentId}/start`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))).replace(`{${"itemType"}}`, encodeURIComponent(String(requestParameters.itemType))).replace(`{${"componentId"}}`, encodeURIComponent(String(requestParameters.componentId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonRelayItemFromJSON(jsonValue));
    }

    /**
     * startRelayItem
     */
    async startRelayItemUsingPOST(requestParameters: StartRelayItemUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonRelayItem> {
        const response = await this.startRelayItemUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * stopRelayItemGroup
     */
    async stopRelayItemGroupUsingPOSTRaw(requestParameters: StopRelayItemGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonRelayItem>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling stopRelayItemGroupUsingPOST.');
        }

        if (requestParameters.itemType === null || requestParameters.itemType === undefined) {
            throw new runtime.RequiredError('itemType','Required parameter requestParameters.itemType was null or undefined when calling stopRelayItemGroupUsingPOST.');
        }

        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling stopRelayItemGroupUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/{relayId}/group/{itemType}/{groupId}/stop`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))).replace(`{${"itemType"}}`, encodeURIComponent(String(requestParameters.itemType))).replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonRelayItemFromJSON(jsonValue));
    }

    /**
     * stopRelayItemGroup
     */
    async stopRelayItemGroupUsingPOST(requestParameters: StopRelayItemGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonRelayItem> {
        const response = await this.stopRelayItemGroupUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * stopRelayItem
     */
    async stopRelayItemUsingPOSTRaw(requestParameters: StopRelayItemUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonRelayItem>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling stopRelayItemUsingPOST.');
        }

        if (requestParameters.itemId === null || requestParameters.itemId === undefined) {
            throw new runtime.RequiredError('itemId','Required parameter requestParameters.itemId was null or undefined when calling stopRelayItemUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/relay/{relayId}/item/{itemId}/stop`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))).replace(`{${"itemId"}}`, encodeURIComponent(String(requestParameters.itemId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonRelayItemFromJSON(jsonValue));
    }

    /**
     * stopRelayItem
     */
    async stopRelayItemUsingPOST(requestParameters: StopRelayItemUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonRelayItem> {
        const response = await this.stopRelayItemUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetRelayCountUsingGETRelayStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Finished = 'FINISHED'
}
/**
    * @export
    * @enum {string}
    */
export enum GetRelayListUsingGETRelayStatusEnum {
    Draft = 'DRAFT',
    Active = 'ACTIVE',
    Finished = 'FINISHED'
}
/**
    * @export
    * @enum {string}
    */
export enum StartRelayItemGroupUsingPOSTItemTypeEnum {
    PollQuestion = 'POLL_QUESTION',
    PollResults = 'POLL_RESULTS',
    Banner = 'BANNER'
}
/**
    * @export
    * @enum {string}
    */
export enum StartRelayItemUsingPOSTItemTypeEnum {
    PollQuestion = 'POLL_QUESTION',
    PollResults = 'POLL_RESULTS',
    Banner = 'BANNER'
}
/**
    * @export
    * @enum {string}
    */
export enum StopRelayItemGroupUsingPOSTItemTypeEnum {
    PollQuestion = 'POLL_QUESTION',
    PollResults = 'POLL_RESULTS',
    Banner = 'BANNER'
}
