import {css, styled} from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

export enum StratairuButtonEnum {
    Yellow = 'yellow',
    Blue = 'blue'
}

export const StratairuButton = styled(MuiButton)<{ mycolor?: StratairuButtonEnum }>`

  ${props => props.mycolor === StratairuButtonEnum.Blue && css`
    background: var(--strat-blue);
    color: var(--strat-white);
  `}
  ${props => props.mycolor === StratairuButtonEnum.Yellow && css`
    background: var(--strat-yellow);
    color: var(--strat-blue);
  `}
  border-radius: 20px;
  width: 100%;
  
  &:hover {
    filter: drop-shadow(3px 3px 2px #888);
    transform: translate(1px, -1px);
    // filter: brightness(150%);
    ${props => props.mycolor === StratairuButtonEnum.Blue && css`
        background: var(--strat-blue);
        color: var(--strat-white);
      `}
    ${props => props.mycolor === StratairuButtonEnum.Yellow && css`
        background: var(--strat-yellow);
        color: var(--strat-blue);
      `}
  }
`;
