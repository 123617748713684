/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonUserData,
    JsonUserDataFromJSON,
    JsonUserDataFromJSONTyped,
    JsonUserDataToJSON,
} from './JsonUserData';

/**
 * 
 * @export
 * @interface JsonUser
 */
export interface JsonUser {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonUser
     */
    allowedActions?: Array<JsonUserAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonUser
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUser
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    firstName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUser
     */
    isNewsletter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    newPassword?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    roleCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    sex?: JsonUserSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    status?: JsonUserStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonUser
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUser
     */
    updatedBy?: number;
    /**
     * 
     * @type {JsonUserData}
     * @memberof JsonUser
     */
    userData?: JsonUserData;
    /**
     * 
     * @type {number}
     * @memberof JsonUser
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonUserAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonUserSexEnum {
    M = 'M',
    F = 'F',
    X = 'X'
}/**
* @export
* @enum {string}
*/
export enum JsonUserStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonUserFromJSON(json: any): JsonUser {
    return JsonUserFromJSONTyped(json, false);
}

export function JsonUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'isNewsletter': !exists(json, 'isNewsletter') ? undefined : json['isNewsletter'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'newPassword': !exists(json, 'newPassword') ? undefined : json['newPassword'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'roleCode': !exists(json, 'roleCode') ? undefined : json['roleCode'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userData': !exists(json, 'userData') ? undefined : JsonUserDataFromJSON(json['userData']),
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonUserToJSON(value?: JsonUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'countryCode': value.countryCode,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'email': value.email,
        'firstName': value.firstName,
        'isNewsletter': value.isNewsletter,
        'lastName': value.lastName,
        'newPassword': value.newPassword,
        'phone': value.phone,
        'roleCode': value.roleCode,
        'sex': value.sex,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userData': JsonUserDataToJSON(value.userData),
        'userId': value.userId,
    };
}

