/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonStratEnabler,
    ApiResultOfJsonStratEnablerFromJSON,
    ApiResultOfJsonStratEnablerToJSON,
    ApiResultOfJsonStratEnablerInfo,
    ApiResultOfJsonStratEnablerInfoFromJSON,
    ApiResultOfJsonStratEnablerInfoToJSON,
    ApiResultOfListOfJsonStratEnablerInfo,
    ApiResultOfListOfJsonStratEnablerInfoFromJSON,
    ApiResultOfListOfJsonStratEnablerInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonStratEnabler,
    JsonStratEnablerFromJSON,
    JsonStratEnablerToJSON,
} from '../models';

export interface GetStratEnablerCountUsingGETRequest {
    search?: string;
}

export interface GetStratEnablerInfoUsingGETRequest {
    enablerId: number;
}

export interface GetStratEnablerListUsingGETRequest {
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetStratEnablerUsingGETRequest {
    enablerId: number;
}

export interface SaveStratEnablerUsingPOSTRequest {
    json: JsonStratEnabler;
    testOnly?: boolean;
}

/**
 * 
 */
export class StratEnablerControllerApi extends runtime.BaseAPI {

    /**
     * getStratEnablerCount
     */
    async getStratEnablerCountUsingGETRaw(requestParameters: GetStratEnablerCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-enabler/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getStratEnablerCount
     */
    async getStratEnablerCountUsingGET(requestParameters: GetStratEnablerCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getStratEnablerCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratEnablerInfo
     */
    async getStratEnablerInfoUsingGETRaw(requestParameters: GetStratEnablerInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratEnablerInfo>> {
        if (requestParameters.enablerId === null || requestParameters.enablerId === undefined) {
            throw new runtime.RequiredError('enablerId','Required parameter requestParameters.enablerId was null or undefined when calling getStratEnablerInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-enabler/info/{enablerId}`.replace(`{${"enablerId"}}`, encodeURIComponent(String(requestParameters.enablerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratEnablerInfoFromJSON(jsonValue));
    }

    /**
     * getStratEnablerInfo
     */
    async getStratEnablerInfoUsingGET(requestParameters: GetStratEnablerInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratEnablerInfo> {
        const response = await this.getStratEnablerInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratEnablerList
     */
    async getStratEnablerListUsingGETRaw(requestParameters: GetStratEnablerListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratEnablerInfo>> {
        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-enabler/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratEnablerInfoFromJSON(jsonValue));
    }

    /**
     * getStratEnablerList
     */
    async getStratEnablerListUsingGET(requestParameters: GetStratEnablerListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratEnablerInfo> {
        const response = await this.getStratEnablerListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratEnabler
     */
    async getStratEnablerUsingGETRaw(requestParameters: GetStratEnablerUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratEnabler>> {
        if (requestParameters.enablerId === null || requestParameters.enablerId === undefined) {
            throw new runtime.RequiredError('enablerId','Required parameter requestParameters.enablerId was null or undefined when calling getStratEnablerUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-enabler/{enablerId}`.replace(`{${"enablerId"}}`, encodeURIComponent(String(requestParameters.enablerId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratEnablerFromJSON(jsonValue));
    }

    /**
     * getStratEnabler
     */
    async getStratEnablerUsingGET(requestParameters: GetStratEnablerUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratEnabler> {
        const response = await this.getStratEnablerUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveStratEnabler
     */
    async saveStratEnablerUsingPOSTRaw(requestParameters: SaveStratEnablerUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratEnabler>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveStratEnablerUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/strat-enabler`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonStratEnablerToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratEnablerFromJSON(jsonValue));
    }

    /**
     * saveStratEnabler
     */
    async saveStratEnablerUsingPOST(requestParameters: SaveStratEnablerUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratEnabler> {
        const response = await this.saveStratEnablerUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
