import styles from '../../../assets/styles/layout';
import logoImage from '../../../assets/images/lab/atairulab_logo_white.svg';
import * as React from "react";

interface Props {
    steps: boolean[];
}

const AuditPageStepper = ({ steps }: Props) => {

	return (
        <div className={styles.auditStepper}>
            <div className={styles.infoContent}>
                <img className={styles.logo} alt="Atairu" src={logoImage}/>
                <div className={styles.steps}>
                    { !!steps?.length && steps.map((step: boolean) => <div className={step ? styles.answered : styles.unanswered }></div>) }
                </div>
            </div>
        </div>
	);
}

export default AuditPageStepper;
