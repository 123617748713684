import {useNavigate} from "react-router-dom";
import {Card, Tooltip} from '@mui/material';
import SearchOnePagerForm from "../../../forms/SearchOnePagerForm";
import * as React from "react";
import {useCallback, useState} from "react";
import {searchOnePagers} from "../../../store/strategy";
import {useAppDispatch} from "../../../store";
import ClosableModal from "../../../components/layout/ClosableModal";
import SimpleList from "../../../components/layout/SimpleList";
import {JsonStratOnepagerInfo} from "../../../generated-api";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useAppTranslation} from "../../../services/i18n";
import {addMessage} from "../../../store/localApp";

const SearchOnePager = ({strategyId}: {strategyId: number}) => {

    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [onePagers, setOnePagers] = useState<JsonStratOnepagerInfo[]|undefined>(undefined);

    const onePagerCols = [
        {
            title: 'OnePager',
            style: { width: '40%' },
            renderer: (row: JsonStratOnepagerInfo) => {
                return (
                    <div>
                        { row.firstName + ' ' + row.lastName }
                        <br/><i>{ row.email }</i>
                    </div>
                );
            }
        },
        {
            style: { width: '20%', textAlign: 'right' },
            renderer: (row: JsonStratOnepagerInfo) => {
                return (
                    <>
                        <Tooltip title="Zobrazit">
                            <ArrowForwardIcon color="primary"/>
                        </Tooltip>
                    </>
                );
            }
        }
    ];


    const onSearch = useCallback(async (search: string) => {
        const result = await dispatch(searchOnePagers({search, strategyId}));
        if (!('error' in result) && result.payload.data?.length) {
            setOnePagers(result.payload.data);
        } else {
            dispatch(addMessage({
                severity: 'info',
                title: t('Žádný OnePager neodpovídá zadaným kritériím'),
            }))
            setOnePagers(undefined);
        }
        console.log({ search, result });
    }, [strategyId, t, dispatch]);

    const onCloseResults = useCallback(() => {
        setOnePagers(undefined);
    }, []);

    const onSelect = useCallback((onePager: JsonStratOnepagerInfo) => {
        navigate(`/onepager/${onePager.onepagerId}`)
    }, [navigate]);

    return (
        <>
        <Card sx={{ padding: '0px 10px', marginBottom: '20px', border: '1px solid black' }}>
            <SearchOnePagerForm data={{search: ''}} onSearch={onSearch}/>
        </Card>
        { onePagers &&
            <ClosableModal open={!!onePagers} title={t('Vyhledané OnePagery')} onClose={onCloseResults}>
                <div style={{ minWidth: '500px' }}>
                    <SimpleList
                        cols={onePagerCols}
                        config={{ hideHeader: false }}
                        rows={onePagers || []}
                        action={onSelect}>
                    </SimpleList>
                </div>
            </ClosableModal>
        }
        </>
	);
}

export default SearchOnePager;
