import * as React from "react";
import {Card, CardContent, FormControl, FormLabel} from "@mui/material";

import {JsonGroup, JsonPoll, JsonPollChartTypeEnum, JsonPollResults, JsonRelayResponse} from "../../generated-api";
import styles from '../../assets/styles/relay';
import RelayBarChart from "./charts/RelayBarChart";
import RelayHorizontalBarChart from "./charts/RelayHorizontalBarChart";
import RelaySliderChart from "./charts/RelaySliderChart";

const RelayItemPollResults = (props: { group?: JsonGroup, poll: JsonPoll, results: JsonPollResults, responses: JsonRelayResponse[], groupIndex?: number }) => {
    const { poll } = props;

    let ChartComponent;
    switch (poll.chartType) {
        case JsonPollChartTypeEnum.None:
            // text etc
            return null;

        case JsonPollChartTypeEnum.Barchart:
            ChartComponent = RelayBarChart;
            break;
        case JsonPollChartTypeEnum.BarchartH:
            ChartComponent = RelayHorizontalBarChart;
            break;
        case JsonPollChartTypeEnum.Sliders:
            ChartComponent = RelaySliderChart;
            break;
        default:
            ChartComponent = RelayBarChart;
            break;
    }

    return <Card key={'item-poll-results-' + poll.pollId} variant="elevation" className={styles.relayItemCard}>
        <CardContent>
            <FormControl style={{width: "100%"}}>
                <FormLabel className={styles.formLabel}><strong>{poll.title}</strong></FormLabel>
                <ChartComponent { ...props } />
                { /* <table style={{width: "100%", tableLayout: "fixed", fontFamily: "Roboto,Helvetica,Arial,sans-serif", paddingTop: "15px"}}>
                    <tbody>
                    <tr>
                        {poll.options?.map((option, i) => {
                            const optionTotal = getOptionTotal(option, results);
                            return <td key={i} style={{verticalAlign: "bottom", textAlign: "center", fontSize: "0.8rem"}}>
                                {total ? <div style={{height: BarHeight * (optionTotal / total), background: COLORS[i % COLORS.length]}}></div> : null}
                                <span style={{display: "block", padding: "10px 0"}}>{optionTotal}</span>
                            </td>;
                        })}
                    </tr>
                    <tr>
                        {poll.options?.map((option, i) => {
                            return <td key={i} style={{ verticalAlign: "top" }}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", fontSize: "0.8rem", textAlign: "center" }}>
                                    <strong>{option.title}</strong>
                                    {responses?.find((response) => response.pollOptionId === option.optionId)
                                        && <CheckCircleOutlineIcon sx={{ marginLeft: '5px', fontSize: '1rem' }}/>}
                                </div>
                            </td>;
                        })}
                    </tr>
                    {poll.pollParams && <tr className={styles.scaleLabels}>
						<td colSpan={Math.floor((poll.options?.length || 0) / 2)}>{poll.pollParams?.minLabel}</td>
						<td colSpan={Math.ceil((poll.options?.length || 0) / 2)}>{poll.pollParams?.maxLabel}</td>
					</tr>}
                    </tbody>
                    </table> */}
            </FormControl>
        </CardContent>
    </Card>
};

export default RelayItemPollResults;
