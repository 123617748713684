import {useEffect, useState} from "react";
import {fetchSerieUserContextByCode} from "../store/series";
import {fetchAuthLogout} from "../store/auth";
import {LiveContextInfo} from "../model/live";
import {useAppDispatch} from "../store";
import {useParams} from "react-router-dom";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../store/selectors";
import {useNavigate} from "react-router";

export function useLiveContext(needLogged: boolean) {

    const [context, setContext] = useState<LiveContextInfo|undefined>(undefined);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const { code } = useParams();

    const loggedUser = useSelector(selectAuthUser);

    useEffect(() => {
        // console.log({ code, needLogged, loggedUser: JSON.stringify(loggedUser), dispatch });
        if (code && (!needLogged || loggedUser?.userId)) {
            dispatch(fetchSerieUserContextByCode(code))
                .then(result => {
                    const userId = (result.payload as any)?.user?.userId;
                    if (needLogged) {
                        if (userId) {
                            if (userId !== loggedUser.userId) {
                                dispatch(fetchAuthLogout());
                            }
                            setContext(result.payload as LiveContextInfo)
                        }
                    } else {
                        setContext(result.payload as LiveContextInfo);
                    }
                    if (!userId) {
                        navigate(`/live/unregistered`);
                    }
                })
        }
    }, [dispatch, code, loggedUser, needLogged, navigate]);

    return [context];

}
