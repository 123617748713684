import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './app/App';
import {AppProviders} from './app/AppProviders';
import reportWebVitals from './reportWebVitals';
import configureAppStore from './store/configureStore';

export const store = configureAppStore();

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);
root.render(
		<AppProviders store={store}>
			<App />
		</AppProviders>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
