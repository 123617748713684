import {Alert, Grid} from '@mui/material';
import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {
    JsonStratCommentInfo,
    JsonStratCommentStatusEnum,
    JsonStratOnepager
} from "../../../generated-api";
import {useAppDispatch} from "../../../store";
import {fetchComments, saveComment} from "../../../store/strategy";
import {selectAuthUser, selectStrategyOnePagerComments} from "../../../store/selectors";
import {useSelector} from "react-redux";
import styles from "../../../assets/styles/stratairu-onepager.module.css";
import {OnepagerEntityIndex, OnepagerEntityType} from "../../../model/onepager";
import {CommonActionsType} from "../../../model/form";
import StratairuOnePagerActionsContainer from "./StratairuOnePagerActionsContainer";
import StratairuOnePagerEditableText from "./StratairuOnePagerEditableText";
import {Nl2BrText} from "../../../components/layout/Nl2BrText";
import CheckIcon from "@mui/icons-material/Check";

interface CommentItemProps {
    comment: JsonStratCommentInfo
}

const CommentItem = ({ comment }: CommentItemProps) => {
    return <div className={styles.commentContainer}>
        <div className={styles.commentHeader}>{ `${comment.authorFirstName} ${comment.authorLastName }` }<CheckIcon/></div>
        <div className={styles.commentContent}><Nl2BrText text={comment.description!} /></div>
    </div>
}

interface Props {
    onePager: JsonStratOnepager,
}

const CommentsContainer = ({ onePager }: Props) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const comments = useSelector(selectStrategyOnePagerComments);
    const user = useSelector(selectAuthUser);

    const [addComment, setAddComment] = useState<boolean>(false);

    useEffect(() => {
        if (onePager.onepagerId) {
            dispatch(fetchComments(onePager.onepagerId));
        }
    }, [onePager, dispatch])

    const handleSaveComment = useCallback(async (text: string) => {
        if (onePager.onepagerId && text) {
            const result = await dispatch(saveComment({ onepagerId: onePager.onepagerId, description: text }));
            if (!('error' in result)) {
                dispatch(fetchComments(onePager.onepagerId));
                setAddComment(false);
            }
        }
    }, [dispatch, onePager])

    const handleDeleteComment = useCallback(async (commentId: number) => {
        if (onePager.onepagerId && commentId) {
            const result = await dispatch(saveComment({ commentId, onepagerId: onePager.onepagerId, status: JsonStratCommentStatusEnum.Deleted }));
            if (!('error' in result)) {
                dispatch(fetchComments(onePager.onepagerId));
            }
        }
    }, [dispatch, onePager])

    const handleAction = useCallback(async (action: CommonActionsType, index: OnepagerEntityIndex) => {
        console.log({ action, index });
        switch (action) {
            case CommonActionsType.Create:
                setAddComment(true);
                break;
            case CommonActionsType.Delete:
                if (index.entityIndex) {
                    await handleDeleteComment(index.entityIndex);
                }
                break;
            case CommonActionsType.Cancel:
                setAddComment(false);
                break;
            default:
                break;
        }
    }, [handleDeleteComment]);

    return (
        <Grid item xs={12} md={3}>
            <div>
                <StratairuOnePagerActionsContainer index={{ entity: OnepagerEntityType.Comment }} actions={ addComment ? [CommonActionsType.Cancel] : [CommonActionsType.Create] } onAction={handleAction} alwaysVisible>
                    <div className={styles.commentsContainer}>
                        { !comments?.length && <Alert severity="info">{ t('onePager.messages.noComments') }</Alert> }
                        { comments?.map((comment: JsonStratCommentInfo) =>
                            <StratairuOnePagerActionsContainer index={{ entity: OnepagerEntityType.Comment, entityIndex: comment.commentId }} actions={ comment.createdBy === user?.userId ? [CommonActionsType.Delete] : []} onAction={handleAction}>
                                <CommentItem comment={comment} />
                            </StratairuOnePagerActionsContainer>
                        )}
                        { addComment && <div className={styles.commentContainer}>
                            <div className={styles.commentHeader}>{ `${user?.firstName} ${user.lastName }` }</div>
                            <div className={styles.commentContent}>
                                <StratairuOnePagerEditableText sx={{ border: '0px' }} index={{ entity: OnepagerEntityType.Comment }} value="" onConfirm={(index, text) => handleSaveComment(text)}/>
                            </div>
                        </div>
                        }
                    </div>
                </StratairuOnePagerActionsContainer>
            </div>
        </Grid>
    );

}

export default CommentsContainer;
