import {combineReducers} from 'redux';
import {configureStore} from '@reduxjs/toolkit'
import {apiErrorMiddlewareFactory} from '../services/apiErrorMiddleware';
import {apiUnautorizedMiddlewareFactory} from '../services/apiUnautorizedMiddleware';
import {fetchAuthLogout} from './auth';
import {reducers} from "./index";

export default function configureAppStore() {
    return configureStore({
        reducer: combineReducers({
            ...reducers,
        }), middleware: getDefaultMiddleware =>
            getDefaultMiddleware({serializableCheck: false})
                .prepend(
                    // correctly typed middlewares can just be used
                    apiErrorMiddlewareFactory(),
                    apiUnautorizedMiddlewareFactory(fetchAuthLogout), //as Middleware<(action: PayloadAction<{ error: SerializedError }>) => PayloadAction<{ error: SerializedError }>, RootState>,
                    // you can also type middlewares manually
                    // untypedMiddleware as Middleware<
                    // 	(action: Action<'specialAction'>) => number,
                    // 	RootState
                    // 	>
                )
    });
}
