import {Grid} from '@mui/material';
import {Formik, FormikErrors} from 'formik';
import TextFormfield from '../components/form/TextFormfield';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';
import SearchIcon from '@mui/icons-material/Search';

export interface SearchOnePagerFormData {
    search: string;
}

interface Props extends FormProps<SearchOnePagerFormData> {
    onSearch: (search: string) => Promise<void>;
}

const SearchOnePagerForm = ({ onSearch }: Props) => {

    const t = useAppTranslation();

    const validate = (values: SearchOnePagerFormData) => {
        let errors = {} as FormikErrors<SearchOnePagerFormData>;
        /*if (!values.title) {
            errors['title'] = t('Název episody je povinný.');
        }*/
        return errors;
    }

	return (
        <Formik
            initialValues={{ search: ''}}
            enableReinitialize={true}
            validate={validate}
            onSubmit={(values: any) => {
                // console.log({ values });
            }}
        >{props => (
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
                <TextFormfield name="search" label={t('Vyhledat OnePager (podle jména nebo názvu týmu)')} onEnter={() => onSearch && onSearch(props.values.search)}></TextFormfield>
                <SearchIcon style={{ cursor: 'pointer' }} onClick={() => onSearch && onSearch(props.values.search)}/>
            </Grid>)}
		</Formik>
	);
}

export default SearchOnePagerForm;
