/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonCompanyInfo,
    JsonCompanyInfoFromJSON,
    JsonCompanyInfoFromJSONTyped,
    JsonCompanyInfoToJSON,
} from './JsonCompanyInfo';
import {
    JsonConfiguration,
    JsonConfigurationFromJSON,
    JsonConfigurationFromJSONTyped,
    JsonConfigurationToJSON,
} from './JsonConfiguration';
import {
    JsonSerieInfo,
    JsonSerieInfoFromJSON,
    JsonSerieInfoFromJSONTyped,
    JsonSerieInfoToJSON,
} from './JsonSerieInfo';
import {
    JsonStrategyInfo,
    JsonStrategyInfoFromJSON,
    JsonStrategyInfoFromJSONTyped,
    JsonStrategyInfoToJSON,
} from './JsonStrategyInfo';
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserFromJSONTyped,
    JsonUserToJSON,
} from './JsonUser';

/**
 * 
 * @export
 * @interface JsonAuthUserInfo
 */
export interface JsonAuthUserInfo {
    /**
     * 
     * @type {Array<JsonCompanyInfo>}
     * @memberof JsonAuthUserInfo
     */
    companies?: Array<JsonCompanyInfo>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof JsonAuthUserInfo
     */
    companyRoles?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {JsonConfiguration}
     * @memberof JsonAuthUserInfo
     */
    _configuration?: JsonConfiguration;
    /**
     * 
     * @type {Array<JsonSerieInfo>}
     * @memberof JsonAuthUserInfo
     */
    series?: Array<JsonSerieInfo>;
    /**
     * 
     * @type {Array<JsonStrategyInfo>}
     * @memberof JsonAuthUserInfo
     */
    strategies?: Array<JsonStrategyInfo>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof JsonAuthUserInfo
     */
    strategyRoles?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {JsonUser}
     * @memberof JsonAuthUserInfo
     */
    user?: JsonUser;
}

export function JsonAuthUserInfoFromJSON(json: any): JsonAuthUserInfo {
    return JsonAuthUserInfoFromJSONTyped(json, false);
}

export function JsonAuthUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonAuthUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'companies': !exists(json, 'companies') ? undefined : ((json['companies'] as Array<any>).map(JsonCompanyInfoFromJSON)),
        'companyRoles': !exists(json, 'companyRoles') ? undefined : json['companyRoles'],
        '_configuration': !exists(json, 'configuration') ? undefined : JsonConfigurationFromJSON(json['configuration']),
        'series': !exists(json, 'series') ? undefined : ((json['series'] as Array<any>).map(JsonSerieInfoFromJSON)),
        'strategies': !exists(json, 'strategies') ? undefined : ((json['strategies'] as Array<any>).map(JsonStrategyInfoFromJSON)),
        'strategyRoles': !exists(json, 'strategyRoles') ? undefined : json['strategyRoles'],
        'user': !exists(json, 'user') ? undefined : JsonUserFromJSON(json['user']),
    };
}

export function JsonAuthUserInfoToJSON(value?: JsonAuthUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'companies': value.companies === undefined ? undefined : ((value.companies as Array<any>).map(JsonCompanyInfoToJSON)),
        'companyRoles': value.companyRoles,
        'configuration': JsonConfigurationToJSON(value._configuration),
        'series': value.series === undefined ? undefined : ((value.series as Array<any>).map(JsonSerieInfoToJSON)),
        'strategies': value.strategies === undefined ? undefined : ((value.strategies as Array<any>).map(JsonStrategyInfoToJSON)),
        'strategyRoles': value.strategyRoles,
        'user': JsonUserToJSON(value.user),
    };
}

