import { useCallback, useState } from "react";
import { Grid } from "@mui/material";

import {JsonGroup, JsonPoll, JsonRelayItemItemTypeEnum} from "../../generated-api";
import FormContainer from "../form/FormContainer";
import TextFormfield from "../form/TextFormfield";
import { useAppTranslation } from "../../services/i18n";
import { FormikErrors } from "formik";
import EpisodePollsList from "../../pages/admin/editEpisodePage/components/EpisodePollsList";
import EditActionButtons from "./EditActionButtons";
import PreviewGroup from "./PreviewGroup";

interface Props {
    group: JsonGroup;
    pollList: JsonPoll[];
    pollAction: (poll: JsonPoll, action: string) => void;
    onSave?: (group: JsonGroup) => void;
    onDelete?: (group: JsonGroup) => void;
    onCancel?: () => void;
    onAdd: (group: JsonGroup) => void;
}

const EditGroup = ({ group, pollList, pollAction, onSave, onDelete, onCancel, onAdd }: Props) => {

    const t = useAppTranslation();
    const [editMode, setEditMode] = useState<boolean>(true);

    const handleSave = useCallback((data: JsonGroup) => {
        onSave && onSave(data);
    }, [onSave]);

    const validate = (values: JsonGroup) => {

        let errors = {} as FormikErrors<JsonGroup>;

        if (!values.title) {
            errors['title'] = t('Název skupiny musí být uveden.');
        }
        if (!values.orderNo) {
            errors['orderNo'] = t('Pořadí skupiny musí být uvedeno.');
        }

        return errors;
    }
    
    return (
        <>
            { group && 
            <FormContainer 
                title={t('Skupina')}
                data={group} 
                validate={validate} 
                onSave={(values) => { handleSave({ ...values }) }} 
                headerAction={<EditActionButtons onEdit={() => setEditMode(true)} onPreview={() => setEditMode(false)} onAdd={() => onAdd(group)} onDelete={() => onDelete && onDelete(group)}/>}
                onCancel={onCancel}
                cardLayout>
                    { /* <div>{  JSON.stringify(group, null, 2) }</div>
                    <div>{  JSON.stringify(pollList, null, 2) }</div> */ }
                    { editMode && 
                        <>
                        <Grid item xs={8}>
                            <TextFormfield name="title" label={t('Název skupiny')} maxlength={300}></TextFormfield>
                        </Grid>
                        <Grid item xs={4}>
                            <TextFormfield name="orderNo" label={t('Pořadí')}></TextFormfield>
                        </Grid>
                        <Grid item xs={12}>
                            <EpisodePollsList rows={pollList} action={pollAction}></EpisodePollsList>
                        </Grid>
                        </>
                    }
                    { !editMode && 
                        <>
                            <PreviewGroup items={pollList} itemType={JsonRelayItemItemTypeEnum.PollQuestion}/>
                            <PreviewGroup items={pollList} itemType={JsonRelayItemItemTypeEnum.PollResults}/>
                        </>
                    }
            </FormContainer>
            }
        </>
      );
}

export default EditGroup;
