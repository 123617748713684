import * as React from 'react';
import {useTranslation} from "react-i18next";
import {useCallback} from "react";
import {useSelector} from "react-redux";
import {useNavigate} from 'react-router-dom';

import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import {AppBar} from '@mui/material';

import {JsonAuthUserInfo} from '../../generated-api';
import { fetchAuthLogout } from '../../store/auth';
import { useAppDispatch } from '../../store';
import PoplistValue from '../form/PoplistValue';
import {selectAuthIsAdmin, selectAuthStrategies, selectStrategyContext} from "../../store/selectors";
import {useAppTranslation} from "../../services/i18n";
import styles from '../../assets/styles/layout';

import atairuLabLogo from '../../assets/images/lab/atairulab_logo_white.svg';

interface PageLink {
  title: string;
  link: string;
}

interface Props {
  user?: JsonAuthUserInfo['user'];
}

const AppHeader = ({ user }: Props) => {

  const { i18n } = useTranslation();
  const t = useAppTranslation();

  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isAdmin = useSelector(selectAuthIsAdmin);
  const strategies = useSelector(selectAuthStrategies);
  const context = useSelector(selectStrategyContext);

  const settings = [
    { title: 'Logout', action: () => { dispatch(fetchAuthLogout()); navigate('/'); }}
  ];

    const pages: PageLink[] = [
        isAdmin && { title: 'Společnosti', link: '/companies' },
        isAdmin && { title: 'Uživatelé', link: '/users' },
        isAdmin && { title: 'Seriály', link: '/series' },
        isAdmin && { title: 'Vysílání', link: '/relays' },
        isAdmin && { title: 'Strategie', link: '/strategies' },
        !!strategies?.length && { title: 'Moje Strategie', link: '/strategy/selection' }
    ].filter(page => !!page).map(page => page as PageLink);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleSelectMenu = (page: PageLink) => {
    handleCloseNavMenu();
    navigate(page.link);
  }

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const switchLang = useCallback(() => {
      const newLang = i18n.language === 'cs' ? 'en' : 'cs';
      i18n.changeLanguage(newLang);
  }, [i18n])

  const contextStyle = {
      backgroundColor: context?.company?.companyData?.backgroundColor || undefined,
      color: context?.company?.companyData?.textColor || 'white'
  }

  // TODO: update for other portals
  const logo = () => {
      return atairuLabLogo;
  }

  return (
    <AppBar position="static" style={contextStyle}>
      <Container maxWidth="xl">
          <Toolbar disableGutters>
              <img style={{ height: '20px', marginRight: '20px' }} alt="Atairu" src={logo()}/>
              <Box sx={{flexGrow: 1, display: {xs: 'flex', md: 'none'}}}>
                  <IconButton
                      size="large"
                      aria-label="account of current user"
                      aria-controls="menu-appbar"
                      aria-haspopup="true"
                      onClick={handleOpenNavMenu}
                      color="inherit"
                  >
                  </IconButton>
                  <Menu
                      id="menu-appbar"
                      anchorEl={anchorElNav}
                      anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                      }}
                      open={Boolean(anchorElNav)}
                      onClose={handleCloseNavMenu}
                      sx={{
                          display: {xs: 'block', md: 'none'},
                      }}
                  >
                      {pages.map((page) => (
                          <MenuItem key={page.title} onClick={() => {
                              handleSelectMenu(page)
                          }}>
                              <Typography textAlign="center">[{page.title}]</Typography>
                          </MenuItem>
                      ))}
                  </Menu>
              </Box>
              <Typography
                  variant="h5"
                  noWrap
                  component="a"
                  href=""
                  sx={{
                      mr: 2,
                      display: {xs: 'flex', md: 'none'},
                      flexGrow: 1,
                      fontFamily: 'monospace',
                      fontWeight: 700,
                      letterSpacing: '.3rem',
                      color: 'inherit',
                      textDecoration: 'none',
                  }}
              >
                  AtairuLAB
              </Typography>
              <Box sx={{flexGrow: 1, display: {xs: 'none', md: 'flex'}}}>
                  {pages.map((page) => (
                      <Button
                          key={page.title}
                          onClick={() => {
                              handleSelectMenu(page)
                          }}
                          sx={{my: 2, color: contextStyle.color, display: 'block'}}
                      >
                          {page.title}
                      </Button>
                  ))}
              </Box>

              <Box>
                  <div className={styles.langSwitch} onClick={switchLang}
                       style={{backgroundColor: contextStyle.color}}>{t('lang.' + i18n.language)}</div>
              </Box>

              <Box sx={{flexGrow: 0, display: 'flex', alignItems: 'center'}}>
                  {user && <div className={styles.loggedUser}>
                      {user.firstName}&nbsp;{user.lastName}
                      <br/><span style={{fontSize: '0.8rem', fontWeight: '400'}}><PoplistValue value={user.roleCode}
                                                                                               poplist="roleCode"/></span>
                  </div>}
                  <Tooltip title="Open settings">
                      <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                          <Avatar alt="Remy Sharp" src="/static/images/avatar/2.jpg"/>
                      </IconButton>
                  </Tooltip>
                  <Menu
                      sx={{mt: '45px'}}
                      id="menu-appbar"
                      anchorEl={anchorElUser}
                      anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      keepMounted
                      transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                      }}
                      open={Boolean(anchorElUser)}
                      onClose={handleCloseUserMenu}
                  >
                      {settings.map((setting) => (
                          <MenuItem key={setting.title} onClick={() => setting.action()}>
                              <Typography textAlign="center">{setting.title}</Typography>
                          </MenuItem>
                      ))}

                      { /* <pre>{JSON.stringify(user, null, 2)}</pre> */}
                  </Menu>
              </Box>
          </Toolbar>
      </Container>
    </AppBar>
  );
};

export default AppHeader;
