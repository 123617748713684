import {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Card, CardContent, Container, Grid} from '@mui/material';

import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import RegistrationForm, {RegistrationFormData} from '../../forms/RegistrationForm';
import {
    JsonCompanyDepartment, JsonCompanyDuration, JsonCompanyPosition,
    JsonSerieSerieTypeEnum,
    JsonSerieUserRegistrationContext,
    JsonSerieUserRegistrationResponse, JsonSerieUserRegistrationResponseRegistrationResultTypeEnum
} from '../../generated-api';
import {fetchRegistrationInfo, fetchSerieUser, saveRegistration} from '../../store/auth';
import {registrationFactory} from '../../model/factories';
import { addMessage } from '../../store/localApp';
import styles from '../../assets/styles/layout';
import AtairuHeader from '../../components/layout/AtairuCardHeader';
import { fetchCodebooks } from '../../store/codebooks';
import {useTranslation} from "react-i18next";

const RegistrationPage = () => {
  const t = useAppTranslation();
  const { i18n } = useTranslation()
  const dispatch = useAppDispatch();
  // const navigate = useNavigate();
  const [context, setContext] = useState<JsonSerieUserRegistrationContext>();
  const [sendResult, setSendResult] = useState<JsonSerieUserRegistrationResponse>();
  const [registrationData, setRegistrationData] = useState<RegistrationFormData | null>(null);

  const { code, clientGuid } = useParams();

  useEffect(() => {
    if (code) {
      dispatch(fetchCodebooks())
        .then(() => dispatch(fetchRegistrationInfo(code)))
        .then((result) => {
            if (result && !('error' in result)) {
                setRegistrationData(registrationFactory());
                setContext((result.payload as any).data);
                // set language
                const lang = (result.payload as any).data?.serie?.lang || 'cs';
                i18n.changeLanguage(lang);
            }
        })
    }
  }, [dispatch, code, i18n])



    useEffect(() => {
        if (clientGuid) {
            dispatch(fetchCodebooks())
                .then(() => dispatch(fetchSerieUser(clientGuid)))
                .then((result) => {
                    if (result && !('error' in result)) {
                        setRegistrationData({...registrationFactory(), ...result.payload.data?.user});
                        setContext(result.payload.data?.context);
                    }
                })
        }
    }, [dispatch, clientGuid])

    const handleSave = useCallback(async function (data: RegistrationFormData) {
      
      console.log({ data });

      if (data.companyDepartmentId) {
        data.departmentId = context?.companyDepartments?.find((item: JsonCompanyDepartment) => item.companyDepartmentId === data.companyDepartmentId)?.departmentId || 0;
      }
      if (data.companyPositionId) {
        data.positionId = context?.companyPositions?.find((item: JsonCompanyPosition) => item.companyPositionId === data.companyPositionId)?.positionId || 0;
      }
      if (data.companyDurationId) {
        data.durationId = context?.companyDurations?.find((item: JsonCompanyDuration) => item.companyDurationId === data.companyDurationId)?.durationId || 0;
      }

      if (!code && !clientGuid) {
        return;
      }

      const result = await dispatch(saveRegistration({ registrationData: data, serieGuid: code, clientGuid }));

      if ('error' in result) {
        dispatch(addMessage({
          severity: 'error',
          title: t('Došlo k chybě při registraci ke kurzu.'),
        }));
        return;
      }

      console.log({ result, context });

      if (context?.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast) {
        // change to info page with sent email
        // EMAIL_SENT - email odeslán
        // USER_ALREADY_CONFIRMED - uzivatel by již potvrzen
        setSendResult((result.payload as any).data);
      }

      if (context?.serie?.serieType === JsonSerieSerieTypeEnum.Audit) {
        // redirect to audit page
        const redirectUrl = (result.payload.data as JsonSerieUserRegistrationResponse).redirectUrl as string;
        console.log({ redirectUrl });

        if (['localhost'].includes(window.location.hostname)) {
          const tempRedirectUrl = redirectUrl
                .replace('https://atairu.codito.cz', 'http://localhost:3000')
                .replace('https://app.atairulab.com', 'http://localhost:3000')
                .replace('https://app.atairuleadership.tv', 'http://localhost:3000')
          console.log({ tempRedirectUrl });
          document.location = tempRedirectUrl;
          return;
        }

        document.location = redirectUrl;
      }

    }, [context, dispatch, code, clientGuid, t])

  return (
    <div className={styles.centeredContent} style={{ backgroundColor: 'var(--audit-white)' }}>
      { context && registrationData && <Container>
        <Grid container spacing={2}>

            <Grid item xs={0} md={4}>
            </Grid>
            <Grid item xs={12} md={4}>
            </Grid>
            <Grid item xs={0} md={4}>
            </Grid>

            <Grid item xs={0} md={4}>
            </Grid>
            <Grid item xs={12} md={4}>
              { !sendResult &&
                <>
                <AtairuHeader type={context.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast ? 'tv' : 'basic'}/>
                <RegistrationForm context={context}
                                  data={registrationData}
                                  onSave={handleSave}
                                  saveButtonTitle={t(context.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast ? 'Registrovat' : 'buttons.openQuestionnaire')}
                                  cardLayout>
                  { context.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast &&
                    <h2 style={{textAlign:'center', color: 'var(--audit-blue)', fontWeight: '200', fontSize: '20px' }}>{t('Registrace do')}<br/>{t('ATAIRU Leadership TV')}</h2>
                  }
                  { context.serie?.serieType === JsonSerieSerieTypeEnum.Audit &&
                    <h2 style={{textAlign:'center', color: 'var(--audit-blue)', fontWeight: '200', fontSize: '20px' }}>{t('RegistrationForm.title.audit')}</h2>
                  }
                  {/*
                  <div>{t('Společnost')}: <strong>{ context.company?.companyName }</strong></div>
                  <div>{t('Název seriálu')}: <strong>{ context.serie?.title }</strong></div>
                  */}
                  <div style={{ marginTop: '10px', fontStyle: 'italic'}}>{ context.serie?.description }</div>
                </RegistrationForm>
                </> 
              }
              { sendResult && 
                <>
                  <AtairuHeader type={context.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast ? 'tv' : 'basic'}/>
                  <Card>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '0px 20px 10px 20px' }}>
                          <h2 style={{ textAlign: 'center' }}>{t('Potvrzení registrace do ATAIRU LEADERSHIP TV')}</h2>
                          <div>
                            <p style={{ lineHeight: '25px' }}>
                              {t('Dobrý den,')}<br/><br/>
                              { sendResult?.registrationResultType === JsonSerieUserRegistrationResponseRegistrationResultTypeEnum.UserCreated &&
                                <span>{t('vaše registrace proběhla úspěšně. Uložte si Vaše přihlašovací údaje pro pozdější použití. Přihlašovací jméno je Váš email použitý při registraci.')}</span>
                              }
                              { sendResult?.registrationResultType === JsonSerieUserRegistrationResponseRegistrationResultTypeEnum.AlreadyRegistered &&
                                  <span>{t('na toto školení jste již zaregistrován. Link naleznete v pozvánce v kalendáři.')}</span>
                              }
                              <br/><br/>{t('Těšíme se na Vás.')}
                              <br/>{t('Váš tým ATAIRU.')}
                            </p>
                          </div>
                        </div>  
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                </>
              }
            </Grid>
            <Grid item xs={0} md={4}>
            </Grid>
				</Grid>
      </Container> }
    </div>
	);
}

export default RegistrationPage;
