/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonCompanyBranchInfo
 */
export interface JsonCompanyBranchInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyBranchInfo
     */
    allowedActions?: Array<JsonCompanyBranchInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyBranchInfo
     */
    companyBranchId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyBranchInfo
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyBranchInfo
     */
    status?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyBranchInfo
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyBranchInfo
     */
    userCount?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyBranchInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonCompanyBranchInfoFromJSON(json: any): JsonCompanyBranchInfo {
    return JsonCompanyBranchInfoFromJSONTyped(json, false);
}

export function JsonCompanyBranchInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyBranchInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyBranchId': !exists(json, 'companyBranchId') ? undefined : json['companyBranchId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function JsonCompanyBranchInfoToJSON(value?: JsonCompanyBranchInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyBranchId': value.companyBranchId,
        'companyId': value.companyId,
        'status': value.status,
        'title': value.title,
        'userCount': value.userCount,
    };
}

