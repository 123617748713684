import * as React from 'react';
import {PropsWithChildren} from "react";
import {useAppTranslation} from "../../../services/i18n";
import StratairuMenu from "./StratairuMenu";
import styles from '../../../assets/styles/stratairu.module.css';
interface Props {
}

const StratairuPage= ({ children }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();

	return (
        <div className={styles.pageContainer}>
            <StratairuMenu />
            <div style={{ width: '100%' }}>{ children }</div>
        </div>
	);
}

export default StratairuPage;
