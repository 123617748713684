import FormModal from "../components/form/FormModal";
import CompanySerieForm, { CompanySerieFormData } from "../forms/CompanySerieForm";
import { FormProps } from "../model/form";
import { useAppTranslation } from "../services/i18n";

const CompanySerieModal = (props: FormProps<CompanySerieFormData>) => {

  const t = useAppTranslation();

  return (
    <FormModal title={t('Přidání seriálu společnosti')} open={!!props?.data}>
      <CompanySerieForm { ...props }></CompanySerieForm>
    </FormModal>
	);
}

export default CompanySerieModal;
