/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonCompanyDepartmentInfo
 */
export interface JsonCompanyDepartmentInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyDepartmentInfo
     */
    allowedActions?: Array<JsonCompanyDepartmentInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartmentInfo
     */
    companyDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartmentInfo
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartmentInfo
     */
    departmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyDepartmentInfo
     */
    status?: JsonCompanyDepartmentInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyDepartmentInfo
     */
    title?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartmentInfo
     */
    userCount?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyDepartmentInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonCompanyDepartmentInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonCompanyDepartmentInfoFromJSON(json: any): JsonCompanyDepartmentInfo {
    return JsonCompanyDepartmentInfoFromJSONTyped(json, false);
}

export function JsonCompanyDepartmentInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyDepartmentInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyDepartmentId': !exists(json, 'companyDepartmentId') ? undefined : json['companyDepartmentId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function JsonCompanyDepartmentInfoToJSON(value?: JsonCompanyDepartmentInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyDepartmentId': value.companyDepartmentId,
        'companyId': value.companyId,
        'departmentId': value.departmentId,
        'status': value.status,
        'title': value.title,
        'userCount': value.userCount,
    };
}

