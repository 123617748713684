/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPollParams
 */
export interface JsonPollParams {
    /**
     * 
     * @type {boolean}
     * @memberof JsonPollParams
     */
    allowMultipleResponses?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonPollParams
     */
    maxLabel?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPollParams
     */
    minLabel?: string;
}

export function JsonPollParamsFromJSON(json: any): JsonPollParams {
    return JsonPollParamsFromJSONTyped(json, false);
}

export function JsonPollParamsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPollParams {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowMultipleResponses': !exists(json, 'allowMultipleResponses') ? undefined : json['allowMultipleResponses'],
        'maxLabel': !exists(json, 'maxLabel') ? undefined : json['maxLabel'],
        'minLabel': !exists(json, 'minLabel') ? undefined : json['minLabel'],
    };
}

export function JsonPollParamsToJSON(value?: JsonPollParams | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowMultipleResponses': value.allowMultipleResponses,
        'maxLabel': value.maxLabel,
        'minLabel': value.minLabel,
    };
}

