import React, {PropsWithChildren, useCallback, useMemo} from 'react';
import {Container, Grid} from '@mui/material';
import styles from '../../../assets/styles/stratairu-onepager.module.css';
import {OnepagerEntityIndex, OnepagerEntityType} from "../../../model/onepager";
import {CommonActionsType} from "../../../model/form";

import StratairuOnePagerActionsContainer from "./StratairuOnePagerActionsContainer";
import StratairuOnePagerEditableText from "./StratairuOnePagerEditableText";
import {
    JsonStratCvb,
    JsonStratCvbStatusEnum,
    JsonStratEnabler,
    JsonStratEnablerStatusEnum,
    JsonStratInitiative,
    JsonStratInitiativeStatusEnum,
    JsonStratOnepagerInfo,
    JsonStratPillar,
    JsonStratPillarInfo,
    JsonStratPillarInfoStatusEnum,
    JsonStratSuccessLook,
    JsonStratSuccessLookStatusEnum,
    JsonStratTeam,
    JsonStratTeamStatusEnum
} from "../../../generated-api";
import {
    OnePagerHeaderTitleColor,
    OnePagerRowConfig,
    OnePagerRowSource,
    PillarOrder
} from "../../../helpers/onepagerContent";
import {useAppTranslation} from "../../../services/i18n";
import classnames from "classnames";
import {
    MAX_ENABLER_TEAMS,
    MAX_INITIATIVE_SUCCESS_LOOKS,
    MAX_INITIATIVE_TEAMS,
    MAX_INITIATIVES,
    MAX_SUCCESS_LOOKS
} from "../../../pages/stratairu/StratairuOnePagerPage";
import StratairuOnePagerParentPillarInfo from "./StratairuOnePagerParentPillarInfo";
import StratairuOnePagerPicos from "./StratairuOnePagerPicos";
import StratairuOnePagerTeam from "./StratairuOnePagerTeam";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../../../store/selectors";

interface Props extends OnePagerRowConfig {
    selfOnepager: JsonStratOnepagerInfo;
    parentOnepager: JsonStratOnepagerInfo;
    rootOnepager: JsonStratOnepagerInfo;
    onAction?: (action: CommonActionsType, index: OnepagerEntityIndex, source: OnePagerRowSource) => void;
}

const StratairuOnePagerComplexRow = ({ source, title, titleColor, entity, rowActions, actions, readonly, children, multipleIndex, pillarOrder, onAction, selfOnepager, parentOnepager, rootOnepager, subteamsCount }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();
    const index= useMemo(() => ({ entity }), [entity]);
    const user = useSelector(selectAuthUser);

    const handleAction = useCallback((action: CommonActionsType, index: OnepagerEntityIndex) => {
        if (onAction) {
            onAction(action, index, source);
        }
    }, [onAction, source])

    let sourceOnePager: JsonStratOnepagerInfo | undefined = undefined;
    switch (source) {
        case OnePagerRowSource.PARENT:
            sourceOnePager = parentOnepager;
            break;
        case OnePagerRowSource.SELF:
            sourceOnePager = selfOnepager;
            break;
        case OnePagerRowSource.ROOT:
            sourceOnePager = rootOnepager;
            break;
    }

    const addBottomMargin = [OnepagerEntityType.Purpose, OnepagerEntityType.Ambition, OnepagerEntityType.Enabler, OnepagerEntityType.CVB].includes(entity);
    const editableDraft = titleColor === OnePagerHeaderTitleColor.DARK;
    const isRootOnePager = selfOnepager.onepagerId === rootOnepager.onepagerId;

    return (
      <Container>
        <Grid container style={{ marginTop: addBottomMargin ? '10px' : undefined }}>
            <Grid item md={4} xs={12}
                  className={classnames(title && titleColor ? styles.rowHeader : styles.noRowHeader, title ? (titleColor === OnePagerHeaderTitleColor.DARK ? styles.dark : styles.light) : undefined) }
                  style={{ margin: !addBottomMargin ? '5px 0px' : undefined }}
                >
                <StratairuOnePagerActionsContainer index={index} actions={rowActions} onAction={handleAction} alwaysVisible>
                    { title && <div className={styles.title}>
                        { t(title!) }
                        { /* hasTooltip && <span style={{ position: 'relative' }}>
                            <HelpIcon style={{ cursor: 'pointer', paddingTop: '0px', position: 'absolute', top: '-3px', left: '5px' }} onClick={() => handleRowAction(CommonActionsType.Help)}/>
                        </span> */ }
                    </div> }
                </StratairuOnePagerActionsContainer>
            </Grid>
          <Grid item md={8} xs={12} className={styles.rowContent} style={{ paddingLeft: '10px' }}>

                  <>
                  { (() => {
                      if ([OnepagerEntityType.Purpose, OnepagerEntityType.Ambition].includes(index.entity)) {
                        let value = undefined;
                        switch (index.entity) {
                            case OnepagerEntityType.Purpose:
                                value = sourceOnePager!.purpose;
                                break;
                            case OnepagerEntityType.Ambition:
                                value = sourceOnePager!.ambition;
                                break;
                        }
                        return (
                            <StratairuOnePagerActionsContainer index={ index } actions={actions} onAction={handleAction}>
                                <StratairuOnePagerEditableText index={ index } value={ value } readonly={ readonly } editableDraft={editableDraft}/>
                            </StratairuOnePagerActionsContainer>
                        );
                      }
                      if (index.entity === OnepagerEntityType.Enabler) {
                          return <div style={{ width: '100%' }}>{ sourceOnePager.enablers?.map((enabler: JsonStratEnabler, enablerIndex: number) => {
                              const index = { entity: OnepagerEntityType.Enabler, entityIndex: enablerIndex };
                              const actions = [];
                              if (!readonly) {
                                  actions.push(CommonActionsType.Delete);
                                  actions.push(CommonActionsType.Edit);
                                  actions.push(CommonActionsType.Down);
                              }
                              let order = 0;
                              let firstTeam = true;
                              let addTeamIndex = enabler?.teams?.reduce((acc: number, team: JsonStratTeam, index: number) => team.status === JsonStratTeamStatusEnum.Active ? index : acc, -1);
                              let hasAddTeamActionAvailable = (enabler?.teams?.filter((team: JsonStratTeam) => team.status === JsonStratTeamStatusEnum.Active)?.length || 0) < (subteamsCount || MAX_ENABLER_TEAMS);

                              return enabler.status === JsonStratEnablerStatusEnum.Active ?
                                  <div style={{ width: '100%', marginBottom: '5px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                      <StratairuOnePagerActionsContainer index={index} actions={actions} onAction={handleAction}>
                                        <StratairuOnePagerEditableText index={index} value={ enabler.description } readonly={readonly} editableDraft={editableDraft}/>
                                      </StratairuOnePagerActionsContainer>
                                      <div style={{ display: 'flex', gap: '5px' }}>
                                          <div style={{ flex: 1 }}>
                                              <StratairuOnePagerPicos index={{ ...index, entity: OnepagerEntityType.EnablerSuccessLook, subEntityIndex: 0 }} value={ enabler.successLooks![0] } readonly={readonly} editableDraft={editableDraft} actions={[CommonActionsType.Edit]} onAction={handleAction} isHorizontal/>
                                          </div>
                                          <div style={{ flex: 1 }}>
                                              { <div className={styles.itemsContainers} style={{ flexDirection: 'row' }}>
                                                  <div className={classnames(styles.editableText, styles.teamTitle, styles.enablerTitle)} style={{ minWidth: '80px', width: '80px' }}>{t('stratairu.onePager.root.initiativeTeam')}</div>
                                                  { enabler?.teams?.map((team: JsonStratTeam, teamIndex: number) => {
                                                      const actions = [CommonActionsType.Edit];
                                                      if (firstTeam) {
                                                          firstTeam = false;
                                                      } else {
                                                          actions.push(CommonActionsType.Delete);
                                                      }
                                                      if (teamIndex === addTeamIndex && hasAddTeamActionAvailable) { actions.push(CommonActionsType.Create) };
                                                      return team.status === JsonStratTeamStatusEnum.Active ?
                                                            <div style={{ flex: 1 }}>
                                                                <StratairuOnePagerTeam index={{ ...index, entity: OnepagerEntityType.EnablerTeam, subEntityIndex: teamIndex }} value={team} readonly={readonly} editableDraft={editableDraft} actions={actions} onAction={handleAction} order={++order} noOrder/>
                                                            </div> : <></>
                                                } ) }
                                              </div> }
                                          </div>
                                      </div>
                                  </div> : <></>;
                          })}</div>
                      }
                      if (index.entity === OnepagerEntityType.CVB) {
                          return <div style={{ display: 'flex', gap: '10px', width: '100%'}}>{ sourceOnePager.cvbs?.map((cvb: JsonStratCvb, cvbIndex: number) => {
                              const index = { entity: OnepagerEntityType.CVB, entityIndex: cvbIndex };
                              return (cvb.status === JsonStratCvbStatusEnum.Active || (source === OnePagerRowSource.ROOT && !cvb.status)) ?
                                  <div style={{ flex: 1 }}>
                                  <StratairuOnePagerActionsContainer index={index} actions={readonly ? [] : [CommonActionsType.Delete, CommonActionsType.Edit] } onAction={handleAction}>
                                      <StratairuOnePagerEditableText index={index} value={ cvb.description } readonly={readonly} sx={{ height: '100%' }} editableDraft={editableDraft}/>
                                  </StratairuOnePagerActionsContainer>
                                  </div> : <></>;
                          }) } </div>
                      }
                      if ([OnepagerEntityType.PillarHeader, OnepagerEntityType.PillarParent, OnepagerEntityType.Pillar, OnepagerEntityType.PillarSuccessLook,
                           OnepagerEntityType.Initiative, OnepagerEntityType.InitiativeHeader, OnepagerEntityType.InitiativeSuccessLook, OnepagerEntityType.InitiativeTeam].includes(index.entity)) {
                          let borderRadius: string | undefined = undefined;
                          if (pillarOrder) {
                              if (pillarOrder === PillarOrder.FIRST) {
                                  borderRadius = '15px 15px 0px 0px';
                              }
                              if (pillarOrder === PillarOrder.LAST) {
                                  borderRadius = '0px 0px 15px 15px';
                              }
                          }
                          return <div className={styles.pillarContainer} style={{ marginTop: index.entity === OnepagerEntityType.PillarHeader ? '10px' : undefined }}>
                              { sourceOnePager?.pillars?.filter((pillar: JsonStratPillarInfo) => pillar.status === JsonStratPillarInfoStatusEnum.Active)
                                  .map((pillar: JsonStratPillarInfo, order: number) => {

                                    const pillarIndex = sourceOnePager?.pillars?.findIndex((p: JsonStratPillarInfo) => p === pillar);
                                    let content = <></>;
                                    let tempIndex: OnepagerEntityIndex = index;
                                    let tempActions = actions;

                                      let parentEntity: any;
                                      if (isRootOnePager) {
                                          parentEntity = pillar;
                                      } else {
                                          if (pillar.parentInitiativeId) {
                                              parentEntity = parentOnepager.pillars?.reduce((acc: JsonStratInitiative[], pillar: JsonStratPillarInfo) => {
                                                  acc.push(...(pillar.initiatives || []));
                                                  return acc;
                                              },[])
                                                  .find((initiative: JsonStratInitiative) => initiative.initiativeId === pillar.parentInitiativeId);
                                          }
                                          if (pillar.parentEnablerId) {
                                              parentEntity = parentOnepager.enablers?.find((enabler: JsonStratEnabler) => enabler.enablerId === pillar.parentEnablerId);
                                          }
                                      };

                                    if (index.entity === OnepagerEntityType.PillarHeader) {
                                        content = <div className={styles.pillarHeader}>{ t(title!, { order: order + 1 }) } </div>;
                                    }

                                    if (index.entity === OnepagerEntityType.PillarParent) {
                                      content = <div>
                                          <StratairuOnePagerParentPillarInfo parentOnePager={parentOnepager} pillar={pillar as JsonStratPillar} pillarIndex={pillarIndex} isEditable={!readonly} onAction={handleAction}/>
                                      </div>
                                    }

                                    if (index.entity === OnepagerEntityType.Pillar && parentEntity) {
                                        tempIndex = { entity: OnepagerEntityType.Pillar, entityIndex: pillarIndex };
                                        tempActions = [];
                                        if (!readonly) {
                                            if (!pillar.createdBy || pillar.createdBy === user.userId) {
                                                tempActions.push(CommonActionsType.Delete);
                                                tempActions.push(CommonActionsType.Edit);
                                            }
                                        }
                                        tempActions.push(CommonActionsType.Down)
                                        content = <StratairuOnePagerEditableText index={tempIndex} value={ parentEntity.description } readonly={readonly} sx={{ height: '100%' }} editableDraft={editableDraft}/>;
                                    }
                                    if (index.entity === OnepagerEntityType.PillarSuccessLook && parentEntity) {
                                        tempIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.PillarSuccessLook };
                                        const enableNewSuccessLook = !pillar.successLooks || pillar?.successLooks?.filter((look: JsonStratSuccessLook) => look.status === JsonStratSuccessLookStatusEnum.Active).length < MAX_SUCCESS_LOOKS;
                                        tempActions = enableNewSuccessLook && !readonly ? [CommonActionsType.Create] : [];

                                        content = <div className={styles.pillarMultiple}>{ parentEntity.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => {
                                            const subIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.PillarSuccessLook, entityIndex: successLookIndex };
                                            return successLook.status === JsonStratSuccessLookStatusEnum.Active ?
                                                <StratairuOnePagerPicos index={subIndex} value={successLook} readonly={readonly} editableDraft={editableDraft} actions={[CommonActionsType.Edit, CommonActionsType.Delete]} onAction={handleAction}/> : <></>;
                                        }) }</div>
                                    }

                                    // Multiple Initiative
                                    if ([OnepagerEntityType.InitiativeHeader, OnepagerEntityType.Initiative, OnepagerEntityType.InitiativeSuccessLook, OnepagerEntityType.InitiativeTeam].includes(index.entity) && multipleIndex !== undefined) {
                                        tempIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: OnepagerEntityType.Initiative };
                                        const enableNewInitiative = !pillar.initiatives || pillar?.initiatives?.filter((initiative: JsonStratInitiative) => initiative.status === JsonStratInitiativeStatusEnum.Active).length < MAX_INITIATIVES;
                                        tempActions = [];

                                        const initiatives = pillar.initiatives?.filter((initiative: JsonStratInitiative) => initiative.status === JsonStratInitiativeStatusEnum.Active);
                                        const hasMultipleIndexInitiative = initiatives?.length && (initiatives?.length - 1 >= multipleIndex);
                                        const hasNewInciativeAction = (initiatives?.length || 0) < MAX_INITIATIVES;
                                        const initiative = initiatives && initiatives[multipleIndex];
                                        const realIndex = pillar.initiatives?.findIndex((tempInitiative: JsonStratInitiative) => tempInitiative === initiative);
                                        const subIndex = { parentEntity: OnepagerEntityType.Pillar, parentEntityIndex: pillarIndex, entity: index.entity, entityIndex: realIndex };


                                        let subContent;
                                        if (!initiative && multipleIndex === 0) {
                                            switch (index.entity) {
                                                case OnepagerEntityType.InitiativeHeader:
                                                    subContent = <StratairuOnePagerActionsContainer index={subIndex} actions={!readonly ? [CommonActionsType.Create] : []} onAction={handleAction} noPadding>
                                                        <div className={styles.noInitiativeHeader}>{ t(title!, { order: ' ' }) }</div>
                                                    </StratairuOnePagerActionsContainer>
                                                    break;
                                                default: break;
                                            }
                                        }
                                        if (hasMultipleIndexInitiative && !!initiative) {
                                            switch(index.entity) {
                                                case OnepagerEntityType.InitiativeHeader:
                                                    const initiativeHeaderActions = hasNewInciativeAction ? [CommonActionsType.Delete, CommonActionsType.Create] : [CommonActionsType.Delete];
                                                    subContent = <StratairuOnePagerActionsContainer index={subIndex} actions={!readonly ? initiativeHeaderActions : []} onAction={handleAction} noPadding>
                                                        <div className={styles.initiativeHeader}>{ t(title!, { order: multipleIndex + 1 }) }</div>
                                                    </StratairuOnePagerActionsContainer>
                                                    break;
                                                case OnepagerEntityType.Initiative:
                                                    subContent = <StratairuOnePagerActionsContainer index={subIndex} actions={!readonly ? [CommonActionsType.Delete, CommonActionsType.Edit] : []} onAction={handleAction}>
                                                        <StratairuOnePagerEditableText index={subIndex} value={initiative.description} readonly={readonly} editableDraft={true}/>
                                                    </StratairuOnePagerActionsContainer>
                                                    break;
                                                case OnepagerEntityType.InitiativeSuccessLook:
                                                    let hasSuccessLookActionsAvailable = (initiative?.successLooks?.filter((successLook: JsonStratSuccessLook) => successLook.status === JsonStratSuccessLookStatusEnum.Active)?.length || 0) < MAX_INITIATIVE_SUCCESS_LOOKS;
                                                    subContent = <StratairuOnePagerActionsContainer index={subIndex} actions={(!readonly && hasSuccessLookActionsAvailable) ? [CommonActionsType.Create] : []} onAction={handleAction}>
                                                        <div className={classnames(styles.itemsContainers, hasSuccessLookActionsAvailable ? styles.containerWithActions : undefined)}>
                                                            { initiative?.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => successLook.status === JsonStratSuccessLookStatusEnum.Active ?
                                                                <StratairuOnePagerPicos index={{ ...subIndex, subEntityIndex: successLookIndex }} value={successLook} readonly={readonly} editableDraft={true} actions={[CommonActionsType.Edit, CommonActionsType.Delete]} onAction={handleAction}/> : <></> ) }
                                                        </div>
                                                    </StratairuOnePagerActionsContainer>
                                                    break;
                                                case OnepagerEntityType.InitiativeTeam:
                                                    let hasTeamActionsAvailable = (initiative?.teams?.filter((team: JsonStratTeam) => team.status === JsonStratTeamStatusEnum.Active)?.length || 0) < (subteamsCount || MAX_INITIATIVE_TEAMS);
                                                    let order = 0;
                                                    subContent = <StratairuOnePagerActionsContainer index={subIndex} actions={(!readonly && hasTeamActionsAvailable) ? [CommonActionsType.Create] : []} onAction={handleAction}>
                                                        <div className={classnames(styles.itemsContainers, hasTeamActionsAvailable ? styles.containerWithActions : undefined)}>
                                                            { initiative?.teams?.map((team: JsonStratTeam, teamIndex: number) => team.status === JsonStratTeamStatusEnum.Active ?
                                                                <StratairuOnePagerTeam index={{ ...subIndex, subEntityIndex: teamIndex }} value={team} readonly={readonly} editableDraft={editableDraft} actions={[CommonActionsType.Edit, CommonActionsType.Delete]} onAction={handleAction} order={++order}/> : <></>) }
                                                        </div>
                                                    </StratairuOnePagerActionsContainer>
                                                    break;
                                                default:
                                                    break;
                                            }
                                        }
                                        content = <div className={styles.pillarMultiple}>{ subContent }</div>
                                    }

                                    return <StratairuOnePagerActionsContainer index={tempIndex} actions={tempActions} onAction={handleAction}>
                                        <div className={styles.pillarItem}
                                             style={{
                                                 marginBottom: tempActions?.includes(CommonActionsType.Create) ? '50px' : undefined,
                                                 borderRadius
                                            }}
                                        >{ content }</div>
                                    </StratairuOnePagerActionsContainer>
                                  })
                              }
                          </div>
                      }
                  })() }
                  </>

          </Grid>
        </Grid>
      </Container>
	);
}

export default StratairuOnePagerComplexRow;
