/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonWebSocketMessage
 */
export interface JsonWebSocketMessage {
    /**
     * 
     * @type {object}
     * @memberof JsonWebSocketMessage
     */
    data?: object;
    /**
     * 
     * @type {string}
     * @memberof JsonWebSocketMessage
     */
    type?: JsonWebSocketMessageTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonWebSocketMessageTypeEnum {
    RelayItem = 'RELAY_ITEM',
    RelayResponse = 'RELAY_RESPONSE',
    RelayResults = 'RELAY_RESULTS',
    RelaySync = 'RELAY_SYNC',
    RelayStats = 'RELAY_STATS',
    Error = 'ERROR',
    Bundle = 'BUNDLE'
}

export function JsonWebSocketMessageFromJSON(json: any): JsonWebSocketMessage {
    return JsonWebSocketMessageFromJSONTyped(json, false);
}

export function JsonWebSocketMessageFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonWebSocketMessage {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : json['data'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function JsonWebSocketMessageToJSON(value?: JsonWebSocketMessage | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data,
        'type': value.type,
    };
}

