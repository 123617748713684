import {Card, CardActions, CardContent, CardHeader} from '@mui/material';
import {PropsWithChildren, ReactNode} from 'react';
import styles from '../../assets/styles/layout';

export type ColumnDefinition<T> = {
    title?: string;
    style?: any;
    renderer: (row: T) => any;
}

export type SimpleListHeaderAction = (action: string) => void;

export type SimpleListAction<T> = (row: T, action: string) => void;

export type SimpleListConfig = {
    title?: string;
    hideHeader?: boolean;
}

export type SimpleListDefinition<T> = {
    config?: SimpleListConfig;
    action: SimpleListAction<T>;
    cols: ColumnDefinition<T>[];
    rows: T[] | null;
    filter?: ReactNode | undefined;
    headerAction?: ReactNode | undefined;
}

const SimpleList = <T extends {}>({ cols, rows, config, children, filter, action, headerAction }: PropsWithChildren<SimpleListDefinition<T>>) => {

	return (
        <Card style={{ border: "none", boxShadow: "none" }}>
            { config?.title && <CardHeader
                title={config.title}
                action={headerAction}
            /> }
            <CardContent>
                <>
                    { filter }
                    <div className={styles.simpleList}>
                        { (!config || !config.hideHeader) && 
                            <div className={styles.simpleListHeader} key={'header'}>
                                { cols?.map((colDef, cIndex) => <div style={colDef.style}  key={'header-' + cIndex}>{ colDef?.title }</div>) }
                            </div>
                        }
                        { rows?.map((row, rIndex) =>
                            <div className={styles.simpleListRow} key={rIndex} onClick={() => { action && action(row, 'ROW_CLICK')}}>
                                { cols?.map((colDef, cIndex) => <div style={colDef.style} key={rIndex + '-' + cIndex}>{ colDef?.renderer(row) }</div>) }
                            </div>
                        )}
                    </div>
                </>
            </CardContent>
            <CardActions>
                { children }
            </CardActions>
        </Card>
	);
}

export default SimpleList;
