import { TextField } from '@mui/material';
import { useField } from 'formik';
import { useCallback } from 'react';
import { FormfieldProps } from '../../model/form';

interface Props extends FormfieldProps {
}

const DateTimeFormfield = ({ name, label, ...props }: Props) => {
	
	const [field, meta, helpers] = useField(name);
	const { setValue, setTouched } = helpers;

	const onChange = useCallback((event: any) => {
		setValue(event.target.value, true);
		setValue(event.target.value, true);
	}, [setValue]);

	const setTouchedOnBlur = useCallback((event: any) => {
		setTouched(true);
		setValue(event.target.value, true);
	}, [setValue, setTouched]);

	return (
		<TextField
			fullWidth
			id={name}
			name={name}
			label={label}
			type="datetime-local"
			value={field.value}
			onBlur={setTouchedOnBlur}
			onChange={onChange}
			error={meta.touched && Boolean(meta.error)}
			helperText={meta.touched && meta.error}
			variant="standard"
			inputProps={{ }}
			>
		</TextField>	
	);
}

export default DateTimeFormfield;