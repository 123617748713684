/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonCompanyDepartment
 */
export interface JsonCompanyDepartment {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyDepartment
     */
    allowedActions?: Array<JsonCompanyDepartmentAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonCompanyDepartment
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartment
     */
    companyDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartment
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyDepartment
     */
    departmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyDepartment
     */
    status?: JsonCompanyDepartmentStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyDepartment
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyDepartmentAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonCompanyDepartmentStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonCompanyDepartmentFromJSON(json: any): JsonCompanyDepartment {
    return JsonCompanyDepartmentFromJSONTyped(json, false);
}

export function JsonCompanyDepartmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyDepartment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyDepartmentId': !exists(json, 'companyDepartmentId') ? undefined : json['companyDepartmentId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonCompanyDepartmentToJSON(value?: JsonCompanyDepartment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyDepartmentId': value.companyDepartmentId,
        'companyId': value.companyId,
        'departmentId': value.departmentId,
        'status': value.status,
        'title': value.title,
    };
}

