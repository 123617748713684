import {createAsyncThunk, createSlice, PayloadAction,} from '@reduxjs/toolkit'
import {RootState} from '..';
import {API_CONFIG} from '../app/api-config';
import {
	GetStrategyCountUsingGETRequest,
	GetStrategyListUsingGETRequest,
	JsonStrategy,
	JsonStrategyInfo, PrepareStratUserImportUsingPOSTRequest,
	StrategyControllerApi,
	StratUserControllerApi
} from '../generated-api';

const strategyApi = new StrategyControllerApi(API_CONFIG);
const stratUserApi = new StratUserControllerApi(API_CONFIG);

export const fetchStrategies = createAsyncThunk('strategies/list', async (arg: { page?: number, offset?: number }, thunkApi) => {
	
	const { currentPage, rowsPerPage, filter } = (thunkApi.getState() as RootState).strategies;

	const params = {
		start: arg.page ? (arg.page - 1) * rowsPerPage : ((currentPage - 1) + (arg?.offset || 0)) * rowsPerPage,
		rows: rowsPerPage,
		orderCol: 'title',
		...filter
	} as GetStrategyListUsingGETRequest;
	
	return await strategyApi.getStrategyListUsingGET(params);
});

export const fetchStrategiesCount = createAsyncThunk('strategies/count', async (_: void, thunkApi) => {

	const { filter } = (thunkApi.getState() as RootState).strategies;

	const params = {
		...filter
	} as GetStrategyListUsingGETRequest;

	return await strategyApi.getStrategyCountUsingGET(params);
});

export const fetchStrategy = createAsyncThunk('strategy/fetch', async (strategyId: number) => {
	return await strategyApi.getStrategyUsingGET({ strategyId });
});

export const saveStrategy = createAsyncThunk('strategy/save', async (strategy: JsonStrategy) => {
	delete strategy['createdAt'];
	delete strategy['createdBy'];
	delete strategy['updatedAt'];
	delete strategy['updatedBy'];
	return await strategyApi.saveStrategyUsingPOST({ json: strategy });
});

export const importStrategyUser = createAsyncThunk('strategy/import-users', async (strategyImport: PrepareStratUserImportUsingPOSTRequest) => {
	return await stratUserApi.prepareStratUserImportUsingPOST(strategyImport)
});


/*
export const fetchRelaysCombo = createAsyncThunk('relays/fetchCombo', async () => {
	return await relayApi.getRelayListUsingGET({});
});

*/

export type StrategiesState = {
	loading: boolean;
	rowsPerPage: number;
	currentPage: number;
	count: number;
	items: JsonStrategyInfo[];
	filter: GetStrategyListUsingGETRequest;
}

const initialState: StrategiesState = {
	loading: false,
	rowsPerPage: 10,
	currentPage: 1,
	count: 0,
	items: [],
	filter: {}
};

export const strategiesSlice = createSlice({
	name: 'strategies',
	initialState,
	reducers: {
		setStrategiesFilter: (state, action: PayloadAction<GetStrategyCountUsingGETRequest>) => {
			state.filter = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchStrategiesCount.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchStrategiesCount.fulfilled, (state, action) => ({ ...state, count: action.payload.data || 0, loading: false }));
		builder.addCase(fetchStrategiesCount.rejected, (state, action) => ({ ...state, count: 0, loading: false }));

		builder.addCase(fetchStrategies.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchStrategies.fulfilled, (state, action) => ({ ...state, items: action.payload.data || [], currentPage: action.meta.arg.page || state.currentPage, loading: false }));
		builder.addCase(fetchStrategies.rejected, (state, action) => ({ ...state, items: [], loading: false }));
	}
});

export const strategiesReducer = strategiesSlice.reducer;
export const { setStrategiesFilter } = strategiesSlice.actions;
