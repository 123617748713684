/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonStratCvb
 */
export interface JsonStratCvb {
    /**
     * 
     * @type {string}
     * @memberof JsonStratCvb
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratCvb
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratCvb
     */
    cvbId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratCvb
     */
    description?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonStratCvb
     */
    isRemoved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonStratCvb
     */
    onepagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratCvb
     */
    orderNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratCvb
     */
    status?: JsonStratCvbStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonStratCvb
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratCvb
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratCvbStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonStratCvbFromJSON(json: any): JsonStratCvb {
    return JsonStratCvbFromJSONTyped(json, false);
}

export function JsonStratCvbFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratCvb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'cvbId': !exists(json, 'cvbId') ? undefined : json['cvbId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isRemoved': !exists(json, 'isRemoved') ? undefined : json['isRemoved'],
        'onepagerId': !exists(json, 'onepagerId') ? undefined : json['onepagerId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonStratCvbToJSON(value?: JsonStratCvb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'cvbId': value.cvbId,
        'description': value.description,
        'isRemoved': value.isRemoved,
        'onepagerId': value.onepagerId,
        'orderNo': value.orderNo,
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

