import { Formik } from 'formik';
import { useAppTranslation } from '../services/i18n';
import styles from '../assets/styles/layout';
import { useCallback } from 'react';
import { Button } from '@mui/material';
import { useAppDispatch } from '../store';
import TextFormfield from '../components/form/TextFormfield';
import { setCompaniesFilter } from '../store/companies';
import SelectFormfield from "../components/form/SelectFormfield";
import {useSelector} from "react-redux";
import {selectCompaniesFilter} from "../store/selectors";

interface Props {}

const CompanyListFilter = (props: Props) => {

    const dispatch = useAppDispatch();
    const t = useAppTranslation();

    const filter = useSelector(selectCompaniesFilter);

    const validate = (values: any) => {
    }

    const onFilter = useCallback(async (values: any) => {
        await dispatch(setCompaniesFilter({
            search: values.search || undefined,
            serieId: values.serieId || undefined,
        }));
    }, [dispatch])

	return (
        <Formik
            initialValues={filter}
            enableReinitialize={true}
            validate={validate}
            onSubmit={(values: any) => {
                // console.log({ values });
            }}
            >       
            {props => (
            <form onSubmit={props.handleSubmit}>
                <div className={styles.listFilter}>
                    <div className={styles.items}>
                        <TextFormfield name="search" label={t('Vyhledat')}></TextFormfield>
                        <SelectFormfield name="serieId" label={t('Seriál')} poplist="broadcastSerieId" numberic></SelectFormfield>
                    </div>
                    <div style={{ minWidth: '150px' }}>
                        <Button variant="contained" onClick={() => onFilter(props.values) } fullWidth>{t('Filtrovat')}</Button>
                    </div>
                </div>
            </form>)}
        </Formik>
    );
}

export default CompanyListFilter;
