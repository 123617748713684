import FormModal from "../components/form/FormModal";
import CompanyAdminForm from "../forms/CompanyAdminForm";
import { JsonUser } from "../generated-api";
import { FormProps } from "../model/form";
import { useAppTranslation } from "../services/i18n";

const CompanyAdminModal = (props: FormProps<JsonUser>) => {

  const t = useAppTranslation();

  return (
    <FormModal title={t('Přidání administrátora společnosti')} open={!!props?.data}>
      <CompanyAdminForm { ...props }></CompanyAdminForm>
    </FormModal>
	);
}

export default CompanyAdminModal;
