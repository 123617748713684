/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPollInfo
 */
export interface JsonPollInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonPollInfo
     */
    allowedActions?: Array<JsonPollInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    chartType?: JsonPollInfoChartTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    episodeFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPollInfo
     */
    episodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    episodeTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    episodeTo?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPollInfo
     */
    groupId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    groupTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPollInfo
     */
    orderNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPollInfo
     */
    pollId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    pollType?: JsonPollInfoPollTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonPollInfo
     */
    rootPollId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    serieTitle?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    status?: JsonPollInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPollInfo
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonPollInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonPollInfoChartTypeEnum {
    Barchart = 'BARCHART',
    BarchartH = 'BARCHART_H',
    Sliders = 'SLIDERS',
    Spiderchart = 'SPIDERCHART',
    Wordcloud = 'WORDCLOUD',
    Matrix = 'MATRIX',
    None = 'NONE'
}/**
* @export
* @enum {string}
*/
export enum JsonPollInfoPollTypeEnum {
    Choice = 'CHOICE',
    Multichoice = 'MULTICHOICE',
    Scale = 'SCALE',
    Ranking = 'RANKING',
    Text = 'TEXT'
}/**
* @export
* @enum {string}
*/
export enum JsonPollInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonPollInfoFromJSON(json: any): JsonPollInfo {
    return JsonPollInfoFromJSONTyped(json, false);
}

export function JsonPollInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPollInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'chartType': !exists(json, 'chartType') ? undefined : json['chartType'],
        'episodeFrom': !exists(json, 'episodeFrom') ? undefined : json['episodeFrom'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'episodeTitle': !exists(json, 'episodeTitle') ? undefined : json['episodeTitle'],
        'episodeTo': !exists(json, 'episodeTo') ? undefined : json['episodeTo'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'groupTitle': !exists(json, 'groupTitle') ? undefined : json['groupTitle'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'pollId': !exists(json, 'pollId') ? undefined : json['pollId'],
        'pollType': !exists(json, 'pollType') ? undefined : json['pollType'],
        'rootPollId': !exists(json, 'rootPollId') ? undefined : json['rootPollId'],
        'serieTitle': !exists(json, 'serieTitle') ? undefined : json['serieTitle'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonPollInfoToJSON(value?: JsonPollInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'chartType': value.chartType,
        'episodeFrom': value.episodeFrom,
        'episodeId': value.episodeId,
        'episodeTitle': value.episodeTitle,
        'episodeTo': value.episodeTo,
        'groupId': value.groupId,
        'groupTitle': value.groupTitle,
        'orderNo': value.orderNo,
        'pollId': value.pollId,
        'pollType': value.pollType,
        'rootPollId': value.rootPollId,
        'serieTitle': value.serieTitle,
        'status': value.status,
        'title': value.title,
    };
}

