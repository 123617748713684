import {PropsWithChildren, useCallback} from 'react';
import {Grid} from '@mui/material';
import {useAppTranslation} from '../../../services/i18n';
import {
    JsonStratOnepagerInfo,
    JsonStratOnepagerStatusEnum
} from "../../../generated-api";
import {useAppDispatch} from "../../../store";
import {setOnepagerStatus} from "../../../store/strategy";
import {StratairuButton, StratairuButtonEnum} from "./StratairuButton";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../../../store/selectors";
import StratairuOnePagerFavouriteButton from "./StratairuOnePagerFavouriteButton";
import {useModal} from "../../../services/modal";

interface Props {
    onePager?: JsonStratOnepagerInfo;
    selfOnePager?: JsonStratOnepagerInfo;
    onSave?: (status: JsonStratOnepagerStatusEnum) => Promise<void>;
    isRootOnepager?: boolean;
}

const StratairuOnePagerButtons = ({ onePager, selfOnePager, onSave, isRootOnepager }: PropsWithChildren<Props>) => {

  const t = useAppTranslation();
  const modal = useModal();

  const dispatch = useAppDispatch();
  const loggedUser = useSelector(selectAuthUser);

  const handleSave = useCallback(async (status: JsonStratOnepagerStatusEnum) => {

      if (status === JsonStratOnepagerStatusEnum.Finished) {

          // check connected pillar
          // TODO: temporarily removed - uncomment it
          /*
          if (onePager && !isRootOnepager) {
              const hasNotConnectedPillar = !!onePager?.pillars?.find((pillar: JsonStratPillarInfo) => pillar.status === JsonStratPillarInfoStatusEnum.Active && !(pillar.parentPillarId || pillar.parentEnablerId));
              if (hasNotConnectedPillar) {
                  dispatch(addMessage({
                      severity: 'error',
                      title: t('stratairu.messages.pillarsNotConnected'),
                  }));
                  return;
              }
          }
          */

          const confirmResult = await modal.confirm({
              title: t('onePager.messages.confirmStatusTitle'),
              message: t('onePager.messages.confirmStatusMessage'),
              confirmButton: t('onePager.buttons.yesConfirm'),
              cancelButton: t('onePager.buttons.continueEditing')
          });
          console.log({ confirmResult });
          if (confirmResult !== 'CONFIRM') {
              return;
          }

      }

      await dispatch(setOnepagerStatus(status));
      onSave && await onSave(status);

  }, [onePager, isRootOnepager, onSave, dispatch, modal, t])

    // TODO: uncomment
    // TODO: remove finished at first button
    // const status = (onePager?.status || JsonStratOnepagerStatusEnum.Draft) as JsonStratOnepagerStatusEnum;
    // const buttonsCount = status === JsonStratOnepagerStatusEnum.Public ? 2 : 3;

    const status = (onePager?.status || JsonStratOnepagerStatusEnum.Draft) as JsonStratOnepagerStatusEnum;
    console.log({ onePager, selfOnePager, parentUserId: onePager?.parentUserId });
    const isConfirmable = (!selfOnePager?.parentUserId && selfOnePager?.userId === loggedUser.userId) || selfOnePager?.parentUserId === loggedUser.userId;
    // const buttonsCount = loggedUser.userId === onePager?.userId ? (isConfirmable ? 3 : 2) : (isConfirmable ? 2 : 1);
    const buttonsCount = 3;

  return (
      <>
        { loggedUser.userId === onePager?.userId && <Grid container sx={{ paddingBottom: '20px' }}>
              { /* <Grid item xs={12}>
                  { status }
              </Grid> */ }
              <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
              { [JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.Public].includes(status) &&
                  <StratairuButton variant="contained" color="secondary" mycolor={StratairuButtonEnum.Blue} fullWidth onClick={() => handleSave(status)}>{t('onePager.buttons.save')}</StratairuButton>
              }
              </Grid>
              <Grid item xs={12} md={12/buttonsCount} style={{ padding: '25px 20px 0px 20px' }}>
              { [JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.Public].includes(status) &&
                  <div style={{ display: 'flex', cursor: 'pointer', alignItems: 'flex-end', gap: '10px' }} onClick={() => handleSave(status === JsonStratOnepagerStatusEnum.Public ? JsonStratOnepagerStatusEnum.Draft : JsonStratOnepagerStatusEnum.Public)}>
                      { [JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.New].includes(status) && <RadioButtonUncheckedIcon /> }
                      { status === JsonStratOnepagerStatusEnum.Public && <TaskAltIcon /> }
                      <div>{ t('onePager.buttons.allowPreview') }</div>
                  </div>
              }
              </Grid>
                { isConfirmable &&
                    <>
                      { [JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.Public].includes(status) && <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
                          <StratairuButton variant="contained" color="success" mycolor={StratairuButtonEnum.Yellow} fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Finished)}>{t('onePager.buttons.confirm')}</StratairuButton>
                      </Grid> }
                      { [JsonStratOnepagerStatusEnum.Finished].includes(status) && <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
                          <StratairuButton variant="contained" color="success" mycolor={StratairuButtonEnum.Yellow} fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Public)}>{t('onePager.buttons.unconfirm')}</StratairuButton>
                      </Grid> }
                    </>
                }
            </Grid>
        }
        { loggedUser.userId !== onePager?.userId && <Grid container sx={{ paddingBottom: '20px' }}>
            <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <StratairuOnePagerFavouriteButton onepager={onePager!} >{ t('onePager.buttons.addToFavourite') }</StratairuOnePagerFavouriteButton>
                </div>
            </Grid>
            { isConfirmable &&
                <>
                    { [JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.Public].includes(status) && <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
                        <StratairuButton variant="contained" color="success" mycolor={StratairuButtonEnum.Yellow} fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Finished)}>{t('onePager.buttons.confirm')}</StratairuButton>
                    </Grid> }
                    { [JsonStratOnepagerStatusEnum.Finished].includes(status) && <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
                        <StratairuButton variant="contained" color="success" mycolor={StratairuButtonEnum.Yellow} fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Public)}>{t('onePager.buttons.unconfirm')}</StratairuButton>
                    </Grid> }
                </>
            }
        </Grid>
        }
      </>
	);
}

export default StratairuOnePagerButtons;
