import * as React from "react";
import styles from '../../../assets/styles/relay';
import {BarHeight, ChartOptions, COLORS, getOptionTotal} from '../../../helpers/relay';
import { JsonGroup, JsonPoll, JsonPollResults, JsonRelayResponse } from '../../../generated-api';

const RelayBarChart = (props: { group?: JsonGroup, poll: JsonPoll, results: JsonPollResults, responses: JsonRelayResponse[], options?: ChartOptions }) => {
    const {poll, results} = props;

    const total = results?.optionTotals
        ? poll.options?.reduce(((total: number, option) => total + (results.optionTotals ? (results.optionTotals[option.optionId as number] || 0) : 0)), 0)
        : 0;

    const chartWidth = (props.options?.percentWidth || 100) + '%';
    const chartHeight = props.options?.pixelHeight || BarHeight;
    const chartFontSize = props.options?.labelFontSize || '0.8rem';
    const colors = props.options?.colors || COLORS;

    return <table style={{width: chartWidth, tableLayout: "fixed", fontFamily: "Roboto,Helvetica,Arial,sans-serif", paddingTop: "15px"}}>
                <tbody>
                    <tr>
                        {poll.options?.map((option, i) => {
                            const optionTotal = getOptionTotal(option, results);
                            const percent =  results?.userCount ? Math.round(optionTotal / results.userCount * 1000) / 10 : 0;
                            return <td key={i} style={{verticalAlign: "bottom", textAlign: "center", fontSize: chartFontSize}}>
                                {total ? <div style={{height: chartHeight * (optionTotal / total), background: colors[i % colors.length]}}></div> : null}
                                <span style={{display: "block", padding: "10px 0"}}>{percent}&nbsp;%</span>
                            </td>;
                        })}
                    </tr>
                    <tr>
                        {poll.options?.map((option, i) => {
                            return <td key={i} style={{ verticalAlign: "top" }}>
                                <div style={{ display: "flex", alignItems: "flex-start", justifyContent: "center", fontSize: chartFontSize, textAlign: "center" }}>
                                    <strong>{option.title}</strong>
                                    { /* responses?.find((response) => response.pollOptionId === option.optionId)
                                        && <CheckCircleOutlineIcon sx={{ marginLeft: '5px', fontSize: '1rem' }}/> */ }
                                </div>
                            </td>;
                        })}
                    </tr>
                    {poll.pollParams && <tr className={styles.scaleLabels}>
						<td colSpan={Math.floor((poll.options?.length || 0) / 2)}>{poll.pollParams?.minLabel}</td>
						<td colSpan={Math.ceil((poll.options?.length || 0) / 2)}>{poll.pollParams?.maxLabel}</td>
					</tr>}
                    </tbody>
             </table>
};

export default RelayBarChart;
