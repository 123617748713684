/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonSerieUserInfo,
    JsonSerieUserInfoFromJSON,
    JsonSerieUserInfoFromJSONTyped,
    JsonSerieUserInfoToJSON,
} from './JsonSerieUserInfo';
import {
    JsonSerieUserRegistrationContext,
    JsonSerieUserRegistrationContextFromJSON,
    JsonSerieUserRegistrationContextFromJSONTyped,
    JsonSerieUserRegistrationContextToJSON,
} from './JsonSerieUserRegistrationContext';

/**
 * 
 * @export
 * @interface JsonAuthSerieUserResponse
 */
export interface JsonAuthSerieUserResponse {
    /**
     * 
     * @type {JsonSerieUserRegistrationContext}
     * @memberof JsonAuthSerieUserResponse
     */
    context?: JsonSerieUserRegistrationContext;
    /**
     * 
     * @type {JsonSerieUserInfo}
     * @memberof JsonAuthSerieUserResponse
     */
    user?: JsonSerieUserInfo;
}

export function JsonAuthSerieUserResponseFromJSON(json: any): JsonAuthSerieUserResponse {
    return JsonAuthSerieUserResponseFromJSONTyped(json, false);
}

export function JsonAuthSerieUserResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonAuthSerieUserResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'context': !exists(json, 'context') ? undefined : JsonSerieUserRegistrationContextFromJSON(json['context']),
        'user': !exists(json, 'user') ? undefined : JsonSerieUserInfoFromJSON(json['user']),
    };
}

export function JsonAuthSerieUserResponseToJSON(value?: JsonAuthSerieUserResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'context': JsonSerieUserRegistrationContextToJSON(value.context),
        'user': JsonSerieUserInfoToJSON(value.user),
    };
}

