
import {PropsWithChildren} from 'react';
import SimpleList, {ColumnDefinition, SimpleListAction} from "../../../../components/layout/SimpleList";
import {JsonCompanyBranchInfo} from "../../../../generated-api";
import {useAppTranslation} from "../../../../services/i18n";
import {Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
    rows: JsonCompanyBranchInfo[] | null;
    action: SimpleListAction<JsonCompanyBranchInfo>;
}

const CompanyBranchesList = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    const cols = [
        { 
            title: 'name', 
            renderer: (row: JsonCompanyBranchInfo) => {
                return (
                    <div><strong>{ row.title }</strong></div>
                );
            }
        },
        {
            renderer: (row: JsonCompanyBranchInfo) => {
                return (
                    <>
                        <Tooltip title="Upravit">
                            <EditIcon color="primary" onClick={() => props?.action(row, 'EDIT')}/>
                        </Tooltip>
                        <Tooltip title="Odstranit">
                            <DeleteIcon color="primary" onClick={() => props?.action(row, 'DELETE')}/>
                        </Tooltip>
                    </>
                );
            }
        }
    ] as ColumnDefinition<JsonCompanyBranchInfo>[];

	return (
		<SimpleList cols={cols} config={{hideHeader: true, title: t('Pobočky společnosti')}} { ...props }></SimpleList>
	);
}

export default CompanyBranchesList;
