import {Checkbox, FormControl, FormControlLabel, FormHelperText} from '@mui/material';
import {useField} from 'formik';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { JsonPoplistItem } from '../../generated-api';
import {PoplistFormfieldProps} from '../../model/form';
import {selectCodebooks} from '../../store/selectors';

const MultiCheckboxFormfield = ({ name, label, poplist, poplistItems, numberic, onChange }: PoplistFormfieldProps) => {
	
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue, setTouched } = helpers;
	const { value } = field;
	const hasError = !!meta.error && meta.touched;

	const [options, setOptions] = useState<JsonPoplistItem[]>([]);
	
	const codebooks = useSelector(selectCodebooks);

	useEffect(() => {
		if (poplistItems) {
			setOptions(poplistItems);
		} else {
			const codebookName: string = poplist || name;
			if (codebookName && codebooks) {
				setOptions(codebooks[codebookName]);
			}
		}
	}, [poplist, name, poplistItems, codebooks])

	const handleChange = useCallback((event: any, optionValue: any) => {
		const checked = event.target.checked;
		let newValue = (value || []).filter((item: string) => item !== optionValue);
		if (checked) {
			newValue.push(optionValue);
		}
		setValue(newValue);
		onChange && onChange(newValue);
		setTouched(true);
	}, [value, setValue, setTouched, onChange]);

	return (
		<FormControl variant="standard" fullWidth error={hasError}>
			{ (options || []).map((option) => (<div key={option.value} style={{ display: 'flex', alignItems: 'center' }}>
			<FormControlLabel
  				label={option.label}
				control={<Checkbox
					name={name}
					checked={!!field.value?.includes(option.value)}
					onChange={(event) => handleChange(event, option.value)}
					inputProps={{ 'aria-label': 'controlled' }}
				/>}
			/>
			</div>))}
			
			{ hasError && <FormHelperText>{ meta.error }</FormHelperText> }

			{ /*
<FormControl variant="standard" fullWidth error={hasError}>
	<InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
	<Select
		labelId="demo-simple-select-helper-label"
		id="demo-simple-select-helper"
		value={ (numberic && field.value === 0) || !field.value ? '' : field.value }
		label={label}
		onBlur={handleTouchedOnBlur}
		onChange={handleChange}
		MenuProps={MenuProps}
		>
		<MenuItem key={ numberic ? 0 : '' } value={ numberic ? 0 : '' }>
			<em>Neuvedeno</em>
		</MenuItem>
		{ options?.map((item: LocalCodebookItem) => 
			<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
	</Select>
	{ hasError && <FormHelperText>{ meta.error }</FormHelperText> }
</FormControl>	
		*/ }

		</FormControl>
	)
}

export default MultiCheckboxFormfield;
