import {useCallback} from "react";
import {useNavigate} from "react-router";
import {JsonStratOnepagerInfo, JsonStratOnepagerInfoStatusEnum} from "../generated-api";
import {useAppDispatch} from "../store";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../store/selectors";
import {useAppTranslation} from "../services/i18n";
import {addMessage} from "../store/localApp";
import {fetchOnePagerInfo} from "../store/strategy";


export function useOnePager() {

    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const user = useSelector(selectAuthUser);

    const checkAccess = useCallback((onepager: JsonStratOnepagerInfo): boolean => {
        if ([JsonStratOnepagerInfoStatusEnum.Public, JsonStratOnepagerInfoStatusEnum.Finished].includes(onepager.status!) || onepager.userId === user?.userId) {
            return true;
        }
        dispatch(addMessage({
            severity: 'error',
            title: t('stratairu.messages.onePagerAccessDenied'),
        }));
        return false;
    }, [dispatch, t]);

    const navigateOnePager = useCallback((onepager: JsonStratOnepagerInfo) => {
        if (checkAccess(onepager)) {
            navigate(`/stratairu/${onepager.strategyId}/onepager/${onepager.onepagerId}`);
            return true;
        }
        return false;
    }, [checkAccess, dispatch, navigate]);

    const navigateOnePagerById = useCallback(async (onepagerId: number) => {
        const result = await dispatch(fetchOnePagerInfo(onepagerId));
        if ('error' in result) {
            return false;
        }
        const onepager = result.payload.data;
        if (onepager) {
            return navigateOnePager(onepager as JsonStratOnepagerInfo);
        }
        return false;
    }, [navigateOnePager, dispatch]);

    return { checkAccess, navigateOnePager, navigateOnePagerById }
}
