import {useParams} from "react-router-dom";
import {Alert, Container, Grid} from '@mui/material';
import React, {useCallback, useEffect, useState} from 'react';
import {useAppDispatch} from '../../store';
import {
  GetStratPillarListUsingGETPivotModeEnum,
  JsonPoplistItem,
  JsonStratOnepagerInfo,
  JsonStratOnepagerStatusEnum,
  JsonStratTeam
} from '../../generated-api';
import {getLocale, useAppTranslation} from '../../services/i18n';
import {
  addOnepagerEntity,
  deleteOnepagerEntity,
  fetchComments,
  fetchDashboard,
  fetchOnePager,
  fetchStrategyContext,
  saveOnePager,
  setOnepagerActiveIndex,
  updateOnepagerEntity
} from "../../store/strategy";
import {useSelector} from "react-redux";
import {
  selectAuthUser,
  selectCodebooks,
  selectOnePagerSubTeamsCombo,
  selectStrategyContext,
  selectStrategyOnePager,
  selectStrategyOnePagerActiveIndex,
  selectStrategyParentOnePager,
  selectStrategyRootOnePager,
  selectStrategySelfOnePager,
} from "../../store/selectors";
import {addMessage} from "../../store/localApp";
import {OnepagerEntityIndex, OnepagerEntityType} from "../../model/onepager";
import {CommonActionsType} from "../../model/form";
import HelpModal from "../../components/layout/HelpModal";
import {useInterval} from "../../hooks/useInterval";
import {useModal} from "../../services/modal";
import {addVisited} from "../../store/auth";

import StratairuPageHeader from "../../components/stratairu/components/StratairuPageHeader";
import StratairuLabel from "../../components/stratairu/components/StratairuLabel";
import StratairuOnePagerButtons from "../../components/stratairu/components/StratairuOnePagerButtons";
import StratairuOnePagerPillarStructureModal, {
  PillarStructurePivot
} from "../../components/stratairu/components/StratairuOnePagerPillarStructureModal";
import StratairuOnePagerCommentsContainer
  from "../../components/stratairu/components/StratairuOnePagerCommentsContainer";
import {getOnePagerContentDef, getOnePagerNamespace, OnePagerRowSource} from "../../helpers/onepagerContent";
import StratairuOnePagerComplexRow from "../../components/stratairu/components/StratairuOnePagerComplexRow";
import StratairuPage from "../../components/stratairu/components/StratairuPage";
import {useOnePager} from "../../hooks/useOnePager";
import StratairuCommentIcon from "../../components/stratairu/components/StratairuCommentIcon";
import StratairuExportIcon from "../../components/stratairu/components/StratairuExportIcon";
import {apiBasePath} from "../../app/config";
import StratairuOnePagerStatusProgress from "../../components/stratairu/components/StratairuOnePagerStatusProgress";
import StratairuOnePagerTeamModal from "../../components/stratairu/components/StratairuOnePagerTeamModal";
import {checkOnePagerData} from "../../helpers/onepager";
import WarningIcon from '@mui/icons-material/Warning';

export const MAX_PILLARS = 5;
export const MAX_ENABLERS = 5;
export const MAX_ENABLER_TEAMS = 3;
export const MAX_CVBS = 5;
export const MAX_SUCCESS_LOOKS = 5;

export const MAX_INITIATIVES = 5;
export const MAX_INITIATIVE_SUCCESS_LOOKS = 3;
export const MAX_INITIATIVE_TEAMS = 3;

export const AUTOSAVE_INTERVAL = 30000;

const StratairuOnePagerPage = () => {

  const t = useAppTranslation();

  const dispatch = useAppDispatch();
  const modal = useModal();

  const onePager = useSelector(selectStrategyOnePager);
  const selfOnePager = useSelector(selectStrategySelfOnePager);
  const rootOnePager = useSelector(selectStrategyRootOnePager);
  const parentOnePager = useSelector(selectStrategyParentOnePager);
  const teams = useSelector(selectOnePagerSubTeamsCombo);

  const context = useSelector(selectStrategyContext);
  const activeIndex = useSelector(selectStrategyOnePagerActiveIndex);
  const codebooks = useSelector(selectCodebooks);

  const user = useSelector(selectAuthUser);

  const [structurePivot, setStructurePivot] = useState<PillarStructurePivot|undefined>(undefined);
  const [showComments, setShowComments] = useState<boolean>(false);
  const [help, setHelp] = useState<string | null>(null);
  const [initialized, setInitialized] = useState<string|undefined>(undefined);
  const [accessGranted, setAccessGranted] = useState<boolean>(false);

  const [teamIndex, setTeamIndex] = useState<OnepagerEntityIndex|undefined>(undefined);
  const [editedTeam, setEditedTeam] = useState<JsonStratTeam|null>(null);
  const [listTeams, setListTeams] = useState<JsonStratTeam[]>([]);


  const { id } = useParams();
  const { checkAccess } = useOnePager();

  const isRootOnepager = onePager?.onepagerId === rootOnePager?.onepagerId;

  const loggedUser = useSelector(selectAuthUser);

  const ns = getOnePagerNamespace(loggedUser, onePager, selfOnePager, parentOnePager, rootOnePager);

  useEffect(() => {

    if (!loggedUser?.userId) {
      return;
    }

    if (id && initialized !== id) {
      setInitialized(id);
      dispatch(fetchOnePager(+id))
          .then((result: any) => {
            if (result?.payload?.data) {
              if (checkAccess(result?.payload?.data as JsonStratOnepagerInfo)) {
                const strategyId = +result.payload.data.strategyId;
                dispatch(fetchStrategyContext(strategyId));
                dispatch(fetchDashboard({ strategyId: strategyId }));
                dispatch(fetchComments(+id));
                dispatch(addVisited(+id));
                setAccessGranted(true);
              } else {
                setAccessGranted(false);
              }
            }
          });
    }
  }, [dispatch, id, loggedUser, initialized, checkAccess]);

  const onSave = useCallback(async(status: JsonStratOnepagerStatusEnum | undefined, showMessage: boolean = true) => {
    if (onePager) {
      if (status === JsonStratOnepagerStatusEnum.Finished) {
        const checkResult = checkOnePagerData(onePager);
        if (!checkResult.success) {
          return;
        }
      }
      const saveResult = await dispatch(saveOnePager({ status, autosave: false }));
      if (showMessage) {
        if (saveResult) {
          dispatch(addMessage({
            severity: 'success',
            title: t('stratairu.messages.onePagerSaveSuccess'),
          }));
        } else {
          dispatch(addMessage({
            severity: 'error',
            title: t('stratairu.messages.onePagerSaveError'),
          }));
        }
      }
    }
  }, [onePager, dispatch, t]);

  const onAutoSave = useCallback(async() => {
    if (onePager) {
      const saveResult = await dispatch(saveOnePager({ status: undefined, autosave: true }));
      if (!saveResult) {
        dispatch(addMessage({
          severity: 'error',
          title: t('stratairu.messages.onePagerAutoSaveError'),
        }));
      }
    }
  }, [onePager, dispatch, t]);

  const handleAction = useCallback(async (action: CommonActionsType, index: OnepagerEntityIndex, source: OnePagerRowSource) => {
    console.log({ action, index });
    switch (action) {
      case CommonActionsType.Create:
        await dispatch(addOnepagerEntity(index));
        if (index.entity === OnepagerEntityType.Pillar) {
          await onSave(undefined, false);
        }
        break;
      case CommonActionsType.Delete:
        const confirmResult = await modal.confirm({ title: t('onePager.messages.confirmation'), message: t('onePager.messages.deleteConfirm.' + index.entity) });
        if (confirmResult !== 'CONFIRM') {
          return;
        }
        dispatch(deleteOnepagerEntity(index));
        break;
      case CommonActionsType.Edit:
        switch (index.entity) {
          case OnepagerEntityType.InitiativeTeam:
              const listPillarTeams = onePager?.pillars[index.parentEntityIndex!]?.initiatives[index.entityIndex!]?.teams;
              const editedPillarTeam = listPillarTeams[index.subEntityIndex!];
              if (editedPillarTeam) {
                setTeamIndex(index);
                setEditedTeam(editedPillarTeam);
                setListTeams(listPillarTeams);
              } else {
                console.error('Team not found!');
              }
              break;
          case OnepagerEntityType.EnablerTeam:
              const listEnablerTeams = onePager?.enablers[index.entityIndex!]?.teams;
              const editedEnablerTeam = listEnablerTeams[index.subEntityIndex!];
              if (editedEnablerTeam) {
                setTeamIndex(index);
                setEditedTeam(editedEnablerTeam);
                setListTeams(listEnablerTeams);
              } else {
                console.error('Team not found!');
              }
              break;
          default:
              dispatch(setOnepagerActiveIndex(index));
              break;
        }
        break;
      case CommonActionsType.Down:
        if (index.entity === OnepagerEntityType.Pillar) {
          const pillar = onePager?.pillars[index.entityIndex!];
          setStructurePivot({
            pivotPillarId: pillar.pillarId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Down
          });
        }
        if (index.entity === OnepagerEntityType.Enabler) {
          const enabler = onePager?.enablers[index.entityIndex!];
          setStructurePivot({
            pivotEnablerId: enabler.enablerId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Down
          })
        }
        break;
      case CommonActionsType.Up:
        if (index.entity === OnepagerEntityType.Pillar) {
          const pillar = onePager?.pillars[index.entityIndex!];
          setStructurePivot({
            pivotPillarId: pillar.pillarId,
            pivotMode: GetStratPillarListUsingGETPivotModeEnum.Up
          });
        }
        break;
      case CommonActionsType.Help:
        let postfix = '';
        if (source !== OnePagerRowSource.SELF && [OnepagerEntityType.Ambition, OnepagerEntityType.Pillar].includes(index.entity)) {
          postfix = 'Parent';
        }
        setHelp('stratairu.onePager.' + ns + '.help.' + index.entity + postfix);
        break;
    }
  }, [onePager, dispatch, ns, modal, onSave, t]);

  const handleSave = useCallback(async (status: JsonStratOnepagerStatusEnum, showMessage: boolean = true) => {
    // check open text area
    if (activeIndex) {
      await dispatch(setOnepagerActiveIndex(undefined));
      setTimeout(async () => {
        await onSave(status, showMessage);
      }, 0);
    } else {
      await onSave(status, showMessage);
    }
  }, [activeIndex, onSave, dispatch]);

  const handleExportPDF = useCallback(() => {
    window.open(`${apiBasePath(getLocale())}/rest-service/strat-onepager/${onePager?.onepagerId}/export/onepager.pdf`, '_blank');
  }, [onePager])

  const handleEditTeamCancel = useCallback(() => {
    console.log('Edit team cancel');
    setTeamIndex(undefined);
    setEditedTeam(null);
  }, [])

  const handleEditTeamSave = useCallback((team: JsonStratTeam) => {
    console.log('Edit team save', { team, teamIndex });
    if (teamIndex) {
      dispatch(updateOnepagerEntity({index: teamIndex, data: team }));
    }
    setTeamIndex(undefined);
    setEditedTeam(null);
  }, [teamIndex, dispatch])

  // autosaver
  useInterval(() => {
    // TODO: autosave temporarily disabled
    // onAutoSave();
  }, AUTOSAVE_INTERVAL);

  const content = getOnePagerContentDef(user, onePager, selfOnePager, parentOnePager, rootOnePager, teams);

  const departmentId = selfOnePager?.companyDepartmentId || selfOnePager?.departmentId;
  const departmentName = departmentId ? (selfOnePager.companyDepartmentId ? context?.companyDepartments : codebooks['companyDepartment'])
      .find((item: JsonPoplistItem) => item.value === departmentId || item.value === departmentId + '' )?.label : '';

  const checkResult = checkOnePagerData(onePager, teams);

  return (
    <>
		{ !!onePager && !!rootOnePager && !!accessGranted &&
            <StratairuPage>
              <Container>
                <Grid container>
                  <Grid item xs={12}>
                    <StratairuPageHeader>
                    </StratairuPageHeader>
                  </Grid>
                  <Grid item xs={12}>
                    <StratairuLabel content={<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
                      <div>
                          { user.userId === onePager.userId ? t('stratairu.titles.myOnePager') : <span>{ selfOnePager.firstName + ' ' + selfOnePager.lastName } <span style={{ fontWeight: 200 }}>, { departmentName }</span></span> }
                      </div>
                      <StratairuOnePagerStatusProgress status={onePager.status}/>
                    </div>}>
                      <div style={{ display: 'flex', gap: '10px' }}>
                        <StratairuExportIcon onClick={handleExportPDF} />
                        <StratairuCommentIcon onClick={() => setShowComments((show) => !show)}/>
                      </div>
                    </StratairuLabel>
                  </Grid>
                  { !checkResult.success &&
                      <Grid item xs={12}>
                        {
                          (checkResult.messages || []).map((message) => {
                            return <Alert icon={<WarningIcon fontSize="inherit" />} severity="error">{ t(message.text, message.params) }</Alert>
                          })
                        }
                      </Grid>
                  }
                  <Grid item xs={12} md={showComments ? 9 : 12}>
                    { content?.map((row) =>
                        <StratairuOnePagerComplexRow
                            { ...row }
                            onAction={handleAction}
                            selfOnepager={onePager}
                            parentOnepager={parentOnePager}
                            rootOnepager={rootOnePager}
                        ></StratairuOnePagerComplexRow>
                    )}
                  </Grid>

                  { !!showComments && <StratairuOnePagerCommentsContainer onePager={onePager}></StratairuOnePagerCommentsContainer> }

                </Grid>
                { /* isEditable  && */
                    <StratairuOnePagerButtons onSave={handleSave} onePager={onePager} selfOnePager={selfOnePager} isRootOnepager={isRootOnepager}/>
                }
                <StratairuOnePagerPillarStructureModal pivot={structurePivot} onClose={() => setStructurePivot(undefined)}/>
                <StratairuOnePagerTeamModal teams={listTeams} data={editedTeam} onCancel={handleEditTeamCancel} onSave={handleEditTeamSave}/>
              </Container>
              </StratairuPage>
      }
        { /* <CommentsModal onePager={onePager} show={showComments} onClose={() => setShowComments(false)}></CommentsModal> */ }
      <HelpModal help={help} onClose={() => setHelp(null)}></HelpModal>
    </>
	);
}

export default StratairuOnePagerPage;
