import {Container, Grid} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import {
  JsonPoll,
  JsonPollInfo,
  StartRelayItemGroupUsingPOSTItemTypeEnum,
  StartRelayItemUsingPOSTItemTypeEnum,
  StopRelayItemGroupUsingPOSTItemTypeEnum,
  
} from "../../generated-api";
import { useAppDispatch } from "../../store";
import {fetchPoll, fetchRelay, fetchRelayGroups, fetchRelayItems, fetchRelayPolls, startRelayGroup, startRelayItem, stopRelayGroup, stopRelayItem} from "../../store/relay";
import {selectRelayGroups, selectRelayPolls, selectRelayPollsDef, selectRelayPollsWithStatus} from "../../store/selectors";
import { RelayPollListItem } from "./RemoteControllerItem";
import style from '../../assets/styles/layout';
import RemoteControllerItem from "./RemoteControllerItem";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import * as React from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";


const RemoteControllerPage = () => {

  const dispatch = useAppDispatch();

  const [currentItemIndex, setCurrentItemIndex] = useState<number>(0);
  const [maxItemIndex, setMaxItemIndex] = useState<number>(0);
  const [currentItem, setCurrentItem] = useState<RelayPollListItem | undefined>(undefined);

  const items = useSelector(selectRelayPollsWithStatus);
  const groups = useSelector(selectRelayGroups);
  const polls = useSelector(selectRelayPollsDef);
  const relayPolls = useSelector(selectRelayPolls);

  const { relayId } = useParams();

  useEffect(() => {
    if (relayId) {
      dispatch(fetchRelay(+relayId));
      dispatch(fetchRelayGroups(+relayId));
      dispatch(fetchRelayPolls(+relayId));
      dispatch(fetchRelayItems(+relayId));
    }
  }, [dispatch, relayId]);

  useEffect(() => {
    // All items
    const displayedGroups: number[] = [];
    const rows = items?.filter(
        // show only first row in group
        (row) => {
          if (!row.groupId) {
            return true;
          } else {
            if (!displayedGroups.includes(row.groupId)) {
              displayedGroups.push(row.groupId);
              return true;
            }
          }
          return false;
        }
    ) || [];
    rows.sort((a: RelayPollListItem, b: RelayPollListItem) => {
      const aOrder = (a.groupId ? groups?.find((group: RelayPollListItem) => group.groupId === a.groupId)?.orderNo : a.orderNo) || 0;
      const bOrder = (b.groupId ? groups?.find((group: RelayPollListItem) => group.groupId === b.groupId)?.orderNo : b.orderNo) || 0;
      if (aOrder === bOrder) {
        return (a.orderNo || 0) > (b.orderNo || 0) ? 1 : -1;
      }
      return aOrder > bOrder ? 1 : -1;
    })

    setCurrentItem(rows[currentItemIndex]);
    setMaxItemIndex(rows.length - 1);

  }, [items, currentItemIndex, groups, dispatch]);


  useEffect(() => {

    const poll = currentItem;

    if (!poll || !poll.pollId) {
      return;
    }

    // load poll deifinition if needed
    if (poll.groupId) {
      const pollToLoad: Promise<any>[] = [];
      const pollIds = relayPolls.filter((tempPoll: JsonPollInfo) => tempPoll.groupId === poll.groupId).map((tempPoll: JsonPollInfo) => tempPoll.pollId);
      pollIds.forEach((tempPollId: number) => {
        if (!polls?.find((pollDef: JsonPoll) => tempPollId === pollDef.pollId)) {
          pollToLoad.push(dispatch(fetchPoll(tempPollId)));
        }
      });
      Promise.all(pollToLoad);
    } else {
      if (!polls?.find((pollDef: JsonPoll) => pollDef.pollId === poll.pollId)) {
          dispatch(fetchPoll(poll.pollId));
      }
    }

  }, [currentItem, dispatch, polls, relayPolls]);


  const handleAction = useCallback(async (poll: RelayPollListItem, action: string) => {

    if (!poll.pollId) {
      return;
    }

    switch (action) {
      case 'START_POLL_QUESTION':
      case 'START_POLL_RESULTS':
        // console.log('START', action);
        if (relayId) {
          await dispatch(startRelayItem({
            relayId: +relayId,
            itemType: action === 'START_POLL_QUESTION' ? StartRelayItemUsingPOSTItemTypeEnum.PollQuestion : StartRelayItemUsingPOSTItemTypeEnum.PollResults, 
            componentId: poll.pollId 
          }));
          await dispatch(fetchRelayItems(+relayId));
        }
        break;
      case 'STOP_POLL_QUESTION':
      case 'STOP_POLL_RESULTS':
        // console.log('STOP', action, poll);
        if (relayId) {
          await dispatch(stopRelayItem({
            relayId: +relayId,
            itemId: (action === 'STOP_POLL_QUESTION' ? poll.pollQuestionActiveItem.itemId : poll.pollResultActiveItem.itemId) as number,
          }));
          await dispatch(fetchRelayItems(+relayId));
        }
        break;
      case 'START_GROUP_QUESTION': 
      case 'START_GROUP_RESULTS':
        // console.log({ start: poll });
        // console.log('START_GROUP', action);
        if (relayId && poll.groupId) {
          await dispatch(startRelayGroup({
            relayId: +relayId,
            itemType: action === 'START_GROUP_QUESTION' ? StartRelayItemGroupUsingPOSTItemTypeEnum.PollQuestion : StartRelayItemGroupUsingPOSTItemTypeEnum.PollResults, 
            groupId: poll.groupId
          }));
          await dispatch(fetchRelayItems(+relayId));
        }
        break;
      case 'STOP_GROUP_QUESTION': 
      case 'STOP_GROUP_RESULTS':
        // console.log({ start: poll });
        // console.log('START_GROUP', action);
        if (relayId && poll.groupId) {
          await dispatch(stopRelayGroup({
            relayId: +relayId,
            itemType: action === 'STOP_GROUP_QUESTION' ? StopRelayItemGroupUsingPOSTItemTypeEnum.PollQuestion : StopRelayItemGroupUsingPOSTItemTypeEnum.PollResults,
            groupId: poll.groupId
          }));
          await dispatch(fetchRelayItems(+relayId));
        }
        break;
      case 'REFRESH_POLL':
      case 'REFRESH_GROUP':
        if (relayId) {
          await dispatch(fetchRelayItems(+relayId));
        }
        break;
      default:
        break;
    }
  }, [dispatch, relayId])

	return (
		<Container>
          <Grid container>
            <Grid item xs={12} md={6} className={style.remoteController}>
              { currentItem && <RemoteControllerItem itemIndex={currentItemIndex} item={currentItem!} items={items} groups={groups} action={handleAction}></RemoteControllerItem> }
              <div className={style.buttons}>
                { currentItemIndex > 0 ? <div className={style.button} onClick={() => setCurrentItemIndex((current) => current - 1)}><ArrowBackIosNewIcon/>&nbsp;&nbsp;&nbsp;Předchozí</div> : <div></div> }
                { currentItemIndex < maxItemIndex ? <div className={style.button} onClick={() => setCurrentItemIndex((current) => current + 1)}>Další&nbsp;&nbsp;&nbsp;<ArrowForwardIosIcon/></div> : <div></div> }
              </div>
            </Grid>
          </Grid>
		</Container>
	);
}

export default RemoteControllerPage;
