import {useCallback, useEffect, useState} from 'react';
import {Alert, Button, Card, CardContent, CardHeader} from '@mui/material';
import {JsonStrategy} from "../../../../generated-api";
import { useAppTranslation } from "../../../../services/i18n";
import {useAppDispatch} from "../../../../store";
import SimpleDropzone from "../../../../components/form/dropzone/SimpleDropzone";
import {importStrategyUser} from "../../../../store/strategies";
import {checkImportResult, ImportStrategyUserError} from "../../../../helpers/strategy";
import dropzoneStyles from "../../../../assets/styles/dropzone.module.css";
import * as React from "react";
import {addMessage} from "../../../../store/localApp";
import styles from "../../../../assets/styles/layout";

interface Props {
    strategy: JsonStrategy | null;
    onImport?: () => void;
}

const StrategyImport = ({ strategy }: Props) => {

    const t = useAppTranslation();

    const dispatch = useAppDispatch();

    const [importFile, setImportFile] = useState<File | null>(null);
    const [importErrors, setImportErrors] = useState<ImportStrategyUserError[] | null>(null);
    const [importError, setImportError] = useState<any|null>(null);

    useEffect(() => {
        setImportErrors(null);
        setImportFile(null);
    }, [])

    const onDropImport = useCallback(async (file: File): Promise<boolean> => {
        try {
            const res = await dispatch(importStrategyUser({
                file,
                strategyId: strategy?.strategyId,
                testOnly: true
            }));
            if (!('error' in res)) {
                const errors = checkImportResult(res.payload.data);
                setImportErrors(errors);
                setImportError(null);
                if (errors) {
                    setImportFile(null);
                } else {
                    setImportFile(file);
                }
                return true;
            } else {
                setImportErrors(null);
                setImportError(res.error);
                dispatch(addMessage({
                    severity: 'error',
                    title: 'Importní soubor obsahuje chyby a nelze ho naimportovat',
                }));
            }
        } catch(e) {
        }
        setImportFile(null);
        return true;
    }, [strategy, dispatch])

    const handleImportFile = useCallback(async () => {
        if (!importFile || !strategy?.strategyId) {
            dispatch(addMessage({
                severity: 'error',
                title: t('Není dostupný validní soubor pro import.'),
            }));
            return false;
        }
        if (importFile && strategy?.strategyId) {
            try {
                const res = await dispatch(importStrategyUser({
                    file: importFile,
                    strategyId: strategy?.strategyId,
                    testOnly: false
                }));
                if (!('error' in res)) {
                    dispatch(addMessage({
                        severity: 'success',
                        title: t('Import uživatelů proběhl v pořádku.'),
                    }));
                    setImportErrors(null);
                    setImportError(null);
                    setImportFile(null);
                    return true;
                }
            } catch(e) {
            }
        }
    }, [dispatch, strategy, importFile, t])

	return (
        <Card style={{ border: "none", boxShadow: "none" }}>
            <CardHeader title={t('Import struktury')}/>
            <CardContent>
                { strategy?.strategyId && <SimpleDropzone onUploadFile={onDropImport} hideMessages={true}></SimpleDropzone> }
                { importFile && <>
                    <div className={dropzoneStyles.uploadedFileTitle}>Soubor připravený k importu</div>
                    <div>{ importFile.name }</div>
                    <Alert style={{ marginTop: '20px', marginBottom: '20px' }} severity="success">{ t('Importní soubor je v pořádku, stisknutím tlačítka importovat, provedete import do systému.') }</Alert>
                    <Button variant="contained" type="submit" fullWidth className={styles.yellowButton} onClick={handleImportFile}>{t('Importovat')}</Button>
                </> }
                { !!importError &&
                    <>
                        <Alert style={{ marginTop: '20px' }} severity="error">{ t('Importní soubor obsahuje chybu a proto jej není možné použít.') }</Alert>
                        <div style={{ maxHeight: '300px' }}>
                            <div className={dropzoneStyles.uploadedFileTitle}>{ t('Chyba v importu:') }</div>
                            <div>{ t('ImportErrors.' + importError.message) === 'importErrors.' + importError.message ? importError.message : t('importErrors.' + importError.message) } { importError.name ? ' - ' + importError.name : '' }</div>
                        </div>
                    </>
                }
                { !!importErrors?.length &&
                    <>
                        <Alert style={{ marginTop: '20px' }} severity="error">{ t('Importní soubor obsahuje níže uvedené chyby a proto jej není možné použít.') }</Alert>
                        <div style={{ maxHeight: '300px' }}>
                            <div className={dropzoneStyles.uploadedFileTitle}>{ t('Chyby v importu:') }</div>
                            { importErrors.map((error) => {
                                return (<div><b>{t('Řádek #')}{error.row}:</b> { t('ImportErrors.' + error.error) === 'importErrors.' + error.error ? error.error : t('importErrors.' + error.error) } { error.errorDesc ? ' - ' + error.errorDesc : '' }</div>)
                            })}
                        </div>
                    </>
                }
            </CardContent>
        </Card>
	);
}

export default StrategyImport;
