/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonSerieUserRegistrationResponse
 */
export interface JsonSerieUserRegistrationResponse {
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserRegistrationResponse
     */
    redirectUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserRegistrationResponse
     */
    registrationResultType?: JsonSerieUserRegistrationResponseRegistrationResultTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonSerieUserRegistrationResponseRegistrationResultTypeEnum {
    UserCreated = 'USER_CREATED',
    AlreadyRegistered = 'ALREADY_REGISTERED'
}

export function JsonSerieUserRegistrationResponseFromJSON(json: any): JsonSerieUserRegistrationResponse {
    return JsonSerieUserRegistrationResponseFromJSONTyped(json, false);
}

export function JsonSerieUserRegistrationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieUserRegistrationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redirectUrl': !exists(json, 'redirectUrl') ? undefined : json['redirectUrl'],
        'registrationResultType': !exists(json, 'registrationResultType') ? undefined : json['registrationResultType'],
    };
}

export function JsonSerieUserRegistrationResponseToJSON(value?: JsonSerieUserRegistrationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redirectUrl': value.redirectUrl,
        'registrationResultType': value.registrationResultType,
    };
}

