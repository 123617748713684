/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonCompanyData
 */
export interface JsonCompanyData {
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyData
     */
    backgroundColor?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyData
     */
    textColor?: string;
}

export function JsonCompanyDataFromJSON(json: any): JsonCompanyData {
    return JsonCompanyDataFromJSONTyped(json, false);
}

export function JsonCompanyDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyData {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'backgroundColor': !exists(json, 'backgroundColor') ? undefined : json['backgroundColor'],
        'textColor': !exists(json, 'textColor') ? undefined : json['textColor'],
    };
}

export function JsonCompanyDataToJSON(value?: JsonCompanyData | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'backgroundColor': value.backgroundColor,
        'textColor': value.textColor,
    };
}

