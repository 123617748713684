/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPoplistItem
 */
export interface JsonPoplistItem {
    /**
     * 
     * @type {string}
     * @memberof JsonPoplistItem
     */
    label?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPoplistItem
     */
    value?: string;
}

export function JsonPoplistItemFromJSON(json: any): JsonPoplistItem {
    return JsonPoplistItemFromJSONTyped(json, false);
}

export function JsonPoplistItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPoplistItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'label': !exists(json, 'label') ? undefined : json['label'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function JsonPoplistItemToJSON(value?: JsonPoplistItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'label': value.label,
        'value': value.value,
    };
}

