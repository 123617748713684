/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonGroupInfo,
    JsonGroupInfoFromJSON,
    JsonGroupInfoFromJSONTyped,
    JsonGroupInfoToJSON,
} from './JsonGroupInfo';

/**
 * 
 * @export
 * @interface ApiResultOfJsonGroupInfo
 */
export interface ApiResultOfJsonGroupInfo {
    /**
     * 
     * @type {JsonGroupInfo}
     * @memberof ApiResultOfJsonGroupInfo
     */
    data?: JsonGroupInfo;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonGroupInfo
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResultOfJsonGroupInfo
     */
    messageValues?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonGroupInfo
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonGroupInfo
     */
    result?: ApiResultOfJsonGroupInfoResultEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApiResultOfJsonGroupInfoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

export function ApiResultOfJsonGroupInfoFromJSON(json: any): ApiResultOfJsonGroupInfo {
    return ApiResultOfJsonGroupInfoFromJSONTyped(json, false);
}

export function ApiResultOfJsonGroupInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResultOfJsonGroupInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : JsonGroupInfoFromJSON(json['data']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageValues': !exists(json, 'messageValues') ? undefined : json['messageValues'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function ApiResultOfJsonGroupInfoToJSON(value?: ApiResultOfJsonGroupInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': JsonGroupInfoToJSON(value.data),
        'message': value.message,
        'messageValues': value.messageValues,
        'requestId': value.requestId,
        'result': value.result,
    };
}

