/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonAuthSerieUserResponse,
    ApiResultOfJsonAuthSerieUserResponseFromJSON,
    ApiResultOfJsonAuthSerieUserResponseToJSON,
    ApiResultOfJsonAuthUserInfo,
    ApiResultOfJsonAuthUserInfoFromJSON,
    ApiResultOfJsonAuthUserInfoToJSON,
    ApiResultOfJsonEpisodeInfo,
    ApiResultOfJsonEpisodeInfoFromJSON,
    ApiResultOfJsonEpisodeInfoToJSON,
    ApiResultOfJsonRelayInfo,
    ApiResultOfJsonRelayInfoFromJSON,
    ApiResultOfJsonRelayInfoToJSON,
    ApiResultOfJsonSerieUserRegistrationContext,
    ApiResultOfJsonSerieUserRegistrationContextFromJSON,
    ApiResultOfJsonSerieUserRegistrationContextToJSON,
    ApiResultOfJsonSerieUserRegistrationResponse,
    ApiResultOfJsonSerieUserRegistrationResponseFromJSON,
    ApiResultOfJsonSerieUserRegistrationResponseToJSON,
    ApiResultOfListOfJsonStratOnepagerInfo,
    ApiResultOfListOfJsonStratOnepagerInfoFromJSON,
    ApiResultOfListOfJsonStratOnepagerInfoToJSON,
    ApiResultOfstring,
    ApiResultOfstringFromJSON,
    ApiResultOfstringToJSON,
    JsonLoginRequest,
    JsonLoginRequestFromJSON,
    JsonLoginRequestToJSON,
    JsonSerieUserRegistrationRequest,
    JsonSerieUserRegistrationRequestFromJSON,
    JsonSerieUserRegistrationRequestToJSON,
    JsonUpdateUserRequest,
    JsonUpdateUserRequestFromJSON,
    JsonUpdateUserRequestToJSON,
} from '../models';

export interface AliasUsingPOSTRequest {
    aliasUserId: number;
}

export interface ChangePasswordUsingPOSTRequest {
    auth: JsonUpdateUserRequest;
}

export interface ForgottenPasswordUsingPOSTRequest {
    auth: JsonLoginRequest;
}

export interface GetAliasesForStrategyUsingGETRequest {
    strategyId: number;
}

export interface GetCurrentEpisodeByClientGuidUsingGETRequest {
    clientGuid: string;
}

export interface GetCurrentRelayByClientGuidUsingGETRequest {
    clientGuid: string;
}

export interface GetSerieUserByClientGuidUsingGETRequest {
    clientGuid: string;
}

export interface GetSerieUserRegistrationUsingGETRequest {
    serieGuid: string;
}

export interface LoginUsingPOSTRequest {
    auth: JsonLoginRequest;
}

export interface LogoutUsingGETRequest {
    cookie?: string;
}

export interface SaveSerieUserRegistrationUsingPOSTRequest {
    json: JsonSerieUserRegistrationRequest;
}

export interface ValidateEmailUsingGETRequest {
    email: string;
}

/**
 * 
 */
export class AuthControllerApi extends runtime.BaseAPI {

    /**
     * alias
     */
    async aliasUsingPOSTRaw(requestParameters: AliasUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAuthUserInfo>> {
        if (requestParameters.aliasUserId === null || requestParameters.aliasUserId === undefined) {
            throw new runtime.RequiredError('aliasUserId','Required parameter requestParameters.aliasUserId was null or undefined when calling aliasUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/alias/{aliasUserId}`.replace(`{${"aliasUserId"}}`, encodeURIComponent(String(requestParameters.aliasUserId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAuthUserInfoFromJSON(jsonValue));
    }

    /**
     * alias
     */
    async aliasUsingPOST(requestParameters: AliasUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAuthUserInfo> {
        const response = await this.aliasUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * changePassword
     */
    async changePasswordUsingPOSTRaw(requestParameters: ChangePasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling changePasswordUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/change-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonUpdateUserRequestToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * changePassword
     */
    async changePasswordUsingPOST(requestParameters: ChangePasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.changePasswordUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * forgottenPassword
     */
    async forgottenPasswordUsingPOSTRaw(requestParameters: ForgottenPasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling forgottenPasswordUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/forgotten-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonLoginRequestToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * forgottenPassword
     */
    async forgottenPasswordUsingPOST(requestParameters: ForgottenPasswordUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.forgottenPasswordUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getAliasesForStrategy
     */
    async getAliasesForStrategyUsingGETRaw(requestParameters: GetAliasesForStrategyUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratOnepagerInfo>> {
        if (requestParameters.strategyId === null || requestParameters.strategyId === undefined) {
            throw new runtime.RequiredError('strategyId','Required parameter requestParameters.strategyId was null or undefined when calling getAliasesForStrategyUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/alias/strategy/{strategyId}`.replace(`{${"strategyId"}}`, encodeURIComponent(String(requestParameters.strategyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratOnepagerInfoFromJSON(jsonValue));
    }

    /**
     * getAliasesForStrategy
     */
    async getAliasesForStrategyUsingGET(requestParameters: GetAliasesForStrategyUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratOnepagerInfo> {
        const response = await this.getAliasesForStrategyUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCurrentEpisodeByClientGuid
     */
    async getCurrentEpisodeByClientGuidUsingGETRaw(requestParameters: GetCurrentEpisodeByClientGuidUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEpisodeInfo>> {
        if (requestParameters.clientGuid === null || requestParameters.clientGuid === undefined) {
            throw new runtime.RequiredError('clientGuid','Required parameter requestParameters.clientGuid was null or undefined when calling getCurrentEpisodeByClientGuidUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientGuid !== undefined) {
            queryParameters['clientGuid'] = requestParameters.clientGuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/current-episode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEpisodeInfoFromJSON(jsonValue));
    }

    /**
     * getCurrentEpisodeByClientGuid
     */
    async getCurrentEpisodeByClientGuidUsingGET(requestParameters: GetCurrentEpisodeByClientGuidUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEpisodeInfo> {
        const response = await this.getCurrentEpisodeByClientGuidUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCurrentRelayByClientGuid
     */
    async getCurrentRelayByClientGuidUsingGETRaw(requestParameters: GetCurrentRelayByClientGuidUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonRelayInfo>> {
        if (requestParameters.clientGuid === null || requestParameters.clientGuid === undefined) {
            throw new runtime.RequiredError('clientGuid','Required parameter requestParameters.clientGuid was null or undefined when calling getCurrentRelayByClientGuidUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientGuid !== undefined) {
            queryParameters['clientGuid'] = requestParameters.clientGuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/current-relay`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonRelayInfoFromJSON(jsonValue));
    }

    /**
     * getCurrentRelayByClientGuid
     */
    async getCurrentRelayByClientGuidUsingGET(requestParameters: GetCurrentRelayByClientGuidUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonRelayInfo> {
        const response = await this.getCurrentRelayByClientGuidUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getInfo
     */
    async getInfoUsingGETRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAuthUserInfo>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAuthUserInfoFromJSON(jsonValue));
    }

    /**
     * getInfo
     */
    async getInfoUsingGET(initOverrides?: RequestInit): Promise<ApiResultOfJsonAuthUserInfo> {
        const response = await this.getInfoUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getSerieUserByClientGuid
     */
    async getSerieUserByClientGuidUsingGETRaw(requestParameters: GetSerieUserByClientGuidUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAuthSerieUserResponse>> {
        if (requestParameters.clientGuid === null || requestParameters.clientGuid === undefined) {
            throw new runtime.RequiredError('clientGuid','Required parameter requestParameters.clientGuid was null or undefined when calling getSerieUserByClientGuidUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.clientGuid !== undefined) {
            queryParameters['clientGuid'] = requestParameters.clientGuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/serie-user`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAuthSerieUserResponseFromJSON(jsonValue));
    }

    /**
     * getSerieUserByClientGuid
     */
    async getSerieUserByClientGuidUsingGET(requestParameters: GetSerieUserByClientGuidUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAuthSerieUserResponse> {
        const response = await this.getSerieUserByClientGuidUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieUserRegistration
     */
    async getSerieUserRegistrationUsingGETRaw(requestParameters: GetSerieUserRegistrationUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerieUserRegistrationContext>> {
        if (requestParameters.serieGuid === null || requestParameters.serieGuid === undefined) {
            throw new runtime.RequiredError('serieGuid','Required parameter requestParameters.serieGuid was null or undefined when calling getSerieUserRegistrationUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.serieGuid !== undefined) {
            queryParameters['serieGuid'] = requestParameters.serieGuid;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/serie/registration`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieUserRegistrationContextFromJSON(jsonValue));
    }

    /**
     * getSerieUserRegistration
     */
    async getSerieUserRegistrationUsingGET(requestParameters: GetSerieUserRegistrationUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerieUserRegistrationContext> {
        const response = await this.getSerieUserRegistrationUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * login
     */
    async loginUsingPOSTRaw(requestParameters: LoginUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonAuthUserInfo>> {
        if (requestParameters.auth === null || requestParameters.auth === undefined) {
            throw new runtime.RequiredError('auth','Required parameter requestParameters.auth was null or undefined when calling loginUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonLoginRequestToJSON(requestParameters.auth),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonAuthUserInfoFromJSON(jsonValue));
    }

    /**
     * login
     */
    async loginUsingPOST(requestParameters: LoginUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonAuthUserInfo> {
        const response = await this.loginUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * logout
     */
    async logoutUsingGETRaw(requestParameters: LogoutUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/logout`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.cookie as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * logout
     */
    async logoutUsingGET(requestParameters: LogoutUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.logoutUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * ping
     */
    async pingUsingGETRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/ping`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * ping
     */
    async pingUsingGET(initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.pingUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * saveSerieUserRegistration
     */
    async saveSerieUserRegistrationUsingPOSTRaw(requestParameters: SaveSerieUserRegistrationUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerieUserRegistrationResponse>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveSerieUserRegistrationUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/auth/serie/registration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonSerieUserRegistrationRequestToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieUserRegistrationResponseFromJSON(jsonValue));
    }

    /**
     * saveSerieUserRegistration
     */
    async saveSerieUserRegistrationUsingPOST(requestParameters: SaveSerieUserRegistrationUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerieUserRegistrationResponse> {
        const response = await this.saveSerieUserRegistrationUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * validateEmail
     */
    async validateEmailUsingGETRaw(requestParameters: ValidateEmailUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling validateEmailUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.email !== undefined) {
            queryParameters['email'] = requestParameters.email;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/auth/validate-email`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * validateEmail
     */
    async validateEmailUsingGET(requestParameters: ValidateEmailUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.validateEmailUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
