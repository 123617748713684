/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonSerieUser,
    ApiResultOfJsonSerieUserFromJSON,
    ApiResultOfJsonSerieUserToJSON,
    ApiResultOfJsonSerieUserInfo,
    ApiResultOfJsonSerieUserInfoFromJSON,
    ApiResultOfJsonSerieUserInfoToJSON,
    ApiResultOfListOfJsonSerieUser,
    ApiResultOfListOfJsonSerieUserFromJSON,
    ApiResultOfListOfJsonSerieUserToJSON,
    ApiResultOfListOfJsonSerieUserInfo,
    ApiResultOfListOfJsonSerieUserInfoFromJSON,
    ApiResultOfListOfJsonSerieUserInfoToJSON,
    ApiResultOfVoid,
    ApiResultOfVoidFromJSON,
    ApiResultOfVoidToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonSerieUser,
    JsonSerieUserFromJSON,
    JsonSerieUserToJSON,
} from '../models';

export interface DeleteSerieUserUsingDELETERequest {
    serieUserId: number;
}

export interface GetSerieUserCountUsingGETRequest {
    serieId?: number;
    userId?: number;
    search?: string;
}

export interface GetSerieUserInfoUsingGETRequest {
    serieUserId: number;
}

export interface GetSerieUserListUsingGETRequest {
    serieId?: number;
    userId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetSerieUserUsingGETRequest {
    serieUserId: number;
}

export interface PrepareSerieUserImportUsingPOSTRequest {
    file: Blob;
    serieId?: any | null;
    companyId?: any | null;
    testOnly?: any | null;
}

export interface SaveSerieUserUsingPOSTRequest {
    json: JsonSerieUser;
    testOnly?: boolean;
}

/**
 * 
 */
export class SerieUserControllerApi extends runtime.BaseAPI {

    /**
     * deleteSerieUser
     */
    async deleteSerieUserUsingDELETERaw(requestParameters: DeleteSerieUserUsingDELETERequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfVoid>> {
        if (requestParameters.serieUserId === null || requestParameters.serieUserId === undefined) {
            throw new runtime.RequiredError('serieUserId','Required parameter requestParameters.serieUserId was null or undefined when calling deleteSerieUserUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie-user/{serieUserId}`.replace(`{${"serieUserId"}}`, encodeURIComponent(String(requestParameters.serieUserId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfVoidFromJSON(jsonValue));
    }

    /**
     * deleteSerieUser
     */
    async deleteSerieUserUsingDELETE(requestParameters: DeleteSerieUserUsingDELETERequest, initOverrides?: RequestInit): Promise<ApiResultOfVoid> {
        const response = await this.deleteSerieUserUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieUserCount
     */
    async getSerieUserCountUsingGETRaw(requestParameters: GetSerieUserCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie-user/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getSerieUserCount
     */
    async getSerieUserCountUsingGET(requestParameters: GetSerieUserCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getSerieUserCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieUserInfo
     */
    async getSerieUserInfoUsingGETRaw(requestParameters: GetSerieUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerieUserInfo>> {
        if (requestParameters.serieUserId === null || requestParameters.serieUserId === undefined) {
            throw new runtime.RequiredError('serieUserId','Required parameter requestParameters.serieUserId was null or undefined when calling getSerieUserInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie-user/info/{serieUserId}`.replace(`{${"serieUserId"}}`, encodeURIComponent(String(requestParameters.serieUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieUserInfoFromJSON(jsonValue));
    }

    /**
     * getSerieUserInfo
     */
    async getSerieUserInfoUsingGET(requestParameters: GetSerieUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerieUserInfo> {
        const response = await this.getSerieUserInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieUserList
     */
    async getSerieUserListUsingGETRaw(requestParameters: GetSerieUserListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonSerieUserInfo>> {
        const queryParameters: any = {};

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie-user/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonSerieUserInfoFromJSON(jsonValue));
    }

    /**
     * getSerieUserList
     */
    async getSerieUserListUsingGET(requestParameters: GetSerieUserListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonSerieUserInfo> {
        const response = await this.getSerieUserListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieUser
     */
    async getSerieUserUsingGETRaw(requestParameters: GetSerieUserUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerieUser>> {
        if (requestParameters.serieUserId === null || requestParameters.serieUserId === undefined) {
            throw new runtime.RequiredError('serieUserId','Required parameter requestParameters.serieUserId was null or undefined when calling getSerieUserUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie-user/{serieUserId}`.replace(`{${"serieUserId"}}`, encodeURIComponent(String(requestParameters.serieUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieUserFromJSON(jsonValue));
    }

    /**
     * getSerieUser
     */
    async getSerieUserUsingGET(requestParameters: GetSerieUserUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerieUser> {
        const response = await this.getSerieUserUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * prepareSerieUserImport
     */
    async prepareSerieUserImportUsingPOSTRaw(requestParameters: PrepareSerieUserImportUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonSerieUser>> {
        if (requestParameters.file === null || requestParameters.file === undefined) {
            throw new runtime.RequiredError('file','Required parameter requestParameters.file was null or undefined when calling prepareSerieUserImportUsingPOST.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.serieId !== undefined) {
            formParams.append('serieId', requestParameters.serieId);
                    }

        if (requestParameters.companyId !== undefined) {
            formParams.append('companyId', requestParameters.companyId);
                    }

        if (requestParameters.testOnly !== undefined) {
            formParams.append('testOnly', requestParameters.testOnly);
                    }

        if (requestParameters.file !== undefined) {
            formParams.append('file', requestParameters.file as any);
        }

        const response = await this.request({
            path: `/rest-service/serie-user/import`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonSerieUserFromJSON(jsonValue));
    }

    /**
     * prepareSerieUserImport
     */
    async prepareSerieUserImportUsingPOST(requestParameters: PrepareSerieUserImportUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonSerieUser> {
        const response = await this.prepareSerieUserImportUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveSerieUser
     */
    async saveSerieUserUsingPOSTRaw(requestParameters: SaveSerieUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerieUser>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveSerieUserUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/serie-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonSerieUserToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieUserFromJSON(jsonValue));
    }

    /**
     * saveSerieUser
     */
    async saveSerieUserUsingPOST(requestParameters: SaveSerieUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerieUser> {
        const response = await this.saveSerieUserUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
