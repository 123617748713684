/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonNotification
 */
export interface JsonNotification {
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonNotification
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonNotification
     */
    episodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    failedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonNotification
     */
    notificationId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    notificationType?: JsonNotificationNotificationTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    recipientBcc?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    recipientCc?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    recipientTo?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    sentAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonNotification
     */
    serieId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    status?: JsonNotificationStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonNotification
     */
    subject?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonNotification
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonNotificationNotificationTypeEnum {
    UserActivate = 'USER_ACTIVATE',
    UserRecover = 'USER_RECOVER',
    SerieAdminLink = 'SERIE_ADMIN_LINK',
    SerieUserFinalize = 'SERIE_USER_FINALIZE',
    SerieUserLink = 'SERIE_USER_LINK',
    SerieUserLinkPost = 'SERIE_USER_LINK_POST',
    SerieUserWorkbook = 'SERIE_USER_WORKBOOK',
    SerieUserWelcome = 'SERIE_USER_WELCOME',
    SerieUserInvitation = 'SERIE_USER_INVITATION',
    SerieUserInvitationU = 'SERIE_USER_INVITATION_U',
    SerieUserAuditWelcome = 'SERIE_USER_AUDIT_WELCOME',
    TestSerieUserLink = 'TEST_SERIE_USER_LINK',
    StratUserActivate = 'STRAT_USER_ACTIVATE',
    StratUserRecover = 'STRAT_USER_RECOVER',
    LabUserActivate = 'LAB_USER_ACTIVATE',
    LabUserRecover = 'LAB_USER_RECOVER'
}/**
* @export
* @enum {string}
*/
export enum JsonNotificationStatusEnum {
    Deleted = 'DELETED',
    Pending = 'PENDING',
    Sent = 'SENT',
    Skipped = 'SKIPPED',
    Failed = 'FAILED'
}

export function JsonNotificationFromJSON(json: any): JsonNotification {
    return JsonNotificationFromJSONTyped(json, false);
}

export function JsonNotificationFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonNotification {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'body': !exists(json, 'body') ? undefined : json['body'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'failedAt': !exists(json, 'failedAt') ? undefined : json['failedAt'],
        'notificationId': !exists(json, 'notificationId') ? undefined : json['notificationId'],
        'notificationType': !exists(json, 'notificationType') ? undefined : json['notificationType'],
        'recipientBcc': !exists(json, 'recipientBcc') ? undefined : json['recipientBcc'],
        'recipientCc': !exists(json, 'recipientCc') ? undefined : json['recipientCc'],
        'recipientTo': !exists(json, 'recipientTo') ? undefined : json['recipientTo'],
        'sentAt': !exists(json, 'sentAt') ? undefined : json['sentAt'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'subject': !exists(json, 'subject') ? undefined : json['subject'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonNotificationToJSON(value?: JsonNotification | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'body': value.body,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'episodeId': value.episodeId,
        'failedAt': value.failedAt,
        'notificationId': value.notificationId,
        'notificationType': value.notificationType,
        'recipientBcc': value.recipientBcc,
        'recipientCc': value.recipientCc,
        'recipientTo': value.recipientTo,
        'sentAt': value.sentAt,
        'serieId': value.serieId,
        'status': value.status,
        'subject': value.subject,
        'userId': value.userId,
    };
}

