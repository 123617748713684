import {
	Middleware,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';
import {t} from '../services/i18n';
import {getApiErrorMessage} from '../helpers/api';

/**
 * Show api error message for unhandled api errors
 * @param errorMessage
 */
export const apiErrorMiddlewareFactory: (
) => Middleware =
	() =>
	store =>
	next =>
	(action: PayloadAction<never, any, any, SerializedError>) => {
		if (
			action.error &&
			!action.meta.rejectedWithValue &&
			action.error.code !== 'abort'
		) {
			const message = getApiErrorMessage(action, t);
			console.log('apiErrorMiddleware', action, message);
			// store.dispatch(); // TODO - some error message action
		}

		return next(action);
	};
