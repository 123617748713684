import { PropsWithChildren } from 'react';
import { useAppTranslation } from "../../../../services/i18n";

import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';
import RefreshIcon from '@mui/icons-material/Refresh';

import styles from '../../../../assets/styles/relay';
import layoutStyles from '../../../../assets/styles/layout';
import { RelayItemStatus } from './RelayPoolList';

interface Props {
    status: RelayItemStatus | null;
    count: number;
    onStart?: () => void;
    onStop?: () => void;
    onRefresh?: () => void;
}

const RelayItemActions = ({ status, count, onStart, onStop, onRefresh }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

	return (
        <div className={styles.actionButtonContainer} style={{ display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
            <div>
                <div className={layoutStyles.listInfo}>{t('Zobrazeno')}: { count }x</div>
                { /* <div className={layoutStyles.listInfo}>{t('Stav')}: { status }</div> */ }
            </div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { onRefresh &&
                    <RefreshIcon color="primary" onClick={onRefresh}></RefreshIcon>
                }
                { onStart && status === 'AVAILABLE' && <div className={styles.startButton} onClick={onStart}>
                    {t('START')}
                    <PlayArrowIcon></PlayArrowIcon>
                </div> }
                { onStop && status === 'ACTIVE' && <div className={styles.stopButton} onClick={onStop}>
                    {t('STOP')}
                    <StopIcon></StopIcon>
                </div> }
            </div>
        </div>
	);
}

export default RelayItemActions;
