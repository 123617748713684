import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import {FormProps} from '../model/form';
import {useAppTranslation} from '../services/i18n';

export interface CompanySerieFormData {
    serieId: number | null;
};

interface Props extends FormProps<CompanySerieFormData> {}

const CompanySerieForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: CompanySerieFormData) => {
        let errors = {} as FormikErrors<CompanySerieFormData>;
        if (!values.serieId) {
            errors['serieId'] = t('Vyberte seriál, který chcete přiřadit');
        }
        return errors;
    }

    return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <SelectFormfield name="serieId" label={t('Seriál')} poplist="serieId" numberic></SelectFormfield>
            </Grid>
		</FormContainer>
	);
}

export default CompanySerieForm;
