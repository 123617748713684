import { FormikErrors } from "formik";
import { ReactNode } from "react";
import { JsonPoplistItem } from "../generated-api";

export interface FormfieldProps {
	name: string;
	label?: string;
    onChange?: (value: any) => void;
}

export interface PoplistFormfieldProps extends FormfieldProps {
    poplist?: string;
    poplistItems?: JsonPoplistItem[];
    numberic?: boolean;
    translate?: boolean;
    sortByLabel?: boolean;
}

export interface FormProps<T> {
    title?: string;
    cardLayout?: boolean;
    data: T | null;
    saveButtonTitle?: string;
    cancelButtonTitle?: string;
    headerAction?: ReactNode | undefined;
    validate?: (data: T) => FormikErrors<T> | undefined;
    onSave?: (data: T) => void;
    onCancel?: () => void;
}

export enum CommonActionsType {
    Create = 'CREATE',
    View = 'VIEW',
    Edit = 'EDIT',
    Delete = 'DELETE',
    Up = 'UP',
    Down = 'DOWN',
    Help = 'HELP',
    Comment = 'COMMENT',
    Cancel = 'CANCEL'
}

export const alwaysVisibleActions = [CommonActionsType.Up, CommonActionsType.Down];
