import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Button, Grid} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {FormikProps} from "formik";

import {useAppTranslation} from "../../../../services/i18n";
import ClosableModal, {ModalButton, ModalButtonActionEnum} from "../../../../components/layout/ClosableModal";
import FormContainer from "../../../../components/form/FormContainer";
import TextFormfield from "../../../../components/form/TextFormfield";
import {useAppDispatch} from "../../../../store";
import {copyPollsToEpisode, searchPolls} from "../../../../store/episodes";
import {StratairuButtonEnum} from "../../../../components/stratairu/components/StratairuButton";

import styles from '../../../../assets/styles/layout';
import {fetchTemplatePolls} from "../../../../store/relay";
import {JsonPollInfo} from "../../../../generated-api";

interface Props {
    open: boolean;
    episodeId: number;
    onClose: () => void;
    onSave: () => void;
}

interface SearchForm {
    search: string;
}

interface TemplateGroup {
    title: string;
    groupId: number;
}

const SearchPollModal = ({ open, episodeId, onClose, onSave }: Props) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [templatePolls, setTemplatePolls] = useState<JsonPollInfo[]>([]);
    const [templateGroups, setTemplateGroups] = useState<TemplateGroup[]>([]);
    const [foundPolls, setFoundPolls] = useState<JsonPollInfo[]>([]);
    const [selectedPolls, setSelectedPolls] = useState<JsonPollInfo[]>([]);

    const buttons = [
        { action: ModalButtonActionEnum.Close, title: t('Zavřít') },
        selectedPolls?.length && { action: ModalButtonActionEnum.Save, title: t('Přidat'), color: StratairuButtonEnum.Yellow },
    ].filter(button => !!button) as ModalButton[];

    useEffect(() => {
        if (open === true) {
            dispatch(fetchTemplatePolls())
                .then((result) => {
                    if (!('error' in result)) {
                        setTemplatePolls((result.payload?.data || []).filter((item: JsonPollInfo) => !item.rootPollId));
                        setTemplateGroups((result.payload?.data || []).reduce((acc: TemplateGroup[], item: JsonPollInfo) => {
                            if (!item.rootPollId && item.groupId && !acc.find(a => a.groupId === item.groupId)) {
                                acc.push({ title: item.groupTitle!, groupId: item.groupId });
                            }
                            return acc;
                        }, []));
                    }
                });
        }
    }, [dispatch, open])

    const handleSearch = useCallback(async (values: SearchForm) => {
        console.log({ values });
        const result = await dispatch(searchPolls(values.search));
        if (!('error' in result)) {
            setFoundPolls(result.payload.data?.filter((poll: JsonPollInfo) => {
                return poll.episodeId !== episodeId && !poll.rootPollId
            }) as JsonPollInfo[]);
        }
    }, [episodeId, dispatch]);

    const handleAdd = useCallback((poll: JsonPollInfo) => {
        console.log('handleAdd');
        setSelectedPolls((items) => {
            if (!items?.find((p) => p.pollId === poll.pollId)) {
                items.push(poll);
                return [...items];
            }
            return items;
        })
    }, []);

    const handleAddAll = useCallback((event: any) => {
        setSelectedPolls([...foundPolls]);
    }, [foundPolls]);

    const handleRemove = useCallback((poll: JsonPollInfo) => {
        console.log('handleRemove');
        setSelectedPolls((items) => { return items.filter((item) => item !== poll); })
    }, []);

    const handleAction = useCallback(async (action: ModalButtonActionEnum) => {
        console.log({ action });
        switch (action) {
            case ModalButtonActionEnum.Close:
                setFoundPolls([]);
                setSelectedPolls([]);
                break;
            case ModalButtonActionEnum.Save:
                const result = await dispatch(copyPollsToEpisode({ episodeId, pollIds: selectedPolls.map((poll) => poll.pollId!), testOnly: false }))
                setFoundPolls([]);
                setSelectedPolls([]);
                onSave();
                break;
        }
    }, [dispatch, selectedPolls, episodeId]);

    const handleAddTemplateGroup = useCallback((groupId: number) => {
        setFoundPolls([]);
        setSelectedPolls(templatePolls.filter((poll: JsonPollInfo) => poll.groupId === groupId));
    }, [templatePolls]);

    return (
        <ClosableModal open={open} title={t('Vyhledejte otázku, kterou chcete přidat')} onClose={onClose} buttons={buttons} onAction={handleAction} size="lg">
            <div style={{width: '100%'}}>
                <FormContainer data={{search: ''}} validate={(data: { search: string }) => undefined}>
                    {(formikProps: FormikProps<SearchForm>) => {
                        return (
                            <>
                                <Grid item xs={12} style={{display: 'flex', alignItems: 'center'}}>
                                    <TextFormfield name="search" label={t('Vyhledat')}></TextFormfield>
                                    <SearchIcon style={{cursor: 'pointer'}}
                                                onClick={() => handleSearch(formikProps.values)}/>
                                </Grid>
                                <Grid item xs={12}>
                                    { templateGroups?.map((grp: TemplateGroup) => <Button variant="outlined" type="submit" color="primary" onClick={() => handleAddTemplateGroup(grp.groupId)}>{ grp.title }</Button>)}
                                </Grid>
                            </>
                        )
                    }}
                </FormContainer>
                { !!foundPolls.length && <h2>{t('Nalezené otázky')} ({foundPolls.length})</h2> }
                <div style={{maxHeight: 'calc(100vh - 700px)', overflow: 'auto'}}>
                    {foundPolls.map((poll: JsonPollInfo) => <PollItem poll={poll} onAdd={() => handleAdd(poll)} />)}
                </div>
                { !!foundPolls.length &&
                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                        <Button variant="outlined" type="submit" color="primary" onClick={handleAddAll}>{t('Přidat vše')}</Button>
                    </div>
                }
                { !!selectedPolls.length && <h2>{t('Vybrané otázky')} ({selectedPolls.length})</h2> }
                <div style={{maxHeight: 'calc(100vh - 700px)', overflow: 'auto'}}>
                    {selectedPolls.map((poll: JsonPollInfo) => <PollItem poll={poll}  onRemove={() => handleRemove(poll)} />)}
                </div>
            </div>
        </ClosableModal>
    );

}

interface PollItemProps {
    poll: JsonPollInfo;
    onAdd?: () => void;
    onRemove?: () => void;
}

const PollItem = ({ poll, onAdd, onRemove }: PollItemProps) => {
    return (
        <div className={styles.pollListItem} onClick={() => {
            onAdd && onAdd();
            onRemove && onRemove();
        }}>
            <div>{poll.episodeTitle}</div>
            <div>{poll.groupTitle}</div>
            <div>{poll.title}</div>
            { !!onRemove && <div style={{ backgroundColor: 'var(--red)' }} className={styles.hoverOnly}><DeleteIcon/></div> }
            { !!onAdd && <div style={{ backgroundColor: 'var(--green)' }} className={styles.hoverOnly}><AddCircleIcon/></div> }
        </div>
    )
}

export default SearchPollModal;

