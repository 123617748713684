/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonChangeOfobject
 */
export interface JsonChangeOfobject {
    /**
     * 
     * @type {string}
     * @memberof JsonChangeOfobject
     */
    name?: string;
    /**
     * 
     * @type {object}
     * @memberof JsonChangeOfobject
     */
    newValue?: object;
    /**
     * 
     * @type {object}
     * @memberof JsonChangeOfobject
     */
    oldValue?: object;
    /**
     * 
     * @type {string}
     * @memberof JsonChangeOfobject
     */
    type?: JsonChangeOfobjectTypeEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonChangeOfobjectTypeEnum {
    I = 'I',
    U = 'U',
    D = 'D'
}

export function JsonChangeOfobjectFromJSON(json: any): JsonChangeOfobject {
    return JsonChangeOfobjectFromJSONTyped(json, false);
}

export function JsonChangeOfobjectFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonChangeOfobject {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'newValue': !exists(json, 'newValue') ? undefined : json['newValue'],
        'oldValue': !exists(json, 'oldValue') ? undefined : json['oldValue'],
        'type': !exists(json, 'type') ? undefined : json['type'],
    };
}

export function JsonChangeOfobjectToJSON(value?: JsonChangeOfobject | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'newValue': value.newValue,
        'oldValue': value.oldValue,
        'type': value.type,
    };
}

