import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {Grid} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import {FormikProps} from "formik";

import {useAppTranslation} from "../../../../services/i18n";
import ClosableModal, {ModalButton, ModalButtonActionEnum} from "../../../../components/layout/ClosableModal";
import FormContainer from "../../../../components/form/FormContainer";
import TextFormfield from "../../../../components/form/TextFormfield";
import {useAppDispatch} from "../../../../store";
import {copyPollsToEpisode, searchPolls} from "../../../../store/episodes";
import {JsonPollInfo, JsonRelayResetResponse, ResetRelayUsingPOSTRequest} from "../../../../generated-api";
import {StratairuButtonEnum} from "../../../../components/stratairu/components/StratairuButton";

import styles from '../../../../assets/styles/layout';
import {initStaticCodebooks, LocalCodebookItem} from "../../../../store/codebooks";
import {isMicroSite} from "../../../../app/config";
import {processLogin} from "../../../../store/auth";
import {cleanRelayResults} from "../../../../store/relay";

interface Props {
    open: boolean;
    relayId: number;
    onClose: () => void;
}

const CleanResultsModal = ({ open, relayId, onClose }: Props) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [cleanResult, setCleanResult] = useState<JsonRelayResetResponse|undefined>(undefined);

    useEffect(() => {
        if (open && relayId) {
            dispatch(cleanRelayResults({ relayId, resetRequest: { confirmedResponseCount: 0 }, testOnly: true } as ResetRelayUsingPOSTRequest))
                .then(result => {
                    if (!('error' in result)) {
                        setCleanResult(result.payload.data as JsonRelayResetResponse);
                    }
                })
        }
    }, [dispatch, t, relayId, open]);

    const handleAction = useCallback(async (action: ModalButtonActionEnum) => {
        console.log({ action });
        switch (action) {
            case ModalButtonActionEnum.Confirm:
                const result = await dispatch(cleanRelayResults({ relayId, resetRequest: { confirmedResponseCount: cleanResult?.responseCount }, testOnly: false } as ResetRelayUsingPOSTRequest))
                if (!('error' in result)) {
                    onClose && onClose();
                }
                break;
        }
    }, [dispatch, relayId, cleanResult]);

    const buttons = [
        { action: ModalButtonActionEnum.Close, title: t('Zavřít') },
        !!cleanResult && { action: ModalButtonActionEnum.Confirm, title: t('Smazat'), color: StratairuButtonEnum.Yellow },
    ].filter(button => !!button) as ModalButton[];

    return (
        <ClosableModal open={open} title={t('Skutečně chcete odstranit odpovědi?')} onClose={onClose} buttons={buttons} onAction={handleAction}>
            <div style={{width: '100%', marginBottom: '20px' }}>
                { t('Bude ostraněno {{ responseCount }} vysledků. Pro potvrzení stiskněte tlačítko smazat.', { responseCount: cleanResult?.responseCount } ) }
            </div>
        </ClosableModal>
    );

}

export default CleanResultsModal;

