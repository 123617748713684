/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompanyDepartment,
    ApiResultOfJsonCompanyDepartmentFromJSON,
    ApiResultOfJsonCompanyDepartmentToJSON,
    ApiResultOfJsonCompanyDepartmentInfo,
    ApiResultOfJsonCompanyDepartmentInfoFromJSON,
    ApiResultOfJsonCompanyDepartmentInfoToJSON,
    ApiResultOfListOfJsonCompanyDepartmentInfo,
    ApiResultOfListOfJsonCompanyDepartmentInfoFromJSON,
    ApiResultOfListOfJsonCompanyDepartmentInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompanyDepartment,
    JsonCompanyDepartmentFromJSON,
    JsonCompanyDepartmentToJSON,
} from '../models';

export interface GetCompanyDepartmentCountUsingGETRequest {
    companyId?: number;
    status?: GetCompanyDepartmentCountUsingGETStatusEnum;
    search?: string;
}

export interface GetCompanyDepartmentInfoUsingGETRequest {
    companyDepartmentId: number;
}

export interface GetCompanyDepartmentListUsingGETRequest {
    companyId?: number;
    status?: GetCompanyDepartmentListUsingGETStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanyDepartmentUsingGETRequest {
    companyDepartmentId: number;
}

export interface SaveCompanyDepartmentUsingPOSTRequest {
    json: JsonCompanyDepartment;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanyDepartmentControllerApi extends runtime.BaseAPI {

    /**
     * getCompanyDepartmentCount
     */
    async getCompanyDepartmentCountUsingGETRaw(requestParameters: GetCompanyDepartmentCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-department/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanyDepartmentCount
     */
    async getCompanyDepartmentCountUsingGET(requestParameters: GetCompanyDepartmentCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanyDepartmentCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyDepartmentInfo
     */
    async getCompanyDepartmentInfoUsingGETRaw(requestParameters: GetCompanyDepartmentInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyDepartmentInfo>> {
        if (requestParameters.companyDepartmentId === null || requestParameters.companyDepartmentId === undefined) {
            throw new runtime.RequiredError('companyDepartmentId','Required parameter requestParameters.companyDepartmentId was null or undefined when calling getCompanyDepartmentInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-department/info/{companyDepartmentId}`.replace(`{${"companyDepartmentId"}}`, encodeURIComponent(String(requestParameters.companyDepartmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyDepartmentInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyDepartmentInfo
     */
    async getCompanyDepartmentInfoUsingGET(requestParameters: GetCompanyDepartmentInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyDepartmentInfo> {
        const response = await this.getCompanyDepartmentInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyDepartmentList
     */
    async getCompanyDepartmentListUsingGETRaw(requestParameters: GetCompanyDepartmentListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanyDepartmentInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-department/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanyDepartmentInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyDepartmentList
     */
    async getCompanyDepartmentListUsingGET(requestParameters: GetCompanyDepartmentListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanyDepartmentInfo> {
        const response = await this.getCompanyDepartmentListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyDepartment
     */
    async getCompanyDepartmentUsingGETRaw(requestParameters: GetCompanyDepartmentUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyDepartment>> {
        if (requestParameters.companyDepartmentId === null || requestParameters.companyDepartmentId === undefined) {
            throw new runtime.RequiredError('companyDepartmentId','Required parameter requestParameters.companyDepartmentId was null or undefined when calling getCompanyDepartmentUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-department/{companyDepartmentId}`.replace(`{${"companyDepartmentId"}}`, encodeURIComponent(String(requestParameters.companyDepartmentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyDepartmentFromJSON(jsonValue));
    }

    /**
     * getCompanyDepartment
     */
    async getCompanyDepartmentUsingGET(requestParameters: GetCompanyDepartmentUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyDepartment> {
        const response = await this.getCompanyDepartmentUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompanyDepartment
     */
    async saveCompanyDepartmentUsingPOSTRaw(requestParameters: SaveCompanyDepartmentUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyDepartment>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanyDepartmentUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company-department`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanyDepartmentToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyDepartmentFromJSON(jsonValue));
    }

    /**
     * saveCompanyDepartment
     */
    async saveCompanyDepartmentUsingPOST(requestParameters: SaveCompanyDepartmentUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyDepartment> {
        const response = await this.saveCompanyDepartmentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetCompanyDepartmentCountUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCompanyDepartmentListUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
