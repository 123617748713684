/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonEpisode,
    JsonEpisodeFromJSON,
    JsonEpisodeFromJSONTyped,
    JsonEpisodeToJSON,
} from './JsonEpisode';

/**
 * 
 * @export
 * @interface JsonBanner
 */
export interface JsonBanner {
    /**
     * 
     * @type {string}
     * @memberof JsonBanner
     */
    bannerData?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonBanner
     */
    bannerId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonBanner
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonBanner
     */
    createdBy?: number;
    /**
     * 
     * @type {JsonEpisode}
     * @memberof JsonBanner
     */
    episode?: JsonEpisode;
    /**
     * 
     * @type {string}
     * @memberof JsonBanner
     */
    status?: JsonBannerStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonBanner
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonBanner
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonBannerStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonBannerFromJSON(json: any): JsonBanner {
    return JsonBannerFromJSONTyped(json, false);
}

export function JsonBannerFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonBanner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'bannerData': !exists(json, 'bannerData') ? undefined : json['bannerData'],
        'bannerId': !exists(json, 'bannerId') ? undefined : json['bannerId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'episode': !exists(json, 'episode') ? undefined : JsonEpisodeFromJSON(json['episode']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonBannerToJSON(value?: JsonBanner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'bannerData': value.bannerData,
        'bannerId': value.bannerId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'episode': JsonEpisodeToJSON(value.episode),
        'status': value.status,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

