import { PropsWithChildren } from 'react';
import { Chip, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import SimpleList, { ColumnDefinition, SimpleListAction } from "../../../../components/layout/SimpleList";
import { JsonPoll } from "../../../../generated-api";
interface Props {
    rows: JsonPoll[] | null;
    action: SimpleListAction<JsonPoll>;
}

const EpisodePollsList = (props: PropsWithChildren<Props>) => {

    const cols = [
        { 
            title: 'name', 
            renderer: (row: JsonPoll) => (
                <>
                    <div><Chip label={row.orderNo}/> <strong>{ row.title }</strong></div>
                    { /* <div><strong>{ JSON.stringify(row, null, 2) }</strong></div> */ }
                </>
            )
        },
        {
            renderer: (row: JsonPoll) => (
                <div style={{ width: '55px' }}>
                    <Tooltip title="Upravit skupinu">
                        <EditIcon color="primary" onClick={() => props.action(row, 'EDIT')}/>
                    </Tooltip>
                    <Tooltip title="Odstranit skupinu">
                        <DeleteIcon color="primary" onClick={() => props.action(row, 'DELETE')}/>
                    </Tooltip>
                </div>
            )
        }
    ] as ColumnDefinition<JsonPoll>[];

	return (
		<SimpleList cols={cols} config={{hideHeader: true, title: undefined }} { ...props }></SimpleList>
	);
}

export default EpisodePollsList;