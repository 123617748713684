import React, {PropsWithChildren} from 'react';
import {OnepagerEntityIndex} from "../../../model/onepager";
import StratairuOnePagerEditableText from "./StratairuOnePagerEditableText";
import styles from "../../../assets/styles/stratairu-onepager.module.css";
import classnames from "classnames";
import {CommonActionsType} from "../../../model/form";
import StratairuOnePagerActionsContainer from "./StratairuOnePagerActionsContainer";
import {useAppTranslation} from "../../../services/i18n";

interface PicosAttributes {
    description?: string;
    descriptionFrom?: string;
}

interface Props {
    index: OnepagerEntityIndex;
    readonly?: boolean;
    value?: PicosAttributes;
    editableDraft?: boolean;
    actions?: CommonActionsType[];
    onAction?: (action: CommonActionsType, index: OnepagerEntityIndex) => void;
    isHorizontal?: boolean;
}

export const getAllowedActions = (requestedActions: CommonActionsType[], allowedActions?: CommonActionsType[]) => {
  return requestedActions.filter((action: CommonActionsType) => !!allowedActions?.includes(action));
};

// Picos = Picture Of Success
const StratairuOnePagerPicos = ({ index, value, readonly, editableDraft, actions, onAction, isHorizontal }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    return (
        <div className={styles.picosContainer} style={{ flexDirection: isHorizontal ? 'row' : 'column' }}>
            { isHorizontal &&
                <div className={classnames(styles.editableText, styles.picosTitle, styles.enablerTitle)} style={{ height: '56px' }}>
                    { t('stratairu.onePager.picos.title') }<br/>{ t('stratairu.onePager.picos.fromTo') }
                </div>
            }
            <div className={styles.picosRow}>
                { !isHorizontal && <div className={classnames(styles.editableText, readonly ? styles.readonlyEditableText : undefined, styles.picosTitle)}>{ t('stratairu.onePager.picos.from') }</div> }
                <div className={styles.picosValue}>
                    <StratairuOnePagerActionsContainer index={{ ...index, attribute: 'descriptionFrom' }} actions={!readonly ? getAllowedActions([CommonActionsType.Edit], actions) : []} onAction={onAction}>
                        <StratairuOnePagerEditableText index={{ ...index, attribute: 'descriptionFrom' }} value={value?.descriptionFrom} readonly={readonly} editableDraft={editableDraft}/>
                    </StratairuOnePagerActionsContainer>
                </div>
            </div>
            <div className={styles.picosRow}>
                { !isHorizontal && <div className={classnames(styles.editableText, readonly ? styles.readonlyEditableText : undefined, styles.picosTitle)}>{ t('stratairu.onePager.picos.to') }</div> }
                <div className={styles.picosValue}>
                    <StratairuOnePagerActionsContainer index={{ ...index, attribute: 'description' }} actions={!readonly ? getAllowedActions([CommonActionsType.Edit, CommonActionsType.Delete], actions) : []} onAction={onAction}>
                        <StratairuOnePagerEditableText index={{ ...index, attribute: 'description' }} value={value?.description} readonly={readonly} editableDraft={editableDraft}/>
                    </StratairuOnePagerActionsContainer>
                </div>
            </div>
        </div>
	);
}

export default StratairuOnePagerPicos;
