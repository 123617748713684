/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfLinkedHashMapOfstringAndstring,
    ApiResultOfLinkedHashMapOfstringAndstringFromJSON,
    ApiResultOfLinkedHashMapOfstringAndstringToJSON,
    ApiResultOfstring,
    ApiResultOfstringFromJSON,
    ApiResultOfstringToJSON,
} from '../models';

export interface PurgeUserUsingGETRequest {
    userId: number;
}

export interface RecycleUsingGETRequest {
    date?: string;
}

export interface SyncRelayCacheUsingGETRequest {
    relayId: number;
}

/**
 * 
 */
export class ServiceControllerApi extends runtime.BaseAPI {

    /**
     * purgeUser
     */
    async purgeUserUsingGETRaw(requestParameters: PurgeUserUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling purgeUserUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/service/purge-user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * purgeUser
     */
    async purgeUserUsingGET(requestParameters: PurgeUserUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.purgeUserUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * recycle
     */
    async recycleUsingGETRaw(requestParameters: RecycleUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        const queryParameters: any = {};

        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/service/recycle`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * recycle
     */
    async recycleUsingGET(requestParameters: RecycleUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.recycleUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * runDaily
     */
    async runDailyUsingGETRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/service/run-daily`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * runDaily
     */
    async runDailyUsingGET(initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.runDailyUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * runMinuteJob
     */
    async runMinuteJobUsingGETRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/service/run-minute`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * runMinuteJob
     */
    async runMinuteJobUsingGET(initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.runMinuteJobUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * syncRelayCache
     */
    async syncRelayCacheUsingGETRaw(requestParameters: SyncRelayCacheUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfstring>> {
        if (requestParameters.relayId === null || requestParameters.relayId === undefined) {
            throw new runtime.RequiredError('relayId','Required parameter requestParameters.relayId was null or undefined when calling syncRelayCacheUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/service/sync-relay-cache/{relayId}`.replace(`{${"relayId"}}`, encodeURIComponent(String(requestParameters.relayId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfstringFromJSON(jsonValue));
    }

    /**
     * syncRelayCache
     */
    async syncRelayCacheUsingGET(requestParameters: SyncRelayCacheUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfstring> {
        const response = await this.syncRelayCacheUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * version
     */
    async versionUsingGETRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfLinkedHashMapOfstringAndstring>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/version`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfLinkedHashMapOfstringAndstringFromJSON(jsonValue));
    }

    /**
     * version
     */
    async versionUsingGET(initOverrides?: RequestInit): Promise<ApiResultOfLinkedHashMapOfstringAndstring> {
        const response = await this.versionUsingGETRaw(initOverrides);
        return await response.value();
    }

}
