import * as React from 'react';
import {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {
    JsonPollResults,
    JsonRelayItem,
    JsonRelayItemItemTypeEnum,
    JsonSerieSerieTypeEnum
} from "../../generated-api";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";
import styles from '../../assets/styles/relay';
import {fetchRelayItems} from "../../store/relay";
import {useAppDispatch} from "../../store";
import {renderFullScreenResultGroup, renderFullScreenResultItem} from "./MicroSiteMainPage";
import {useInterval} from "../../hooks/useInterval";
import atairuLabLogo from '../../assets/images/lab/atairulab_logo_dark.svg';

const sortItems = (a: JsonRelayItem, b: JsonRelayItem): number => {
    if ((a.poll?.pollId || 0) === (b.poll?.pollId || 0)) {
        return a.itemType === JsonRelayItemItemTypeEnum.PollResults ? 1 : -1;
    }
    return (a.poll?.orderNo || 0) > (b.poll?.orderNo || 0) ? 1 : -1;
}

const SimpleFullScreenPage = (props: { lang?: string, relayGuid?: string, clientGuid?: string, serieType?: JsonSerieSerieTypeEnum }) => {

    const dispatch = useAppDispatch();

    const [items, setItems] = useState<JsonRelayItem[]>([]);

    const { relayId, itemType, id } = useParams();

    console.log({ relayId });

    const reloadItems = useCallback(async () => {
        if (relayId && itemType && id) {
            const result = await dispatch(fetchRelayItems(+relayId));
            if (!('error' in result)) {
                const items = (result.payload as any).data
                    .filter((item: JsonRelayItem) => (itemType === 'group' && item.group?.groupId === +id) || (itemType === 'poll' && item.poll?.pollId === +id));
                items.sort((a: JsonRelayItem, b: JsonRelayItem) => (a.itemId || 0) < (b.itemId || 0) ? 1 : -1 );
                const reducedItems = items.reduce((acc: JsonRelayItem[], item: JsonRelayItem) => {
                    if (!acc.find((existing_item: JsonRelayItem) => existing_item.poll?.pollId === item.poll?.pollId)) {
                        acc.push(item);
                    }
                    return acc;
                }, []);
                reducedItems.sort(sortItems);
                console.log({ items: reducedItems });
                setItems(reducedItems);
            }
        }
    }, [relayId, itemType, id, dispatch]);

    useEffect(() => {
        reloadItems();
    }, [dispatch, reloadItems]);

    // refreshing data
    useInterval(
        () => {
            reloadItems();
        },
        10000,
    )

    const doneGroups: number[] = [];
    const activeItems: ReactJSXElement[] = [];

    items.forEach((item) => {
        console.log({ item });
        const group = item.group;
        if (group?.groupId) {
            const groupKey = group.groupId;
            if (doneGroups.indexOf(groupKey) >= 0) {
                return;
            }
            const groupItems = items.filter((item2) => item2.group?.groupId === group.groupId /* && item2.itemType === JsonRelayItemItemTypeEnum.PollResults */);
            const groupPollResults: { [key: number]: JsonPollResults } = {}; // by pollId & optionId
            groupItems.forEach((item) => {
                const pollId = item.poll?.pollId;
                if (pollId) {
                    groupPollResults[pollId] = item.pollResults!;
                }
            });
            if (groupItems.length) {
                activeItems.push(renderFullScreenResultGroup(groupItems, groupPollResults, {}, group));
            }
            doneGroups.push(groupKey);
            // TODO
        } else {
            const pollId = item.poll?.pollId;
            const results = (pollId && item.pollResults) || {};

            if (item.itemType === JsonRelayItemItemTypeEnum.PollResults) {
                activeItems.push(renderFullScreenResultItem(item, results, []));
            }
        }
    });

    console.log({ activeItems });

    // https://mui.com/material-ui/getting-started/usage/
    return (
        <div className={styles.fullScreenContainer}>
            <div>
                <img className={styles.logo} alt="Atairu" src={atairuLabLogo}/>
                {activeItems}
            </div>
        </div>
    );
}

export default SimpleFullScreenPage;
