import * as React from 'react';
import {useEffect, useState} from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {
    GetStratPillarListUsingGETPivotModeEnum,
    JsonPoplistItem,
    JsonStratEnablerInfo,
    JsonStratPillarInfo
} from "../../../generated-api";
import ClosableModal, {ModalButtonActionEnum} from "../../../components/layout/ClosableModal";
import {fetchEnablerInfo, fetchPillars} from "../../../store/strategy";
import {useAppDispatch} from "../../../store";
import styles from "../../../assets/styles/layout";
import {useSelector} from "react-redux";
import {selectCodebooks, selectStrategyContext} from "../../../store/selectors";

const RecursiveList = ({ parentPillarId, parentEnablerId, pillars, index }: { parentPillarId?: number, parentEnablerId?:number, pillars?: JsonStratPillarInfo[], index: number }) => {
    const filteredPillars = (pillars || []).filter(p => (parentPillarId && p.parentPillarId === parentPillarId) || (parentEnablerId && p.parentEnablerId === parentEnablerId))
    return <>
        {filteredPillars.map((p) => {
            const hasSubPillars = !!pillars?.find(sp => sp.parentPillarId === p.pillarId);
            return <>
                <div className={styles.simpleListRow} style={{ paddingLeft: (20 * index) + 'px' }}>{ p.description }</div>
                { hasSubPillars && <RecursiveList parentPillarId={p.pillarId} pillars={pillars} index={index + 1}/> }
            </>
        })}
        </>;
}

export interface PillarStructurePivot {
    pivotPillarId?: number | undefined;
    pivotEnablerId?: number | undefined;
    pivotMode: GetStratPillarListUsingGETPivotModeEnum | undefined;
}
interface Props {
    pivot: PillarStructurePivot | undefined;
    onClose: () => void;
}

const PillarStructureItem = ({ item }: { item: JsonStratPillarInfo | JsonStratEnablerInfo }) => {

    const codebooks = useSelector(selectCodebooks);
    const context = useSelector(selectStrategyContext);
    let positionLabel = '';
    let departmentLabel = '';

    console.log({ codebooks });

    if (item.companyPositionId) {
        positionLabel = context.companyPositions.find((pos: JsonPoplistItem) => pos.value === item.companyPositionId)?.label;
    } else {
        positionLabel = codebooks.companyPosition.find((pos: JsonPoplistItem) => pos.value === item.positionId)?.label || '';
    }

    if (item.companyDepartmentId) {
        departmentLabel = context.companyDepartments.find((pos: JsonPoplistItem) => pos.value === item.companyDepartmentId)?.label;
    } else {
        departmentLabel = codebooks.companyDepartment.find((pos: JsonPoplistItem) => pos.value === item.departmentId)?.label || '';
    }

    return (<div>
        <div>{ item.description }</div>
        <div>{ item.firstName } { item.lastName } ({ item.email })</div>
        <div>{ departmentLabel } / { positionLabel }</div>
    </div>);
}

const PillarStructureModal = ({ pivot, onClose }: Props) => {

    const t = useAppTranslation();

    const dispatch = useAppDispatch();

    const [drillUpPillars, setDrillUpPillars] = useState<JsonStratPillarInfo[]|undefined>(undefined);
    const [drillUpParentEnabler, setDrillUpParentEnabler] = useState<JsonStratEnablerInfo|undefined>(undefined);

    const [drillDownPillars, setDrillDownPillars] = useState<JsonStratPillarInfo[]|undefined>(undefined);
    const [drillDownRootPillar, setDrillDownRootPillar] = useState<JsonStratPillarInfo|undefined>(undefined);
    const [drillDownRootEnabler, setDrillDownRootEnabler] = useState<JsonStratEnablerInfo|undefined>(undefined);


    useEffect(() => {
        // load only when pivot is set
        if (!pivot) {
            return;
        }
        dispatch(fetchPillars({
            pivotPillarId: pivot?.pivotPillarId,
            pivotEnablerId: pivot?.pivotEnablerId,
            pivotMode: pivot?.pivotMode
        })).then((result) => {
            if (!('error' in result)) {
                if (pivot?.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Up) {
                    const pillars = result.payload.data || [];
                    pillars.sort((a, b) => (a.pillarPath || '').split('/').length > (b.pillarPath || '').split('/').length ? -1 : 1);
                    if (pillars && pillars[0] && pillars[0].parentEnablerId) {
                        dispatch(fetchEnablerInfo(pillars[0].parentEnablerId))
                            .then((enablerResult) => {
                                if (!('error' in enablerResult)) {
                                    setDrillUpParentEnabler(enablerResult.payload.data);
                                }
                            });
                    } else {
                        setDrillUpParentEnabler(undefined);
                    }
                    setDrillUpPillars(pillars);
                }
                if (pivot?.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Down) {
                    const pillars = result.payload.data || [];
                    setDrillDownPillars(pillars);
                    setDrillDownRootPillar(undefined);
                    setDrillDownRootEnabler(undefined);
                    if (pivot?.pivotPillarId) {
                        const rootPillar = pillars?.find(p => p.pillarId === pivot?.pivotPillarId);
                        setDrillDownRootPillar(rootPillar);
                    }
                    if (pivot?.pivotEnablerId) {
                        dispatch(fetchEnablerInfo(pivot?.pivotEnablerId))
                            .then((enablerResult) => {
                                if (!('error' in enablerResult)) {
                                    setDrillDownRootEnabler(enablerResult.payload.data);
                                }
                            });
                    }
                }
            }
        });
    }, [pivot, dispatch])

    const buttons = [
        { action: ModalButtonActionEnum.Close, title: t('Zavřít') }
    ]

    return (
        <>
            { pivot &&
                <ClosableModal open={!!pivot} title={t('Struktura pilířů')} onClose={onClose} buttons={buttons}>
                    { pivot.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Up &&
                        <div className={styles.simpleList} style={{ minWidth: '500px', boxSizing: 'border-box' }}>
                            { drillUpParentEnabler &&
                                <div className={styles.simpleListRow} style={{ paddingLeft: '0px' }}>
                                    <PillarStructureItem item={drillUpParentEnabler} />
                                </div>
                            }
                            { drillUpPillars?.map((pillar, index) =>
                                <div className={styles.simpleListRow} style={{ paddingLeft: (20 * (index + (drillUpParentEnabler ? 1 : 0))) + 'px' }}>
                                    <PillarStructureItem item={pillar} />
                                </div>
                            )}
                        </div>
                    }
                    { pivot.pivotMode === GetStratPillarListUsingGETPivotModeEnum.Down &&
                        <div className={styles.simpleList}  style={{ minWidth: '500px', boxSizing: 'border-box'}}>
                            <div className={styles.simpleListRow} style={{ paddingLeft: '0px' }}>{ drillDownRootEnabler?.description || drillDownRootPillar?.description }</div>
                            <RecursiveList parentEnablerId={pivot.pivotEnablerId} parentPillarId={pivot.pivotPillarId} pillars={drillDownPillars} index={1}/>
                        </div>
                    }
                </ClosableModal>
            }
        </>
    );

}

export default PillarStructureModal;
