import {useCallback, useEffect} from 'react';

import {Container, Tooltip} from '@mui/material';
import {useAppTranslation} from '../../services/i18n';
import {Link} from "react-router-dom";
import EditIcon from '@mui/icons-material/Edit';
import StopIcon from '@mui/icons-material/Stop';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote';

import PoplistValue from '../../components/form/PoplistValue';

import PageHeader from '../../components/layout/PageHeader';
import PageableList from '../../components/layout/PagableList';
import { selectRelays, selectRelaysPaging } from '../../store/selectors';

import styles from '../../assets/styles/layout';
import { useAppDispatch } from '../../store';
import { JsonRelay, JsonRelayInfo, JsonRelayInfoStatusEnum } from '../../generated-api/models';
import { fetchActiveRelay, fetchRelays, fetchRelaysCount, startRelay, stopRelay } from '../../store/relays';
import { fetchEpisodesCombo } from '../../store/codebooks';

const ListRelaysPage = () => {
	const t = useAppTranslation();
	const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(fetchEpisodesCombo());
    dispatch(fetchActiveRelay());
  }, [dispatch])

  const cols = [
    {
      title: t('Vysílání'),
      style: { width: '45%' },
      renderer: (row: JsonRelayInfo) => 
        <div>
          <div className={styles.listTitle}>
            <Link to={`/relays/${row.relayId}`} className={styles.link}><PoplistValue value={row.episodeId} poplist="episodeId"/></Link>
          </div>
          <div>
            <div className={styles.listInfo}>{row.relayGuid}</div>
          </div>
        </div>
    },
    {
      title: t('Stav'), 
      style: { width: '45%' },
      renderer: (row: JsonRelayInfo) => 
        <div className={styles.listInfo}>
          {row.status}
        </div>
    },
    {
      title: '',
      style: { width: '10%', display: 'flex', justifyContent: 'end' },
      renderer: (row: JsonRelayInfo) => 
        <div>
          { row.status === JsonRelayInfoStatusEnum.Active &&
            <>
              <Tooltip title="Dalkový ovladač">
               <Link target="_blank" to={`/microsite/remote-controller/${row.relayId}`}><SettingsRemoteIcon color="primary"/></Link>
              </Tooltip>
              <Tooltip title="Zastavit vysílání">
                <StopIcon color="primary" onClick={() => handleAction(row, 'STOP_RELAY')}/>
              </Tooltip>
              <Tooltip title="Spravovat vysílání">
                <Link to={`/relays/${row.relayId}`}><EditIcon color="primary"/></Link>
              </Tooltip>
            </>
          }
          { row.status !== JsonRelayInfoStatusEnum.Active &&
            <Tooltip title="Spustit vysílání">
              <PlayArrowIcon color="primary" onClick={() => handleAction(row, 'START_RELAY')}/>
            </Tooltip>
          }
        </div>
    }
  ]

  const handleAction = useCallback(async (relay: JsonRelayInfo, action: string) => {
    switch (action) {
      case 'START_RELAY':
      case 'STOP_RELAY':
        const result = await dispatch(action === 'START_RELAY' ? startRelay(relay as JsonRelay) : stopRelay(relay as JsonRelay));
        if ('error' in result) {
          return;
        }
        await dispatch(fetchRelays({}));
        await dispatch(fetchActiveRelay());
        break;
      default: 
        break;
    }
  }, [dispatch]);

  // https://mui.com/material-ui/getting-started/usage/
	return (
		<Container>
      <PageHeader title={t('Seznam vysílání')} />

      <PageableList 
        cols={cols} 
        action={handleAction}
        config={{
          selectItems: selectRelays,
          selectItemsPaging: selectRelaysPaging,
          fetchItemsCount: fetchRelaysCount,
          fetchItems: fetchRelays
        }}
      ></PageableList>

      { /* 
			<div style={{ height: 600, width: '100%' }}>
			<DataGrid
				rows={rows}
				columns={getColumns(t)}
				pageSize={5}
				rowsPerPageOptions={[10]}
			/>
      </div> */ }
    </Container>
	);
}

export default ListRelaysPage;
