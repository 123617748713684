import {useCallback, useState} from 'react';

import {Dropzone, FileUploadStatus, FileUploadStatusPhase} from './Dropzopne';
import {useAppDispatch} from "../../../store";
import {addMessage} from "../../../store/localApp";

interface Props {
	onUploadFile?: (file: File) => Promise<boolean>;
	displayTimeout?: number;
	hideMessages?: boolean;
}
const SimpleDropzone = ({ onUploadFile, displayTimeout, hideMessages }: Props) => {

	const dispatch = useAppDispatch();

	const [isUploading, setIsUploading] = useState<boolean>(false);
	const [filesUploadStatus, setFilesUploadStatus] = useState<FileUploadStatus[]>();

	const setFileUploadStatus = useCallback((tempFilesUploadStatus: FileUploadStatus[], file: File, status: FileUploadStatusPhase) => {
		const newStatuses = tempFilesUploadStatus?.map(sfile => sfile.file === file ? { ...sfile, status } : sfile);
		setFilesUploadStatus(newStatuses);
		return newStatuses;
	}, []);

	const onUploadPdf = useCallback(async (files: File[]) => {

		if (files.length) {
			let statuses = (files || []).map(file => ({ file, status: 'PENDING' } as FileUploadStatus));
			let allError = false;
			setFilesUploadStatus(statuses);
			setIsUploading(true);

			for (const file of files) {
				statuses = setFileUploadStatus(statuses, file, 'PROCESSING');
				const error = onUploadFile ? !await onUploadFile(file) : false;
				allError = allError || error;
				statuses = setFileUploadStatus(statuses, file, error ? 'ERROR' : 'FINISHED');
			}
			setIsUploading(false);
			// await reloadDrawings();
			if (allError) {
				if (!hideMessages) {
					dispatch(addMessage({
						severity: 'error',
						title: 'Došlo k chybě při nahrávání souborů.',
					}));
				}
			} else {
				if (!hideMessages) {
					dispatch(addMessage({
						severity: 'success',
						title: 'Všechny soubory byly nahrány. Počet nahraných souborů: ' + files.length,
					}));
				}
				setTimeout(() => setFilesUploadStatus(undefined), displayTimeout === 0 ? 0 : displayTimeout || 2000);
			}
		}

	}, [dispatch, onUploadFile, displayTimeout, setFileUploadStatus, hideMessages]);

	return (
		<Dropzone onDrop={onUploadPdf} isLoading={isUploading} uploadStatus={filesUploadStatus} multiple={true}></Dropzone>
	);
}

export default SimpleDropzone;
