import { useCallback, useState } from "react";
import { Container, Draggable } from "react-smooth-dnd";
import { JsonPoll, JsonPollOption, JsonPollResults, JsonRelayResponse } from "../../../generated-api";
import { TSendResponses } from "../../../helpers/relay";
import styles from '../../../assets/styles/relay';

interface Props {
    poll: JsonPoll, 
    formReadOnly: boolean, 
    newResponses: JsonRelayResponse[], 
    setNewResponses: TSendResponses, 
    results: JsonPollResults, 
    showResults: boolean
}

const RankingQuestion = ({ poll, formReadOnly, newResponses, setNewResponses, results, showResults }: Props) => {
    
    const [unsortedItems, setUnsortedItems] = useState<JsonPollOption[]>(poll?.options || []);
    const [sortedItems, setSortedItems] = useState<JsonPollOption[]>([]);

    const pollId = poll?.pollId as number;
    
    const onDrop = useCallback(({ removedIndex, addedIndex, payload, element }: { removedIndex: number | null, addedIndex: number | null, payload?: any, element?: any }) => {
        // console.log('onDrop', { removedIndex, addedIndex, payload, element });
        if (addedIndex === null && removedIndex !== null) {
            setUnsortedItems(unsortedItems.filter(item => item !== payload));
        }
    }, [unsortedItems]);

    const onDrop2 = useCallback(({ removedIndex, addedIndex, payload, element }: { removedIndex: number | null, addedIndex: number | null, payload?: any, element?: any }) => {
        // console.log('onDrop2', { removedIndex, addedIndex, payload, element });
        if (addedIndex !== null) {
            const newSortedItems: JsonPollOption[] = sortedItems.length ? sortedItems.reduce((acc: JsonPollOption[], item: JsonPollOption, index: number) => {
                // console.log({ index, addedIndex, sortedItemsLength: sortedItems.length });
                if (index === addedIndex) {
                    acc.push(payload);
                }
                if (removedIndex === null || removedIndex !== index) {
                    acc.push(item);
                }
                if (index === sortedItems.length - 1 && addedIndex === sortedItems.length) {
                    acc.push(payload);
                }
                // console.log({ acc, acc2: JSON.stringify(acc) });
                return acc;
            }, []) : [ payload ];
            setSortedItems(newSortedItems);
            if (poll?.options?.length === newSortedItems.length) {
                setNewResponses(newSortedItems.map(
                    (item: JsonPollOption, index: number) => ({ pollId: +pollId, pollOptionId: item.optionId, value: '' + (newSortedItems.length - index) })
                ));
            }
        }
    }, [sortedItems, setNewResponses, pollId, poll]);

    return (
        <>
            { !formReadOnly && 
                <>
                    <div className={styles.unsortedItems}>Seřazené položky</div>
                    <div style={{ position: 'relative' }}>
                    { !sortedItems.length && <div style={{ position: 'absolute', top: '20px', width: '100%', textAlign: 'center', color: '#666', fontSize: '80%' }}>Sem přetáhněte nazařazené položky</div> }
                    <Container onDrop={onDrop2} groupName="source" getChildPayload={(index: number) => sortedItems[index]} 
                        style={{ padding: '0px', backgroundColor: 'transparent', minHeight: '100px' /*, display: 'flex', justifyContent: 'center', alignItems: 'center'*/ }}>
                        {sortedItems?.map((item: JsonPollOption, index: number) => {
                            return (
                                <Draggable key={item.optionId}>
                                    <div className={styles.sortedItem}>
                                        <div>{(index + 1)}. {item.title}</div>
                                    </div>
                                </Draggable>
                            );
                        })}
                    </Container>
                    </div>
                    { !!unsortedItems.length && 
                        <>
                            <div className={styles.unsortedItems}>Nezařazeno</div>
                            <Container onDrop={onDrop} groupName="source" getChildPayload={(index: number) => unsortedItems[index]}>
                                {unsortedItems?.map(item => {
                                    return (
                                        <Draggable key={item.optionId}>
                                            <div className={styles.sortedItem}>
                                                <div>{item.title}</div>
                                            </div>
                                        </Draggable>
                                    );
                                })}
                            </Container>
                        </>
                    }
                </>
            }
            { !!formReadOnly && 
                <>
                    {sortedItems?.map((item: JsonPollOption, index: number) => 
                        (<div key={index} className={styles.sortedItem}>
                            <div>{(index + 1)}. {item.title}</div>
                        </div>))
                    }
                    {unsortedItems?.map((item: JsonPollOption, index: number) => 
                        (<div key={sortedItems.length + index} className={styles.sortedItem}>
                            <div>{(sortedItems.length + index + 1)}. {item.title}</div>
                        </div>))
                    }
                </>
            }
        </>
    );
}

export default RankingQuestion;
