import * as React from 'react';
import styles from '../../../assets/styles/stratairu.module.css';
import {PropsWithChildren, useEffect, useRef} from "react";
import {useAppTranslation} from "../../../services/i18n";
import {JsonStratEnablerInfo, JsonStratOnepagerInfo, JsonStratPillarInfo} from "../../../generated-api";
import {Nl2BrText} from "../../layout/Nl2BrText";
import {getOnePagerLevel} from "../../../helpers/strategy";

const SupportElementCount = ({ isEnabler, enablerId, pillarIds, subtree, level, rootLevel }: { isEnabler: boolean, enablerId?: number, pillarIds?: number[], subtree: JsonStratOnepagerInfo[], level: number, rootLevel: number }) => {

    const supportedPillarIds: number[] = [];
    let count = 0;
    let countLevel: number = 0;

    subtree?.filter(op => getOnePagerLevel(op) === level).forEach(
        (op) => {
            countLevel++;
            op.pillars?.filter(pillar => (pillar.parentEnablerId && pillar.parentEnablerId === enablerId) || (pillar.parentPillarId && pillarIds?.includes(pillar.parentPillarId)))
                .forEach(pillar => {
                    count++;
                    supportedPillarIds.push(pillar.pillarId!);
                })
        }
    )

    return <>
        { !!countLevel &&
            <>
                <div className={styles.supportElementContainer}>
                    { count }
                    <div className={isEnabler ? styles.NEW : styles.FINISHED}>-{ level - rootLevel }</div>
                </div>
                { !!count && <SupportElementCount isEnabler={isEnabler} pillarIds={supportedPillarIds} subtree={subtree} level={level+1} rootLevel={rootLevel} /> }
            </>
        }
    </>
}

interface Props {
    element: JsonStratPillarInfo | JsonStratEnablerInfo;
    index: number;
    subtree: JsonStratOnepagerInfo[],
    level: number,
    rootLevel: number,
    minHeight: number,
    setMinHeight: (val:number) => void
}
const StratairuElementCard = ({ element, index, level, rootLevel, subtree, minHeight, setMinHeight, children }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();
    const descRef = useRef(null);

    const isEnabler = Object.keys(element).includes('enablerId');
    const order = index + 1;

    useEffect(() => {
        // descRef?.current
        const clientHeight = (descRef?.current as any)?.clientHeight;
        console.log({ minHeight, clientHeight: (descRef?.current as any)?.clientHeight });
        if (!minHeight || minHeight < clientHeight) {
            console.log({ set: clientHeight });
            setMinHeight && setMinHeight(clientHeight);
        }
    }, [descRef, minHeight])

	return (
        <div className={styles.elementCard}>
            <div className={styles.title}>{ isEnabler ? t('stratairu.common.enabler') + ' ' + order : t('stratairu.common.pillar') + ' ' + order  }</div>
            <div className={styles.desc} ref={descRef} style={{ minHeight: minHeight + 'px', marginBottom: '10px' }}><Nl2BrText text={ element.description || '' } /></div>
            <SupportElementCount isEnabler={isEnabler} level={level} subtree={subtree} rootLevel={rootLevel} pillarIds={[(element as any).pillarId]} enablerId={(element as any).enablerId}></SupportElementCount>
        </div>
	);
}

export default StratairuElementCard;
