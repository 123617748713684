export enum AtairuEnv {
	Tv = 'tv',
	Stratairu = 'stratairu',
	Lab = 'lab'
};

export const getEnvironment = (): AtairuEnv => {
	console.log('ENV:' + document.location.host);
	if (document.location.host.includes('localhost:3000')) {
		return AtairuEnv.Stratairu;
	}
	if (document.location.host.includes('stratairu.com')) {
		return AtairuEnv.Stratairu;
	}
	if (document.location.host.includes('atairulab.com')) {
		return AtairuEnv.Lab;
	}
	if (document.location.host.includes('stratairu.codito.cz')) {
		return AtairuEnv.Stratairu;
	}
	return AtairuEnv.Tv;
}
