import { useFormikContext } from "formik";

import { JsonGroup, JsonPoll, JsonRelayItem, JsonRelayItemItemTypeEnum } from "../../generated-api";
import { generatePreviewData } from "../../helpers/relay";
import RelayItemGroup from "../relay/RelayItemGroup";

interface Props {
    items: JsonPoll[];
    itemType: JsonRelayItemItemTypeEnum;
}

const PreviewGroup = ({ items, itemType }: Props) => {

    const { values } = useFormikContext<JsonGroup>();

    const results = items.reduce((acc: any, item: JsonPoll) => {
        acc[item.pollId!] = generatePreviewData(item);
        return acc;
    }, []);

    const responses = items.reduce((acc: any, item: JsonPoll) => {
        acc[item.pollId!] = [];
        return acc;
    }, []);

    return (
        <>
            { /* JSON.stringify(values, null, 2) */ }
            <RelayItemGroup group={values} items={items.map((item: JsonPoll) => ({poll: item, pollResults: results, itemType } as JsonRelayItem))} results={results} responses={responses}/>
        </>
      );
}

export default PreviewGroup;
