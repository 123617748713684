import styles from '../../../assets/styles/stratairu.module.css';
import {CommonActionsType} from "../../../model/form";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PreviewIcon from "@mui/icons-material/Preview";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CommentIcon from "@mui/icons-material/Comment";
import CloseIcon from "@mui/icons-material/Close";
import {Badge} from "@mui/material";
import {ReactJSXElement} from "@emotion/react/types/jsx-namespace";

interface Props {
	action: CommonActionsType;
	withCount?: number;
	onClick: () => void;
}
export const StratairuCircleButton = ({ onClick, withCount, action }: Props) => {

	let Icon = EditIcon;
	switch (action) {
		case CommonActionsType.Edit:
			Icon = EditIcon;
			break;
		case CommonActionsType.Delete:
			Icon = DeleteIcon;
			break;
		case CommonActionsType.Create:
			Icon = AddIcon;
			break;
		case CommonActionsType.View:
			Icon = PreviewIcon;
			break;
		case CommonActionsType.Up:
			Icon = ArrowUpwardIcon;
			break;
		case CommonActionsType.Down:
			Icon = ArrowDownwardIcon;
			break;
		case CommonActionsType.Help:
			Icon = QuestionMarkIcon;
			break;
		case CommonActionsType.Comment:
			Icon = CommentIcon;
			break;
		case CommonActionsType.Cancel:
			Icon = CloseIcon;
			break;
	}

	return <div
			className={styles.circleButton}
			onClick={onClick}>
		{ withCount !== undefined && <Badge badgeContent={withCount} color="secondary">
			<Icon color="primary"/>
		</Badge> }
		{ withCount === undefined && <Icon color="primary"/> }
	</div>
};

/*
{ action === CommonActionsType.Edit && <EditIcon color="primary"/> }
{ action === CommonActionsType.Delete && <DeleteIcon color="primary"/> }
{ action === CommonActionsType.Create && <AddIcon color="primary"/> }
{ action === CommonActionsType.View && <PreviewIcon color="primary"/> }
{ action === CommonActionsType.Up && <ArrowUpwardIcon color="primary"/> }
{ action === CommonActionsType.Down && <ArrowDownwardIcon color="primary"/> }
{ action === CommonActionsType.Help && <QuestionMarkIcon color="primary"/> }
{ action === CommonActionsType.Comment && <CommentIcon color="primary"/> }
{ action === CommonActionsType.Cancel && <CloseIcon color="primary"/> }
*/
