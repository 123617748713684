import {
	AsyncThunk,
	Middleware,
	PayloadAction,
	SerializedError,
} from '@reduxjs/toolkit';

export const apiUnautorizedMiddlewareFactory: (
	fetchAuthLogout: AsyncThunk<any, void, any>,
) => Middleware =
	fetchAuthLogout =>
	store =>
	next =>
	(action: PayloadAction<never, any, any, SerializedError>) => {
		if (
			(action.error?.code === '403' || action.error?.code === '401') &&
			!fetchAuthLogout.rejected.match(action)
		) {
			store.dispatch(fetchAuthLogout() as any);
		}

		return next(action);
	};
