/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonStratTeam
 */
export interface JsonStratTeam {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonStratTeam
     */
    allowedActions?: Array<JsonStratTeamAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonStratTeam
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratTeam
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    enablerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    initiativeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonStratTeam
     */
    isRemoved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    orderNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratTeam
     */
    status?: JsonStratTeamStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    stratTeamId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    stratUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratTeam
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratTeam
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratTeam
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratTeamAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonStratTeamStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonStratTeamFromJSON(json: any): JsonStratTeam {
    return JsonStratTeamFromJSONTyped(json, false);
}

export function JsonStratTeamFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratTeam {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'enablerId': !exists(json, 'enablerId') ? undefined : json['enablerId'],
        'initiativeId': !exists(json, 'initiativeId') ? undefined : json['initiativeId'],
        'isRemoved': !exists(json, 'isRemoved') ? undefined : json['isRemoved'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'stratTeamId': !exists(json, 'stratTeamId') ? undefined : json['stratTeamId'],
        'stratUserId': !exists(json, 'stratUserId') ? undefined : json['stratUserId'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonStratTeamToJSON(value?: JsonStratTeam | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'enablerId': value.enablerId,
        'initiativeId': value.initiativeId,
        'isRemoved': value.isRemoved,
        'orderNo': value.orderNo,
        'status': value.status,
        'stratTeamId': value.stratTeamId,
        'stratUserId': value.stratUserId,
        'teamName': value.teamName,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

