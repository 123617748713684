import * as React from 'react';
import {PropsWithChildren, useCallback} from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {JsonStratOnepager, JsonStratOnepagerInfo} from "../../../generated-api";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import StarIcon from "@mui/icons-material/Star";
import {useAppDispatch} from "../../../store";
import {addMessage} from "../../../store/localApp";
import {switchFavourite} from "../../../store/auth";
import {
    selectAuthFavourites, selectAuthUser,
    selectStrategyDashboard,
    selectStrategyDashboardOnePagersType
} from "../../../store/selectors";
import {useSelector} from "react-redux";
import {OnepagersMenuEnum} from "../../../pages/stratairu/StratairuHomePage";
import {refreshFavouriteOnePagers, refreshOnePagers} from "../../../store/strategy";

interface Props {
    onepager: JsonStratOnepagerInfo | JsonStratOnepager;
}

const StratairuOnePagerFavouriteButton = ({ onepager, children }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();

    const dispatch = useAppDispatch();
    const favourite = useSelector(selectAuthFavourites);
    const onePagersType = useSelector(selectStrategyDashboardOnePagersType);
    const dashboard = useSelector(selectStrategyDashboard);
    const user = useSelector(selectAuthUser);
    const myOnePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => op.userId === user.userId);

    const handleSetFavourite = useCallback(async (event: any) => {
        event.stopPropagation();
        const result = await dispatch(switchFavourite(onepager.onepagerId!));
        if ('error' in result) {
            dispatch(addMessage({
                severity: 'error',
                title: favourite.includes(onepager.onepagerId!) ? t('stratairu.messages.removeFavouriteError') : t('stratairu.messages.addFavouriteError'),
            }));
            return;
        }
        dispatch(addMessage({
            severity: 'success',
            title: favourite.includes(onepager.onepagerId!) ? t('stratairu.messages.removeFavouriteSuccess') : t('stratairu.messages.addFavouriteSuccess'),
        }));
        if (onePagersType === OnepagersMenuEnum.Favourite) {
            dispatch(refreshOnePagers());
        } else if (!myOnePager?.onepagerId) {
            dispatch(refreshFavouriteOnePagers());
        }
    }, [favourite, dispatch, onepager, onePagersType, myOnePager, t])

    const isFavourite = favourite.includes(onepager.onepagerId);

	return (
        <div onClick={handleSetFavourite} style={{ display: 'flex', alignItems: 'center', gap: '10px', cursor: 'pointer' }}>
            <div style={{ color: 'var(--strat-yellow)'}}>
                { isFavourite ? <StarIcon /> : <StarBorderIcon style={{ color: '#ccc' }}/> }
            </div>
            <div>{ children }</div>
        </div>
	);
}

export default StratairuOnePagerFavouriteButton;
