import * as React from "react";
import {Card, CardContent, FormControl, FormLabel} from "@mui/material";
import {useAppTranslation} from "../../services/i18n";
import styles from '../../assets/styles/relay';

const RelayStats = (props: { relayStats: any }) => {
    const {relayStats} = props;
    const t = useAppTranslation();

    return <Card variant="elevation" className={styles.relayAlert}>
        <CardContent>
            <FormControl style={{width: "100%"}}>
                <FormLabel>{t('Připojeno uživatelů')}: <strong>{relayStats['clients']}</strong></FormLabel>
            </FormControl>
        </CardContent>
    </Card>
};

export default RelayStats;
