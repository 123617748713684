import {AppDispatch, RootState} from '../index';
import { authReducer } from "./auth";
import { useDispatch } from "react-redux";
import { codebooksReducer } from "./codebooks";
import { localAppReducer } from './localApp';
import { companiesReducer } from './companies';
import { usersReducer } from './users';
import { relayReducer } from './relay';
import { seriesReducer } from './series';
import { relaysReducer } from './relays';
import {strategiesReducer} from "./strategies";
import {strategyReducer} from "./strategy";

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
	codebooks: codebooksReducer,
	localApp: localAppReducer,
	companies: companiesReducer,
	users: usersReducer,
	series: seriesReducer,
	relay: relayReducer,
	relays: relaysReducer,
	strategies: strategiesReducer,
	strategy: strategyReducer,
	auth: authReducer,
};

export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types

export type AppThunkConfig = {
	state: RootState;
	dispatch: AppDispatch;
	extra?: unknown;
	rejectValue?: unknown;
};
