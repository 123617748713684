
import { Button, Tooltip } from '@mui/material';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import SimpleList, {ColumnDefinition} from "../../../../components/layout/SimpleList";
import { CompanyPoplistItemFormData } from '../../../../forms/CompanyPoplistItemForm';
import {
    JsonCompanyDepartment,
    JsonCompanyDepartmentInfo, JsonCompanyDuration,
    JsonCompanyDurationInfo,
    JsonCompanyPosition,
    JsonCompanyPositionInfo
} from "../../../../generated-api";
import {useAppTranslation} from "../../../../services/i18n";
import { useAppDispatch } from '../../../../store';
import {
    deleteCompanyDepartment, deleteCompanyDuration,
    deleteCompanyPosition,
    fetchCompanyDepartments, fetchCompanyDurations,
    fetchCompanyPositions,
    saveCompanyDepartment,
    saveCompanyDuration,
    saveCompanyPosition
} from '../../../../store/companies';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CompanyPoplistItemModal from './CompanyPoplistItemModal';
import PoplistValue from '../../../../components/form/PoplistValue';
import { useModal } from '../../../../services/modal';

type CompanyPoplistItem = JsonCompanyPositionInfo | JsonCompanyDepartmentInfo | JsonCompanyDurationInfo;

export enum CompanyPoplistTypeEnum {
    Positions = 'Positions',
    Departmnets = 'Departments',
    Durations = 'Durations'
}

interface Props {
    type: CompanyPoplistTypeEnum;
    companyId: number;
}

const CompanyPoplist = ({ type, companyId }: PropsWithChildren<Props>) => {

    const dispatch = useAppDispatch();
    const t = useAppTranslation();
    const modal = useModal();

    const [items, setItems] = useState<CompanyPoplistItem[]>();
    const [editedItem, setEditedItem] = useState<CompanyPoplistItemFormData>();

    const loadItems = useCallback(async () => {
        let result;
        switch (type) {
            case CompanyPoplistTypeEnum.Departmnets:
                result = await dispatch(fetchCompanyDepartments(+companyId));
                break;
            case CompanyPoplistTypeEnum.Positions:
                result = await dispatch(fetchCompanyPositions(+companyId));
                break;
            case CompanyPoplistTypeEnum.Durations:
                result = await dispatch(fetchCompanyDurations(+companyId));
                break;
            default:
                break;
        }
        if (result) {
            if ('error' in result) {
                return;
            } 
            setItems(result.payload.data! || null);
        }
    }, [dispatch, companyId, type]);

    const handleAddItem = useCallback(() => {
        let item;
        switch (type) {
            case CompanyPoplistTypeEnum.Departmnets:
                item = { companyId, title: '', departmentId: 0 };
                break;
            case CompanyPoplistTypeEnum.Positions:
                item = { companyId, title: '', positionId: 0 };
                break;
            case CompanyPoplistTypeEnum.Durations:
                item = { companyId, title: '', durationId: 0 };
                break;
            default:
                item = { companyId, title: '', durationId: 0 };
                break;
        }
        setEditedItem({
            type,
            item
        })
    }, [type, companyId]);

    const handleSaveItem = useCallback(async (item: CompanyPoplistItemFormData) => {
        console.log({ item });
        let result;
        switch (type) {
            case CompanyPoplistTypeEnum.Departmnets:
                result = await dispatch(saveCompanyDepartment(item.item as JsonCompanyDepartment))
                break;
            case CompanyPoplistTypeEnum.Positions:
                result = await dispatch(saveCompanyPosition(item.item as JsonCompanyPosition))
                break;
            case CompanyPoplistTypeEnum.Durations:
                result = await dispatch(saveCompanyDuration(item.item as JsonCompanyDuration))
                break;
            default:
                break;
        }
        if (!result || ('error' in result)) {
            return;
        } 
        loadItems();
        setEditedItem(undefined);
    }, [type, loadItems, dispatch]);

    const handleDeleteItem = useCallback(async (row: CompanyPoplistItem) => {

        const confirmResult = await modal.confirm({ title: t('Potvrzení'), message: t('Skutečně chcete položku odstranit?') });
        if (confirmResult !== 'CONFIRM') {
          return;
        }

        console.log({ deleted: row });
        let result;
        switch (type) {
            case CompanyPoplistTypeEnum.Departmnets:
                result = await dispatch(deleteCompanyDepartment(row as JsonCompanyDepartment));
                break;
            case CompanyPoplistTypeEnum.Positions:
                result = await dispatch(deleteCompanyPosition(row as JsonCompanyPosition))
                break;
            case CompanyPoplistTypeEnum.Durations:
                result = await dispatch(deleteCompanyDuration(row as JsonCompanyDuration))
                break;
            default:
                break;
        }
        if (!result || ('error' in result)) {
            return;
        } 
        loadItems();
    }, [modal, type, loadItems, dispatch, t]);

    const handleListAction = useCallback((row: CompanyPoplistItem, action: string) => {
        console.log({ row, action });
        let item;
        switch (type) {
            case CompanyPoplistTypeEnum.Departmnets:
                item = row as JsonCompanyDepartment;
                break;
            case CompanyPoplistTypeEnum.Positions:
                item = row as JsonCompanyPosition;
                break;
            case CompanyPoplistTypeEnum.Durations:
                item = row as JsonCompanyDuration;
                break;
            default:
                item = row as JsonCompanyDuration;
                break;
        }
        switch (action) {
            case 'EDIT': 
                setEditedItem({
                    type,
                    item,
                });
                break;
            case 'DELETE':
                handleDeleteItem(row);
                break;
        }
    }, [type, handleDeleteItem]);

    useEffect(() => {
        loadItems();
    }, [loadItems]);

    const cols = [
        { 
            title: 'name', 
            style: { width: '40%' },
            renderer: (row: CompanyPoplistItem) => { 
                return (
                    <div><strong>{ row.title }</strong></div>
                );
            }
        },
        { 
            title: 'general', 
            style: { width: '40%' },
            renderer: (row: CompanyPoplistItem) => {
                let poplist;
                let value;
                switch (type) {
                    case CompanyPoplistTypeEnum.Departmnets:
                        value = (row as JsonCompanyDepartment).departmentId;
                        poplist = "companyDepartment";
                        break;
                    case CompanyPoplistTypeEnum.Positions:
                        value = (row as JsonCompanyPosition).positionId;
                        poplist = "companyPosition";
                        break;
                    case CompanyPoplistTypeEnum.Durations:
                        value = (row as JsonCompanyDuration).durationId;
                        poplist = "duration";
                        break;
                    default:
                        value = (row as JsonCompanyDuration).durationId;
                        poplist = "duration";
                        break;
                }
                return (
                    <div><PoplistValue 
                        value={value}
                        poplist={poplist}/>
                    </div>
                );
            }
        },
        {
            style: { width: '20%', textAlign: 'right' },
            renderer: (row: CompanyPoplistItem) => {
                return (
                    <>
                        <Tooltip title="Upravit">
                            <EditIcon color="primary" onClick={() => handleListAction(row, 'EDIT')}/>
                        </Tooltip>
                        <Tooltip title="Odstranit">
                            <DeleteIcon color="primary" onClick={() => handleListAction(row, 'DELETE')}/>
                        </Tooltip>
                    </>
                );
            }
        }
    ] as ColumnDefinition<CompanyPoplistItem>[];

	return (
        <>
		    { items && <SimpleList 
                cols={cols} 
                config={{ hideHeader: true, title: t( 'CompanyPoplist.' + type + '.title' ) }}
                rows={items} 
                action={handleListAction}>
                    <Button variant="contained" onClick={handleAddItem} fullWidth>{ t( 'CompanyPoplist.' + type + '.addButton' )}</Button>
                </SimpleList> }
            
            { editedItem && <CompanyPoplistItemModal 
                    data={editedItem}
                    onSave={handleSaveItem} 
                    onCancel={() => setEditedItem(undefined)}
                ></CompanyPoplistItemModal> }
        </>
    );
}

export default CompanyPoplist;
