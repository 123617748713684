/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonUserInfo
 */
export interface JsonUserInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonUserInfo
     */
    allowedActions?: Array<JsonUserInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    firstName?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUserInfo
     */
    isNewsletter?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    sex?: JsonUserInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonUserInfo
     */
    status?: JsonUserInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonUserInfo
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonUserInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonUserInfoSexEnum {
    M = 'M',
    F = 'F',
    X = 'X'
}/**
* @export
* @enum {string}
*/
export enum JsonUserInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonUserInfoFromJSON(json: any): JsonUserInfo {
    return JsonUserInfoFromJSONTyped(json, false);
}

export function JsonUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'isNewsletter': !exists(json, 'isNewsletter') ? undefined : json['isNewsletter'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonUserInfoToJSON(value?: JsonUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyName': value.companyName,
        'email': value.email,
        'firstName': value.firstName,
        'isNewsletter': value.isNewsletter,
        'lastName': value.lastName,
        'phone': value.phone,
        'roleId': value.roleId,
        'sex': value.sex,
        'status': value.status,
        'userId': value.userId,
    };
}

