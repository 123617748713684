/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPollOption
 */
export interface JsonPollOption {
    /**
     * 
     * @type {string}
     * @memberof JsonPollOption
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPollOption
     */
    createdBy?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonPollOption
     */
    isRemoved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonPollOption
     */
    optionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPollOption
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPollOption
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPollOption
     */
    updatedBy?: number;
}

export function JsonPollOptionFromJSON(json: any): JsonPollOption {
    return JsonPollOptionFromJSONTyped(json, false);
}

export function JsonPollOptionFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPollOption {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'isRemoved': !exists(json, 'isRemoved') ? undefined : json['isRemoved'],
        'optionId': !exists(json, 'optionId') ? undefined : json['optionId'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonPollOptionToJSON(value?: JsonPollOption | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'isRemoved': value.isRemoved,
        'optionId': value.optionId,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

