import FormModal from "../components/form/FormModal";
import {JsonCompanyBranch, JsonUser} from "../generated-api";
import { FormProps } from "../model/form";
import { useAppTranslation } from "../services/i18n";
import CompanyBranchForm from "../forms/CompanyBranchForm";

const CompanyBranchModal = (props: FormProps<JsonCompanyBranch>) => {

  const t = useAppTranslation();

  return (
    <FormModal title={t('Přidání pobočky společnosti')} open={!!props?.data}>
      <CompanyBranchForm { ...props }></CompanyBranchForm>
    </FormModal>
	);
}

export default CompanyBranchModal;
