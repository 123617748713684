import { PropsWithChildren, useState } from 'react';

import { Chip, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import FolderIcon from '@mui/icons-material/Folder';
import PollIcon from '@mui/icons-material/Poll';

import SimpleList, { ColumnDefinition, SimpleListAction, SimpleListHeaderAction } from "../../../../components/layout/SimpleList";
import { useAppTranslation } from "../../../../services/i18n";
import { EpisodeItem } from '../EditEpisodePage';
import styles from '../../../../assets/styles/editor.module.css';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

interface HeaderProps {
    action: SimpleListHeaderAction;
}

const EpisodeItemsListHeaderAction = ({ action }: HeaderProps) => { 

    const t = useAppTranslation();

    const [anchorEl, setAnchorEl] = useState<null | SVGElement>(null);
    const open = Boolean(anchorEl);

    const handleClickIcon = (event: React.MouseEvent<SVGElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <IconButton aria-label="settings">
            <AddCircleOutlineIcon onClick={handleClickIcon}/>
            <Menu
                id="lock-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                MenuListProps={{
                'aria-labelledby': 'lock-button',
                role: 'listbox',
                }}
            >
                <MenuItem key={1} onClick={() => { setAnchorEl(null); action && action('NEW_GROUP')}}>{t('Nová skupina')}</MenuItem>
                <MenuItem key={2} onClick={() => { setAnchorEl(null); action && action('NEW_POLL')}}>{t('Nová otázka')}</MenuItem>
                <MenuItem key={2} onClick={() => { setAnchorEl(null); action && action('SEARCH_POLL')}}>{t('Vyhledat')}</MenuItem>
            </Menu>
        </IconButton>
    )
}

interface Props {
    rows: EpisodeItem[] | null;
    action: SimpleListAction<EpisodeItem>;
    actionHeader: SimpleListHeaderAction;
}

const EpisodeItemsList = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    const cols = [
        { 
            title: 'name', 
            renderer: (row: EpisodeItem) => (
                <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                    <Chip label={row.order}/>
                    <div style={{ marginLeft: '10px' }}>
                        <div className={styles.episodeItemName}>
                            
                            { !!row.group && 
                                <>
                                    <FolderIcon color="disabled"/>
                                    <strong>{ row.group.title }</strong> 
                                </>
                            }
                            { !!row.poll && 
                                <>
                                    <PollIcon color="disabled"/>
                                    <strong>{ row.poll.title }</strong>
                                </> }
                        </div>
                        { row.polls?.map((poll, index) => <div key={index} className={styles.episodeItemName}><PollIcon color="disabled"/>{ poll.title }</div>) }
                    </div>
                </div>
            )
        },
        {
            renderer: (row: EpisodeItem) => (
                <div className={styles.episodeItemButtons}>
                    <Tooltip title={ !!row.group ? 'Upravit skupinu' : 'Upravit otázku' }>
                        <EditIcon color="primary" onClick={() => props.action(row, 'EDIT')}/>
                    </Tooltip>
                    {/* 
                    <Tooltip title={ !!row.group ? 'Odstranit skupinu' : 'Odstranit otázku' }>
                        <DeleteIcon color="primary" onClick={() => props.action(row, 'DELETE')}/>
                    </Tooltip>
                    */ }
                </div>
            )
        }
    ] as ColumnDefinition<EpisodeItem>[];

	return (
		<SimpleList cols={cols} config={{hideHeader: true, title: t('Obsah episody')}} 
            headerAction={<EpisodeItemsListHeaderAction action={props.actionHeader}/>} { ...props }></SimpleList>
	);
}

export default EpisodeItemsList;