/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonSerie,
    ApiResultOfJsonSerieFromJSON,
    ApiResultOfJsonSerieToJSON,
    ApiResultOfJsonSerieInfo,
    ApiResultOfJsonSerieInfoFromJSON,
    ApiResultOfJsonSerieInfoToJSON,
    ApiResultOfListOfJsonSerieInfo,
    ApiResultOfListOfJsonSerieInfoFromJSON,
    ApiResultOfListOfJsonSerieInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonSerie,
    JsonSerieFromJSON,
    JsonSerieToJSON,
} from '../models';

export interface GetSerieCountUsingGETRequest {
    companyId?: number;
    search?: string;
}

export interface GetSerieInfoUsingGETRequest {
    serieId: number;
}

export interface GetSerieListUsingGETRequest {
    companyId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetSerieUsingGETRequest {
    serieId: number;
}

export interface SaveSerieUsingPOSTRequest {
    json: JsonSerie;
    testOnly?: boolean;
}

/**
 * 
 */
export class SerieControllerApi extends runtime.BaseAPI {

    /**
     * getSerieCount
     */
    async getSerieCountUsingGETRaw(requestParameters: GetSerieCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getSerieCount
     */
    async getSerieCountUsingGET(requestParameters: GetSerieCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getSerieCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieInfo
     */
    async getSerieInfoUsingGETRaw(requestParameters: GetSerieInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerieInfo>> {
        if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
            throw new runtime.RequiredError('serieId','Required parameter requestParameters.serieId was null or undefined when calling getSerieInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie/info/{serieId}`.replace(`{${"serieId"}}`, encodeURIComponent(String(requestParameters.serieId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieInfoFromJSON(jsonValue));
    }

    /**
     * getSerieInfo
     */
    async getSerieInfoUsingGET(requestParameters: GetSerieInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerieInfo> {
        const response = await this.getSerieInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerieList
     */
    async getSerieListUsingGETRaw(requestParameters: GetSerieListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonSerieInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonSerieInfoFromJSON(jsonValue));
    }

    /**
     * getSerieList
     */
    async getSerieListUsingGET(requestParameters: GetSerieListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonSerieInfo> {
        const response = await this.getSerieListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getSerie
     */
    async getSerieUsingGETRaw(requestParameters: GetSerieUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerie>> {
        if (requestParameters.serieId === null || requestParameters.serieId === undefined) {
            throw new runtime.RequiredError('serieId','Required parameter requestParameters.serieId was null or undefined when calling getSerieUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/serie/{serieId}`.replace(`{${"serieId"}}`, encodeURIComponent(String(requestParameters.serieId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieFromJSON(jsonValue));
    }

    /**
     * getSerie
     */
    async getSerieUsingGET(requestParameters: GetSerieUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerie> {
        const response = await this.getSerieUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveSerie
     */
    async saveSerieUsingPOSTRaw(requestParameters: SaveSerieUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonSerie>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveSerieUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/serie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonSerieToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonSerieFromJSON(jsonValue));
    }

    /**
     * saveSerie
     */
    async saveSerieUsingPOST(requestParameters: SaveSerieUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonSerie> {
        const response = await this.saveSerieUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
