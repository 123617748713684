import * as React from "react";

import {JsonGroup, JsonPoll, JsonPollChartTypeEnum, JsonPollResults, JsonRelayResponse} from "../../generated-api";
import styles from '../../assets/styles/relay';
import RelayBarChart from "./charts/RelayBarChart";
import RelayHorizontalBarChart from "./charts/RelayHorizontalBarChart";
import RelaySliderChart from "./charts/RelaySliderChart";
import {ChartOptions, COLORS, FULLPAGE_COLORS} from "../../helpers/relay";

const RelayItemPollFullScreenResults = (props: { group?: JsonGroup, poll: JsonPoll, results: JsonPollResults, responses: JsonRelayResponse[], groupIndex?: number, groupCount?: number }) => {
    const { poll } = props;
    let options: ChartOptions = {};

    console.log({ height: window.innerHeight });

    let ChartComponent;
    switch (poll.chartType) {
        case JsonPollChartTypeEnum.None:
            // text etc
            return null;

        case JsonPollChartTypeEnum.Barchart:
            ChartComponent = RelayBarChart;
            options = { percentWidth: 100, pixelHeight: 600, labelFontSize: 'max(15px, min(100vh / 40, 100vw / 40))', colors: FULLPAGE_COLORS };
            break;
        case JsonPollChartTypeEnum.BarchartH:
            ChartComponent = RelayHorizontalBarChart;
            // const optionsCount = poll.options || 1;
            options = { noSortOptions: true, contentMarginTop: "-30px", marginTop: "max(5px, calc(100vh * 0.026))", marginBottom: "max(2px, calc(100vh * 0.004))", height: 'max(15px, min(100vh / 30, 100vw / 30))', labelFontSize: 'max(15px, min(100vh / 40, 100vw / 40))', padding: 'max(45px, min(100vh / 10, 100vw / 10))', colors: FULLPAGE_COLORS };
            break;
        case JsonPollChartTypeEnum.Sliders:

            let rows = props.groupCount || 1;
            if (rows > 3) {
                rows = Math.round(rows / 2);
            }
            options = {
                lineColor: 'var(--audit-blue)',
                textColor: 'var(--audit-white)',
                height: 'max(15px, min(100vh / ' + (rows * 4) + '))',
                sliderScaleFontSize: 'max(10px, min(100vh / 80, 100vw / 80))',
                sliderCircleSize: 'max(30px, min(100vh / 30, 100vw / 30))',
                sliderCircleFontSize: 'max(12px, min(100vh / 70, 100vw / 70))'
            };
            ChartComponent = RelaySliderChart;
            break;
        default:
            ChartComponent = RelayBarChart;
            break;
    }

    return <div className={styles.fullChartItem}>
        { !!props.group && <div className={styles.chartTitle}><strong>{poll.title}</strong></div> }
        <div className={styles.chart}>
            <ChartComponent { ...props } options={options}/>
        </div>
    </div>

};

export default RelayItemPollFullScreenResults;
