import React from 'react';
import {Route} from 'react-router';
import OnePagerPage, {OnePagerMode} from "../pages/admin/onePagerPage/OnePagerPage";
import LiveDashboardPage from "../pages/live/LiveDashboardPage";
import LiveCountdownPage from "../pages/public/LiveCountdownPage";
import LiveLayout from "../components/layout/LiveLayout";
import LiveReportsPage from "../pages/live/LiveReportsPage";
import LiveReportPage from "../pages/live/LiveReportPage";
import LiveFAQPage from "../pages/live/LiveFAQPage";
import LiveArchivesPage from "../pages/live/LiveArchivesPage";
import LiveUnregisteredPage from "../pages/public/LiveUnregisteredPage";

/*
const grantedCodes = ['23ZRJDJ', '1YQM5M9', '12U3HGTD'];

const grantedCodes = ['12U3HGTD', '13ANVB1F', '13UJF8ZU', '13Z4TLE2', '13Z4TLE2', '11K7Q1ZA', '12JHY1AM', '81XXXZQ3', '19PNMRR6', '3WG1PX1M', '1B447MTY', '36M33DZ2', '82FKR9PC', '59X4GQ1L', '691BBECU', '82S67RTP', '83AEN472', '69RGJVX2', '17BW1JBY',
	'84N2SCLN', '88EVBMBZ', '849AL6EP', '14H37W6E', '88LP4PTZ', '64D5V1JF', '23TMV66C', '5ZKNC15Y', '821DM149', '82GYVVX4', '6FSQZAKT', '19KHDBFX', '5G574AEC', '88V9M28E', '53D1YQK5', '5525LC9R', '893BNJ7K', '38Z8KWKH', '8213Q4BP', '89445CES', '89498PLR',
	'89BZUKPJ', '81QQ9CYC', '81PMGUTM', '89CJJ98A', '2V4UBZF7', '4DXAF5PB', '89J43HCP', '39CJ6D8A', '7P9TRHUH', '89WTJ213', '7X79MTPB', '89ZL6XZM', '66A8RQJN', '8A3QUWWE', '6CSR55PY', '8A5HK2AZ', '64JS6UXS', '8AGQEB6A', '8AM7BNCK', '8AS9N6JH', '123ZRJDJ',
	'8SNAXWJS', '82FKR9PC', '8SNAXWJS', '8ZDZ39KQ', '9JUPWSJU', '5GMDENUL', '2V4UBZF7', '3LPCX8NJ', '3THJXMG3', '821DM149', '849AL6EP', 'AKXYHPLN', 'BY3L3XCL', 'BYDTFSWT', 'BYM3FXDS', 'BZ1KXY8U', 'BZAH98BK', 'BZP5265R', 'BZP54G8G', 'C1BPCAD7', 'C1E4AXE3',
	'C24XRGNE', 'C5UGMLWN', 'C5WAX642', 'C677QRB8', 'C6R1AERK',	'C6SR99TS', 'C6SZCTD8', 'C7MYARBN', 'C7YP6XFG', 'C81KDPL6', 'C84NCZAL', 'C8ADHER7', 'C8GPVJWH', 'C8QQSZ8R', 'C972G9WG'];

const isAccessGranted = grantedCodes.find((code: string) => window.location.pathname.includes(code));
*/

const isAccessGranted = true;

export const getLiveRoutes = (isLogged: boolean) => {

	return [
		<Route key="live" path='/live/unregistered' element={<LiveUnregisteredPage/>}/>,
		!isAccessGranted && <Route key="live" path='/live/:code' element={<LiveCountdownPage/>}/>,
		isAccessGranted && <Route key="live" path='/live/:code' element={
			<LiveDashboardPage />
		}/>,
		isAccessGranted && <Route key="live-report" path='live/:code/reports' element={
			<LiveReportsPage />
		}/>,
		isAccessGranted && <Route key="live-archives" path='live/:code/archives' element={
			<LiveArchivesPage />
		}/>,
		isAccessGranted && isLogged && <Route key="live-onepager" path='live/:code/workzone/onepager' element={
			<LiveLayout
				title="Interaktivní <span style='color: var(--live-green)'>workbook</span>"
				subtitle="Vyplňte váš One-Pager"
				backButton
			>
				<OnePagerPage mode={OnePagerMode.Workzone}/>
			</LiveLayout>
		}/>,
		isAccessGranted && isLogged && <Route key="live-report" path='live/:code/report/:episodeId' element={
			<LiveReportPage />
		}/>,
		isAccessGranted && <Route key="live-faq" path='/live/:code/faq' element={<LiveFAQPage/>}/>
	].filter(route => !!route);
};
