import {useNavigate} from "react-router-dom";
import {JsonPoplistItem, JsonStratOnepagerInfo} from '../../../generated-api';
import {Card, CardContent} from '@mui/material';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useSelector} from "react-redux";
import {selectAuthUser, selectCodebooks, selectStrategyContext} from "../../../store/selectors";
import stylesOnepager from '../../../assets/styles/onepager.module.css';
import DescriptionIcon from '@mui/icons-material/Description';

interface Props {
  onepager: JsonStratOnepagerInfo
}

const OnePagerCard = ({ onepager }: Props) => {

    const navigate = useNavigate();

    const strategyContext = useSelector(selectStrategyContext);
    const codebooks = useSelector(selectCodebooks);
    const user = useSelector(selectAuthUser);

    console.log({ codebooks });

    const departmentId = onepager.companyDepartmentId || onepager.departmentId;
    const departments = onepager.companyDepartmentId ? strategyContext.companyDepartments : codebooks['companyDepartment'];
    const departmentName = departments?.find((item: JsonPoplistItem) => item.value === departmentId || item.value === departmentId + '' )?.label;

    const positionId = onepager.companyPositionId || onepager.positionId;
    const positions = onepager.companyPositionId ? strategyContext.companyPositions : codebooks['companyPosition'];
    const positionName = positions?.find((item: JsonPoplistItem) => item.value === positionId || item.value === positionId + '' )?.label;

    return (
    <>
		{ !!onepager &&
            <Card sx={{ display: 'flex' }} className={user?.userId === onepager.userId ? stylesOnepager.selectedCard : stylesOnepager.onePagerCard} onClick={() => navigate(`/onepager/${onepager.onepagerId}`)}>
              <Box className={ stylesOnepager.onePagerIcon }>
                  { false && <DescriptionIcon style={{ fontSize: '60px' }}/> }
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                <CardContent sx={{ flex: '1', position: 'relative' }}>
                    <Typography component="div" variant="h6">
                      { onepager.firstName } { onepager.lastName }
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        { onepager.email }
                    </Typography>
                    <Typography variant="subtitle1" color="text.secondary" component="div">
                        { departmentName } / { positionName }
                    </Typography>
                    <div style={{ position: 'absolute', top: '5px', right: '5px', border: '1px solid black', borderRadius: '5px', padding: '5px 10px' }} className={stylesOnepager[onepager.status!]}>{ onepager.status }</div>
                </CardContent>
              </Box>
            </Card>
        }
    </>
	);
}

export default OnePagerCard;
