import {
	createSlice,
	createAsyncThunk,
	PayloadAction,
} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
	CompanyControllerApi,
	EpisodeControllerApi,
	JsonPoplistItem, JsonSerieInfoSerieTypeEnum,
	PoplistControllerApi,
	SerieControllerApi
} from '../generated-api';

const codebooksApi = new PoplistControllerApi(API_CONFIG);
const seriesApi = new SerieControllerApi(API_CONFIG);
const episodesApi = new EpisodeControllerApi(API_CONFIG);
const companiesApi = new CompanyControllerApi(API_CONFIG);

export type LocalCodebookItem = JsonPoplistItem;

export const fetchCodebooks = createAsyncThunk('codebooks/all', async () => {

	const list = await codebooksApi.getPoplistListUsingGET();

	if ('error' in list || !list.data) {
		return;
	}

	return await codebooksApi.getPoplistsUsingGET({
		names: Object.keys(list.data)
	});
});

export const fetchSeriesCombo = createAsyncThunk('series/fetchCombo', async () => {
	return await seriesApi.getSerieListUsingGET({ rows: 10000 });
});

export const fetchEpisodesCombo = createAsyncThunk('episodes/fetchCombo', async () => {
	return await episodesApi.getEpisodeListUsingGET({});
});

export const fetchCompaniesCombo = createAsyncThunk('companies/combo', async () => {
	return await companiesApi.getCompanyListUsingGET({ rows: 10000 });
});

const initialState = {
};

export type CodebookState = {
	[name: string]: LocalCodebookItem[];
}
export type CodebookCodesType = keyof CodebookState;

export const codebooksSlice = createSlice({
	name: 'codebooks',
	initialState,
	reducers: {
		initStaticCodebooks: (state, action: PayloadAction<{[name: string]: LocalCodebookItem[]}>) => {
			return { ...state, ...action.payload };
		}
	},
	extraReducers: builder => {
		builder.addCase(fetchCodebooks.fulfilled, (state, action) => {
			return Object.keys(action.payload!.data || {}).reduce((acc, curr) => {
				acc[curr as CodebookCodesType] = action.payload!.data![curr];
				return acc;
			}, state as CodebookState)
		});

		builder.addCase(fetchSeriesCombo.fulfilled, (state, action) => {
			return { ...state,
				serieId: (action?.payload?.data || []).map(item => ({value: item.serieId, label: item.title})),
				broadcastSerieId: (action?.payload?.data || []).filter(item => item.serieType === JsonSerieInfoSerieTypeEnum.Broadcast).map(item => ({value: item.serieId, label: item.title}))
			};
		});

		builder.addCase(fetchEpisodesCombo.fulfilled, (state, action) => {
			return { ...state, episodeId: (action?.payload?.data || []).map(item => ({value: item.episodeId, label: item.title}))};
		});

		builder.addCase(fetchCompaniesCombo.fulfilled, (state, action) => {
			const data = (action?.payload?.data || []).map(item => ({value: item.companyId, label: item.companyName}));
			data.sort((a, b) => (a.label || '') > (b.label || '') ? 1 : -1);
			return { ...state, companyId: data };
		});
	}
});

export const codebooksReducer = codebooksSlice.reducer;
export const { initStaticCodebooks } = codebooksSlice.actions;
