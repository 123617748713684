import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BarChartIcon from '@mui/icons-material/BarChart';
import { PropsWithChildren } from 'react';
import SimpleList, { ColumnDefinition, SimpleListAction } from "../../../../components/layout/SimpleList";
import {JsonGroup, JsonGroupInfo, JsonPoll, JsonPollInfo, JsonPollInfoChartTypeEnum, JsonRelayItem, JsonRelayItemItemTypeEnum} from "../../../../generated-api";
import { useAppTranslation } from "../../../../services/i18n";
import RelayItemPollQuestion from '../../../../components/relay/RelayItemPollQuestion';
import { useSelector } from 'react-redux';
import { selectRelayPollsDef } from '../../../../store/selectors';
import RelayItemPollResults from '../../../../components/relay/RelayItemPollResults';
import RelayItemActions from './RelayItemActions';
import layoutStyles from '../../../../assets/styles/layout';
import relayStyles from '../../../../assets/styles/relay';
import { Chip } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

export type RelayItemStatus = 'PENDING' | 'AVAILABLE' | 'ACTIVE' | 'FINISHED';

export interface RelayPollListItem extends JsonPollInfo {
    pollQuestionActiveItem: JsonRelayItem,
    pollResultActiveItem: JsonRelayItem,
    pollQuestionCount: number,
    pollResultCount: number,
    pollLastResultItem: JsonRelayItem,
    pollQuestionStatus: RelayItemStatus,
    pollResultStatus: RelayItemStatus,
}

interface Props {
    rows: RelayPollListItem[] | null;
    groups: JsonGroupInfo[] | null;
    previewQuestions: number[];
    previewResults: number[];
    fullscreenResults: (type: string, id: number) => void;
    action: SimpleListAction<RelayPollListItem>;
}

const renderRowButtons = (action: SimpleListAction<RelayPollListItem>, row: RelayPollListItem, group: boolean, chartVisible: boolean, onFullScreen?: (type: string, id: number) => void) => {
    const questionColor = row.pollQuestionStatus === 'ACTIVE' ? 'error' : (!!row.pollQuestionCount ? 'success' : 'disabled');
    const chartColor = row.pollResultStatus === 'ACTIVE' ? 'error' : (!!row.pollResultCount ? 'success' : 'disabled');

    return <>
        { onFullScreen && <OpenInNewIcon color="primary" onClick={() => onFullScreen(group ? 'group' : 'poll', group ? row.groupId! : row.pollId! )} /> }
        <FormatListBulletedIcon color={questionColor} onClick={() => action && action(row, group ? 'GROUP_QUESTION' : JsonRelayItemItemTypeEnum.PollQuestion)}/>
        { chartVisible &&
            <BarChartIcon color={chartColor} onClick={() => action && action(row, group ? 'GROUP_RESULTS' : JsonRelayItemItemTypeEnum.PollResults)}/>
        }
        </>
}

const renderRow = (row: RelayPollListItem, props: PropsWithChildren<Props>, t: any, group?: JsonGroupInfo, poll?: JsonPoll, groupIndex?: number) => {

    const handleOnStartQuestion = !!group ? undefined : () => props.action && props.action(row, 'START_' + JsonRelayItemItemTypeEnum.PollQuestion);
    const handleOnStopQuestion = !!group ? undefined : () => props.action && props.action(row, 'STOP_' + JsonRelayItemItemTypeEnum.PollQuestion);

    const handleOnStartResults = !!group ? undefined : () => props.action && props.action(row, 'START_' + JsonRelayItemItemTypeEnum.PollResults);
    const handleOnStopResults = !!group ? undefined : () => props.action && props.action(row, 'STOP_' + JsonRelayItemItemTypeEnum.PollResults);

    const handleOnRefresh = !!group ? undefined : () => props.action && props.action(row, 'REFRESH_POLL');

    console.log({ row });

    return <>
        { (!group || !(props.previewQuestions.includes(poll?.pollId || 0) || props.previewResults.includes(poll?.pollId || 0))) &&
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
            <div>
                { !group && <div className={layoutStyles.listTitle}><Chip label={row.orderNo}/> { row.title }</div> }
                { group && <div style={{ fontSize: '14px' }}>{ row.title }</div> }
                { /* <div className={layoutStyles.listInfo}>{ row.pollType } / { row.chartType }</div> */ }
                { !group && row.pollQuestionStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Je vysílána otázka')}</div> }
                { !group && row.pollResultStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Jsou vysílány výsledky')}</div> }
            </div>
            {!group && <div style={{ width: '75px' }}>
                { renderRowButtons(props.action, row, false, row?.chartType !== JsonPollInfoChartTypeEnum.None, props.fullscreenResults) }
            </div>}
        </div>
        }
        { poll?.pollId && (props.previewQuestions.includes(poll.pollId) || props.previewResults.includes(poll.pollId)) &&
            <div>
                    { !poll.groupId &&
                        <>
                            <RelayItemActions
                                status={props.previewQuestions.includes(poll.pollId) ? row.pollQuestionStatus : row.pollResultStatus}
                                count={props.previewQuestions.includes(poll.pollId) ? row.pollQuestionCount : row.pollResultCount}
                                onStart={props.previewQuestions.includes(poll.pollId) ? handleOnStartQuestion : handleOnStartResults}
                                onStop={props.previewQuestions.includes(poll.pollId) ? handleOnStopQuestion : handleOnStopResults}
                                onRefresh={props.previewResults.includes(poll.pollId) ? handleOnRefresh : undefined}
                            />
                        </>
                    }
                    { props.previewQuestions.includes(poll.pollId) &&
                        <RelayItemPollQuestion
                            poll={poll}
                            group={group as JsonGroup}
                            results={{}}
                            responses={[]}
                        />
                    }
                    { props.previewResults.includes(poll.pollId) &&
                    <>
                        {row.pollLastResultItem?.pollResults && <div style={{ fontSize: '0.8rem', padding: '0px' }}>{t('Počet osob, které hlasovaly')}: <b>{row.pollLastResultItem.pollResults.userCount}</b></div>}
                        <RelayItemPollResults
                            poll={poll}
                            group={group as JsonGroup}
                            groupIndex={groupIndex}
                            results={row.pollLastResultItem?.pollResults || {}}
                            responses={[]}
                        />
                    </>
                    }
            </div>
        }
    </>
}

const RelayPoolList = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();
    const polls = useSelector(selectRelayPollsDef);

    const groupsDone: number[] = [];
    const cols = [
        {
            title: 'name',
            style: { width: '100%' },
            renderer: (row: RelayPollListItem) => {

                const groupId = row.groupId;
                const poll = polls?.find((poll: JsonPoll) => poll.pollId === row.pollId);

                if (groupId) {
                    const group = props.groups?.find((g) => g.groupId === groupId);
                    const groupRows = props.rows?.filter((r) => r.groupId === groupId);
                    const poll = polls?.find((poll: JsonPoll) => poll.pollId === row.pollId);
                    if (group && groupRows) {
                        if (groupsDone.indexOf(groupId) >= 0) {
                            return null;
                        }
                        groupsDone.push(groupId);
                        return <div>
                            <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                                <div>
                                    <div className={layoutStyles.listTitle}><Chip label={group.orderNo}/> {group.title || t('(skupina bez názvu)')}</div>
                                    {row.pollQuestionStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Je vysílána skupina otázek')}</div>}
                                    {row.pollResultStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Je vysílána skupina výsledků')}</div>}
                                </div>
                                <div style={{ width: '75px' }}>
                                    { renderRowButtons(props.action, row, true, true, props.fullscreenResults) }
                                </div>
                            </div>
                            <div  style={{display: 'flex', flexFlow: 'column'}}>
                                { /* JSON.stringify(row, null, 2)*/ }
                                {
                                    poll && (props.previewQuestions.includes(poll.pollId) || props.previewResults.includes(poll.pollId)) ? <RelayItemActions
                                        status={props.previewQuestions.includes(poll.pollId) ? row.pollQuestionStatus : row.pollResultStatus}
                                        count={props.previewQuestions.includes(poll.pollId) ? row.pollQuestionCount : row.pollResultCount}
                                        onStart={() => props.action && props.action(row, props.previewQuestions.includes(poll.pollId) ? 'START_GROUP_QUESTION' : 'START_GROUP_RESULTS')}
                                        onStop={() => props.action && props.action(row, props.previewQuestions.includes(poll.pollId) ? 'STOP_GROUP_QUESTION' : 'STOP_GROUP_RESULTS')}
                                        onRefresh={() => props.action && props.previewResults.includes(poll.pollId) && props.action(row, 'REFRESH_GROUP')}
                                    /> : <></>
                                }
                                {groupRows.map((row, i) => <div key={i}>{renderRow(row, props, t, group,  polls?.find((poll: JsonPoll) => poll.pollId === row.pollId), i)}</div>)}
                            </div>
                        </div>
                    }
                }
                return renderRow(row, props, t, undefined, poll);
            },
        },
    ] as ColumnDefinition<RelayPollListItem>[];

    const displayedGroups: number[] = [];
    const rows = props.rows?.filter(
        // show only first row in group
        (row) => {
            if (!row.groupId) {
                return true;
            } else {
                if (!displayedGroups.includes(row.groupId)) {
                    displayedGroups.push(row.groupId);
                    return true;
                }
            }
            return false;
        }
    ) || [];
    rows.sort((a: RelayPollListItem, b: RelayPollListItem) => {
        const aOrder = (a.groupId ? props.groups?.find((group) => group.groupId === a.groupId)?.orderNo : a.orderNo) || 0;
        const bOrder = (b.groupId ? props.groups?.find((group) => group.groupId === b.groupId)?.orderNo : b.orderNo) || 0;
        if (aOrder === bOrder) {
            return (a.orderNo || 0) > (b.orderNo || 0) ? 1 : -1;
        }
        return aOrder > bOrder ? 1 : -1;
    })

    return (
		<SimpleList cols={cols} config={{hideHeader: true, title: t('Komponenty vysílání')}} { ...props } rows={rows} />
	);
}

export default RelayPoolList;
