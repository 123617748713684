import { useCallback, useEffect, useState } from "react";
import { FormikErrors, FormikProps } from "formik";
import { Container, Draggable } from "react-smooth-dnd";
import { Grid, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import RefreshIcon from '@mui/icons-material/Refresh';
import AddIcon from '@mui/icons-material/Add';

import {JsonPoll, JsonPollChartTypeEnum, JsonPollOption, JsonPollPollTypeEnum, JsonPoplistItem} from "../../generated-api";
import styles from '../../assets/styles/relay';
import FormContainer from "../form/FormContainer";
import TextFormfield from "../form/TextFormfield";
import { useAppTranslation } from "../../services/i18n";
import SelectFormfield from "../form/SelectFormfield";
import classnames from "classnames";
import PreviewPoll from "./PreviewPoll";
import EditActionButtons from "./EditActionButtons";
import CheckboxFormfield from "../form/CheckboxFormfield";

const scaleValuesOptions: JsonPoplistItem[] = [
    { value: "0_3", label: "Škála 0 - 3"},
    { value: "0_4", label: "Škála 0 - 4"},
    { value: "0_5", label: "Škála 0 - 5"},
    { value: "0_6", label: "Škála 0 - 6"},
    { value: "0_7", label: "Škála 0 - 7"},
    { value: "0_8", label: "Škála 0 - 8"},
    { value: "0_9", label: "Škála 0 - 9"},
    { value: "0_10", label: "Škála 0 - 10"},

    { value: "1_3", label: "Škála 1 - 3"},
    { value: "1_4", label: "Škála 1 - 4"},
    { value: "1_5", label: "Škála 1 - 5"},
    { value: "1_6", label: "Škála 1 - 6"},
    { value: "1_7", label: "Škála 1 - 7"},
    { value: "1_8", label: "Škála 1 - 8"},
    { value: "1_9", label: "Škála 1 - 9"},
    { value: "1_10", label: "Škála 1 - 10"},
]

export function getJsonPollValue(values: JsonPollForm, options: JsonPollOption[]) {
    return { ...values,
            options, 
            chartType: values.pollType === JsonPollPollTypeEnum.Text ? JsonPollChartTypeEnum.None : values.chartType, 
            groupId: values.groupId && +values.groupId 
        }
};

function prepareScaleOptions(options: JsonPollOption[], scale: number, start: number) {
    const scaleOptions = [];
    for (let i = start; i <= scale || 0; i++) { scaleOptions.push('' + i); }
    const newOptions = scaleOptions.reduce((acc: JsonPollOption[], title: string) => {
        const option = options?.find((opt: JsonPollOption) => opt.title === title);
        if (option) {
            acc.push(option);
        } else {
            acc.push({ title });
        }
        return acc;
    }, []);

    options?.forEach((option: JsonPollOption) => {
        const newOption = newOptions.find((opt: JsonPollOption) => opt.title === option.title);
        if (!newOption && option.optionId) {
            newOptions.push({ ...option, isRemoved: true });
        }
    });

    return newOptions;
}

function initPollFormData(poll: JsonPoll): JsonPollForm {
    const extremes = (poll.options || []).reduce((acc: { min: number, max: number }, option: JsonPollOption) => {
        if (+option.title! > acc.max) acc.max = +option.title!;
        if (+option.title! < acc.min) acc.min = +option.title!;
        return acc;
    }, { min: 10, max: 0 });
    return {
        ...poll,
        scaleValues: (extremes && extremes.min + '_' + extremes.max) || '0_3'
    }
}

export interface JsonPollForm extends JsonPoll {
    scaleValues: string;
}

interface Props {
    poll: JsonPoll;
    pollGroups?: JsonPoplistItem[];
    onSave?: (poll: JsonPoll) => void;
    onDelete?: (poll: JsonPoll) => void;
    onCancel?: () => void;
}

const EditPoll = ({ poll, pollGroups, onSave, onDelete, onCancel }: Props) => {

    const t = useAppTranslation();

    const [items, setItems] = useState<JsonPollOption[]>([]);
    const [newItem, setNewItem] = useState<string>('');
    const [editMode, setEditMode] = useState<boolean>(true);

    useEffect(() => {
        setItems(poll.options || []);
    }, [poll])

    const onDrop = useCallback(({ removedIndex, addedIndex }: { removedIndex: number | null, addedIndex: number | null }) => {
        const newItems: JsonPollOption[] = [];
        if (removedIndex !== addedIndex) {
            items.forEach((item: JsonPollOption, index: number) => {
                if (index === addedIndex && addedIndex < removedIndex!) {
                    newItems.push(items[removedIndex!]);
                }
                if (index !== removedIndex) {
                    newItems.push(item);
                }
                if (index === addedIndex && addedIndex > removedIndex!) {
                    newItems.push(items[removedIndex!]);
                }
            });
            setItems(newItems);
        }
    }, [items]);

    const addNewItem = useCallback(() => {
        setItems([ ...items, { title: newItem } ]);
        setNewItem('');
    }, [items, newItem]);

    const deleteItem = useCallback((deletedItem: JsonPollOption) => {
        if (deletedItem.optionId) {
            setItems(items?.map((item: JsonPollOption) => item === deletedItem ? { ...item, isRemoved: true } : item ));
        } else {
            setItems(items?.filter((item: JsonPollOption) => item !== deletedItem));
        }
    }, [items]);

    const refreshItem = useCallback((refreshedItem: JsonPollOption) => {
        setItems(items?.map((item: JsonPollOption) => item === refreshedItem ? { ...item, isRemoved: false } : item ));
    }, [items]);

    const handleSave = useCallback((data: JsonPoll) => {
        onSave && onSave(data);
    }, [onSave]);

    const handleChangeScaleValues = useCallback((key: string) => {
        const [start, scale] = key.split('_');
        console.log('handleChangeScaleValues', key, start, scale);
        setItems(prepareScaleOptions(items, +scale, +start));
    }, [items]);

    const validate = (values: JsonPollForm) => {
        let errors = {} as FormikErrors<JsonPoll>;
        if (!values.pollType) {
            errors['pollType'] = t('Není zvolen typ otázky.');
        }
        if (!values.chartType) {
            errors['chartType'] = t('Není zvolen typ grafu.');
        }
        if (!values.title) {
            errors['title'] = t('Text otázky musí být uveden.');
        }
        return errors;
    }
    
    const hasChartType = (values: JsonPoll) => {
        if (!values?.pollType || [JsonPollPollTypeEnum.Text].includes(values.pollType)) {
            return false;
        }
        return true;
    }

    const hasOptions = (values: JsonPoll) => {
        if (!values?.pollType || [JsonPollPollTypeEnum.Scale, JsonPollPollTypeEnum.Text].includes(values.pollType)) {
            return false;
        }
        return true;
    }
 
    return (
        <>
        { /* JSON.stringify(poll, null, 2) */ }
            { poll && 
            <>
            <FormContainer 
                title={t('Otázka')}
                data={initPollFormData(poll)} 
                validate={validate} 
                onSave={(values: JsonPollForm) => { handleSave(getJsonPollValue(values, items)) }} 
                headerAction={<EditActionButtons onEdit={() => setEditMode(true)} onPreview={() => setEditMode(false)} onDelete={() => onDelete && onDelete(poll)}/>}
                onCancel={onCancel}
                cardLayout>
                    {(formikProps: FormikProps<JsonPollForm>) => 
                        <>
                            { editMode && 
                                <>
                                    <Grid item xs={12}>
                                        <TextFormfield name="title" label={t('Text otázky')} maxlength={300}></TextFormfield>
                                    </Grid>
                                    <Grid item xs={8}>
                                        <SelectFormfield name="groupId" label={t('Skupina')} poplistItems={pollGroups}></SelectFormfield>
                                    </Grid>
                                    <Grid item xs={4}>
                                        <TextFormfield name="orderNo" label={t('Pořadí')}></TextFormfield>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectFormfield name="pollType" label={t('Typ otázky')}></SelectFormfield>
                                    </Grid>
                                    { hasChartType(formikProps.values) &&
                                        <Grid item xs={12}>
                                            <SelectFormfield name="chartType" label={t('Typ grafu')}></SelectFormfield>
                                        </Grid>
                                    }
                                    { hasOptions(formikProps.values) && 
                                        <>
                                            <Grid item xs={12}>
                                                <Container onDrop={onDrop}>
                                                    {items.map(item => {
                                                        return (
                                                            <Draggable key={item.optionId}>
                                                                <div className={classnames(styles.sortedItem, item.isRemoved ? styles.removedSortedItem : null)}>
                                                                    <div>{item.title}</div>
                                                                    { !item.isRemoved && <DeleteIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => deleteItem(item)}/> }
                                                                    { !!item.isRemoved && <RefreshIcon color="primary" style={{ cursor: 'pointer' }} onClick={() => refreshItem(item)}/> }
                                                                </div>
                                                            </Draggable>
                                                        );
                                                    })}
                                                </Container>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <div style={{display: 'flex', alignItems: 'center'}}>
                                                    <TextField
                                                        fullWidth
                                                        label={t('Nová možnost')}
                                                        type={'text'}
                                                        value={newItem}
                                                        onChange={(event) => setNewItem(event.target.value)}
                                                        variant="standard"
                                                        inputProps={{ maxLength: 100 }}
                                                        >
                                                    </TextField>
                                                    <AddIcon color="primary" style={{ cursor: 'pointer' }} onClick={addNewItem}/>
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                    { formikProps.values && formikProps.values.pollType && formikProps.values.pollType === JsonPollPollTypeEnum.Scale &&
                                        <>
                                            <Grid item xs={4}>
                                                <SelectFormfield name="scaleValues" label={t('Počet hodnot')} poplistItems={scaleValuesOptions} onChange={handleChangeScaleValues}></SelectFormfield>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFormfield name="pollParams.minLabel" label={t('Popisek minimum')}></TextFormfield>
                                            </Grid>
                                            <Grid item xs={4}>
                                                <TextFormfield name="pollParams.maxLabel" label={t('Popisek maximum')}></TextFormfield>
                                            </Grid>
                                        </>
                                    }
                                    { formikProps.values && formikProps.values.pollType && formikProps.values.pollType === JsonPollPollTypeEnum.Text && !formikProps.values.groupId &&
										<>
											<Grid item xs={12}>
												<CheckboxFormfield name="pollParams.allowMultipleResponses" label={t('Umožnit odeslat více odpovědí')}></CheckboxFormfield>
											</Grid>
										</>
                                    }
                                </>                
                            }
                            { /*
                            <div>{ JSON.stringify(items, null, 2) }</div>
                            <div>{ JSON.stringify(formikProps.values, null, 2) }</div>
                            <div>{ JSON.stringify(items, null, 2) }</div>
                            */ }

                            { !editMode && <PreviewPoll options={items}></PreviewPoll> }

                        </>
                    }
            </FormContainer>
            </>
            }
        </>
      );
}

export default EditPoll;
