import {Tooltip} from '@mui/material';
import * as React from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {OnepagerEntityIndex} from "../../../model/onepager";
import {
    JsonStratEnabler,
    JsonStratOnepagerInfo,
    JsonStratPillar,
    JsonStratPillarInfo
} from "../../../generated-api";
import SimpleList from "../../../components/layout/SimpleList";
import CheckIcon from "@mui/icons-material/Check";
import ClosableModal, {ModalButtonActionEnum} from "../../../components/layout/ClosableModal";

interface Props {
    parentOnePager: JsonStratOnepagerInfo | undefined;
    index: OnepagerEntityIndex | undefined;
    onSelect: (index: OnepagerEntityIndex, pillar: Partial<JsonStratPillar>) => void;
    onClose: () => void;
}

const PillarParentModal = ({ parentOnePager, index, onClose, onSelect }: Props) => {

    const t = useAppTranslation();

    const pillarCols = [
        {
            title: 'Piliře',
            style: { width: '40%' },
            renderer: (row: JsonStratPillarInfo) => {
                return (
                    <div><strong>{ row.description }</strong></div>
                );
            }
        },
        {
            style: { width: '20%', textAlign: 'right' },
            renderer: (row: JsonStratPillarInfo) => {
                return (
                    <>
                        <Tooltip title="Zvolit">
                            <CheckIcon color="primary"/>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const enablerCols = [
        {
            title: 'Enablery',
            style: { width: '40%' },
            renderer: (row: JsonStratEnabler) => {
                return (
                    <div><strong>{ row.description }</strong></div>
                );
            }
        },
        {
            style: { width: '20%', textAlign: 'right' },
            renderer: (row: JsonStratEnabler) => {
                return (
                    <>
                        <Tooltip title="Zvolit">
                            <CheckIcon color="primary"/>
                        </Tooltip>
                    </>
                );
            }
        }
    ];

    const buttons = [
        { action: ModalButtonActionEnum.Close, title: t('Zavřít') }
    ]

    return (
        <>
            { index &&
                <ClosableModal open={!!index} title={t('Vyberte nadřazený pilíř nebo enabler')} onClose={onClose} buttons={buttons}>
                    <SimpleList
                        cols={pillarCols}
                        config={{ hideHeader: false }}
                        rows={parentOnePager?.pillars || []}
                        action={(row, action) => onSelect(index, { parentPillarId: row.pillarId })}>
                    </SimpleList>
                    <SimpleList
                        cols={enablerCols}
                        config={{ hideHeader: false }}
                        rows={parentOnePager?.enablers || []}
                        action={(row, action) => onSelect(index, { parentEnablerId: row.enablerId })}>
                    </SimpleList>
                </ClosableModal>
            }
        </>
    );

}

export default PillarParentModal;
