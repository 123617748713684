import format from 'date-fns/format';
import localeSK from 'date-fns/locale/sk';
import localeEN from 'date-fns/locale/en-US';

const dateLocales: {[lng: string]: any} = {
	sk: localeSK,
	en: localeEN,
};

let locale: Locale = dateLocales.en;

export function setDateLocale(lng: string) {
	locale = dateLocales[lng] || dateLocales.en;
}

export function dateToGui(val: Date | undefined) {
	if (!val) {
		return null;
	}
	return format(val, 'P', {locale}); // 'd.M.yyyy'
}

export function datetimeToGui(val: Date | undefined) {
	if (!val) {
		return null;
	}
	return format(val, 'P p', {locale}); // 'd.M.yyyy HH:mm'
}

export function getDayOfWeekName(dow: number) {
	return locale.localize?.day(dow === 7 ? 0 : dow);
}

export function stringDateToGui(date?: string) {
	if (date) {
		const day = date.substring(0,10);
		const parts = date.split('-');
		if (parts.length === 3) {
			return parseInt(parts[2]) + '.' + parseInt(parts[1]) + '.' + parseInt(parts[0]);
		}
		return day;
	}
	return '';
}
