import {useAppDispatch} from '../../../store';
import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from 'react';
import {JsonEpisodeInfo, JsonRelayInfo, JsonSerie} from '../../../generated-api';
import PageHeader from '../../../components/layout/PageHeader';
import {serieFactory} from '../../../model/factories';
import {Container, Grid} from '@mui/material';
import SerieForm from '../../../forms/SerieForm';
import {fetchSerie, saveSerie} from '../../../store/series';
import SerieEpisodesList from './components/SerieEpisodesList';
import {fetchSerieEpisodes} from '../../../store/episodes';
import {fetchRelaysCombo, saveRelay} from '../../../store/relays';

interface Props {
  createNew?: boolean;
}

const EditSeriePage = ({ createNew }: Props) => {
	const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [serie, setSerie] = useState<JsonSerie | null>(null);
  const [seriesEpisodes, setSerieEpisodes] = useState<JsonEpisodeInfo[] | null>(null);
  const [relays, setRelays] = useState<JsonRelayInfo[] | null>(null);

  const { id } = useParams();

  const loadSeriesEpisodes = useCallback(async (id: number) => {
    const result = await dispatch(fetchSerieEpisodes(+id));
    if ('error' in result) {
      return;
    } else {
      setSerieEpisodes(result.payload.data || null);
    }
  }, [dispatch]);

  const loadRelays = useCallback(async () => {
    const result = await dispatch(fetchRelaysCombo());
    if ('error' in result) {
      return;
    } else {
      setRelays(result.payload.data || null);
    }
  }, [dispatch]);

  useEffect(() => {
    if (createNew) {
      setSerie(serieFactory());
      return;
    } 
    if (id) {
      dispatch(fetchSerie(+id))
        .then((result) => { 
          setSerie((result?.payload as any).data);
          return Promise.all([loadSeriesEpisodes(+id), loadRelays()]);
        })
        .then((result) => { 
          // TODO
        });
    }
	}, [dispatch, createNew, id, loadSeriesEpisodes, loadRelays]);

  const handleSave = useCallback(async (data: JsonSerie) => {
    const result = await dispatch(saveSerie(data));
    if ('error' in result) {
      return;
    }
    navigate('/series');
  }, [navigate, dispatch])

  const handleCancel = useCallback(() => {
    navigate('/series');
  }, [navigate])

  const handleNewEpisode = useCallback(() => {
    navigate('/series/' + id + '/episode/new');
  }, [navigate, id]);

  const handleActionEpisode = useCallback(async (episode: JsonEpisodeInfo, action: string) => {
    // TODO add action
    // console.log({ episode, action });
    switch (action) {
      case 'CREATE_RELAY':
        const result = await dispatch(saveRelay({ episodeId: episode.episodeId }));
        if ('error' in result) {
          return;
        }
        await loadRelays();
        break;
      default:
        break;
    }
  }, [dispatch, loadRelays]);

  return (
    <>
		{ !!serie &&
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={serie.title as string} showBack></PageHeader>
          </Grid>
          <Grid item xs={6}>
            <SerieForm data={serie} onSave={handleSave} onCancel={handleCancel} cardLayout></SerieForm>
            { /* <pre>{ JSON.stringify(user, null, 2) }</pre> */ }
          </Grid>
          { serie.serieId &&
            <Grid item xs={6}>
              <SerieEpisodesList rows={seriesEpisodes} relays={relays || []} action={handleActionEpisode} onAdd={handleNewEpisode}></SerieEpisodesList>
            </Grid>
          }
        </Grid>  
      </Container>    
    }
    </>
	);
}

export default EditSeriePage;
