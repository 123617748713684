import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import { useAppTranslation } from '../../../services/i18n';
import styles from '../../../assets/styles/onepager.module.css';
import {OnepagerEntityIndex} from "../../../model/onepager";
import TextFormfield from "../../../components/form/TextFormfield";
import {Formik} from "formik";
import {useAppDispatch} from "../../../store";
import {setOnepagerActiveIndex, updateOnepagerEntity} from "../../../store/strategy";
import {selectStrategyOnePagerActiveIndex} from "../../../store/selectors";
import {useSelector} from "react-redux";
import {Nl2BrText} from "../../../components/layout/Nl2BrText";

interface Props {
  index: OnepagerEntityIndex;
  readonly?: boolean;
  value?: string;
  onConfirm?: (index: OnepagerEntityIndex, data: string) => void;
  sx?: any;
}

const OnePagerEditableText = ({ index, value, readonly, onConfirm, sx }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    const dispatch = useAppDispatch();

    const activeIndex = useSelector(selectStrategyOnePagerActiveIndex);

    const [textValue, setTextValue] = useState<string>(value||'');
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const isActive = useMemo(() => {
        return (JSON.stringify(activeIndex) === JSON.stringify(index)) && !readonly;
    }, [activeIndex, index, readonly]);

    const handleClick = useCallback(() => {
        if (!readonly) {
            dispatch(setOnepagerActiveIndex(index));
        }
    }, [dispatch, index, readonly])

    const handleEnter = useCallback((unsetIndex: boolean = true) => {
        // console.log('handleEnter', textValue, { activeIndex, index }, JSON.stringify(activeIndex) === JSON.stringify(index));
        if (!readonly) {
            if (onConfirm) {
                onConfirm(index, textValue);
            } else {
                dispatch(updateOnepagerEntity({index: index, data: textValue }));
            }
            if (unsetIndex) {
                dispatch(setOnepagerActiveIndex(undefined));
            }
        }
    }, [activeIndex, index, textValue, dispatch, readonly, onConfirm])

    const handleEscape = useCallback(() => {
        if (isActive) {
            dispatch(setOnepagerActiveIndex(undefined));
        }
    }, [isActive, dispatch])

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (!isActive) {
                return false;
            }
            if (event.key === 'Enter') {
                console.log({ ctrlKey: event.ctrlKey });
                if (event.ctrlKey) {
                    console.log({ prevValue: value });
                    setTextValue((value) => value + '\n');
                } else {
                    event.preventDefault();
                    handleEnter();
                }
            }
            if (event.key === 'Escape') {
                event.preventDefault();
                handleEscape();
            }
            setIsChanged(true);
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [handleEnter, handleEscape, textValue, setTextValue, isActive, value]);

    /*
    useEffect(() => {
        // console.log('change - test', { index, value, textValue, isActive });
        if (!isActive && textValue !== value) {
            console.log('change', { index, value, textValue });
        }
    }, [isActive, textValue, value, index]);
     */

    useEffect(() => {
        setTextValue(value||'');
    }, [value]);

    useEffect(() => {
        if (!isActive && isChanged && !onConfirm) {
            console.log({ isActive, isChanged });
            setIsChanged(false);
            setTimeout(() => handleEnter(false), 0);
        }
    }, [isActive, isChanged, handleEnter, onConfirm])

    return (
          <div onClick={() => handleClick()} className={`${styles.editableText} ${readonly ? styles.readonlyEditableText : undefined}`} style={sx}>
              { !isActive && <Nl2BrText text={ value || '' } /> }
              { isActive && !readonly &&
                    <Formik
                        initialValues={{ text: textValue }}
                        enableReinitialize={true}
                        validate={() => {}}
                        onSubmit={(values, actions) => {
                        }}
                    >
                    {formikProps => {
                        return (
                            <TextFormfield name="text" label={t('')} onChange={(text) => setTextValue(text)} multiline={4} maxlength={1000} focused></TextFormfield>
                        )
                    }}
                    </Formik>
              }
          </div>
	);
}

export default OnePagerEditableText;
