import {useNavigate} from "react-router-dom";
import {Card, Grid, Tooltip} from '@mui/material';
import SearchOnePagerForm, {SearchOnePagerFormData} from "../../../forms/SearchOnePagerForm";
import * as React from "react";
import {useCallback, useState} from "react";
import {searchOnePagers} from "../../../store/strategy";
import {useAppDispatch} from "../../../store";
import ClosableModal from "../../../components/layout/ClosableModal";
import SimpleList from "../../../components/layout/SimpleList";
import {JsonStratOnepagerInfo} from "../../../generated-api";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {useAppTranslation} from "../../../services/i18n";
import {addMessage} from "../../../store/localApp";
import {Formik, FormikErrors} from "formik";
import TextFormfield from "../../form/TextFormfield";
import SearchIcon from "@mui/icons-material/Search";
import styles from '../../../assets/styles/stratairu.module.css';
import classnames from "classnames";
import StratairuOnePagerCard from "./StratairuOnePagerCard";


const StratairuSearchOnePager = ({strategyId}: {strategyId: number}) => {

    const t = useAppTranslation();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [onePagers, setOnePagers] = useState<JsonStratOnepagerInfo[]|undefined>(undefined);

    const onSearch = useCallback(async (search: string) => {
        const result = await dispatch(searchOnePagers({search, strategyId}));
        if (!('error' in result) && result.payload.data?.length) {
            setOnePagers(result.payload.data);
        } else {
            dispatch(addMessage({
                severity: 'info',
                title: t('onePager.messages.notFound'),
            }))
            setOnePagers(undefined);
        }
        console.log({ search, result });
    }, [strategyId, t, dispatch]);

    const onCloseResults = useCallback(() => {
        setOnePagers(undefined);
    }, []);

    const onSelect = useCallback((onePager: JsonStratOnepagerInfo) => {
        navigate(`/onepager/${onePager.onepagerId}`)
    }, [navigate]);

    const validate = (values: SearchOnePagerFormData) => {
        let errors = {} as FormikErrors<SearchOnePagerFormData>;
        /*if (!values.title) {
            errors['title'] = t('Název episody je povinný.');
        }*/
        return errors;
    }

    return (
        <>
        <Formik
            initialValues={{ search: ''}}
            enableReinitialize={true}
            validate={validate}
            onSubmit={(values: any) => {
                // console.log({ values });
            }}
        >{props => (
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <div className={styles.searchContainer}>
                    <div>
                        <TextFormfield name="search" variant="outlined" placeholder={t('onePager.buttons.search')} onEnter={() => onSearch && onSearch(props.values.search)}></TextFormfield>
                    </div>
                    <div className={styles.icon}>
                        <SearchIcon style={{ cursor: 'pointer' }} onClick={() => onSearch && onSearch(props.values.search)}/>
                    </div>
                </div>
            </Grid>)}
        </Formik>

        { onePagers &&
            <ClosableModal open={!!onePagers} title={t('onePager.messages.foundOnePagers')} onClose={onCloseResults}>
                <div style={{ minWidth: '500px', marginTop: '10px' }}>
                    { (onePagers || []).map((op: JsonStratOnepagerInfo) => <StratairuOnePagerCard onepager={op}></StratairuOnePagerCard>) }
                </div>
            </ClosableModal>
        }

        </>
	);
}

export default StratairuSearchOnePager;
