/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonGroupInfo
 */
export interface JsonGroupInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonGroupInfo
     */
    allowedActions?: Array<JsonGroupInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonGroupInfo
     */
    episodeId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonGroupInfo
     */
    groupId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonGroupInfo
     */
    orderNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupInfo
     */
    status?: JsonGroupInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonGroupInfo
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonGroupInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonGroupInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonGroupInfoFromJSON(json: any): JsonGroupInfo {
    return JsonGroupInfoFromJSONTyped(json, false);
}

export function JsonGroupInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonGroupInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonGroupInfoToJSON(value?: JsonGroupInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'episodeId': value.episodeId,
        'groupId': value.groupId,
        'orderNo': value.orderNo,
        'status': value.status,
        'title': value.title,
    };
}

