import { CircularProgress } from "@mui/material";
import { useSelector } from "react-redux";
import { selectAppLoading } from "../../store/selectors";

export const Loader = () => {
	const isLoading = useSelector(selectAppLoading);
	return (
		<>
			{ isLoading && 
				<div style={{ width: '100%', height: '100vh', position: 'fixed', background: 'rgba(255,255,255,0.5)', zIndex: '200', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<CircularProgress />
				</div>
			}
		</>
	);
};
