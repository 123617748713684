import {JsonStratUser} from "../generated-api";
import {JsonStratOnepagerInfo, JsonStratOnepagerStatusEnum} from "../generated-api";

export interface ImportStrategyUserError {
    row: number;
    errorGuid?: string;
    errorCode?: string;
    error: string;
    errorDesc: string;
}
export const checkImportResult = (data: JsonStratUser[] | undefined): ImportStrategyUserError[] | null => {
    const errors: ImportStrategyUserError[] = [];
    data?.forEach((user: JsonStratUser, index: number) => {
       if (user.saveErrors && user.saveErrors.length) {
           const error = user.saveErrors[0];
           errors.push({
               row: user.rowNo || 0,
               errorCode: error.errorCode,
               errorGuid: error.errorGuid,
               error: (error.values as any)?.error,
               errorDesc: (error.values as any)?.description
           });
       }
    });
    return errors.length ? errors : null;
}

export const getOnePagerLevel = (onePager: JsonStratOnepagerInfo): number => {
    return (onePager?.userPath?.split('/')?.length || 2) - 2;
}

export const calculateSubtreeStatuses = (subtree: JsonStratOnepagerInfo[], parentUserId: number, firstLevel: boolean) => {

    const result = {
        [JsonStratOnepagerStatusEnum.Pending]: 0,
        [JsonStratOnepagerStatusEnum.New]: 0,
        [JsonStratOnepagerStatusEnum.Draft]: 0,
        [JsonStratOnepagerStatusEnum.Public]: 0,
        [JsonStratOnepagerStatusEnum.Finished]: 0,
    }

    subtree.filter(op => op.userId !== parentUserId && (op.parentUserId === parentUserId || !firstLevel)).forEach(op => {
        console.log({ op });
        result[op.status!]++;
    });

    return result;
}
