import {Button, Card, CardActions, CardContent, CardHeader, Grid} from '@mui/material';
import styles from '../../assets/styles/layout';
import { Formik, FormikProps } from 'formik';
import { ReactNode } from 'react';
import { FormProps } from '../../model/form';
import { useAppTranslation } from '../../services/i18n';

interface Props<T> extends FormProps<T>{
	children?: ((formikProps: FormikProps<T>) => ReactNode) | ReactNode | undefined;
}

const FormContainer = <T extends {}>(props: Props<T>) => {

    const t = useAppTranslation();

	const { onSave, onCancel, saveButtonTitle, cancelButtonTitle, data, validate, children, title, cardLayout, headerAction } = props;

	return (
		<>
			{ data && <Formik
				initialValues={data}
				enableReinitialize={true}
				validate={validate}
				onSubmit={(values, actions) => {
                    onSave && onSave(values);
				}}
				>       
				{formikProps => (
				<form onSubmit={formikProps.handleSubmit}>
					{ cardLayout && 
						<Card>
							{ title && <CardHeader
								title={title}
								action={headerAction}
							/> }
							<CardContent>
								<Grid container spacing={1} padding={2}>
									{ typeof children === 'function' ? children({ ...formikProps }) : children }
								</Grid>
							</CardContent>
							<CardActions>
								<Grid container spacing={2}>
									{ onSave &&
										<Grid item xs={12} md={onCancel ? 6 : 12}>
											<Button variant="contained" type="submit" color="secondary" fullWidth>{saveButtonTitle || t('Uložit')}</Button>
										</Grid>
									}
									{ onCancel && 
										<Grid item xs={12} md={6}>
											<Button variant="contained" onClick={() => onCancel && onCancel() } fullWidth>{cancelButtonTitle || t('Zrušit')}</Button>
										</Grid>
									}
								</Grid>
							</CardActions>
						</Card>
					}
					{ !cardLayout && 
						<>
							<Grid container spacing={2}>
								{ typeof children === 'function' ? children({ ...formikProps }) : children }
							</Grid>
							<Grid container spacing={2} marginTop={2}>
								{ onSave &&
									<Grid item xs={12} md={onCancel ? 6 : 12}>
										<Button variant="contained" type="submit" color="secondary" fullWidth>{saveButtonTitle || t('Uložit')}</Button>
									</Grid>
								}
								{ onCancel && 
									<Grid item xs={12} md={6}>
										<Button variant="contained" fullWidth onClick={() => onCancel && onCancel() }>{cancelButtonTitle || t('Zrušit')}</Button>
									</Grid>
								}
							</Grid>
						</>
					}
				</form>
				)}
			</Formik> }
		</>
	);
}

export default FormContainer;
