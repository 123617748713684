import React, {ReactElement, useCallback, useEffect, useRef} from 'react'
import {useDropzone} from 'react-dropzone'
import styles from '../../../assets/styles/dropzone.module.css';
import {DropzoneFileList, DropzopneFileListProps} from "./DropzopneFileList";

export type FileUploadStatusPhase = 'PENDING' | 'PROCESSING' | 'FINISHED' | 'ERROR';
export interface FileUploadStatus {
  file: File;
  status: FileUploadStatusPhase;
}

interface Props {
	isLoading: boolean;
	onDrop: (files: File[]) => void;
  	uploadStatus?: FileUploadStatus[];
  	autoOpen?: boolean;
	multiple?: boolean;
  	label?: string;
	fileList?: ReactElement<DropzopneFileListProps>;
}

export const Dropzone = ({isLoading, onDrop, autoOpen, multiple, uploadStatus, label, fileList}: Props) => {

	const dropzoneRef = useRef<HTMLDivElement | null>(null);

	const dropzoneFileList = fileList || <DropzoneFileList uploadStatus={uploadStatus}></DropzoneFileList>;

	useEffect(() => {
		// TODO: rewrite with ref callback, dropzoneRef.current sometimes can be null (render / effect race)
		if (autoOpen) {
			dropzoneRef.current?.click();
		}
	}, [autoOpen])

	const handleOnDrop = useCallback((acceptedFiles: File[]) => {
		if (acceptedFiles) {
			onDrop(acceptedFiles);
		}
	}, [onDrop]);

	const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop: handleOnDrop, multiple})

	return (
    <>
      { label && <label>{label}</label> }
      <div {...getRootProps()} className={styles.dropZone} ref={dropzoneRef}>
        {isLoading && <div>Uploading</div>}
        <input {...getInputProps()} />
        {
          !isLoading && (
            isDragActive ?
              <p>Uvolněte tlačítko myši...</p> :
              <p>Přetáhněte sem soubory, nebo klikněte a vybrerte soubory</p>
          )
        }
        {
          isLoading ?
            <p>Nahrávám soubor ...</p> : <></>
        }
      </div>
		{ dropzoneFileList }
      { /*uploadStatus &&
        <>
        <div className={dropzoneStyles.uploadedFileTitle}>Nahrávané soubory</div>
        {uploadStatus.map((fileStatus: FileUploadStatus) =>
        <div className={dropzoneStyles.uploadedFile}>
          <div>{ fileStatus.file.name }</div>
          { fileStatus.status === 'PENDING' && <Icon children="timer" color="disabled" /> }
          { fileStatus.status === 'PROCESSING' && <CircularProgress size={20}/> }
          { fileStatus.status === 'FINISHED' && <div style={{ color: '#006600' }}><Icon children="check-circle"/></div> }
          { fileStatus.status === 'ERROR' && <Icon children="error" color="error"/> }
        </div>
        ) }
        </>
       */ }
    </>
	)
}
