/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonGroup
 */
export interface JsonGroup {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonGroup
     */
    allowedActions?: Array<JsonGroupAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonGroup
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonGroup
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonGroup
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonGroup
     */
    episodeId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonGroup
     */
    groupId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonGroup
     */
    orderNo?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonGroup
     */
    status?: JsonGroupStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonGroup
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonGroup
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonGroup
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonGroupAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonGroupStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonGroupFromJSON(json: any): JsonGroup {
    return JsonGroupFromJSONTyped(json, false);
}

export function JsonGroupFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonGroup {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonGroupToJSON(value?: JsonGroup | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'episodeId': value.episodeId,
        'groupId': value.groupId,
        'orderNo': value.orderNo,
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

