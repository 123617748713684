import {useCallback, useEffect} from 'react';

import {Button, Container, Tooltip} from '@mui/material';
import {useAppTranslation} from '../../services/i18n';
import {Link, useNavigate} from "react-router-dom";

import PageHeader from '../../components/layout/PageHeader';
import PageableList from '../../components/layout/PagableList';
import {
    selectStrategies,
    selectStrategiesPaging
} from '../../store/selectors';

import styles from '../../assets/styles/layout';
import { useAppDispatch } from '../../store';
import {JsonStrategyInfo} from '../../generated-api/models';
import {fetchStrategies, fetchStrategiesCount, setStrategiesFilter} from "../../store/strategies";
import {isProdEnv} from "../../app/config";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";

const ListStrategiesPage = () => {
	const t = useAppTranslation();
	const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // dispatch(fetchEpisodesCombo());
        dispatch(setStrategiesFilter({serieId: -1 /* fake value for NONE */}));
    }, [dispatch])

    const cols = [
        {
            title: t('Společnost'),
            style: { width: '45%' },
            renderer: (row: JsonStrategyInfo) =>
            <div>
              <div>{row.companyName}</div>
            </div>
        },
        {
            title: t('Strategie'),
            style: { width: '45%' },
            renderer: (row: JsonStrategyInfo) =>
              <div>
                  <div className={styles.listTitle}>
                      <Link to={`/strategies/${row.strategyId}`} className={styles.link}>{row.title}</Link>
                  </div>
              </div>
        },
        {
            title: t('Velikost struktury'),
            style: { width: '45%' },
            renderer: (row: JsonStrategyInfo) =>
              <div>
                  <div>
                      {row.userCount}
                  </div>
              </div>
        },
        {
          title: t('Stav'),
          style: { width: '45%' },
          renderer: (row: JsonStrategyInfo) =>
            <div className={styles.listInfo}>
              {row.status}
            </div>
        },
        {
          title: '',
          style: { width: '10%', display: 'flex', justifyContent: 'end' },
          renderer: (row: JsonStrategyInfo) =>
              <div>
                  { !isProdEnv && <Tooltip title="Odstranit strategii">
                      <DeleteIcon color="primary" onClick={() => handleAction(row, 'DELETE')}/>
                  </Tooltip> }
                  <Tooltip title="Upravit parametry strategie">
                      <Link to={`/strategies/${row.strategyId}`}><EditIcon color="primary"/></Link>
                  </Tooltip>
              </div>
        }
    ]

    const handleNewStrategy = useCallback(() => {
        navigate('/strategies/new');
    }, [navigate])

    const handleAction = useCallback(async (strategy: JsonStrategyInfo, action: string) => {
        // console.log('Strategy action', { action, strategy });
    }, []);

    return (
        <Container>
          <PageHeader title={t('Seznam strategií')}>
              <Button variant="contained" className={styles.yellowButton} fullWidth onClick={handleNewStrategy}>{t('Nová strategie')}</Button>
          </PageHeader>

          <PageableList
            cols={cols}
            action={handleAction}
            config={{
              selectItems: selectStrategies,
              selectItemsPaging: selectStrategiesPaging,
              fetchItemsCount: fetchStrategiesCount,
              fetchItems: fetchStrategies
            }}
          ></PageableList>
    </Container>
    );
}

export default ListStrategiesPage;
