/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonStrategyInfo,
    JsonStrategyInfoFromJSON,
    JsonStrategyInfoFromJSONTyped,
    JsonStrategyInfoToJSON,
} from './JsonStrategyInfo';

/**
 * 
 * @export
 * @interface ApiResultOfListOfJsonStrategyInfo
 */
export interface ApiResultOfListOfJsonStrategyInfo {
    /**
     * 
     * @type {Array<JsonStrategyInfo>}
     * @memberof ApiResultOfListOfJsonStrategyInfo
     */
    data?: Array<JsonStrategyInfo>;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfListOfJsonStrategyInfo
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResultOfListOfJsonStrategyInfo
     */
    messageValues?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfListOfJsonStrategyInfo
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfListOfJsonStrategyInfo
     */
    result?: ApiResultOfListOfJsonStrategyInfoResultEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApiResultOfListOfJsonStrategyInfoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

export function ApiResultOfListOfJsonStrategyInfoFromJSON(json: any): ApiResultOfListOfJsonStrategyInfo {
    return ApiResultOfListOfJsonStrategyInfoFromJSONTyped(json, false);
}

export function ApiResultOfListOfJsonStrategyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResultOfListOfJsonStrategyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : ((json['data'] as Array<any>).map(JsonStrategyInfoFromJSON)),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageValues': !exists(json, 'messageValues') ? undefined : json['messageValues'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function ApiResultOfListOfJsonStrategyInfoToJSON(value?: ApiResultOfListOfJsonStrategyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': value.data === undefined ? undefined : ((value.data as Array<any>).map(JsonStrategyInfoToJSON)),
        'message': value.message,
        'messageValues': value.messageValues,
        'requestId': value.requestId,
        'result': value.result,
    };
}

