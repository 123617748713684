import * as React from 'react';
import {useSelector} from 'react-redux';
import {selectAuthIsLogged, selectAuthUser} from '../../store/selectors';
import AppHeader from './AppHeader';
import styles from '../../assets/styles/layout';
import {FlashMessages} from './FlashMessages';
import {Loader} from './Loader';
import classnames from 'classnames';
import {useLocation} from "react-router";
import StratairuLayout from "../stratairu/StratairuLayout";
import {AtairuEnv, getEnvironment} from "../../helpers/environment";

interface Props {
    noHeader?: boolean;
    noBackground?: boolean;
    children?: any;
}

const Layout = ({ children, noHeader, noBackground }: Props) => {
	
    const isLogged = useSelector(selectAuthIsLogged);
    const user = useSelector(selectAuthUser);

    const location = useLocation();
    const isStrategy = location.pathname.startsWith('/strategy') || location.pathname.startsWith('/onepager');
    const isStratairu = getEnvironment() === AtairuEnv.Stratairu;

	return (
        <>
            { !isStratairu &&
                <div className={classnames(isStrategy ? styles.strategyContainer : styles.container, noBackground ? styles.noBackground : null)}>
                    <FlashMessages />
                    <Loader />
                    { !noHeader && isLogged && <AppHeader user={user}/> }
                    <div>{children}</div>
                </div>
            }

            { !!isStratairu &&
                <StratairuLayout>{ children }</StratairuLayout>
            }
        </>
	);
}

export default Layout;
