import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {JsonPoplistItem, JsonStratTeam} from '../generated-api';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';

interface Props extends FormProps<JsonStratTeam> {
    teams: JsonPoplistItem[];
}

const StrategyTeamForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonStratTeam) => {
        let errors = {} as FormikErrors<JsonStratTeam>;
        /*
        if (!values.email) {
            errors['email'] = t('Email je povinný.');
        } else if (!isEmailValid(values.email)) {
            errors['email'] = t('Zadejte platnou emailovou adresu.');
        }
        if (!values.firstName) {
            errors['firstName'] = t('Křestní jméno je povinné.');
        }
        if (!values.lastName) {
            errors['lastName'] = t('Příjmení je povinné.');
        }
        if (!values.sex) {
            errors['sex'] = t('Pohlaví je povinné.');
        }
         */
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            { !props.teams?.length && <Grid item xs={12}>
                <TextFormfield name="teamName" label={t('Team name')}></TextFormfield>
            </Grid> }
            { !!props.teams?.length && <Grid item xs={12}>
                <SelectFormfield name="stratUserId" label={t('Team')} poplistItems={props.teams}></SelectFormfield>
            </Grid> }
		</FormContainer>
	);
}

export default StrategyTeamForm;
