import {JsonStratOnepagerStatusEnum} from '../../../generated-api';
import styles from '../../../assets/styles/stratairu.module.css';
import * as React from "react";
import {useAppTranslation} from "../../../services/i18n";

interface FillingCardProps {
    label: string;
    statuses: {
        [name: string]: number,
    }
}

const StratairuFillingCard = ({ label, statuses }: FillingCardProps) => {

    const t = useAppTranslation();

    const totalCount = (statuses[JsonStratOnepagerStatusEnum.New] || 0) + (statuses[JsonStratOnepagerStatusEnum.Draft] || 0) + (statuses[JsonStratOnepagerStatusEnum.Public] || 0) + (statuses[JsonStratOnepagerStatusEnum.Finished] || 0);
    const result = (((statuses[JsonStratOnepagerStatusEnum.Draft] || 0) * 0.25 +  (statuses[JsonStratOnepagerStatusEnum.Public] || 0) * 0.5 + (statuses[JsonStratOnepagerStatusEnum.Finished] || 0)) / totalCount) * 100;
    const formattedResult = (Math.round(result * 100) / 100).toFixed(2);

    return <>
        { !!totalCount &&
            <div className={styles.elementCard}>
                <div className={styles.title} style={{ border: '0px' }}>{ label }</div>
                <div style={{ display: 'flex', width: '100%' }}>
                    <div className={styles.statusesContainer} style={{ flex: 3, borderRight: '1px solid var(--strat-blue)' }}>
                        <div className={styles.FINISHED}>{ t('enums.opStatus.' + JsonStratOnepagerStatusEnum.Finished) }</div>
                        <div className={styles.PUBLIC}>{ t('enums.opStatus.' + JsonStratOnepagerStatusEnum.Public) }</div>
                        <div className={styles.DRAFT}>{ t('enums.opStatus.' + JsonStratOnepagerStatusEnum.Draft) }</div>
                        <div className={styles.NEW}>{ t('enums.opStatus.' + JsonStratOnepagerStatusEnum.New) }</div>
                    </div>
                    <div className={styles.statusesContainer} style={{ flex: 2 }}>
                        <div className={styles.FINISHED}>{ statuses[JsonStratOnepagerStatusEnum.Finished] }</div>
                        <div className={styles.PUBLIC}>{ statuses[JsonStratOnepagerStatusEnum.Public] }</div>
                        <div className={styles.DRAFT}>{ statuses[JsonStratOnepagerStatusEnum.Draft] }</div>
                        <div className={styles.NEW}>{ statuses[JsonStratOnepagerStatusEnum.New] }</div>
                    </div>
                </div>
                { /* <div>{formattedResult}&nbsp;%</div> */ }
            </div>
        }
    </>
}

export default StratairuFillingCard;
