import styles from '../../assets/styles/layout';
import { PropsWithChildren, useCallback } from 'react';
import ArrowBackIosOutlinedIcon from '@mui/icons-material/ArrowBackIosOutlined';
import { useNavigate } from 'react-router';

interface Props {
    title: string;
    showBack?: boolean;
}

const PageHeader = ({ title, showBack, children }: PropsWithChildren<Props>) => {

    const navigate = useNavigate();

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate])

	return (
		<div className={styles.pageHeaderContainer}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
                { showBack && <ArrowBackIosOutlinedIcon onClick={goBack} sx={{ marginRight: '10px', cursor: 'pointer' }}/>}
                <div>{title}</div>
            </div>
            <div>{children}</div>
        </div>
	);
}

export default PageHeader;
