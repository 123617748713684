import {FormControl, FormHelperText, InputLabel, MenuItem, Select} from '@mui/material';
import {useField} from 'formik';
import {useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import { JsonPoplistItem } from '../../generated-api';
import {PoplistFormfieldProps} from '../../model/form';
import {LocalCodebookItem} from '../../store/codebooks';
import {selectCodebooks} from '../../store/selectors';
import {useAppTranslation} from "../../services/i18n";

const MenuProps = {
	PaperProps: {
	  style: {
		maxHeight: 300,
	  },
	},
  };

const SelectFormfield = ({ name, label, poplist, poplistItems, numberic, translate, sortByLabel, onChange }: PoplistFormfieldProps) => {
	
	const t = useAppTranslation();

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [field, meta, helpers] = useField(name);
	const { setValue, setTouched } = helpers;
	const hasError = !!meta.error && meta.touched;

	const [options, setOptions] = useState<JsonPoplistItem[]>([]);
	
	const codebooks = useSelector(selectCodebooks);

	useEffect(() => {
		if (poplistItems) {
			setOptions(poplistItems);
		} else {
			const codebookName: string = poplist || name;
			if (codebookName && codebooks) {
				let options = codebooks[codebookName];
				if (translate) {
					options = options.map(item => ({ ...item, label: t('select.' + item.label) }));
				}
				if (sortByLabel) {
					options.sort((a, b) => ((a?.label || '') > (b?.label || '')) ? 1 : -1);
				}
				setOptions(options);
			}
		}
	}, [poplist, name, translate, sortByLabel, poplistItems, codebooks, t]);

	const handleChange = useCallback((event: any) => {
		const newValue = numberic ? +event.target.value : event.target.value;
		setValue(newValue);
		onChange && onChange(newValue);
	}, [numberic, setValue, onChange]);

	const handleTouchedOnBlur = useCallback((event: any) => {
		setTouched(true);
	}, [setTouched]);

	return (
		<FormControl variant="standard" fullWidth error={hasError}>
			<InputLabel id="demo-simple-select-helper-label">{label}</InputLabel>
			<Select
				labelId="demo-simple-select-helper-label"
				id="demo-simple-select-helper"
				value={ (numberic && field.value === 0) || !field.value ? '' : field.value }
				label={label}
				onBlur={handleTouchedOnBlur}
				onChange={handleChange}
				MenuProps={MenuProps}
				>
				<MenuItem key={ numberic ? 0 : '' } value={ numberic ? 0 : '' }>
					<em>{t('select.Neuvedeno')}</em>
				</MenuItem>
				{ options?.map((item: LocalCodebookItem) => 
					<MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>)}
			</Select>
			{ hasError && <FormHelperText>{ meta.error }</FormHelperText> }
		</FormControl>	
	);
}

export default SelectFormfield;
