import * as React from 'react';
import { useSelector } from 'react-redux';
import { selectAuthIsLogged, selectAuthUser } from '../../store/selectors';
import styles from '../../assets/styles/stratairu.module.css';
import { FlashMessages } from '../layout/FlashMessages';
import { Loader } from '../layout/Loader';
import {useLocation} from "react-router";
import {PropsWithChildren} from "react";
import StratairuMenu from "./components/StratairuMenu";

interface Props {
}

const StratairuLayout = ({ children }: PropsWithChildren<Props>) => {
	
    const isLogged = useSelector(selectAuthIsLogged);
    const user = useSelector(selectAuthUser);

    const location = useLocation();

	return (
		<div className={styles.layoutContainer}>
            <FlashMessages />
            <Loader />
            <div style={{ width: '100%' }}>{children}</div>
        </div>
	);
}

export default StratairuLayout;
