import {
    JsonPoll,
    JsonPollOption,
    JsonPollResults,
    JsonPollStatusEnum,
    JsonRelayItem, JsonRelayItemItemTypeEnum,
    JsonRelayResponse
} from "../generated-api";

export interface ChartOptions {
    percentWidth?: number;
    pixelHeight?: number;
    height?: string;
    labelFontSize?: string;
    padding?: string;
    lineColor?: string;
    textColor?: string;
    marginTop?: string;
    marginBottom?: string;
    contentMarginTop?: string;
    sliderScaleFontSize?: string;
    sliderCircleSize?: string;
    sliderCircleFontSize?: string;
    noSortOptions?: boolean;
    colors?: string[];
}

export const ADMIN_CLIENT_GUID = "admin";

// export const COLORS = ['#210055', '#2ac0ce', '#f04447', '#08bd5a', '#fcc037'];
export const COLORS = ['#D4AFEE', '#210055', '#A6F5B4', '#4FC5CB'];
export const FULLPAGE_COLORS = ['#D4AFEE', '#210055', '#A6F5B4', '#4FC5CB'];

export const BarHeight = 200;
export const SliderHeight = 50;
export interface TSendResponses {
    (newResponses: JsonRelayResponse[]): void
}

export const getOptionTotal = (option: JsonPollOption, results: JsonPollResults): number => {
    return results?.optionTotals ? (results.optionTotals[option.optionId as number] || 0) : 0;
}

export const generatePreviewData = (poll: JsonPoll): JsonPollResults => {
    const data = {
        optionTotals: (poll?.options || []).reduce((acc: any, option, index) => {
            if (option?.optionId) {
                acc[option.optionId] = Math.floor(Math.random() * 100);
            }
            return acc;
        }, {})
    } as JsonPollResults;
    return data;
}

export const isAudit = () => {
    return true;
}

export const checkGroupResponse = (items: JsonRelayItem[], responses: { [key: number]: JsonRelayResponse[] }) => {

    let isActiveAll = true;
    let hasAnyQuestion = false;
    let hasAnyResultsShowing = false;

    let alreadyRespondedAll = true; // all mandatory answers submitted already
    let alreadyRespondedAny = false; // any answer submitted already

    let emptyOptional: JsonRelayItem[] = [];
    items.forEach((item) => {
        const pollId = item.poll?.pollId;
        if (pollId) {
            if (item.poll?.status !== JsonPollStatusEnum.Active) {
                isActiveAll = false;
            }
            const isOptional = !((item.poll?.options?.length || 0) > 0); // TEXT

            if (JsonRelayItemItemTypeEnum.PollQuestion === item.itemType) {
                hasAnyQuestion = true; // false == only showing results
            } else if (JsonRelayItemItemTypeEnum.PollResults === item.itemType && !isOptional) { // ignore results for TEXT
                hasAnyResultsShowing = true;
            }

            if (responses[pollId]?.length > 0) {
                alreadyRespondedAny = true;
            } else {
                if (isOptional) {
                    emptyOptional.push(item);
                } else {
                    alreadyRespondedAll = false;
                }
            }
        }
    });
    if (hasAnyQuestion && !alreadyRespondedAny) {
        alreadyRespondedAll = false;
    }
    return alreadyRespondedAll;
}


