import {useNavigate} from "react-router-dom";
import {JsonPoplistItem, JsonStratOnepagerInfo} from '../../../generated-api';
import {useSelector} from "react-redux";
import {selectAuthUser, selectCodebooks, selectStrategyContext} from "../../../store/selectors";
import styles from '../../../assets/styles/stratairu.module.css';
import classnames from "classnames";
import {useAppTranslation} from "../../../services/i18n";
import StratairuOnePagerFavouriteButton from "./StratairuOnePagerFavouriteButton";
import {ReactComponent as HomeIcon} from '../../../assets/images/stratairu/home.svg';
import {ReactComponent as ProfileIcon} from '../../../assets/images/stratairu/profile.svg';
import {getOnePagerLevel} from "../../../helpers/strategy";
import StratairuOnePagerStatusFlag from "./StratairuOnePagerStatusFlag";
import {useOnePager} from "../../../hooks/useOnePager";

interface Props {
    onepager: JsonStratOnepagerInfo
}

const StratairuOnePagerCard = ({ onepager }: Props) => {

    const { navigateOnePager } = useOnePager();

    const strategyContext = useSelector(selectStrategyContext);
    const codebooks = useSelector(selectCodebooks);

    const level = getOnePagerLevel(onepager);

    // console.log({ codebooks, strategyContext, companyDepartmentId: onepager.companyDepartmentId });

    const departmentId = onepager.companyDepartmentId || onepager.departmentId;
    const departments = onepager.companyDepartmentId ? strategyContext.companyDepartments : codebooks['companyDepartment'];
    const departmentName = departments?.find((item: JsonPoplistItem) => item.value === departmentId || item.value === departmentId + '' )?.label;

    const positionId = onepager.companyPositionId || onepager.positionId;
    const positions = onepager.companyPositionId ? strategyContext.companyPositions : codebooks['companyPosition'];
    const positionName = positions?.find((item: JsonPoplistItem) => item.value === positionId || item.value === positionId + '' )?.label;

    return (
        <>
            { !!onepager &&
                <div className={styles.cardContainer} onClick={() => navigateOnePager(onepager)}>
                    <div className={styles.content}>
                        <div className={styles.icon}>
                            { level === 1 && <HomeIcon /> }
                            { level !== 1 && <ProfileIcon /> }
                        </div>
                        <div className={styles.info}>
                            <div><b>{ onepager.firstName } { onepager.lastName }</b><br/>{ departmentName }</div>
                            <StratairuOnePagerFavouriteButton onepager={onepager} />
                        </div>
                    </div>
                    <div className={styles.flag}>
                        <StratairuOnePagerStatusFlag status={onepager.status}/>
                    </div>
                </div>
           }
        </>
    );
}

export default StratairuOnePagerCard;
