import {Grid} from '@mui/material';
import {FormikErrors, FormikProps, useFormikContext} from 'formik';
import {PropsWithChildren, useCallback, useEffect, useState} from 'react';
import CheckboxFormfield from '../components/form/CheckboxFormfield';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {
    JsonCompanyBranch, JsonCompanyDepartment,
    JsonCompanyDuration, JsonCompanyPosition,
    JsonPoplistItem,
    JsonSerieSerieTypeEnum,
    JsonSerieUser, JsonSerieUserInfoSexEnum,
    JsonSerieUserRegistrationContext,
    JsonSerieUserRegistrationContextCompanySerieFlagsEnum,
    JsonUser, JsonUserSexEnum
} from '../generated-api';
import { isEmailValid } from '../helpers/validators';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';
import {fetchValidateEmail} from "../store/auth";
import {useAppDispatch} from "../store";

type RegistrationFields = 'firstName' | 'lastName' | 'sex' | 'email' | 'positionId' | 'companyPositionId' |
    'departmentId' | 'companyDepartmentId' | 'durationId' | 'companyDurationId' | 'companyBranchId' | 'sectorId';

export interface RegistrationFormData {
    firstName: JsonUser['firstName'];
    lastName: JsonUser['lastName'];
    sex: JsonUserSexEnum | JsonSerieUserInfoSexEnum | undefined;
    email: JsonUser['email'];
    positionId: JsonSerieUser['positionId'];
    companyPositionId: JsonSerieUser['companyPositionId'];
    departmentId: JsonSerieUser['departmentId'];
    companyDepartmentId: JsonSerieUser['companyDepartmentId'];
    isNewsletter: JsonUser['isNewsletter'];
    newPassword: JsonUser['newPassword'];
    verifyNewPassword: JsonUser['newPassword'];
    companyBranchId: JsonSerieUser['companyBranchId'];
    durationId: JsonSerieUser['durationId'];
    companyDurationId: JsonSerieUser['companyDurationId'];
    sectorId: JsonSerieUser['sectorId'];
    isGdpr: boolean;
}

interface Props extends FormProps<RegistrationFormData> {
    context: JsonSerieUserRegistrationContext;
}

const RevalidateOnNeedPasswordChange = ({ needPassword }: { needPassword: boolean | null }) => {
    const context = useFormikContext();
    const [lastNeed, setLastNeed] = useState<boolean | null>(needPassword);
    useEffect(() => {
        if ((lastNeed === null || needPassword === null) && lastNeed !== needPassword) {
            setLastNeed(needPassword);
            setTimeout(() => context.validateForm().then(() => {}), 0);
        }
    }, [needPassword, lastNeed, context]);
    return <></>
}

const RegistrationForm = ({ context, children, ...props }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [needPassword, setNeedPassword] = useState<boolean | null>(null);
    const [lastEmail, setLastEmail] = useState<string | null>(null);

    const validateEmail = useCallback(async (email: string) => {
        setNeedPassword(null);
        const result = await dispatch(fetchValidateEmail(email));
        if (!('error' in result)) {
            if (result.payload.data === 'PASSWORD_MISSING') {
                setNeedPassword(true);
                return;
            }
        }
        setNeedPassword(false);
    }, [dispatch]);

    const handleEmailChanged = useCallback(async (email: string) => {
        if (email === lastEmail) {
            return;
        }
        setLastEmail(email);
        const isBroadcast = context?.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast;
        if (isEmailValid(email) && isBroadcast) {
            await validateEmail(email);
        }
    }, [context, lastEmail, validateEmail]);

    const handleNameChanged = useCallback(async (formikProps: FormikProps<RegistrationFormData>) => {
        if (formikProps.values?.email) {
            await handleEmailChanged(formikProps.values.email)
        }
    }, [handleEmailChanged])

    useEffect(() => {
        const email = props.data?.email;
        if (email) {
            setLastEmail(email);
            const isBroadcast = context?.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast;
            if (isEmailValid(email) && isBroadcast) {
                validateEmail(email).then(() => {});
            }
        }
    }, [props.data?.email, context, validateEmail])

    const isVisible = useCallback((field: RegistrationFields) => {

        const isBroadcast = context?.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast;

        switch (field) {
            case 'firstName':
            case 'lastName':
                return context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegName);
            case 'sex':
                return context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegSex);
            case 'email':
                return context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegEmail);
            case 'companyBranchId':
                return context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegBranch);
            case 'sectorId':
                return context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegSector);
            case 'durationId':
                return (isBroadcast || !context.companyDurations) && context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegDuration);
            case 'companyDurationId':
                return !isBroadcast && !!context.companyDurations && context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegDuration);
            case 'positionId':
                return (isBroadcast || !context.companyPositions) && context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegPosition);
            case 'companyPositionId': 
                return !isBroadcast && !!context.companyPositions && context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegPosition);
            case 'departmentId':
                return (isBroadcast || !context.companyDepartments)&& context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegDepartment);
            case 'companyDepartmentId':
                return !isBroadcast && !!context.companyDepartments && context.companySerieFlags?.includes(JsonSerieUserRegistrationContextCompanySerieFlagsEnum.RegDepartment);
        }
        return true;
    }, [context])

    const validate = useCallback((values: RegistrationFormData) => {
        console.log('Validate', { values, needPassword });
        const isBroadcast = context?.serie?.serieType === JsonSerieSerieTypeEnum.Broadcast;
        let errors = {} as FormikErrors<RegistrationFormData>;
        if (isVisible('email')) {
            if (!values.email) {
                errors['email'] = t('RegistrationForm.messages.emailRequired');
            } else if (!isEmailValid(values.email)) {
                errors['email'] = t('RegistrationForm.messages.emailInvalid');
            } else if (needPassword === null && isBroadcast) {
                errors['email'] = t('RegistrationForm.messages.emailValidating');
            }
        }
        if (isVisible('firstName') && !values.firstName) {
            errors['firstName'] = t('RegistrationForm.messages.firstNameRequired');
        }
        if (isVisible('lastName') && !values.lastName) {
            errors['lastName'] = t('RegistrationForm.messages.lastNameRequired');
        }
        if (isVisible('sex') && !values.sex) {
            errors['sex'] = t('RegistrationForm.messages.sexRequired');
        }
        if (isVisible('positionId') && !values.positionId) {
            errors['positionId'] = t('RegistrationForm.messages.positionIdRequired');
        }
        if (isVisible('companyPositionId') && !values.companyPositionId) {
            errors['companyPositionId'] = t('RegistrationForm.messages.positionIdRequired');
        }
        if (isVisible('departmentId') && !values.departmentId) {
            errors['departmentId'] = t('RegistrationForm.messages.departmentIdRequired');
        }
        if (isVisible('companyDepartmentId') && !values.companyDepartmentId) {
            errors['companyDepartmentId'] = t('RegistrationForm.messages.departmentIdRequired');
        }
        if (isVisible('email') && !values.isGdpr) {
            errors['isGdpr'] = t('RegistrationForm.messages.isGdprRequired');
        }
        if (isVisible('companyBranchId') && !values.companyBranchId) {
            errors['companyBranchId'] = t('RegistrationForm.messages.companyBranchIdRequired');
        }
        if (isVisible('durationId') && !values.durationId) {
            errors['durationId'] = t('RegistrationForm.messages.durationIdRequired');
        }
        if (isVisible('companyDurationId') && !values.companyDurationId) {
            errors['companyDurationId'] = t('RegistrationForm.messages.durationIdRequired');
        }
        if (isVisible('sectorId') && !values.sectorId) {
            errors['sectorId'] = t('RegistrationForm.messages.sectorIdRequired');
        }

        if (needPassword) {
            if (!values.newPassword) {
                errors['newPassword'] = t('RegistrationForm.messages.newPasswordRequired');
            } else if (values.newPassword !== values.verifyNewPassword) {
                errors['newPassword'] = t('RegistrationForm.messages.newPasswordNotMatch');
                errors['verifyNewPassword'] = t('RegistrationForm.messages.newPasswordNotMatch');
            }
        }

        return errors;
    }, [needPassword, isVisible, context, t]);

	return (
        <>
		<FormContainer {...props} validate={validate} cardLayout={false}>
            { (formikProps: FormikProps<RegistrationFormData>) => {
                return (<>
                    <RevalidateOnNeedPasswordChange needPassword={needPassword} />
                    <Grid item xs={12}>
                        { children }
                    </Grid>
                    { isVisible('firstName') &&
                        <Grid item xs={12}>
                            <TextFormfield name="firstName" label={t('RegistrationForm.fields.firstName')} onBlur={() => handleNameChanged(formikProps)}></TextFormfield>
                        </Grid>
                    }
                    { isVisible('lastName') &&
                        <Grid item xs={12}>
                            <TextFormfield name="lastName" label={t('RegistrationForm.fields.lastName')} onBlur={() => handleNameChanged(formikProps)}></TextFormfield>
                        </Grid>
                    }
                    { isVisible('sex') &&
                        <Grid item xs={12}>
                            <SelectFormfield name="sex" label={t('RegistrationForm.fields.sex')} poplist="sex"></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('email') &&
                        <Grid item xs={12}>
                            <TextFormfield name="email" label={t('RegistrationForm.fields.email')} onBlur={handleEmailChanged}></TextFormfield>
                        </Grid>
                    }
                    { isVisible('sectorId') &&
                        <Grid item xs={12}>
                            <SelectFormfield name="sectorId" label={t('RegistrationForm.fields.sectorId')} poplist="companySector" numberic></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('companyBranchId') &&
                        <Grid item xs={12}>
                            <SelectFormfield name="companyBranchId" label={t('RegistrationForm.fields.companyBranchId')} poplistItems={ (context.companyBranches || []).map((item: JsonCompanyBranch) => ({ label: item.title, value: item.companyBranchId } as JsonPoplistItem)) } translate sortByLabel></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('durationId') &&
                        <Grid item xs={12}>
                            <SelectFormfield name="durationId" label={t('RegistrationForm.fields.durationId')} poplist="duration" translate></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('companyDurationId') && context.companyDurations &&
                        <Grid item xs={12}>
                            <SelectFormfield name="companyDurationId" label={t('RegistrationForm.fields.durationId')} poplistItems={ context.companyDurations.map((item: JsonCompanyDuration) => ({ label: item.title, value: item.companyDurationId } as JsonPoplistItem)) } translate></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('positionId') &&
                        <Grid item xs={12}>
                            <SelectFormfield name="positionId" label={t('RegistrationForm.fields.positionId')} poplist="companyPosition" numberic></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('companyPositionId') && context.companyPositions &&
                        <Grid item xs={12}>
                            <SelectFormfield name="companyPositionId" label={t('RegistrationForm.fields.positionId')} poplistItems={ context.companyPositions.map((item: JsonCompanyPosition) => ({ label: item.title, value: item.companyPositionId } as JsonPoplistItem)) } numberic></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('departmentId')  &&
                        <Grid item xs={12}>
                            <SelectFormfield name="departmentId" label={t('RegistrationForm.fields.departmentId')} poplist="companyDepartment" numberic></SelectFormfield>
                        </Grid>
                    }
                    { isVisible('companyDepartmentId')  && context.companyDepartments &&
                        <Grid item xs={12}>
                            <SelectFormfield name="companyDepartmentId" label={t('RegistrationForm.fields.departmentId')} poplistItems={ context.companyDepartments.map((item: JsonCompanyDepartment) => ({ label: item.title, value: item.companyDepartmentId } as JsonPoplistItem)) } numberic></SelectFormfield>
                        </Grid>
                    }

                    { needPassword  &&
                        <>
                            <Grid item xs={12}>
                                <TextFormfield type="password" name="newPassword" label={t('RegistrationForm.fields.newPassword')}></TextFormfield>
                            </Grid>

                            <Grid item xs={12}>
                                <TextFormfield type="password" name="verifyNewPassword" label={t('RegistrationForm.fields.verifyNewPassword')}></TextFormfield>
                            </Grid>
                        </>
                    }

                    { isVisible('email') &&
                        <>
                            <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                <CheckboxFormfield name="isGdpr">
                                    {t('RegistrationForm.fields.isGdpr')}
                                    <a href="https://www.atairu.com/informace-o-zpracovani-osobnich-udaju-576" target="_blank" rel="noreferrer">{t('RegistrationForm.fields.isGdprDetailLink')}</a>
                                </CheckboxFormfield>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxFormfield name="isNewsletter" label={t('RegistrationForm.fields.isNewsletter')}></CheckboxFormfield>
                            </Grid>
                        </>
                    }

                </>)
            } }
		</FormContainer>
        </>
	);
}

export default RegistrationForm;
