import { TextField } from '@mui/material';
import { useField } from 'formik';
import {useCallback, useEffect, useRef, useState} from 'react';
import { FormfieldProps } from '../../model/form';

export const whiteStyle = {
	'& label': {
		color: 'white !important', // Change the placeholder text color here
	},
	'& input': {
		color: 'white', // Change the placeholder text color here
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: 'white !important', // Change the underline color when the field is not focused
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'white', // Change the underline color when the field is focused
	},
	'&:hover .MuiInput-underline:after': {
		borderBottomColor: 'white', // Underline color on hover
	},
	'&:hover .MuiInput-underline:before': {
		borderBottomColor: 'white', // Underline color on hover
	},

};

export const blueStyle = {
	'& label': {
		color: 'var(--audit-blue) !important', // Change the placeholder text color here
	},
	'& input': {
		color: 'white', // Change the placeholder text color here
	},
	'& .MuiInput-underline:before': {
		borderBottomColor: 'white !important', // Change the underline color when the field is not focused
	},
	'& .MuiInput-underline:after': {
		borderBottomColor: 'white', // Change the underline color when the field is focused
	},
	'&:hover .MuiInput-underline:after': {
		borderBottomColor: 'white', // Underline color on hover
	},
	'&:hover .MuiInput-underline:before': {
		borderBottomColor: 'white', // Underline color on hover
	},

};

interface Props extends FormfieldProps {
	type?: string;
	maxlength?: number;
	multiline?: number;
	focused?: boolean;
	formatValue?: (value: any) => any;
	normalizeValue?: (value: any) => any;
	onChange?: (value: any) => any;
	onBlur?: (value: any) => any;
	onEnter?: (value: any) => void;
	variant?: string;
	placeholder?: string;
	sx?: any;
}

const TextFormfield = ({ name, label, type, maxlength, multiline, focused, formatValue, normalizeValue, onChange, onBlur, onEnter, variant, placeholder, sx, ...props }: Props) => {
	
	const [field, meta, helpers] = useField(name);
	const { setValue, setTouched } = helpers;
	const inputRef = useRef(null);

	const [isFocused, setIsFocused] = useState(false);

	const handleOnChange = useCallback((event: any) => {
		setValue(event.target.value, true);
		const normalizedValue = normalizeValue ? normalizeValue(event.target.value) : event.target.value;
		setValue(normalizedValue, true);
		onChange && onChange(normalizedValue);
	}, [setValue, normalizeValue, onChange]);

	const handleOnBlur = useCallback((event: any) => {
		setTouched(true);
		setIsFocused(false);
		const normalizedValue = normalizeValue ? normalizeValue(event.target.value) : event.target.value;
		setValue(normalizedValue, true);
		onBlur && onBlur(normalizedValue);
	}, [setValue, setTouched, setIsFocused, normalizeValue, onBlur]);

	const setOnFocus = useCallback((event: any) => {
		setIsFocused(true);
	}, [setIsFocused]);

	const handleKeyDown = useCallback((e: any) => {
		if (e.key === 'Enter') {
			const newValue = (e.target as any)['value'];
			if (onEnter) {
				onEnter(newValue);
			}
		}
	}, [onEnter]);

	const trapEnter = useCallback((e: any) => {
		if (e.key === 'Enter') {
			handleOnBlur(e);
			e.preventDefault();
		}
	}, [handleOnBlur]);

	const currentValue = isFocused && normalizeValue ? normalizeValue(field.value) : field.value;
	if (currentValue !== field.value) {
		setValue(name, currentValue);
	}

	useEffect(() => {
		if (focused && inputRef?.current) {
			(inputRef.current as any).focus();
		}
	}, [inputRef, isFocused, focused]);

	return (
		<TextField
			fullWidth
			id={name}
			name={name}
			label={label}
			type={type || 'text'}
			value={(formatValue && !isFocused ? formatValue(currentValue) : currentValue)}
			onFocus={setOnFocus}
			onBlur={handleOnBlur}
			onChange={handleOnChange}
			onKeyDown={type === 'textarea' ? undefined : (type === 'date' ? trapEnter : handleKeyDown)}
			error={meta.touched && Boolean(meta.error)}
			helperText={meta.touched && meta.error}
			variant={ variant as any || 'standard' }
			placeholder={ placeholder }
			inputProps={{ maxLength: maxlength || 100 }}
			multiline={!!multiline}
			rows={multiline}
			inputRef={inputRef}
			sx={sx}
			>
		</TextField>	
	);
}

export default TextFormfield;
