import {JsonGroup, JsonPollResults, JsonPollStatusEnum, JsonRelayItem, JsonRelayItemItemTypeEnum, JsonRelayResponse} from "../../generated-api";
import {TSendResponses} from "../../helpers/relay";
import {useAppTranslation} from "../../services/i18n";
import * as React from "react";
import {useEffect, useState} from "react";
import styles from "../../assets/styles/relay";
import {Button, Card, CardActions, CardContent, CardHeader} from "@mui/material";
import {renderItem} from "../../pages/microsite/MicroSiteMainPage";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const RelayItemGroup = (props: { group: JsonGroup, items: JsonRelayItem[], results: { [key: number]: JsonPollResults }, responses: { [key: number]: JsonRelayResponse[] }, handleSendResponses?: TSendResponses }) => {
    const {results, responses, group, items, handleSendResponses} = props;
    const t = useAppTranslation();
    const [newResponsesAll, setNewResponsesAll] = useState(responses); // populate by current (indexed by poll)
    const [isAnswering, setIsAnswering] = useState(false);

    let isActiveAll = true;
    let hasAnyQuestion = false;
    let hasAnyResultsShowing = false;

    let alreadyRespondedAll = true; // all mandatory answers submitted already
    let alreadyRespondedAny = false; // any answer submitted already

    let hasNewResponsesAny = false; // any pending answers
    let hasNewResponsesForMandatoryMissing = false; // any mandatory questions without pending answers

    let emptyOptional: JsonRelayItem[] = [];
    items.forEach((item) => {
        const pollId = item.poll?.pollId;
        if (pollId) {
            if (item.poll?.status !== JsonPollStatusEnum.Active) {
                isActiveAll = false;
            }
            const isOptional = !((item.poll?.options?.length || 0) > 0); // TEXT

            if (JsonRelayItemItemTypeEnum.PollQuestion === item.itemType) {
                hasAnyQuestion = true; // false == only showing results
            } else if (JsonRelayItemItemTypeEnum.PollResults === item.itemType && !isOptional) { // ignore results for TEXT
                hasAnyResultsShowing = true;
            }

            if (responses[pollId]?.length > 0) {
                alreadyRespondedAny = true;
            } else {
                if (isOptional) {
                    emptyOptional.push(item);
                } else {
                    alreadyRespondedAll = false;
                }
            }
            if (newResponsesAll[pollId]?.length > 0) {
                hasNewResponsesAny = true;
            } else if (!isOptional) {
                hasNewResponsesForMandatoryMissing = true;
            }
        }
    });
    if (hasAnyQuestion && !alreadyRespondedAny) {
        alreadyRespondedAll = false;
    }
    const isDisabledAll = !handleSendResponses || alreadyRespondedAll || !isActiveAll || !hasAnyQuestion;

    useEffect(() => {
        if (alreadyRespondedAll) {
            setIsAnswering(false);
        }
    }, [alreadyRespondedAll]);

    return <Card key={group.groupId} className={styles.groupContainer} style={{width: "100%"}}>
        {group.title && <CardHeader title={group.title} className={styles.groupHeader} />}
        <CardContent>
            {items.map((item, index: number) => {
                const pollId = item.poll?.pollId;
                const sendResponses: TSendResponses | undefined = pollId && handleSendResponses && !isAnswering ? (newResponses) => {
                    setNewResponsesAll((state) => ({
                        ...state,
                        [pollId]: newResponses
                    }))
                } : undefined;
                const itemResults = pollId ? results[pollId] : {};
                const itemResponses = pollId ? [...responses[pollId]] : [];
                if (pollId && alreadyRespondedAll && emptyOptional.indexOf(item) >= 0) {
                    // create synthetic responses to render the textarea as "readonly" (hack)
                    itemResponses.push({value: ''});
                }
                return renderItem(item, itemResults, itemResponses, t, sendResponses, group, index)
            })}
        </CardContent>
        {alreadyRespondedAll && hasAnyQuestion && !hasAnyResultsShowing &&
            <CardActions className={styles.relayItemActions} style={{ padding: '0px', borderTop: '0px' }}>
                <Button variant="contained" disabled={true}><span style={{ color: 'var(--audit-blue)' }}>{t('Odesláno')}</span> <TaskAltIcon sx={{ fontSize: '16px', color: 'red', marginLeft: '10px', marginTop: '-3px' }} /></Button>
            </CardActions>
        }
        {!isDisabledAll && handleSendResponses && <CardActions className={styles.relayItemActions}>
			<Button variant="contained" disabled={!hasNewResponsesAny || hasNewResponsesForMandatoryMissing || isAnswering} onClick={() => {
                if (hasNewResponsesAny && !hasNewResponsesForMandatoryMissing) {
                    const responses: JsonRelayResponse[] = [];
                    Object.keys(newResponsesAll).forEach((k: any) => {
                        newResponsesAll[k]?.forEach((response) => {
                            responses.push(response);
                        });
                    });
                    if (responses.length > 0) {
                        handleSendResponses(responses);
                        setIsAnswering(true);
                    }
                }
            }}>{isAnswering ? t('buttons.sending') : t('buttons.send')}</Button>
		</CardActions>}
    </Card>;
}

export default RelayItemGroup;
