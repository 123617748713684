import * as React from 'react';
import styles from '../../../assets/styles/stratairu.module.css';
import {PropsWithChildren, useCallback, useEffect, useRef, useState} from "react";
import {useAppTranslation} from "../../../services/i18n";
import {JsonPoplistItem} from "../../../generated-api";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

import classnames from "classnames";

export enum StatairuComboDirection {
    Up = 'up',
    Down = 'down'
}


interface Props {
    label?: string;
    items: JsonPoplistItem[];
    selected?: string;
    onSelect: (value: string) => void;
    direction: StatairuComboDirection;
}

const StratairuCombo = ({ label, items, selected, onSelect, direction }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const ref = useRef(null);

    const selectedLabel = label || items.find((item: JsonPoplistItem) => item.value === selected)?.label || '';

    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */
        function handleClickOutside(event: any) {
            if (ref.current && !(ref.current as any).contains(event.target)) {
                setIsOpen(false);
            }
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [ref]);

    const handleToggleOpen = useCallback(() => {
        setIsOpen(value => !value);
    }, []);

    const handleSelect = useCallback((value?: string) => {
        if (value) {
            setIsOpen(false);
            onSelect(value);
        }
    }, [onSelect]);

	return (
        <div className={classnames(styles.comboContainer, isOpen ? styles.comboContainerOpen : undefined)} style={{ width: '100%' }}>
            <div className={styles.comboLabel} onClick={handleToggleOpen}>
                { selectedLabel }
                { direction === StatairuComboDirection.Down ? <KeyboardArrowDownIcon/> : <KeyboardArrowUpIcon/> }
            </div>
            { isOpen && <div ref={ref} className={classnames(styles.comboOptions, direction === StatairuComboDirection.Down ? styles.comboDown : styles.comboUp )}>
                { items?.map((item: JsonPoplistItem) => <div className={styles.comboItem} onClick={() => handleSelect(item.value)}>{ item.label }</div>) }
            </div> }
        </div>
	);
}

export default StratairuCombo;
