import {createAsyncThunk, createSlice,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
	GetGroupListUsingGETStatusEnum,
	GetPollListUsingGETStatusEnum,
	GroupControllerApi,
	JsonGroupInfo,
	JsonPoll,
	JsonPollInfo,
	JsonRelay,
	JsonRelayItem,
	PollControllerApi,
	RelayControllerApi, ResetRelayUsingPOSTRequest,
	StartRelayItemGroupUsingPOSTRequest,
	StartRelayItemUsingPOSTRequest,
	StopRelayItemGroupUsingPOSTRequest,
	StopRelayItemUsingPOSTRequest
} from '../generated-api';

const pollApi = new PollControllerApi(API_CONFIG);
const groupApi = new GroupControllerApi(API_CONFIG);
const relayApi = new RelayControllerApi(API_CONFIG);

export const fetchRelay = createAsyncThunk('relay/fetch', async (relayId: number) => {
	return await relayApi.getRelayUsingGET({ relayId });
});

export const fetchRelayPolls = createAsyncThunk('relay/pool/list', async (relayId: number) => {
	return await pollApi.getPollListUsingGET({ relayId, orderCol: 'orderNo', orderDir: 'asc', status: GetPollListUsingGETStatusEnum.Active });
});

export const fetchRelayGroups = createAsyncThunk('relay/group/list', async (relayId: number) => {
	return await groupApi.getGroupListUsingGET({ relayId, orderCol: 'orderNo', orderDir: 'asc', status: GetGroupListUsingGETStatusEnum.Active });
});

export const fetchPoll = createAsyncThunk('relay/pool', async (pollId: number) => {
	return await pollApi.getPollUsingGET({ pollId });
});

export const fetchRelayItems = createAsyncThunk('relay/item/list', async (relayId: number) => {
	return await relayApi.getRelayItemListUsingGET({ relayId });
});

export const startRelayItem = createAsyncThunk('relay/item/start', async (params: StartRelayItemUsingPOSTRequest) => {
	return await relayApi.startRelayItemUsingPOST(params);
});

export const stopRelayItem = createAsyncThunk('relay/item/stop', async (params: StopRelayItemUsingPOSTRequest) => {
	return await relayApi.stopRelayItemUsingPOST(params);
});

export const startRelayGroup = createAsyncThunk('relay/group/start', async (params: StartRelayItemGroupUsingPOSTRequest) => {
	return await relayApi.startRelayItemGroupUsingPOST(params);
});

export const stopRelayGroup = createAsyncThunk('relay/group/stop', async (params: StopRelayItemGroupUsingPOSTRequest) => {
	return await relayApi.stopRelayItemGroupUsingPOST(params);
});

export const cleanRelayResults = createAsyncThunk('relay/item/clean', async (params: ResetRelayUsingPOSTRequest) => {
	console.log({ params });
	return await relayApi.resetRelayUsingPOST(params);
});

export const fetchTemplatePolls = createAsyncThunk('relay/pool/list', async () => {
	return await pollApi.getPollListUsingGET({ episodeSearch: 'Template', status: GetPollListUsingGETStatusEnum.Active });
});

export interface RelayState {
	info: {
		loading: boolean;
		data?: JsonRelay;
	}
	current: {
		loading: boolean;
		items: JsonRelayItem[] | null;
	},
	group: {
		loading: boolean;
		items: JsonGroupInfo[] | null;
	}
	poll: {
		loading: boolean;
		items: JsonPollInfo[] | null;
	},
	pollDef: {
		loading: boolean;
		items: JsonPoll[] | null;
	}
}

const initialState: RelayState = {
	info: {
		loading: false,
	},
	current: {
		loading: false,
		items: []
	},
	group: {
		loading: false,
		items: []
	},
	poll: {
		loading: false,
		items: []
	},
	pollDef: {
		loading: false,
		items: []
	}
};

export const relaySlice = createSlice({
	name: 'relay',
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(fetchRelay.pending, (state, action) => { state.info.loading = true; });
		builder.addCase(fetchRelay.fulfilled, (state, action) => { state.info.data = action.payload?.data || undefined; state.info.loading = false; });
		builder.addCase(fetchRelay.rejected, (state, action) => { state.info.data = undefined; state.info.loading = false; });

		builder.addCase(fetchRelayGroups.pending, (state, action) => { state.group.items = []; state.group.loading = true; });
		builder.addCase(fetchRelayGroups.fulfilled, (state, action) => { state.group.items = action.payload?.data || []; state.group.loading = false; });
		builder.addCase(fetchRelayGroups.rejected, (state, action) => { state.group.items = []; state.group.loading = false; });

		builder.addCase(fetchRelayPolls.pending, (state, action) => { state.poll.items = []; state.poll.loading = true; });
		builder.addCase(fetchRelayPolls.fulfilled, (state, action) => { state.poll.items = action.payload?.data || []; state.poll.loading = false; });
		builder.addCase(fetchRelayPolls.rejected, (state, action) => { state.poll.items = []; state.poll.loading = false; });

		builder.addCase(fetchRelayItems.pending, (state, action) => { state.current.items = []; state.current.loading = true; });
		builder.addCase(fetchRelayItems.fulfilled, (state, action) => { state.current.items = action.payload?.data || []; state.current.loading = false; });
		builder.addCase(fetchRelayItems.rejected, (state, action) => { state.current.items = []; state.current.loading = false; });

		builder.addCase(fetchPoll.pending, (state, action) => { state.pollDef.loading = true; });
		builder.addCase(fetchPoll.fulfilled, (state, action) => { 
			state.pollDef.items = (state.pollDef.items || []).filter((poll: JsonPoll) => poll.pollId !== action.payload?.data?.pollId); 
			if (action.payload?.data) {
				state.pollDef.items = [ ...state.pollDef.items, action.payload?.data ];
			}
			state.pollDef.loading = false; 
		});
		builder.addCase(fetchPoll.rejected, (state, action) => { state.pollDef.loading = false; });
	}
});

export const relayReducer = relaySlice.reducer;

