import { Checkbox, FormControl, FormHelperText } from '@mui/material';
import { useField } from 'formik';
import { PropsWithChildren, useCallback } from 'react';
import { FormfieldProps } from '../../model/form';

interface Props extends PropsWithChildren<FormfieldProps> {}

const CheckboxFormfield = ({ name, label, children }: Props) => {
	
	const [field, meta, helpers] = useField(name);
	const { setValue } = helpers;
	const hasError = !!meta.error && meta.touched;
	
	const onChange = useCallback((event: any) => {
		setValue(!!event.target.checked, true);
	}, [setValue]);

	return (
		<FormControl variant="standard" fullWidth error={hasError}>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<Checkbox
					name={name}
					checked={!!field.value}
					onChange={onChange}
					inputProps={{ 'aria-label': 'controlled' }}
				/>
				<div style={{ fontSize: '14px' }}>{ label || children }</div>
				{ /*<TextField
						fullWidth
						id={name}
						name={name}
						label={label}
						type={type || 'text'}
						value={(formatValue && !isFocused ? formatValue(currentValue) : currentValue) || ''}
						onFocus={setOnFocus}
						onBlur={setTouchedOnBlur}
						onChange={onChange}
						error={meta.touched && Boolean(meta.error)}
						helperText={meta.touched && meta.error}
						variant="standard"
						inputProps={{ maxLength: maxlength || 100 }}
						>
					</TextField> */ }	
			</div>
			{ hasError && <FormHelperText>{ meta.error }</FormHelperText> }
		</FormControl>
	);
}

export default CheckboxFormfield;