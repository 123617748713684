/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonCompanyData,
    JsonCompanyDataFromJSON,
    JsonCompanyDataFromJSONTyped,
    JsonCompanyDataToJSON,
} from './JsonCompanyData';
import {
    JsonSerieUserCountInfo,
    JsonSerieUserCountInfoFromJSON,
    JsonSerieUserCountInfoFromJSONTyped,
    JsonSerieUserCountInfoToJSON,
} from './JsonSerieUserCountInfo';

/**
 * 
 * @export
 * @interface JsonCompanyInfo
 */
export interface JsonCompanyInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyInfo
     */
    allowedActions?: Array<JsonCompanyInfoAllowedActionsEnum>;
    /**
     * 
     * @type {JsonCompanyData}
     * @memberof JsonCompanyInfo
     */
    companyData?: JsonCompanyData;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    companyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    logoFileGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    logoFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    regionCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    sectorId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    serieCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    serieUserCount?: number;
    /**
     * 
     * @type {Array<JsonSerieUserCountInfo>}
     * @memberof JsonCompanyInfo
     */
    serieUserCounts?: Array<JsonSerieUserCountInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    sizeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyInfo
     */
    status?: JsonCompanyInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    tariffId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyInfo
     */
    userCount?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonCompanyInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonCompanyInfoFromJSON(json: any): JsonCompanyInfo {
    return JsonCompanyInfoFromJSONTyped(json, false);
}

export function JsonCompanyInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyData': !exists(json, 'companyData') ? undefined : JsonCompanyDataFromJSON(json['companyData']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyNumber': !exists(json, 'companyNumber') ? undefined : json['companyNumber'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'logoFileGuid': !exists(json, 'logoFileGuid') ? undefined : json['logoFileGuid'],
        'logoFileId': !exists(json, 'logoFileId') ? undefined : json['logoFileId'],
        'regionCode': !exists(json, 'regionCode') ? undefined : json['regionCode'],
        'sectorId': !exists(json, 'sectorId') ? undefined : json['sectorId'],
        'serieCount': !exists(json, 'serieCount') ? undefined : json['serieCount'],
        'serieUserCount': !exists(json, 'serieUserCount') ? undefined : json['serieUserCount'],
        'serieUserCounts': !exists(json, 'serieUserCounts') ? undefined : ((json['serieUserCounts'] as Array<any>).map(JsonSerieUserCountInfoFromJSON)),
        'sizeId': !exists(json, 'sizeId') ? undefined : json['sizeId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tariffId': !exists(json, 'tariffId') ? undefined : json['tariffId'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function JsonCompanyInfoToJSON(value?: JsonCompanyInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyData': JsonCompanyDataToJSON(value.companyData),
        'companyId': value.companyId,
        'companyName': value.companyName,
        'companyNumber': value.companyNumber,
        'countryCode': value.countryCode,
        'logoFileGuid': value.logoFileGuid,
        'logoFileId': value.logoFileId,
        'regionCode': value.regionCode,
        'sectorId': value.sectorId,
        'serieCount': value.serieCount,
        'serieUserCount': value.serieUserCount,
        'serieUserCounts': value.serieUserCounts === undefined ? undefined : ((value.serieUserCounts as Array<any>).map(JsonSerieUserCountInfoToJSON)),
        'sizeId': value.sizeId,
        'status': value.status,
        'tariffId': value.tariffId,
        'userCount': value.userCount,
    };
}

