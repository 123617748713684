/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonEpisodeInfo
 */
export interface JsonEpisodeInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEpisodeInfo
     */
    allowedActions?: Array<JsonEpisodeInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisodeInfo
     */
    episodeFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEpisodeInfo
     */
    episodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisodeInfo
     */
    episodeTo?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonEpisodeInfo
     */
    isOpen?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisodeInfo
     */
    openFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisodeInfo
     */
    openTo?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEpisodeInfo
     */
    serieId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisodeInfo
     */
    status?: JsonEpisodeInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisodeInfo
     */
    title?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonEpisodeInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEpisodeInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonEpisodeInfoFromJSON(json: any): JsonEpisodeInfo {
    return JsonEpisodeInfoFromJSONTyped(json, false);
}

export function JsonEpisodeInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEpisodeInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'episodeFrom': !exists(json, 'episodeFrom') ? undefined : json['episodeFrom'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'episodeTo': !exists(json, 'episodeTo') ? undefined : json['episodeTo'],
        'isOpen': !exists(json, 'isOpen') ? undefined : json['isOpen'],
        'openFrom': !exists(json, 'openFrom') ? undefined : json['openFrom'],
        'openTo': !exists(json, 'openTo') ? undefined : json['openTo'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
    };
}

export function JsonEpisodeInfoToJSON(value?: JsonEpisodeInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'episodeFrom': value.episodeFrom,
        'episodeId': value.episodeId,
        'episodeTo': value.episodeTo,
        'isOpen': value.isOpen,
        'openFrom': value.openFrom,
        'openTo': value.openTo,
        'serieId': value.serieId,
        'status': value.status,
        'title': value.title,
    };
}

