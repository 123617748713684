/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonSerieInfo
 */
export interface JsonSerieInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerieInfo
     */
    allowedActions?: Array<JsonSerieInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieInfo
     */
    episodeCount?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieInfo
     */
    lang?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerieInfo
     */
    serieFlags?: Array<JsonSerieInfoSerieFlagsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieInfo
     */
    serieId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieInfo
     */
    serieType?: JsonSerieInfoSerieTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieInfo
     */
    status?: JsonSerieInfoStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieInfo
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieInfo
     */
    userCount?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonSerieInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieInfoSerieFlagsEnum {
    RegEmail = 'REG_EMAIL',
    RegPosition = 'REG_POSITION',
    RegDepartment = 'REG_DEPARTMENT',
    RegName = 'REG_NAME',
    RegBranch = 'REG_BRANCH',
    RegDuration = 'REG_DURATION',
    PollStepper = 'POLL_STEPPER',
    RegSector = 'REG_SECTOR',
    RegSex = 'REG_SEX',
    PollAnswerRequired = 'POLL_ANSWER_REQUIRED'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieInfoSerieTypeEnum {
    Broadcast = 'BROADCAST',
    Audit = 'AUDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonSerieInfoFromJSON(json: any): JsonSerieInfo {
    return JsonSerieInfoFromJSONTyped(json, false);
}

export function JsonSerieInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'episodeCount': !exists(json, 'episodeCount') ? undefined : json['episodeCount'],
        'lang': !exists(json, 'lang') ? undefined : json['lang'],
        'serieFlags': !exists(json, 'serieFlags') ? undefined : json['serieFlags'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'serieType': !exists(json, 'serieType') ? undefined : json['serieType'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function JsonSerieInfoToJSON(value?: JsonSerieInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'episodeCount': value.episodeCount,
        'lang': value.lang,
        'serieFlags': value.serieFlags,
        'serieId': value.serieId,
        'serieType': value.serieType,
        'status': value.status,
        'title': value.title,
        'userCount': value.userCount,
    };
}

