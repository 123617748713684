/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonStratSuccessLook,
    JsonStratSuccessLookFromJSON,
    JsonStratSuccessLookFromJSONTyped,
    JsonStratSuccessLookToJSON,
} from './JsonStratSuccessLook';
import {
    JsonStratTeam,
    JsonStratTeamFromJSON,
    JsonStratTeamFromJSONTyped,
    JsonStratTeamToJSON,
} from './JsonStratTeam';

/**
 * 
 * @export
 * @interface JsonStratInitiative
 */
export interface JsonStratInitiative {
    /**
     * 
     * @type {string}
     * @memberof JsonStratInitiative
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratInitiative
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratInitiative
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratInitiative
     */
    initiativeId?: number;
    /**
     * 
     * @type {boolean}
     * @memberof JsonStratInitiative
     */
    isRemoved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonStratInitiative
     */
    orderNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratInitiative
     */
    pillarId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratInitiative
     */
    status?: JsonStratInitiativeStatusEnum;
    /**
     * 
     * @type {Array<JsonStratSuccessLook>}
     * @memberof JsonStratInitiative
     */
    successLooks?: Array<JsonStratSuccessLook>;
    /**
     * 
     * @type {Array<JsonStratTeam>}
     * @memberof JsonStratInitiative
     */
    teams?: Array<JsonStratTeam>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratInitiative
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratInitiative
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratInitiativeStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonStratInitiativeFromJSON(json: any): JsonStratInitiative {
    return JsonStratInitiativeFromJSONTyped(json, false);
}

export function JsonStratInitiativeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratInitiative {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'initiativeId': !exists(json, 'initiativeId') ? undefined : json['initiativeId'],
        'isRemoved': !exists(json, 'isRemoved') ? undefined : json['isRemoved'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'pillarId': !exists(json, 'pillarId') ? undefined : json['pillarId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'successLooks': !exists(json, 'successLooks') ? undefined : ((json['successLooks'] as Array<any>).map(JsonStratSuccessLookFromJSON)),
        'teams': !exists(json, 'teams') ? undefined : ((json['teams'] as Array<any>).map(JsonStratTeamFromJSON)),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonStratInitiativeToJSON(value?: JsonStratInitiative | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'description': value.description,
        'initiativeId': value.initiativeId,
        'isRemoved': value.isRemoved,
        'orderNo': value.orderNo,
        'pillarId': value.pillarId,
        'status': value.status,
        'successLooks': value.successLooks === undefined ? undefined : ((value.successLooks as Array<any>).map(JsonStratSuccessLookToJSON)),
        'teams': value.teams === undefined ? undefined : ((value.teams as Array<any>).map(JsonStratTeamToJSON)),
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

