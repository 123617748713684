import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';
import { PropsWithChildren } from 'react';
import SimpleList, { ColumnDefinition, SimpleListAction } from "../../../../components/layout/SimpleList";
import { JsonCompanyUserInfo } from "../../../../generated-api";
import { formatPhone } from '../../../../helpers/formfield';
import { useAppTranslation } from "../../../../services/i18n";

interface Props {
    rows: JsonCompanyUserInfo[] | null;
    action: SimpleListAction<JsonCompanyUserInfo>;
}

const CompanyAdministratorsList = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    const cols = [
        { 
            title: 'name', 
            renderer: (row: JsonCompanyUserInfo) => (
                <>
                    <div><strong>{ row.firstName } { row.lastName }</strong></div>
                    <div>{ row.email } / { formatPhone(row.phone as string) }</div>
                </>
            )
        },
        {
            renderer: (row: JsonCompanyUserInfo) => (
                <Tooltip title="Odstranit administrátora společnosti">
                    <DeleteIcon color="primary" onClick={() => props.action(row, 'DELETE')}/>
                </Tooltip>
            )
        }
    ] as ColumnDefinition<JsonCompanyUserInfo>[];

	return (
		<SimpleList cols={cols} config={{hideHeader: true, title: t('Administrátoři')}} { ...props }></SimpleList>
	);
}

export default CompanyAdministratorsList;