/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonPoll,
    ApiResultOfJsonPollFromJSON,
    ApiResultOfJsonPollToJSON,
    ApiResultOfJsonPollInfo,
    ApiResultOfJsonPollInfoFromJSON,
    ApiResultOfJsonPollInfoToJSON,
    ApiResultOfListOfJsonPoll,
    ApiResultOfListOfJsonPollFromJSON,
    ApiResultOfListOfJsonPollToJSON,
    ApiResultOfListOfJsonPollInfo,
    ApiResultOfListOfJsonPollInfoFromJSON,
    ApiResultOfListOfJsonPollInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonPoll,
    JsonPollFromJSON,
    JsonPollToJSON,
} from '../models';

export interface CopyPollsIntoEpisodeUsingPOSTRequest {
    episodeId: number;
    pollIds: Array<number>;
    testOnly?: boolean;
}

export interface GetPollCountUsingGETRequest {
    episodeId?: number;
    episodeSearch?: string;
    withGroups?: boolean;
    relayId?: number;
    status?: GetPollCountUsingGETStatusEnum;
    search?: string;
}

export interface GetPollInfoUsingGETRequest {
    pollId: number;
}

export interface GetPollListUsingGETRequest {
    episodeId?: number;
    episodeSearch?: string;
    withGroups?: boolean;
    relayId?: number;
    status?: GetPollListUsingGETStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetPollUsingGETRequest {
    pollId: number;
}

export interface SavePollUsingPOSTRequest {
    json: JsonPoll;
    testOnly?: boolean;
}

/**
 * 
 */
export class PollControllerApi extends runtime.BaseAPI {

    /**
     * copyPollsIntoEpisode
     */
    async copyPollsIntoEpisodeUsingPOSTRaw(requestParameters: CopyPollsIntoEpisodeUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonPoll>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling copyPollsIntoEpisodeUsingPOST.');
        }

        if (requestParameters.pollIds === null || requestParameters.pollIds === undefined) {
            throw new runtime.RequiredError('pollIds','Required parameter requestParameters.pollIds was null or undefined when calling copyPollsIntoEpisodeUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/poll/copy-into-episode/{episodeId}`.replace(`{${"episodeId"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.pollIds,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPollFromJSON(jsonValue));
    }

    /**
     * copyPollsIntoEpisode
     */
    async copyPollsIntoEpisodeUsingPOST(requestParameters: CopyPollsIntoEpisodeUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonPoll> {
        const response = await this.copyPollsIntoEpisodeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPollCount
     */
    async getPollCountUsingGETRaw(requestParameters: GetPollCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.episodeId !== undefined) {
            queryParameters['episodeId'] = requestParameters.episodeId;
        }

        if (requestParameters.episodeSearch !== undefined) {
            queryParameters['episodeSearch'] = requestParameters.episodeSearch;
        }

        if (requestParameters.withGroups !== undefined) {
            queryParameters['withGroups'] = requestParameters.withGroups;
        }

        if (requestParameters.relayId !== undefined) {
            queryParameters['relayId'] = requestParameters.relayId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poll/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getPollCount
     */
    async getPollCountUsingGET(requestParameters: GetPollCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getPollCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPollInfo
     */
    async getPollInfoUsingGETRaw(requestParameters: GetPollInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPollInfo>> {
        if (requestParameters.pollId === null || requestParameters.pollId === undefined) {
            throw new runtime.RequiredError('pollId','Required parameter requestParameters.pollId was null or undefined when calling getPollInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poll/info/{pollId}`.replace(`{${"pollId"}}`, encodeURIComponent(String(requestParameters.pollId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPollInfoFromJSON(jsonValue));
    }

    /**
     * getPollInfo
     */
    async getPollInfoUsingGET(requestParameters: GetPollInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPollInfo> {
        const response = await this.getPollInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPollList
     */
    async getPollListUsingGETRaw(requestParameters: GetPollListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonPollInfo>> {
        const queryParameters: any = {};

        if (requestParameters.episodeId !== undefined) {
            queryParameters['episodeId'] = requestParameters.episodeId;
        }

        if (requestParameters.episodeSearch !== undefined) {
            queryParameters['episodeSearch'] = requestParameters.episodeSearch;
        }

        if (requestParameters.withGroups !== undefined) {
            queryParameters['withGroups'] = requestParameters.withGroups;
        }

        if (requestParameters.relayId !== undefined) {
            queryParameters['relayId'] = requestParameters.relayId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poll/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPollInfoFromJSON(jsonValue));
    }

    /**
     * getPollList
     */
    async getPollListUsingGET(requestParameters: GetPollListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonPollInfo> {
        const response = await this.getPollListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPoll
     */
    async getPollUsingGETRaw(requestParameters: GetPollUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPoll>> {
        if (requestParameters.pollId === null || requestParameters.pollId === undefined) {
            throw new runtime.RequiredError('pollId','Required parameter requestParameters.pollId was null or undefined when calling getPollUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poll/{pollId}`.replace(`{${"pollId"}}`, encodeURIComponent(String(requestParameters.pollId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPollFromJSON(jsonValue));
    }

    /**
     * getPoll
     */
    async getPollUsingGET(requestParameters: GetPollUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPoll> {
        const response = await this.getPollUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * savePoll
     */
    async savePollUsingPOSTRaw(requestParameters: SavePollUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonPoll>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling savePollUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/poll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonPollToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonPollFromJSON(jsonValue));
    }

    /**
     * savePoll
     */
    async savePollUsingPOST(requestParameters: SavePollUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonPoll> {
        const response = await this.savePollUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetPollCountUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetPollListUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
