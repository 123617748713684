/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonBanner,
    JsonBannerFromJSON,
    JsonBannerFromJSONTyped,
    JsonBannerToJSON,
} from './JsonBanner';
import {
    JsonGroup,
    JsonGroupFromJSON,
    JsonGroupFromJSONTyped,
    JsonGroupToJSON,
} from './JsonGroup';
import {
    JsonPoll,
    JsonPollFromJSON,
    JsonPollFromJSONTyped,
    JsonPollToJSON,
} from './JsonPoll';
import {
    JsonPollResults,
    JsonPollResultsFromJSON,
    JsonPollResultsFromJSONTyped,
    JsonPollResultsToJSON,
} from './JsonPollResults';

/**
 * 
 * @export
 * @interface JsonRelayItem
 */
export interface JsonRelayItem {
    /**
     * 
     * @type {JsonBanner}
     * @memberof JsonRelayItem
     */
    banner?: JsonBanner;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayItem
     */
    finishedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayItem
     */
    finishedBy?: number;
    /**
     * 
     * @type {JsonGroup}
     * @memberof JsonRelayItem
     */
    group?: JsonGroup;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayItem
     */
    itemId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayItem
     */
    itemType?: JsonRelayItemItemTypeEnum;
    /**
     * 
     * @type {JsonPoll}
     * @memberof JsonRelayItem
     */
    poll?: JsonPoll;
    /**
     * 
     * @type {JsonPollResults}
     * @memberof JsonRelayItem
     */
    pollResults?: JsonPollResults;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayItem
     */
    startedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayItem
     */
    startedBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayItem
     */
    status?: JsonRelayItemStatusEnum;
}

/**
* @export
* @enum {string}
*/
export enum JsonRelayItemItemTypeEnum {
    PollQuestion = 'POLL_QUESTION',
    PollResults = 'POLL_RESULTS',
    Banner = 'BANNER'
}/**
* @export
* @enum {string}
*/
export enum JsonRelayItemStatusEnum {
    Active = 'ACTIVE',
    Finished = 'FINISHED'
}

export function JsonRelayItemFromJSON(json: any): JsonRelayItem {
    return JsonRelayItemFromJSONTyped(json, false);
}

export function JsonRelayItemFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRelayItem {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'banner': !exists(json, 'banner') ? undefined : JsonBannerFromJSON(json['banner']),
        'finishedAt': !exists(json, 'finishedAt') ? undefined : json['finishedAt'],
        'finishedBy': !exists(json, 'finishedBy') ? undefined : json['finishedBy'],
        'group': !exists(json, 'group') ? undefined : JsonGroupFromJSON(json['group']),
        'itemId': !exists(json, 'itemId') ? undefined : json['itemId'],
        'itemType': !exists(json, 'itemType') ? undefined : json['itemType'],
        'poll': !exists(json, 'poll') ? undefined : JsonPollFromJSON(json['poll']),
        'pollResults': !exists(json, 'pollResults') ? undefined : JsonPollResultsFromJSON(json['pollResults']),
        'startedAt': !exists(json, 'startedAt') ? undefined : json['startedAt'],
        'startedBy': !exists(json, 'startedBy') ? undefined : json['startedBy'],
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function JsonRelayItemToJSON(value?: JsonRelayItem | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'banner': JsonBannerToJSON(value.banner),
        'finishedAt': value.finishedAt,
        'finishedBy': value.finishedBy,
        'group': JsonGroupToJSON(value.group),
        'itemId': value.itemId,
        'itemType': value.itemType,
        'poll': JsonPollToJSON(value.poll),
        'pollResults': JsonPollResultsToJSON(value.pollResults),
        'startedAt': value.startedAt,
        'startedBy': value.startedBy,
        'status': value.status,
    };
}

