
import {useCallback, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {Button, Card, Container, Grid} from '@mui/material';

import {getLocale, useAppTranslation} from '../../../services/i18n';
import {useAppDispatch} from '../../../store';
import {JsonEpisode, JsonGroup, JsonGroupInfo, JsonGroupStatusEnum, JsonPoll, JsonPollInfo, JsonPollStatusEnum, JsonPoplistItem} from '../../../generated-api';
import PageHeader from '../../../components/layout/PageHeader';
import {episodeFactory, groupFactory, pollFactory} from '../../../model/factories';
import EpisodeForm from '../../../forms/EpisodeForm';
import { fetchEpisode, fetchEpisodeGroups, fetchEpisodePolls, fetchGroup, fetchPoll, saveEpisode, saveGroup, savePoll } from '../../../store/episodes';
import EditPoll from '../../../components/editor/EditPoll';
import EpisodeItemsList from './components/EpisodeItemsList';
import EditGroup from '../../../components/editor/EditGroup';
import { useModal } from '../../../services/modal';
import { fetchSerie } from '../../../store/series';
import { apiBasePath } from '../../../app/config';
import styles from '../../../assets/styles/layout';
import SearchPollModal from "./components/SearchPollModal";

export interface EpisodeItem {
  order: number;
  group?: JsonGroupInfo;
  poll?: JsonPollInfo;
  polls?: JsonPollInfo[];
}

interface Props {
  createNew?: boolean;
}

const EditEpisodePage = ({ createNew }: Props) => {
	const t = useAppTranslation();
	const dispatch = useAppDispatch();
  const modal = useModal();

  const [episode, setEpisode] = useState<JsonEpisode | null>(null);

  const [groupList, setGroupList] = useState<JsonGroupInfo[] | null>(null);
  const [groupListCombo, setGroupListCombo] = useState<JsonPoplistItem[] | null>(null);
  const [pollList, setPollList] = useState<JsonPollInfo[] | null>(null);
  const [editedPoll, setEditedPoll] = useState<JsonPoll | null>(null);
  const [editedGroup, setEditedGroup] = useState<JsonGroup | null>(null);
  const [groupPollList, setGroupPollList] = useState<JsonPoll[] | null>(null);
  const [items, setItems] = useState<EpisodeItem[]>([]);

  const [showSearchPoll, setShowSearchPoll] = useState<boolean>(false);

  /*
  const [companyUsers, setCompanyUsers] = useState<JsonCompanyUserInfo[] | null>(null);
  const [companySeries, setCompanySeries] = useState<JsonCompanySerieInfo[] | null>(null);
  const [newCompanyAdmin, setNewCompanyAdmin] = useState<JsonUser | null>(null);
  const [newCompanySerie, setNewCompanySerie] = useState<CompanySerieFormData | null>(null);
  */

  const { id, serieId } = useParams();

  /* loaders */

  const loadEpisodePolls = useCallback(async (id: number) => {
    const result = await dispatch(fetchEpisodePolls(+id));
    if ('error' in result) {
      return;
    } else {
      setPollList(result.payload.data || null);
    }
  }, [dispatch]);

  const loadPoll = useCallback(async (id: number) => {
    const result = await dispatch(fetchPoll(id));
    if ('error' in result) {
    } else {
      setEditedPoll(result.payload.data || null);
    }
  }, [dispatch]);

  const loadGroup = useCallback(async (id: number) => {
    const result = await dispatch(fetchGroup(id));
    if ('error' in result) {
    } else {
      setEditedGroup(result.payload.data || null);
    }
  }, [dispatch]);

  const loadGroupPolls = useCallback(async (group: JsonGroup | JsonGroupInfo) => {
    if (id) {
      const result = await dispatch(fetchEpisodePolls(+id));
      let tempPollList = [];
      if ('error' in result) {
        return;
      } else {
        setPollList(result.payload.data || null);
        tempPollList =  result.payload.data || [];
      }
      const tempPolls: any[] = await Promise.all(
        (tempPollList?.filter((poll: JsonPollInfo) => poll.groupId === group?.groupId!) || [])
                  .map((poll: JsonPollInfo) => dispatch(fetchPoll(poll.pollId!))));
      const resultPolls = tempPolls.filter((result) => (!('error' in result))).map(result => result.payload.data) as JsonPoll[];
      resultPolls.sort((a: JsonPoll, b: JsonPoll) => a && b && (a.orderNo || 0) > (b.orderNo || 0) ? 1 : -1);
      setGroupPollList(resultPolls);
    }
  }, [dispatch, id]);

  const loadEpisodeGroups = useCallback(async (id: number) => {
    const result = await dispatch(fetchEpisodeGroups(+id));
    if ('error' in result) {
      return;
    } else {
      setGroupList(result.payload.data || null);
      setGroupListCombo(result.payload.data?.map(item => ({ value: '' + item.groupId, label: item.title })) || null);
    }
  }, [dispatch]);

  const loadEpisode = useCallback(async (id: number) => {
    const result = await dispatch(fetchEpisode(+id));
    if (!('error' in result)) {
      setEpisode((result?.payload as any).data);
      await Promise.all([loadEpisodePolls(+id), loadEpisodeGroups(+id)]);
    }
  }, [dispatch, loadEpisodePolls, loadEpisodeGroups]);

  /* handlers */

  const handleActionPoll = useCallback(async (row: JsonPoll, action: string) => {
    switch (action) {
      case 'EDIT':
        await loadPoll(row.pollId!);
        break;
    }
  }, [loadPoll]);

  const handleHeaderActionItem = useCallback(async (action: string) => {
    if (id) {
      setEditedPoll(null);
      setEditedGroup(null);
      switch (action) {
        case 'NEW_GROUP': 
          setGroupPollList([]);
          setEditedGroup(groupFactory(+id));
          break;
        case 'NEW_POLL': 
          setEditedPoll(pollFactory(+id));
          break;
        case 'SEARCH_POLL':
          setShowSearchPoll(true);
          break;
      }
    }
  }, [id]);

  // load effect
  useEffect(() => {
    if (createNew && serieId) {
      dispatch(fetchSerie(+serieId))
        .then((result) => { 
          setEpisode(episodeFactory((result?.payload as any).data));
        });
      return;
    } 
    if (id) {
      loadEpisode(+id);
    }
	}, [dispatch, createNew, id, serieId, loadEpisode]);

  // merge groups and polls
  useEffect(() => {
    const newItems: EpisodeItem[] = [];
    groupList?.forEach((group: JsonGroupInfo) => { newItems.push({ order: group.orderNo || 0, group, polls: []}) });
    pollList?.forEach((poll: JsonPollInfo) => {
      if (poll.groupId) {
        const group = newItems?.find((item: EpisodeItem) => item.group && item.group.groupId === poll.groupId);
        if (group) {
          group.polls!.push(poll);
        }
      } else {
        newItems.push({ order: poll.orderNo || 0, poll });
      }
    });
    newItems.sort((a,b) => a.order > b.order ? 1 : -1);
    setItems(newItems);
  }, [groupList, pollList]);

  const handleSaveEpisode = useCallback(async (data: JsonEpisode) => {
    // console.log('handleSaveEpisode', { data });
    const result = await dispatch(saveEpisode(data));
    if (!('error' in result)) {
      const id = result.payload.data?.episodeId;
      if (id) {
        await loadEpisode(+id);
      }
    }
  }, [dispatch, loadEpisode]);

  const handleSavePoll = useCallback(async (data: JsonPoll) => {
    const result = await dispatch(savePoll(data));
    if (!('error' in result) && id) {
      setEditedPoll(null);
      if (data.groupId) {
        await loadGroupPolls({ groupId: data.groupId });
      }
      await loadEpisode(+id);
    }
  }, [dispatch, id, loadGroupPolls, loadEpisode]);

  const handleDeletePoll = useCallback(async (poll: JsonPoll) => {
    const result = await modal.confirm({ title: t('Potvrzení'), message: t('Skutečně chcete odstranit otázku?') });
    if (result === 'CONFIRM') {
      await handleSavePoll({ ...poll, status: JsonPollStatusEnum.Deleted });
    }
  }, [handleSavePoll, modal, t]);

  const handleCancelPoll = useCallback(() => {
    setEditedPoll(null);
  }, []);

  const handleSaveGroup = useCallback(async (data: JsonGroup) => {
    const result = await dispatch(saveGroup(data));
    if (!('error' in result) && id) {
      setEditedGroup(null);
      await loadEpisode(+id);
    }
  }, [dispatch, id, loadEpisode]);

  const handleCancelGroup = useCallback(() => {
    setEditedGroup(null);
  }, []);

  const handleDeleteGroup = useCallback(async (data: JsonGroup) => {
    const result = await modal.confirm({ title: t('Potvrzení'), message: t('Skutečně chcete odstranit skupinu?') });
    if (result === 'CONFIRM') {
      handleSaveGroup({ ...data, status: JsonGroupStatusEnum.Deleted })
    }
  }, [handleSaveGroup, modal, t]);

  const handleAddGroupPoll = useCallback((group: JsonGroup) => {
    if (id) {
      setEditedPoll(pollFactory(+id, group.groupId));
    }
  }, [id])

  const handleActionItem = useCallback(async (row: EpisodeItem, action: string) => {
    switch (action) {
      case 'EDIT':
        if (!!row.group) {
          setEditedPoll(null);
          await loadGroupPolls(row.group);
          await loadGroup(row.group.groupId!);
        }
        if (!!row.poll) { 
          setEditedGroup(null);
          await loadPoll(row.poll.pollId!); 
        }
        break;
    }
  }, [loadGroup, loadGroupPolls, loadPoll]);

  const handleExportData = useCallback(async () => {
    // console.log({ url: `${apiBasePath(getLocale())}/rest-service/user/export` });
    window.open(`${apiBasePath(getLocale())}/rest-service/relay-response/export?episodeId=${episode!.episodeId}`, '_blank');     
  }, [episode]);

  return (
    <>
		{ !!episode &&
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={episode.title as string} showBack>
              { !!episode.episodeId && <div style={{ display: 'flex' }}>
                <Button variant="contained" fullWidth onClick={handleExportData} className={styles.yellowButton} sx={{ width: '200px' }}>{t('Export')}</Button>
              </div> }
            </PageHeader>
          </Grid>
          { !episode.episodeId && <Grid item xs={3}></Grid>}
          <Grid item xs={6}>
            <div style={{ marginBottom: '10px' }}>
              <EpisodeForm data={episode} onSave={handleSaveEpisode} title={t('Parametry episody')} cardLayout></EpisodeForm>
            </div>
            { /* JSON.stringify(episode, null, 2)*/ }
            { !!episode.episodeId &&
              <Card>
                { /* 
                <EpisodeGroupsList rows={groupList} action={handleActionGroup}></EpisodeGroupsList>
                <EpisodePollsList rows={pollList} action={handleActionPoll}></EpisodePollsList>
                */ }
                { /* JSON.stringify(items, null, 2) */ }
                <EpisodeItemsList rows={items} action={handleActionItem} actionHeader={handleHeaderActionItem}></EpisodeItemsList>
              </Card>
            }
          </Grid>
          <Grid item xs={6}>
            { editedGroup &&
                <div style={{ marginBottom: '10px' }}>
                  <EditGroup group={editedGroup} pollList={groupPollList || []} pollAction={handleActionPoll} 
                    onSave={handleSaveGroup} onCancel={handleCancelGroup} onAdd={handleAddGroupPoll}
                    onDelete={handleDeleteGroup}/>
                </div>
            }
            { editedPoll &&
                <EditPoll poll={editedPoll} pollGroups={groupListCombo || []} onSave={handleSavePoll} onCancel={handleCancelPoll} onDelete={handleDeletePoll}/>
            }
          </Grid>
        </Grid>
        <SearchPollModal
            open={showSearchPoll}
            episodeId={episode.episodeId!}
            onClose={() => setShowSearchPoll(false)}
            onSave={() => { loadEpisode(episode?.episodeId!); setShowSearchPoll(false) }}
        ></SearchPollModal>
      </Container>
  }
  </>
	);
}

export default EditEpisodePage;
