/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonSerieUser,
    JsonSerieUserFromJSON,
    JsonSerieUserFromJSONTyped,
    JsonSerieUserToJSON,
} from './JsonSerieUser';
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserFromJSONTyped,
    JsonUserToJSON,
} from './JsonUser';

/**
 * 
 * @export
 * @interface JsonSerieUserRegistrationRequest
 */
export interface JsonSerieUserRegistrationRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserRegistrationRequest
     */
    clientGuid?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserRegistrationRequest
     */
    serieGuid?: string;
    /**
     * 
     * @type {JsonSerieUser}
     * @memberof JsonSerieUserRegistrationRequest
     */
    serieUser?: JsonSerieUser;
    /**
     * 
     * @type {JsonUser}
     * @memberof JsonSerieUserRegistrationRequest
     */
    user?: JsonUser;
}

export function JsonSerieUserRegistrationRequestFromJSON(json: any): JsonSerieUserRegistrationRequest {
    return JsonSerieUserRegistrationRequestFromJSONTyped(json, false);
}

export function JsonSerieUserRegistrationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieUserRegistrationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientGuid': !exists(json, 'clientGuid') ? undefined : json['clientGuid'],
        'serieGuid': !exists(json, 'serieGuid') ? undefined : json['serieGuid'],
        'serieUser': !exists(json, 'serieUser') ? undefined : JsonSerieUserFromJSON(json['serieUser']),
        'user': !exists(json, 'user') ? undefined : JsonUserFromJSON(json['user']),
    };
}

export function JsonSerieUserRegistrationRequestToJSON(value?: JsonSerieUserRegistrationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientGuid': value.clientGuid,
        'serieGuid': value.serieGuid,
        'serieUser': JsonSerieUserToJSON(value.serieUser),
        'user': JsonUserToJSON(value.user),
    };
}

