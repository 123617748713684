import { Pagination } from '@mui/material';
import { PropsWithChildren, ReactNode, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../store';
import SimpleList, { ColumnDefinition, SimpleListAction, SimpleListConfig } from './SimpleList';
import {PagingData} from "../../store/selectors";
import styles from '../../assets/styles/layout';

export interface PageableListConfig extends SimpleListConfig {
    selectItems: any;
    selectItemsPaging: any;
    fetchItemsCount: any;
    fetchItems: any;
}

export type PageableListDefinition<T> = {
    config: PageableListConfig;
    action: SimpleListAction<T>;
    cols: ColumnDefinition<T>[];
    filter?: ReactNode | undefined;
}

const PageableList = <T extends {}>(props: PropsWithChildren<PageableListDefinition<T>>) => {
	
    const rows = useSelector(props.config.selectItems);
    const paging = useSelector(props.config.selectItemsPaging) as PagingData;

    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(props.config.fetchItemsCount())
            .then(() => dispatch(props.config.fetchItems({ page: 1, offset: 0 })));
    }, [dispatch, props.config]);

    const handleChangePage = useCallback(async (event: any, page: number) => {
       await dispatch(props.config.fetchItems({ page }))
    }, [dispatch, props.config]);

	return (
        <SimpleList rows={rows as any} {...props}>
            <div className={styles.listPaging}>
                <div className={styles.info}>{ paging.startItem }&nbsp;-&nbsp;{ paging.finishItem }&nbsp;/&nbsp;{ paging.count }</div>
                <Pagination count={paging.pages} page={paging.currentPage} onChange={handleChangePage} />
            </div>
            { props.children }
        </SimpleList>
	);
}

export default PageableList;
