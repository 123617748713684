import {useAppDispatch} from '../../store';
import {useParams} from "react-router-dom";
import {useCallback, useEffect} from 'react';
import {JsonPoplistItem, JsonStratOnepagerInfo} from '../../generated-api';
import {Container, Grid} from '@mui/material';
import {fetchDashboard, fetchStrategyContext, refreshOnePagers, setDashboardOnePagersType} from "../../store/strategy";
import {useSelector} from "react-redux";
import {
  selectAuthUser, selectCodebooks,
  selectStrategyContext,
  selectStrategyDashboard, selectStrategyDashboardFavouriteOnePagers, selectStrategyDashboardOnePagers,
  selectStrategyDashboardOnePagersType
} from "../../store/selectors";
import {getOnePagerLevel} from "../../helpers/strategy";
import {useAppTranslation} from "../../services/i18n";
import StratairuLabel from "../../components/stratairu/components/StratairuLabel";
import StratairuOnePagerCard from "../../components/stratairu/components/StratairuOnePagerCard";
import StratairuSearchOnePager from "../../components/stratairu/components/StratairuSearchOnePager";
import StratairuPageHeader from "../../components/stratairu/components/StratairuPageHeader";
import * as React from "react";
import StratairuPage from "../../components/stratairu/components/StratairuPage";
import StratairuCombo, {StatairuComboDirection} from "../../components/stratairu/components/StratairuCombo";

export enum OnepagersMenuEnum {
  All = 'All',
  Favourite = 'Favourite',
  MyLevel = 'MyLevel',
  LastVisited = 'LastVisited',
  Position = 'Position',
  CompanyPosition = 'CompanyPosition'
}


const StratairuHomePage = () => {

  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const dashboard = useSelector(selectStrategyDashboard);
  const strategyContext = useSelector(selectStrategyContext);
  const user = useSelector(selectAuthUser);
  const selectedOnepagersType = useSelector(selectStrategyDashboardOnePagersType);
  const selectedOnepagers = useSelector(selectStrategyDashboardOnePagers);
  const favouriteOnepagers = useSelector(selectStrategyDashboardFavouriteOnePagers);
  const codebooks = useSelector(selectCodebooks);

  const { strategyId } = useParams();

  useEffect(() => {
    if (strategyId) {
      dispatch(fetchStrategyContext(+strategyId))
          .then(() => dispatch(fetchDashboard({ strategyId: +strategyId })))
          .then(() => dispatch(refreshOnePagers()));
    }
  }, [dispatch, strategyId]);

  const myOnePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => op.userId === user.userId);
  const companyOnePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => !op.parentUserId);

  let firstSubOnePager = true;

  const horizontalMenuPoplist: JsonPoplistItem[] = [
    { label: t('enums.opLists.' + OnepagersMenuEnum.All), value: OnepagersMenuEnum.All },
    { label: t('enums.opLists.' + OnepagersMenuEnum.Favourite), value: OnepagersMenuEnum.Favourite },
    { label: t('enums.opLists.' + OnepagersMenuEnum.MyLevel), value: !!myOnePager ? OnepagersMenuEnum.MyLevel : undefined },
    { label: t('enums.opLists.' + OnepagersMenuEnum.LastVisited), value: OnepagersMenuEnum.LastVisited }
  ].filter(item => !!item.value);

  if (strategyContext?.companyPositions?.length) {
    strategyContext.companyPositions.forEach((item: JsonPoplistItem) => horizontalMenuPoplist.push({ label: item.label, value: OnepagersMenuEnum.CompanyPosition + '_' + item.value }));
  } else {
    if (codebooks && codebooks['companyPosition']?.length) {
      codebooks['companyPosition'].forEach((item: JsonPoplistItem) => horizontalMenuPoplist.push({ label: item.label, value: OnepagersMenuEnum.Position + '_' + item.value }));
    }
  }

  const handleChangeOnePagesList = useCallback((value: string) => {
    console.log({ value });
    const [type, param] = value.split('_');
    dispatch(setDashboardOnePagersType({ type: type as OnepagersMenuEnum, param: !!param && parseInt(param) }));
  }, [dispatch]);

  return (
    <>
      { !!dashboard && !!strategyContext.strategy && /* myOnePager && */
          <StratairuPage>
            <Container>
              <Grid container>
                <Grid item xs={12} style={{ paddingRight: '10px' }}>
                  <StratairuPageHeader>
                    <StratairuSearchOnePager strategyId={ strategyContext.strategy.strategyId } />
                  </StratairuPageHeader>
                </Grid>
                { myOnePager?.onepagerId && <Grid item md={6} xs={12} style={{ paddingRight: '20px' }}>
                  { <div key={myOnePager.onepagerId}>
                      <StratairuLabel label={t('stratairu.titles.myOnePager')} />
                      <StratairuOnePagerCard onepager={myOnePager}></StratairuOnePagerCard>
                    </div>
                   }
                  { dashboard?.hierarchyOnePagers
                      .filter((op: JsonStratOnepagerInfo) => op.userId !== user.userId) // removing my onepager
                      .map((op: JsonStratOnepagerInfo) => {
                    const level = getOnePagerLevel(op);
                    const titles = [];
                    let showCard = false;
                    if (level === 1) {
                      titles.push(t('stratairu.titles.companyStrategy'));
                      showCard = true;
                    }
                    if (op.userId === myOnePager.parentUserId) {
                      titles.push(t('stratairu.titles.superior'));
                      showCard = true;
                    }
                    if (op.parentUserId === user.userId && firstSubOnePager) {
                      titles.push(t('stratairu.titles.subordinate'));
                      firstSubOnePager = false;
                    }
                    if (op.parentUserId === user.userId) {
                      showCard = true;
                    }
                    return showCard ? <div key={op.onepagerId}>
                      { !!titles.length && <StratairuLabel label={ titles.join(' / ') }></StratairuLabel> }
                      <StratairuOnePagerCard onepager={op}></StratairuOnePagerCard>
                      </div> : <></>
                  }) }
                </Grid> }
                { !myOnePager?.onepagerId && companyOnePager?.onepagerId && <Grid item md={6} xs={12} style={{ paddingRight: '20px' }}>
                  { <div key={companyOnePager.onepagerId}>
                    <StratairuLabel label={t('stratairu.titles.companyStrategy')} />
                    <StratairuOnePagerCard onepager={companyOnePager}></StratairuOnePagerCard>
                  </div>
                  }
                  { favouriteOnepagers?.map((op: JsonStratOnepagerInfo, index: number) => <div key={op.onepagerId}>
                    { index === 0 && <StratairuLabel label={t('stratairu.titles.favouriteOnePagers')} /> }
                    <StratairuOnePagerCard onepager={op}></StratairuOnePagerCard>
                  </div>
                  )}

                </Grid> }
                <Grid item md={6} xs={12} style={{ paddingRight: '10px' }}>
                  <StratairuLabel label={t('stratairu.titles.onePagers')}>
                      { /* <StratairuHorizontalMenu items={horizontalMenu} selected={selectedOnepagersType} onClick={handleChangeOnePagesList}/> */ }
                      <div style={{ fontSize: '14px', width: '300px', fontWeight: '400' }}>
                        <StratairuCombo
                            items={horizontalMenuPoplist}
                            selected={selectedOnepagersType}
                            onSelect={handleChangeOnePagesList}
                            direction={ StatairuComboDirection.Down }
                        />
                      </div>
                  </StratairuLabel>
                  { (selectedOnepagers || []).map((op: JsonStratOnepagerInfo) => <StratairuOnePagerCard onepager={op}></StratairuOnePagerCard>) }
                </Grid>
              </Grid>
            </Container>
          </StratairuPage>
      }
    </>
	);
}

export default StratairuHomePage;
