import {Card, CardActions, CardContent, Grid} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {fetchForgottenPassword} from '../../store/auth';
import {Formik, FormikErrors} from 'formik';
import TextFormfield, {whiteStyle} from '../../components/form/TextFormfield';
import {ApiResultOfstringResultEnum, JsonLoginRequest} from '../../generated-api';
import {isEmpty} from '../../helpers/object';
import {addMessage} from '../../store/localApp';
import logo from '../../assets/images/atairu_logo_white.png';
import { isEmailValid } from '../../helpers/validators';
import {ActionLink} from "../../components/layout/ActionLink";
import {useNavigate} from "react-router";
import {Button} from '../../components/layout/Button';
import {AtairuEnv, getEnvironment} from "../../helpers/environment";
import whiteLogo from "../../assets/images/atairu_logo_white.png";
import stratairuLogo from "../../assets/images/stratairu/stratairu-logo.svg";

const ForgottenPasswordPage = () => {

	const t = useAppTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();

	const env = getEnvironment();

	const validate = useCallback((values: JsonLoginRequest) => {
		const errors = {} as FormikErrors<JsonLoginRequest>;
		if (!values.email) {
			errors['email'] = t('Zadejte přihlašovací email');
		} else if (!isEmailValid(values.email)) {
			errors['email'] = t('Neplatný přihlašovací email');
		}
		return isEmpty(errors) ? undefined : errors;
	}, [t]);

	const handleResetPassword = useCallback(async (values: JsonLoginRequest) => {
		const result = await dispatch(fetchForgottenPassword(values));
		if ('error' in result) {
			dispatch(addMessage({
			  severity: 'error',
			  title: t(result.error.message + ''),
			}));
			return;
		} else {
			if (result.payload.result === ApiResultOfstringResultEnum.Ok) {
				dispatch(addMessage({
					severity: 'success',
					title: t('apiResults.' + result.payload.data || ''),
				}));
			}
		}
	}, [dispatch, t])

	// https://mui.com/material-ui/getting-started/usage/
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				}}
			validate={validate}
			onSubmit={handleResetPassword}
			>
			{props => (
			<form onSubmit={props.handleSubmit}>
				<div style={{ paddingTop: '15%' }}>
					<Card sx={{ width: '100%', maxWidth: 400, margin: 'auto', background: 'transparent' }} elevation={0}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img alt={'Atairu'} src={env === AtairuEnv.Tv ? whiteLogo : stratairuLogo} style={{ height: '140px' }}/>
									</div>
								</Grid>
								<Grid item xs={12} sx={{ color: 'white' }}>{t('Zadejte vaši emailovou adresu, na kterou vám bude zaslán email s odkazem pro obnovu hesla.')}</Grid>
								<Grid item xs={12}>
									<TextFormfield name="email" label={t('E-mail')} sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button variant="contained" color="success" type="submit" fullWidth>{t('Obnovit heslo')}</Button>
						</CardActions>
						<CardContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
							<ActionLink onClick={() => navigate('/')} sx={{ color: env === AtairuEnv.Tv ? 'white' : undefined }}>{t('Zpět na přihlášení')}</ActionLink>
							<div style={{ marginTop: '50px', fontSize: '12px', color: '#aaa' }}>&copy; 2023 ATAIRU</div>
						</CardContent>
					</Card>
				</div>
			</form>
			)}
		</Formik>
	);
}

export default ForgottenPasswordPage;
