import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {JsonCompanyDepartment, JsonCompanyDuration, JsonCompanyPosition} from '../generated-api';
import {FormProps} from '../model/form';
import { CompanyPoplistTypeEnum } from '../pages/admin/editCompanyPage/components/CompanyPoplist';
import {useAppTranslation} from '../services/i18n';

export interface CompanyPoplistItemFormData {
    type: CompanyPoplistTypeEnum;
    item: JsonCompanyDepartment | JsonCompanyPosition | JsonCompanyDuration;
};

interface Props extends FormProps<CompanyPoplistItemFormData> {}

const CompanySerieForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: CompanyPoplistItemFormData) => {
        let errors = {} as FormikErrors<CompanyPoplistItemFormData>;
        /*if (!values.serieId) {
            errors['serieId'] = t('Vyberte seriál, který chcete přiřadit');
        }*/
        return errors;
    }

    return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <TextFormfield name="item.title" label={t('Název')}></TextFormfield>
            </Grid>
            { props.data?.type === CompanyPoplistTypeEnum.Positions && <Grid item xs={12}>
                <SelectFormfield name="item.positionId" label={t('Pozice / úroveň')} poplist="companyPosition" numberic></SelectFormfield>
            </Grid> }
            { props.data?.type === CompanyPoplistTypeEnum.Departmnets && <Grid item xs={12}>
                <SelectFormfield name="item.departmentId" label={t('Oddělení')} poplist="companyDepartment" numberic></SelectFormfield>
            </Grid> }
            { props.data?.type === CompanyPoplistTypeEnum.Durations && <Grid item xs={12}>
                <SelectFormfield name="item.durationId" label={t('Doba práce')} poplist="duration" numberic></SelectFormfield>
            </Grid> }

		</FormContainer>
	);
}

export default CompanySerieForm;
