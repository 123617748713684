/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompanySerie,
    ApiResultOfJsonCompanySerieFromJSON,
    ApiResultOfJsonCompanySerieToJSON,
    ApiResultOfJsonCompanySerieInfo,
    ApiResultOfJsonCompanySerieInfoFromJSON,
    ApiResultOfJsonCompanySerieInfoToJSON,
    ApiResultOfListOfJsonCompanySerieInfo,
    ApiResultOfListOfJsonCompanySerieInfoFromJSON,
    ApiResultOfListOfJsonCompanySerieInfoToJSON,
    ApiResultOfListOfJsonNotification,
    ApiResultOfListOfJsonNotificationFromJSON,
    ApiResultOfListOfJsonNotificationToJSON,
    ApiResultOfVoid,
    ApiResultOfVoidFromJSON,
    ApiResultOfVoidToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompanySerie,
    JsonCompanySerieFromJSON,
    JsonCompanySerieToJSON,
} from '../models';

export interface DeleteCompanySerieUsingDELETERequest {
    companySerieId: number;
}

export interface GetCompanySerieCountUsingGETRequest {
    companyId?: number;
    serieId?: number;
    search?: string;
}

export interface GetCompanySerieInfoUsingGETRequest {
    companySerieId: number;
}

export interface GetCompanySerieListUsingGETRequest {
    companyId?: number;
    serieId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanySerieUsingGETRequest {
    companySerieId: number;
}

export interface NotifyCompanySerieUsingPOSTRequest {
    companySerieId: number;
    notificationType: NotifyCompanySerieUsingPOSTNotificationTypeEnum;
}

export interface SaveCompanySerieUsingPOSTRequest {
    json: JsonCompanySerie;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanySerieControllerApi extends runtime.BaseAPI {

    /**
     * deleteCompanySerie
     */
    async deleteCompanySerieUsingDELETERaw(requestParameters: DeleteCompanySerieUsingDELETERequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfVoid>> {
        if (requestParameters.companySerieId === null || requestParameters.companySerieId === undefined) {
            throw new runtime.RequiredError('companySerieId','Required parameter requestParameters.companySerieId was null or undefined when calling deleteCompanySerieUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-serie/{companySerieId}`.replace(`{${"companySerieId"}}`, encodeURIComponent(String(requestParameters.companySerieId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfVoidFromJSON(jsonValue));
    }

    /**
     * deleteCompanySerie
     */
    async deleteCompanySerieUsingDELETE(requestParameters: DeleteCompanySerieUsingDELETERequest, initOverrides?: RequestInit): Promise<ApiResultOfVoid> {
        const response = await this.deleteCompanySerieUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanySerieCount
     */
    async getCompanySerieCountUsingGETRaw(requestParameters: GetCompanySerieCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-serie/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanySerieCount
     */
    async getCompanySerieCountUsingGET(requestParameters: GetCompanySerieCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanySerieCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanySerieInfo
     */
    async getCompanySerieInfoUsingGETRaw(requestParameters: GetCompanySerieInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanySerieInfo>> {
        if (requestParameters.companySerieId === null || requestParameters.companySerieId === undefined) {
            throw new runtime.RequiredError('companySerieId','Required parameter requestParameters.companySerieId was null or undefined when calling getCompanySerieInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-serie/info/{companySerieId}`.replace(`{${"companySerieId"}}`, encodeURIComponent(String(requestParameters.companySerieId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanySerieInfoFromJSON(jsonValue));
    }

    /**
     * getCompanySerieInfo
     */
    async getCompanySerieInfoUsingGET(requestParameters: GetCompanySerieInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanySerieInfo> {
        const response = await this.getCompanySerieInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanySerieList
     */
    async getCompanySerieListUsingGETRaw(requestParameters: GetCompanySerieListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanySerieInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-serie/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanySerieInfoFromJSON(jsonValue));
    }

    /**
     * getCompanySerieList
     */
    async getCompanySerieListUsingGET(requestParameters: GetCompanySerieListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanySerieInfo> {
        const response = await this.getCompanySerieListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanySerie
     */
    async getCompanySerieUsingGETRaw(requestParameters: GetCompanySerieUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanySerie>> {
        if (requestParameters.companySerieId === null || requestParameters.companySerieId === undefined) {
            throw new runtime.RequiredError('companySerieId','Required parameter requestParameters.companySerieId was null or undefined when calling getCompanySerieUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-serie/{companySerieId}`.replace(`{${"companySerieId"}}`, encodeURIComponent(String(requestParameters.companySerieId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanySerieFromJSON(jsonValue));
    }

    /**
     * getCompanySerie
     */
    async getCompanySerieUsingGET(requestParameters: GetCompanySerieUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanySerie> {
        const response = await this.getCompanySerieUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * notifyCompanySerie
     */
    async notifyCompanySerieUsingPOSTRaw(requestParameters: NotifyCompanySerieUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonNotification>> {
        if (requestParameters.companySerieId === null || requestParameters.companySerieId === undefined) {
            throw new runtime.RequiredError('companySerieId','Required parameter requestParameters.companySerieId was null or undefined when calling notifyCompanySerieUsingPOST.');
        }

        if (requestParameters.notificationType === null || requestParameters.notificationType === undefined) {
            throw new runtime.RequiredError('notificationType','Required parameter requestParameters.notificationType was null or undefined when calling notifyCompanySerieUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.notificationType !== undefined) {
            queryParameters['notificationType'] = requestParameters.notificationType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-serie/{companySerieId}/notification`.replace(`{${"companySerieId"}}`, encodeURIComponent(String(requestParameters.companySerieId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonNotificationFromJSON(jsonValue));
    }

    /**
     * notifyCompanySerie
     */
    async notifyCompanySerieUsingPOST(requestParameters: NotifyCompanySerieUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonNotification> {
        const response = await this.notifyCompanySerieUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompanySerie
     */
    async saveCompanySerieUsingPOSTRaw(requestParameters: SaveCompanySerieUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanySerie>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanySerieUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company-serie`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanySerieToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanySerieFromJSON(jsonValue));
    }

    /**
     * saveCompanySerie
     */
    async saveCompanySerieUsingPOST(requestParameters: SaveCompanySerieUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanySerie> {
        const response = await this.saveCompanySerieUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum NotifyCompanySerieUsingPOSTNotificationTypeEnum {
    UserActivate = 'USER_ACTIVATE',
    UserRecover = 'USER_RECOVER',
    SerieAdminLink = 'SERIE_ADMIN_LINK',
    SerieUserFinalize = 'SERIE_USER_FINALIZE',
    SerieUserLink = 'SERIE_USER_LINK',
    SerieUserLinkPost = 'SERIE_USER_LINK_POST',
    SerieUserWorkbook = 'SERIE_USER_WORKBOOK',
    SerieUserWelcome = 'SERIE_USER_WELCOME',
    SerieUserInvitation = 'SERIE_USER_INVITATION',
    SerieUserInvitationU = 'SERIE_USER_INVITATION_U',
    SerieUserAuditWelcome = 'SERIE_USER_AUDIT_WELCOME',
    TestSerieUserLink = 'TEST_SERIE_USER_LINK',
    StratUserActivate = 'STRAT_USER_ACTIVATE',
    StratUserRecover = 'STRAT_USER_RECOVER',
    LabUserActivate = 'LAB_USER_ACTIVATE',
    LabUserRecover = 'LAB_USER_RECOVER'
}
