import {useCallback} from 'react';
import {
    ApiResultOfJsonFile,
    JsonCompany,
} from "../../../../generated-api";
import {useAppDispatch} from '../../../../store';
import {useFormikContext} from "formik";
import SimpleDropzone from "../../../../components/form/dropzone/SimpleDropzone";
import {saveCompanyLogo} from "../../../../store/companies";
import {API_CONFIG} from "../../../../app/api-config";

interface Props {
}

const CompanyUploadLogo = (props: Props) => {

    const { values, setFieldValue } = useFormikContext<JsonCompany>();
    const dispatch = useAppDispatch();

    const onDropLogo = useCallback(async (file: File): Promise<boolean> => {
        let success = true;
        try {
            const res = await dispatch(saveCompanyLogo({logo: file, companyId: values.companyId}));
            if (!('error' in res)) {
                success = true;
            }
            // console.log({ logoFileGuid: (res.payload as ApiResultOfJsonFile)?.data?.guid });
            setFieldValue('logoFileGuid', (res.payload as ApiResultOfJsonFile)?.data?.guid, true);
        } catch(e) {
            success = true;
        }
        return success;
    }, [values, dispatch, setFieldValue])

	return (
        <>
            { values.logoFileGuid && <img alt={`logo`} style={{ height: '100px' }} src={`${API_CONFIG.basePath}/rest-service/download/?guid=${values.logoFileGuid}`} /> }
            { values.companyId && <SimpleDropzone onUploadFile={onDropLogo}></SimpleDropzone> }
        </>
    );
}

export default CompanyUploadLogo;
