/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonCompanyData,
    JsonCompanyDataFromJSON,
    JsonCompanyDataFromJSONTyped,
    JsonCompanyDataToJSON,
} from './JsonCompanyData';

/**
 * 
 * @export
 * @interface JsonCompany
 */
export interface JsonCompany {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompany
     */
    allowedActions?: Array<JsonCompanyAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonCompany
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {JsonCompanyData}
     * @memberof JsonCompany
     */
    companyData?: JsonCompanyData;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    companyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    companyName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    companyNumber?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    countryCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    domainName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    logoFileGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    logoFileId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    regionCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    sectorId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    sizeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    status?: JsonCompanyStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    tariffId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompany
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompany
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonCompanyStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonCompanyFromJSON(json: any): JsonCompany {
    return JsonCompanyFromJSONTyped(json, false);
}

export function JsonCompanyFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompany {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyData': !exists(json, 'companyData') ? undefined : JsonCompanyDataFromJSON(json['companyData']),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyName': !exists(json, 'companyName') ? undefined : json['companyName'],
        'companyNumber': !exists(json, 'companyNumber') ? undefined : json['companyNumber'],
        'countryCode': !exists(json, 'countryCode') ? undefined : json['countryCode'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'domainName': !exists(json, 'domainName') ? undefined : json['domainName'],
        'logoFileGuid': !exists(json, 'logoFileGuid') ? undefined : json['logoFileGuid'],
        'logoFileId': !exists(json, 'logoFileId') ? undefined : json['logoFileId'],
        'regionCode': !exists(json, 'regionCode') ? undefined : json['regionCode'],
        'sectorId': !exists(json, 'sectorId') ? undefined : json['sectorId'],
        'sizeId': !exists(json, 'sizeId') ? undefined : json['sizeId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tariffId': !exists(json, 'tariffId') ? undefined : json['tariffId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonCompanyToJSON(value?: JsonCompany | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyData': JsonCompanyDataToJSON(value.companyData),
        'companyId': value.companyId,
        'companyName': value.companyName,
        'companyNumber': value.companyNumber,
        'countryCode': value.countryCode,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'domainName': value.domainName,
        'logoFileGuid': value.logoFileGuid,
        'logoFileId': value.logoFileId,
        'regionCode': value.regionCode,
        'sectorId': value.sectorId,
        'sizeId': value.sizeId,
        'status': value.status,
        'tariffId': value.tariffId,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

