import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import LiveLayout from "../../components/layout/LiveLayout";
import styles from "../../assets/styles/live.module.css";
import {useAppTranslation} from "../../services/i18n";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useState} from "react";

const LiveFAQItemGroup = ({ name, items }: { name: string, items: string[] }) => {

    const t = useAppTranslation();

    const [expanded, setExpanded] = useState<boolean>(false);

    const title = 'live.faq.' + name;

    return <div className={styles.faqGroup}>
        <div className={styles.groupHeader} onClick={() => setExpanded((value) => !value)}>
            <div dangerouslySetInnerHTML={{ __html: t(title + '.title') }} />
            { expanded && <ExpandLessIcon style={{ fontSize: '2rem' }}/> }
            { !expanded && <ExpandMoreIcon style={{ fontSize: '2rem' }}/> }
        </div>
        { !!expanded && items?.map((item) => <LiveFAQItem name={ title + '.items.' + item } />) }
    </div>
}

const LiveFAQItem = ({ name }: { name: string }) => {

    const t = useAppTranslation();

    const [expanded, setExpanded] = useState<boolean>(false);

    return <div className={styles.faqItem}>
        <div className={styles.header} onClick={() => setExpanded((value) => !value)}>
            <div dangerouslySetInnerHTML={{ __html: t(name + '.title') }} />
            { expanded && <ExpandLessIcon style={{ fontSize: '2rem' }}/> }
            { !expanded && <ExpandMoreIcon style={{ fontSize: '2rem' }}/> }
        </div>
        { expanded && <div className={styles.content} dangerouslySetInnerHTML={{ __html: t(name + '.desc') }} /> }
    </div>

}

const LiveFAQPage = () => {

    const navigate = useNavigate();

    const { code } = useParams();

    return (
        <LiveLayout
            title="Podpora"
            subtitle="Zde najdete odpovědi na všechny vaše otázky."
            backButton
            hideFaqButton={true}
        >
            <LiveFAQItemGroup name="about" items={["1", "2", "3", "4", "5", "6", "7"]} />
            <LiveFAQItemGroup name="technical" items={["1", "2", "3", "4", "5", "6", "7"]} />
        </LiveLayout>);
}

export default LiveFAQPage;
