/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompanyDuration,
    ApiResultOfJsonCompanyDurationFromJSON,
    ApiResultOfJsonCompanyDurationToJSON,
    ApiResultOfJsonCompanyDurationInfo,
    ApiResultOfJsonCompanyDurationInfoFromJSON,
    ApiResultOfJsonCompanyDurationInfoToJSON,
    ApiResultOfListOfJsonCompanyDurationInfo,
    ApiResultOfListOfJsonCompanyDurationInfoFromJSON,
    ApiResultOfListOfJsonCompanyDurationInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompanyDuration,
    JsonCompanyDurationFromJSON,
    JsonCompanyDurationToJSON,
} from '../models';

export interface GetCompanyDurationCountUsingGETRequest {
    companyId?: number;
    status?: GetCompanyDurationCountUsingGETStatusEnum;
    search?: string;
}

export interface GetCompanyDurationInfoUsingGETRequest {
    companyDurationId: number;
}

export interface GetCompanyDurationListUsingGETRequest {
    companyId?: number;
    status?: GetCompanyDurationListUsingGETStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanyDurationUsingGETRequest {
    companyDurationId: number;
}

export interface SaveCompanyDurationUsingPOSTRequest {
    json: JsonCompanyDuration;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanyDurationControllerApi extends runtime.BaseAPI {

    /**
     * getCompanyDurationCount
     */
    async getCompanyDurationCountUsingGETRaw(requestParameters: GetCompanyDurationCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-duration/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanyDurationCount
     */
    async getCompanyDurationCountUsingGET(requestParameters: GetCompanyDurationCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanyDurationCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyDurationInfo
     */
    async getCompanyDurationInfoUsingGETRaw(requestParameters: GetCompanyDurationInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyDurationInfo>> {
        if (requestParameters.companyDurationId === null || requestParameters.companyDurationId === undefined) {
            throw new runtime.RequiredError('companyDurationId','Required parameter requestParameters.companyDurationId was null or undefined when calling getCompanyDurationInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-duration/info/{companyDurationId}`.replace(`{${"companyDurationId"}}`, encodeURIComponent(String(requestParameters.companyDurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyDurationInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyDurationInfo
     */
    async getCompanyDurationInfoUsingGET(requestParameters: GetCompanyDurationInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyDurationInfo> {
        const response = await this.getCompanyDurationInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyDurationList
     */
    async getCompanyDurationListUsingGETRaw(requestParameters: GetCompanyDurationListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanyDurationInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-duration/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanyDurationInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyDurationList
     */
    async getCompanyDurationListUsingGET(requestParameters: GetCompanyDurationListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanyDurationInfo> {
        const response = await this.getCompanyDurationListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyDuration
     */
    async getCompanyDurationUsingGETRaw(requestParameters: GetCompanyDurationUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyDuration>> {
        if (requestParameters.companyDurationId === null || requestParameters.companyDurationId === undefined) {
            throw new runtime.RequiredError('companyDurationId','Required parameter requestParameters.companyDurationId was null or undefined when calling getCompanyDurationUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-duration/{companyDurationId}`.replace(`{${"companyDurationId"}}`, encodeURIComponent(String(requestParameters.companyDurationId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyDurationFromJSON(jsonValue));
    }

    /**
     * getCompanyDuration
     */
    async getCompanyDurationUsingGET(requestParameters: GetCompanyDurationUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyDuration> {
        const response = await this.getCompanyDurationUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompanyDuration
     */
    async saveCompanyDurationUsingPOSTRaw(requestParameters: SaveCompanyDurationUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyDuration>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanyDurationUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company-duration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanyDurationToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyDurationFromJSON(jsonValue));
    }

    /**
     * saveCompanyDuration
     */
    async saveCompanyDurationUsingPOST(requestParameters: SaveCompanyDurationUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyDuration> {
        const response = await this.saveCompanyDurationUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetCompanyDurationCountUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCompanyDurationListUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
