import * as React from 'react';

interface Props {
    onClick: () => void;
}

const StratairuCommentIcon = ({ onClick }: Props) => {
	
	return (
        <svg id="Group_186" data-name="Group 186" xmlns="http://www.w3.org/2000/svg" width="43.438" height="43.78" viewBox="0 0 60 59" onClick={onClick} style={{ cursor: 'pointer' }}>
            <defs>
                <clipPath id="clip-path">
                    <rect id="Rectangle_510" data-name="Rectangle 510" width="43.438" height="43.78" fill="none"/>
                </clipPath>
            </defs>
            <circle cx={30} cy={30} r={30} fill="var(--strat-blue)"></circle>
            <g clipPath="url(#clip-path)" transform="translate(13,14) scale(0.8,0.8)">
                <path d="M10.931,33.712c-1.161,0-2.249.012-3.336,0A7.575,7.575,0,0,1,.155,27.6,7.837,7.837,0,0,1,.01,26.021Q0,16.849.006,7.676A7.586,7.586,0,0,1,7.707,0Q21.716,0,35.725,0a7.584,7.584,0,0,1,7.7,7.67q.02,9.2,0,18.392a7.569,7.569,0,0,1-7.586,7.644c-1.649.02-3.3.012-4.949,0a1.436,1.436,0,0,0-.916.292q-6.108,4.45-12.231,8.879a4.22,4.22,0,0,1-6.813-3.431c0-1.726,0-3.452,0-5.178v-.557m10.8-30.8q-6.968,0-13.937,0A4.7,4.7,0,0,0,2.911,7.8q-.008,9.057,0,18.113a4.689,4.689,0,0,0,4.878,4.893c1.407.007,2.816.04,4.221-.012a1.628,1.628,0,0,1,1.843,1.855c-.057,2.239-.018,4.479-.014,6.719a1.331,1.331,0,0,0,1.026,1.462,1.438,1.438,0,0,0,1.257-.36q6.351-4.613,12.7-9.224a2.172,2.172,0,0,1,1.346-.442c1.8.011,3.6.007,5.4,0a5.858,5.858,0,0,0,.994-.06,4.661,4.661,0,0,0,3.953-4.714q.015-9.169,0-18.34a4.5,4.5,0,0,0-.044-.723A4.691,4.691,0,0,0,35.713,2.91q-6.991-.009-13.982,0" transform="translate(0 0)" fill="#fff"/>
            </g>
        </svg>
	);
}

export default StratairuCommentIcon;
