import { IconButton } from "@mui/material";

import EditIcon from '@mui/icons-material/Edit';
import PreviewIcon from '@mui/icons-material/Preview';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteIcon from '@mui/icons-material/Delete';

interface Props {
    onEdit?: () => void;
    onPreview?: () => void;
    onAdd?: () => void;
    onDelete?: () => void;
}

const EditActionButtons = ({ onEdit, onPreview, onAdd, onDelete }: Props) => { 

    return (
        <>
            { onDelete && <IconButton aria-label="Odstranit">
                <DeleteIcon onClick={() => onDelete()}/>
            </IconButton> }        
            { onPreview && <IconButton aria-label="Náhled">
                <PreviewIcon onClick={() => onPreview()}/>
            </IconButton> }
            { onEdit && <IconButton aria-label="Úprava">
                <EditIcon onClick={() => onEdit()}/>
            </IconButton> }
            { onAdd && <IconButton aria-label="Přidat otázku">
                <AddCircleOutlineIcon onClick={() => onAdd()}/>
            </IconButton> }
        </>
    )
}

export default EditActionButtons;