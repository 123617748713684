import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {Navigate, Route, Routes} from 'react-router';
import {BrowserRouter} from 'react-router-dom';
import Layout from '../components/layout/Layout';
import EditCompanyPage from '../pages/admin/editCompanyPage/EditCompanyPage';
import EditEpisodePage from '../pages/admin/editEpisodePage/EditEpisodePage';
import EditSeriePage from '../pages/admin/editSeriePage/EditSeriePage';
import EditUserPage from '../pages/admin/EditUserPage';
import ListCompaniesPage from '../pages/admin/ListCompaniesPage';
import ListRelaysPage from '../pages/admin/ListRelaysPage';
import ListSeriesPage from '../pages/admin/ListSeriesPage';
import ListUsersPage from '../pages/admin/ListUsersPage';
import LoginPage from '../pages/admin/LoginPage';
import RelayPage from '../pages/admin/relayPage/RelayPage';
import MicroSiteMainPage from '../pages/microsite/MicroSiteMainPage';
import AuditPage from '../pages/public/AuditPage';
import RegistrationPage from '../pages/public/RegistrationPage';
import {ModalProvider} from '../services/modal';
import {selectAuthIsAdmin, selectAuthIsLogged, selectAuthStrategies} from '../store/selectors';
import {baseUrl, noHeader} from './config';
import ForgottenPasswordPage from "../pages/admin/ForgottenPasswordPage";
import ChangePasswordPage from "../pages/admin/ChangePasswordPage";
import ListStrategiesPage from "../pages/admin/ListStrategiesPage";
import EditStrategyPage from "../pages/admin/editStrategyPage/EditStrategyPage";
import StrategyDashboardPage from "../pages/admin/strategyDashboardPage/StrategyDashboardPage";
import OnePagerPage, {OnePagerMode} from "../pages/admin/onePagerPage/OnePagerPage";
import StrategySelectionPage from "../pages/admin/strategySelectionPage/StrategySelectionPage";
import {JsonUpdateUserRequestTokenTypeEnum} from "../generated-api";
import {getLiveRoutes} from "./live-routing";
import RelayInfoPage from "../pages/admin/RelayInfoPage";
import {getStratairuRoutes} from "./stratairu-routing";
import {AtairuEnv, getEnvironment} from "../helpers/environment";
import {fetchAuthLogout} from "../store/auth";
import {useAppDispatch} from "../store";
import SimpleFullScreenPage from "../pages/microsite/SimpleFullScreenPage";
import RemoteControllerPage from "../pages/microsite/RemoteControllerPage";

// https://reactrouter.com/docs/en/v6/getting-started/overview

export const AppRouter: React.FC = () => {

	const isLogged = useSelector(selectAuthIsLogged);
	const isAdmin = useSelector(selectAuthIsAdmin);
	const strategies = useSelector(selectAuthStrategies);
	const dispatch = useAppDispatch();

	const handleLogout = useCallback(() => {
		dispatch(fetchAuthLogout());
	}, [dispatch]);

	const env = getEnvironment();

	if (isLogged && (env === AtairuEnv.Stratairu && !strategies.length)) {
		handleLogout();
	}

	console.log({ env, strategies });

	const redirect = ((env === AtairuEnv.Stratairu && strategies.length > 0) ? `/stratairu/${strategies[0].strategyId}/home` : (isAdmin ? '/companies' : '/strategy/selection'));

	return (
		<BrowserRouter basename={baseUrl}>
			<Layout noHeader={noHeader}>
				<ModalProvider>
					<Routes>
						{ isLogged && isAdmin && <Route path='/companies' element={<ListCompaniesPage />} /> }
						{ isLogged && isAdmin && <Route path='/companies/new' element={<EditCompanyPage createNew={true}/>} /> }
						{ isLogged && isAdmin && <Route path='/companies/:id' element={<EditCompanyPage />} /> }

						{ isLogged && isAdmin && <Route path='/users' element={<ListUsersPage />} /> }
						{ isLogged && isAdmin && <Route path='/users/new' element={<EditUserPage createNew={true}/>} /> }
						{ isLogged && isAdmin && <Route path='/users/:id' element={<EditUserPage />} /> }

						{ isLogged && isAdmin && <Route path='/series' element={<ListSeriesPage />} /> }
						{ isLogged && isAdmin && <Route path='/series/new' element={<EditSeriePage createNew={true}/>} /> }
						{ isLogged && isAdmin && <Route path='/series/:serieId/episode/new' element={<EditEpisodePage createNew={true}/>} /> }
						{ isLogged && isAdmin && <Route path='/series/:id' element={<EditSeriePage />} /> }

						{ isLogged && isAdmin && <Route path='/episodes/:id' element={<EditEpisodePage /> } /> }

						{ isLogged && isAdmin && <Route path='/relays' element={<ListRelaysPage />} /> }
						{ isLogged && <Route path='/relays/info' element={<RelayInfoPage />} /> }
						{ isLogged && isAdmin && <Route path='/relays/:id' element={<RelayPage />} /> }

						{ isLogged && isAdmin && <Route path='/strategies' element={<ListStrategiesPage />} /> }
						{ isLogged && isAdmin && <Route path='/strategies/new' element={<EditStrategyPage createNew={true}/>} /> }
						{ isLogged && isAdmin && <Route path='/strategies/:id' element={<EditStrategyPage />} /> }

						{ isLogged && <Route path='/strategy/selection' element={<StrategySelectionPage />} /> }
						{ isLogged && <Route path='/strategy/:id/dashboard' element={<StrategyDashboardPage />} /> }
						{ isLogged && <Route path='/onepager/:id' element={<OnePagerPage mode={OnePagerMode.Strategy}/>} /> }

						<Route path='/forgotten-password' element={<ForgottenPasswordPage />} />
						<Route path='/recover/:token' element={<ChangePasswordPage tokenType={JsonUpdateUserRequestTokenTypeEnum.Recover}/>} />
						<Route path='/activate/:token' element={<ChangePasswordPage tokenType={JsonUpdateUserRequestTokenTypeEnum.Activate}/>} />

						<Route path='/registration/:code' element={<RegistrationPage />} />
						<Route path='/finalize/:clientGuid' element={<RegistrationPage />} />
						<Route path='/audit/:clientGuid' element={<AuditPage />} />

						{ getStratairuRoutes(isLogged) }

						{ getLiveRoutes(isLogged) }

						{ isLogged && <Route path="*" element={<Navigate to={redirect} replace/>} /> }
						<Route path='*' element={<LoginPage />} />
					</Routes>
				</ModalProvider>
			</Layout>
		</BrowserRouter>
	);
};

export const MicroSiteRouter: React.FC = () => {
	return (
		<BrowserRouter basename='microsite'>
			<Layout noHeader noBackground>
				<Routes>
					<Route path='/' element={<MicroSiteMainPage />}></Route>
					<Route path='/simple-fullscreen/:relayId/:itemType/:id' element={<SimpleFullScreenPage />}></Route>
					<Route path='/remote-controller/:relayId' element={<RemoteControllerPage />}></Route>
				</Routes>
			</Layout>
		</BrowserRouter>
	);
};
