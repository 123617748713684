import { useField } from 'formik';
import { FormfieldProps } from '../../model/form';
import { BlockPicker } from 'react-color';
import {useCallback} from "react";

interface Props extends FormfieldProps {
	onChange?: (value: any) => any;
}

const ColorPickerFormfield = ({ name, onChange, ...props }: Props) => {
	
	const [field, , helpers] = useField(name);
	const { setValue } = helpers;

	// const [isFocused, setIsFocused] = useState(false);

	const handleOnChange = useCallback((event: any) => {
		console.log({ event });
		if (event.hex) {
			const newValue = event.hex;
			setValue(newValue, true);
			onChange && onChange(newValue);
		}
	}, [setValue, onChange]);

	/*
	const setTouchedOnBlur = useCallback((event: any) => {
		setTouched(true);
		setIsFocused(false);
		const normalizedValue = normalizeValue ? normalizeValue(event.target.value) : event.target.value;
		setValue(normalizedValue, true);
	}, [setValue, setTouched, setIsFocused, normalizeValue]);

	const setOnFocus = useCallback((event: any) => {
		setIsFocused(true);
	}, [setIsFocused]);

	const currentValue = isFocused && normalizeValue ? normalizeValue(field.value) : field.value;
	if (currentValue !== field.value) {
		setValue(name, currentValue);
	}
	 */

	return (<BlockPicker color={ field.value } onChange={ handleOnChange } />);
}

export default ColorPickerFormfield;
