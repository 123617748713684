import {useAppTranslation} from '../../../services/i18n';
import {JsonEpisode} from "../../../generated-api";
import styles from "../../../assets/styles/live.module.css";
import {Button} from "../../../components/layout/Button";
import classnames from "classnames";
import {useLiveContext} from "../../../hooks/useLiveContext";
import {useCallback} from "react";

const EpisodeTile = ({ order, episode, disabled, onClick, onClickWorkbook }: { order: number, episode: JsonEpisode, disabled: boolean, onClick: () => void, onClickWorkbook?: () => void }) => {

    const t = useAppTranslation();

    const title = episode?.title?.split(': ')[0];
    const desc = episode?.title?.split(': ')[1];

    const handleOnClickWorkbook = useCallback((event: any) => {
        event.stopPropagation();
        onClickWorkbook && onClickWorkbook();
    }, [onClickWorkbook])

    return (
        <div className={classnames(styles.dashboardTile, disabled ? styles.disabled : undefined)} onClick={() => !disabled && onClick && onClick() }>
            <div>
                <div className={styles.number}>{ order }.</div>
                <div className={styles.title}>{ title }</div>
                <div className={styles.desc}>{ desc }</div>
            </div>
            { !disabled && <div className={styles.button}>
                <Button variant="contained" color="success" disabled={disabled}>{ t('Přejít') }</Button>
            </div> }
            { !disabled && onClickWorkbook && <div className={styles.secondButton} onClick={handleOnClickWorkbook}>
                <Button variant="contained" color="success" disabled={disabled}>{ t('Workbook') }</Button>
            </div> }
        </div>
    )
}

export enum EpisodeListMode {
    Dashboards = 'Dashboards',
    Archives = 'Archives'
}

const LiveEpisodesList = ({ mode, onClick, onClickWorkbook } : { mode: EpisodeListMode, onClick: (episode: JsonEpisode, index: number) => void, onClickWorkbook?: (episode: JsonEpisode, index: number) => void }) => {

    const t = useAppTranslation();

    const [context] = useLiveContext(false);

    const title = context?.user?.serieTitle?.split(': ')[1];
    const archivedEpisodesIdxs = [0,1,2,3] as number[];
    const hasWoorkbookIdxs = [0,1,2,3] as number[];

    return (
        <>
        { title && <div className={styles.episodesTitle}>{ title }</div> }
        <div className={styles.episodesDesc}>{ t('2. sezóna') }</div>
        <div className={styles.dashboardTiles}>
            { context?.context?.episodes?.map((episode: JsonEpisode, index: number) => {
                const disabled = mode === EpisodeListMode.Dashboards ? !episode.dashboardUrl : !archivedEpisodesIdxs?.includes(index);
                return <EpisodeTile key={episode.episodeId} order={index + 1} episode={episode}
                                    onClick={() => { onClick(episode, index) }}
                                    onClickWorkbook={ hasWoorkbookIdxs?.includes(index) && onClickWorkbook ? () => onClickWorkbook(episode, index) : undefined }
                                    disabled={disabled}/>
            }) }
        </div>
        </>
    );
}

export default LiveEpisodesList;

