/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonStratCvb,
    JsonStratCvbFromJSON,
    JsonStratCvbFromJSONTyped,
    JsonStratCvbToJSON,
} from './JsonStratCvb';
import {
    JsonStratEnabler,
    JsonStratEnablerFromJSON,
    JsonStratEnablerFromJSONTyped,
    JsonStratEnablerToJSON,
} from './JsonStratEnabler';
import {
    JsonStratPillarInfo,
    JsonStratPillarInfoFromJSON,
    JsonStratPillarInfoFromJSONTyped,
    JsonStratPillarInfoToJSON,
} from './JsonStratPillarInfo';

/**
 * 
 * @export
 * @interface JsonStratOnepagerInfo
 */
export interface JsonStratOnepagerInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonStratOnepagerInfo
     */
    allowedActions?: Array<JsonStratOnepagerInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    ambition?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    companyDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    companyPositionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {Array<JsonStratCvb>}
     * @memberof JsonStratOnepagerInfo
     */
    cvbs?: Array<JsonStratCvb>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    departmentId?: number;
    /**
     * 
     * @type {Date}
     * @memberof JsonStratOnepagerInfo
     */
    dueDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    email?: string;
    /**
     * 
     * @type {Array<JsonStratEnabler>}
     * @memberof JsonStratOnepagerInfo
     */
    enablers?: Array<JsonStratEnabler>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    onepagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    parentUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    phone?: string;
    /**
     * 
     * @type {Array<JsonStratPillarInfo>}
     * @memberof JsonStratOnepagerInfo
     */
    pillars?: Array<JsonStratPillarInfo>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    positionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    purpose?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    status?: JsonStratOnepagerInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    stratUserId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    strategyId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    userId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratOnepagerInfo
     */
    userPath?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratOnepagerInfo
     */
    userStatus?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratOnepagerInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonStratOnepagerInfoStatusEnum {
    Pending = 'PENDING',
    New = 'NEW',
    Draft = 'DRAFT',
    Public = 'PUBLIC',
    Finished = 'FINISHED'
}

export function JsonStratOnepagerInfoFromJSON(json: any): JsonStratOnepagerInfo {
    return JsonStratOnepagerInfoFromJSONTyped(json, false);
}

export function JsonStratOnepagerInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratOnepagerInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'ambition': !exists(json, 'ambition') ? undefined : json['ambition'],
        'companyDepartmentId': !exists(json, 'companyDepartmentId') ? undefined : json['companyDepartmentId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyPositionId': !exists(json, 'companyPositionId') ? undefined : json['companyPositionId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'cvbs': !exists(json, 'cvbs') ? undefined : ((json['cvbs'] as Array<any>).map(JsonStratCvbFromJSON)),
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'dueDate': !exists(json, 'dueDate') ? undefined : (new Date(json['dueDate'])),
        'email': !exists(json, 'email') ? undefined : json['email'],
        'enablers': !exists(json, 'enablers') ? undefined : ((json['enablers'] as Array<any>).map(JsonStratEnablerFromJSON)),
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'onepagerId': !exists(json, 'onepagerId') ? undefined : json['onepagerId'],
        'parentUserId': !exists(json, 'parentUserId') ? undefined : json['parentUserId'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'pillars': !exists(json, 'pillars') ? undefined : ((json['pillars'] as Array<any>).map(JsonStratPillarInfoFromJSON)),
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'purpose': !exists(json, 'purpose') ? undefined : json['purpose'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'stratUserId': !exists(json, 'stratUserId') ? undefined : json['stratUserId'],
        'strategyId': !exists(json, 'strategyId') ? undefined : json['strategyId'],
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userPath': !exists(json, 'userPath') ? undefined : json['userPath'],
        'userStatus': !exists(json, 'userStatus') ? undefined : json['userStatus'],
    };
}

export function JsonStratOnepagerInfoToJSON(value?: JsonStratOnepagerInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'ambition': value.ambition,
        'companyDepartmentId': value.companyDepartmentId,
        'companyId': value.companyId,
        'companyPositionId': value.companyPositionId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'cvbs': value.cvbs === undefined ? undefined : ((value.cvbs as Array<any>).map(JsonStratCvbToJSON)),
        'departmentId': value.departmentId,
        'dueDate': value.dueDate === undefined ? undefined : (value.dueDate.toISOString().substr(0,10)),
        'email': value.email,
        'enablers': value.enablers === undefined ? undefined : ((value.enablers as Array<any>).map(JsonStratEnablerToJSON)),
        'firstName': value.firstName,
        'lastName': value.lastName,
        'onepagerId': value.onepagerId,
        'parentUserId': value.parentUserId,
        'phone': value.phone,
        'pillars': value.pillars === undefined ? undefined : ((value.pillars as Array<any>).map(JsonStratPillarInfoToJSON)),
        'positionId': value.positionId,
        'purpose': value.purpose,
        'status': value.status,
        'stratUserId': value.stratUserId,
        'strategyId': value.strategyId,
        'teamName': value.teamName,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
        'userPath': value.userPath,
        'userStatus': value.userStatus,
    };
}

