import {useAppDispatch} from '../../../store';
import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from 'react';
import {JsonStrategy} from '../../../generated-api';
import PageHeader from '../../../components/layout/PageHeader';
import {strategyFactory} from '../../../model/factories';
import {Container, Grid} from '@mui/material';
import { addMessage } from '../../../store/localApp';
import { useAppTranslation } from '../../../services/i18n';
import StrategyForm from "../../../forms/StrategyForm";
import {fetchStrategy, saveStrategy} from "../../../store/strategies";
import StrategyImport from "./components/StrategyImport";

interface Props {
  createNew?: boolean;
}

const EditStrategyPage = ({ createNew }: Props) => {

  const t = useAppTranslation();

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [strategy, setStrategy] = useState<JsonStrategy | null>(null);

  const { id } = useParams();
	useEffect(() => {
    if (createNew) {
      setStrategy(strategyFactory());
      return;
    } 
    if (id) {
      dispatch(fetchStrategy(+id))
        .then((result) => setStrategy((result?.payload as any).data));
    }
	}, [dispatch, createNew, id]);


  const handleSave = useCallback(async (data: JsonStrategy) => {
    const result = await dispatch(saveStrategy(data));
    if ('error' in result) {
      dispatch(addMessage({
        severity: 'error',
        title: result.error.message === 'ENTITY_ALREADY_EXISTS' ?  t('Strategie již existuje.') : t('Chyba při vytváření strategie.'),
      }));
      return;
    }
    navigate('/strategies');
  }, [navigate, dispatch, t])

  const handleCancel = useCallback(() => {
    navigate('/strategies');
  }, [navigate])

  return (
    <>
      { !!strategy &&
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={strategy.title as string} showBack></PageHeader>
          </Grid>
          <Grid item xs={12} md={strategy.strategyId ? 6 : 12}>
            <StrategyForm data={strategy} onSave={handleSave} onCancel={handleCancel} cardLayout></StrategyForm>
          </Grid>
          { strategy.strategyId &&
            <Grid item xs={12} md={6}>
              <StrategyImport strategy={strategy}></StrategyImport>
            </Grid>
          }
        </Grid>
      </Container>    
    }
    </>
	);
}

export default EditStrategyPage;
