import * as React from 'react';
import styles from '../../../assets/styles/stratairu.module.css';
import {PropsWithChildren, ReactNode} from "react";
import {useAppTranslation} from "../../../services/i18n";

interface Props {
    label?: string;
    content?: ReactNode;
}

const StratairuLabel = ({ label, content, children }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();

	return (
        <div className={styles.label}>
            { label && <div>{ label }</div> }
            { content && <div>{ content }</div> }
            <div>{ children }</div>
        </div>
	);
}

export default StratairuLabel;
