/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonCompanySerie
 */
export interface JsonCompanySerie {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanySerie
     */
    allowedActions?: Array<JsonCompanySerieAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonCompanySerie
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerie
     */
    companyId?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanySerie
     */
    companySerieFlags?: Array<JsonCompanySerieCompanySerieFlagsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerie
     */
    companySerieId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanySerie
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerie
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanySerie
     */
    serieGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerie
     */
    serieId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanySerie
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanySerie
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanySerieAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonCompanySerieCompanySerieFlagsEnum {
    RegEmail = 'REG_EMAIL',
    RegPosition = 'REG_POSITION',
    RegDepartment = 'REG_DEPARTMENT',
    RegName = 'REG_NAME',
    RegBranch = 'REG_BRANCH',
    RegDuration = 'REG_DURATION',
    PollStepper = 'POLL_STEPPER',
    RegSector = 'REG_SECTOR',
    RegSex = 'REG_SEX',
    PollAnswerRequired = 'POLL_ANSWER_REQUIRED'
}

export function JsonCompanySerieFromJSON(json: any): JsonCompanySerie {
    return JsonCompanySerieFromJSONTyped(json, false);
}

export function JsonCompanySerieFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanySerie {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companySerieFlags': !exists(json, 'companySerieFlags') ? undefined : json['companySerieFlags'],
        'companySerieId': !exists(json, 'companySerieId') ? undefined : json['companySerieId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'serieGuid': !exists(json, 'serieGuid') ? undefined : json['serieGuid'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonCompanySerieToJSON(value?: JsonCompanySerie | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyId': value.companyId,
        'companySerieFlags': value.companySerieFlags,
        'companySerieId': value.companySerieId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'serieGuid': value.serieGuid,
        'serieId': value.serieId,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

