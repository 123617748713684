import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import * as React from 'react';
import {PropsWithChildren} from 'react';

interface Props {
    title: string;
    open: boolean;
}

const FormModal = ({ children, title, open }: PropsWithChildren<Props>) => {
	
    return (
        <>
        { open &&
            <Dialog open={open}>
                <DialogTitle>{ title }</DialogTitle>
                <DialogContent>{ children }</DialogContent>
            </Dialog>
        }
        </>
    );

}

export default FormModal;
