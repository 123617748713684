import {useAppTranslation} from '../../services/i18n';
import styles from '../../assets/styles/intro.module.css';
import logoImage from '../../assets/images/atairu_logo_tv.png';
import introImage from '../../assets/images/intro_image.jpg';
import {useCallback, useEffect, useState} from "react";

const LiveUnregisteredPage = () => {
    const t = useAppTranslation();

    return (<div className={styles.introContainer}>
        <img className={styles.logo} alt="Atairu" src={logoImage}/>
        <img className={styles.decorationImage} alt="Atairu Leadership TV" src={introImage} style={{  }}/>
        <div className={styles.content}>
            <div>
                <div className={styles.title}>{ t('ATAIRU LEADERSHIP TV:') }<br/>{ t('CHANGE CHALLENGE') }</div>
                <div className={styles.subtitle}>{ t('Vaše cesta k rychlé a úspěšné realizaci změn') }</div>
            </div>
            <div className={styles.register} dangerouslySetInnerHTML={{ __html: t('Pokud máte zájem sledovat ATAIRU LEADERSHIP TV, kontaktujte nás přes formulář na <a href="https://www.atairu.com/atairu-leadership-tv-change-challenge">našich stránkách</a>.<br/>Děkujeme') }} />
        </div>
    </div>);
}

export default LiveUnregisteredPage;
