/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonUser,
    ApiResultOfJsonUserFromJSON,
    ApiResultOfJsonUserToJSON,
    ApiResultOfJsonUserInfo,
    ApiResultOfJsonUserInfoFromJSON,
    ApiResultOfJsonUserInfoToJSON,
    ApiResultOfListOfJsonUserInfo,
    ApiResultOfListOfJsonUserInfoFromJSON,
    ApiResultOfListOfJsonUserInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonUser,
    JsonUserFromJSON,
    JsonUserToJSON,
} from '../models';

export interface FindUserUsingGETRequest {
    email: string;
}

export interface GetUserCountUsingGETRequest {
    companyId?: number;
    serieId?: number;
    companyRoleCode?: string;
    roleCode?: string;
    hasAnyName?: boolean;
    hasEmail?: boolean;
    search?: string;
}

export interface GetUserExportUsingGETRequest {
    companyId?: number;
    serieId?: number;
    companyRoleCode?: string;
    roleCode?: string;
    hasAnyName?: boolean;
    hasEmail?: boolean;
    search?: string;
    orderCol?: string;
    orderDir?: string;
}

export interface GetUserInfoUsingGETRequest {
    userId: number;
}

export interface GetUserListUsingGETRequest {
    companyId?: number;
    serieId?: number;
    companyRoleCode?: string;
    roleCode?: string;
    hasAnyName?: boolean;
    hasEmail?: boolean;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetUserUsingGETRequest {
    userId: number;
}

export interface SaveUserDataUsingPOSTRequest {
    json: JsonUser;
    testOnly?: boolean;
}

export interface SaveUserUsingPOSTRequest {
    json: JsonUser;
    testOnly?: boolean;
}

/**
 * 
 */
export class UserControllerApi extends runtime.BaseAPI {

    /**
     * findUser
     */
    async findUserUsingGETRaw(requestParameters: FindUserUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonUser>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling findUserUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/user/find`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonUserFromJSON(jsonValue));
    }

    /**
     * findUser
     */
    async findUserUsingGET(requestParameters: FindUserUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonUser> {
        const response = await this.findUserUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getUserCount
     */
    async getUserCountUsingGETRaw(requestParameters: GetUserCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.companyRoleCode !== undefined) {
            queryParameters['companyRoleCode'] = requestParameters.companyRoleCode;
        }

        if (requestParameters.roleCode !== undefined) {
            queryParameters['roleCode'] = requestParameters.roleCode;
        }

        if (requestParameters.hasAnyName !== undefined) {
            queryParameters['hasAnyName'] = requestParameters.hasAnyName;
        }

        if (requestParameters.hasEmail !== undefined) {
            queryParameters['hasEmail'] = requestParameters.hasEmail;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/user/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getUserCount
     */
    async getUserCountUsingGET(requestParameters: GetUserCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getUserCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getUserExport
     */
    async getUserExportUsingGETRaw(requestParameters: GetUserExportUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.companyRoleCode !== undefined) {
            queryParameters['companyRoleCode'] = requestParameters.companyRoleCode;
        }

        if (requestParameters.roleCode !== undefined) {
            queryParameters['roleCode'] = requestParameters.roleCode;
        }

        if (requestParameters.hasAnyName !== undefined) {
            queryParameters['hasAnyName'] = requestParameters.hasAnyName;
        }

        if (requestParameters.hasEmail !== undefined) {
            queryParameters['hasEmail'] = requestParameters.hasEmail;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/user/export`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * getUserExport
     */
    async getUserExportUsingGET(requestParameters: GetUserExportUsingGETRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.getUserExportUsingGETRaw(requestParameters, initOverrides);
    }

    /**
     * getUserInfo
     */
    async getUserInfoUsingGETRaw(requestParameters: GetUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonUserInfo>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/user/info/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonUserInfoFromJSON(jsonValue));
    }

    /**
     * getUserInfo
     */
    async getUserInfoUsingGET(requestParameters: GetUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonUserInfo> {
        const response = await this.getUserInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getUserList
     */
    async getUserListUsingGETRaw(requestParameters: GetUserListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonUserInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.companyRoleCode !== undefined) {
            queryParameters['companyRoleCode'] = requestParameters.companyRoleCode;
        }

        if (requestParameters.roleCode !== undefined) {
            queryParameters['roleCode'] = requestParameters.roleCode;
        }

        if (requestParameters.hasAnyName !== undefined) {
            queryParameters['hasAnyName'] = requestParameters.hasAnyName;
        }

        if (requestParameters.hasEmail !== undefined) {
            queryParameters['hasEmail'] = requestParameters.hasEmail;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/user/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonUserInfoFromJSON(jsonValue));
    }

    /**
     * getUserList
     */
    async getUserListUsingGET(requestParameters: GetUserListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonUserInfo> {
        const response = await this.getUserListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getUser
     */
    async getUserUsingGETRaw(requestParameters: GetUserUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonUser>> {
        if (requestParameters.userId === null || requestParameters.userId === undefined) {
            throw new runtime.RequiredError('userId','Required parameter requestParameters.userId was null or undefined when calling getUserUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/user/{userId}`.replace(`{${"userId"}}`, encodeURIComponent(String(requestParameters.userId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonUserFromJSON(jsonValue));
    }

    /**
     * getUser
     */
    async getUserUsingGET(requestParameters: GetUserUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonUser> {
        const response = await this.getUserUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveUserData
     */
    async saveUserDataUsingPOSTRaw(requestParameters: SaveUserDataUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonUser>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveUserDataUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/user/data`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonUserToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonUserFromJSON(jsonValue));
    }

    /**
     * saveUserData
     */
    async saveUserDataUsingPOST(requestParameters: SaveUserDataUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonUser> {
        const response = await this.saveUserDataUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveUser
     */
    async saveUserUsingPOSTRaw(requestParameters: SaveUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonUser>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveUserUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonUserToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonUserFromJSON(jsonValue));
    }

    /**
     * saveUser
     */
    async saveUserUsingPOST(requestParameters: SaveUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonUser> {
        const response = await this.saveUserUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
