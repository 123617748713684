import { isPhoneValid } from "./validators";

export const PHONE_PREFIXES = ['420', '421'];

export function formatPhone(value: string) {
	if (!value) {
		return '';
	}
	if (isPhoneValid(value)) {
		const formatted = (value && value.toString().replace(/\D/g, '')) || '';
		const phonePrefix = PHONE_PREFIXES.find((prefix: string) => 
			formatted.substr(0, formatted.length > prefix.length ? prefix.length : formatted.length) === prefix.substr(0, formatted.length > prefix.length ? prefix.length : formatted.length)
		)
		if (phonePrefix) {
			if (['420','421'].includes(phonePrefix)) {
				return '+' + formatted.substr(0,3) + ' ' + formatted.substr(3,3) + ' ' + formatted.substr(6,3) + ' ' + formatted.substr(9,3);
			} else {
				return '+' + formatted.substr(0, phonePrefix.length) + ' ' + formatted.substr(phonePrefix.length);
			}
		}
	}
	return value;
}


export function createNormalizePhone(driverTeam: boolean = false) {
	return (value: string):string => {

        if (!value) {
            return value;
        }

		let temp = value.toString().replace(/\D/g, '');

		const hasValidStartPrefix = PHONE_PREFIXES.find((prefix: string) => 
			temp.substr(0, temp.length > prefix.length ? prefix.length : temp.length) === prefix.substr(0, temp.length > prefix.length ? prefix.length : temp.length)
		)

		if (!hasValidStartPrefix) {
			temp = '420' + temp;
		}
		
		const formatted = value && '+' + temp; 
		
		return formatted;
	}
	
}