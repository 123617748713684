import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {JsonCompanyBranch, JsonUser} from '../generated-api';
import { createNormalizePhone, formatPhone } from '../helpers/formfield';
import { isEmailValid, isPhoneValid } from '../helpers/validators';
import {FormProps} from '../model/form';
import {useAppTranslation} from '../services/i18n';

interface Props extends FormProps<JsonCompanyBranch> {}

const CompanyBranchForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonCompanyBranch) => {
        let errors = {} as FormikErrors<JsonCompanyBranch>;
        if (!values.title) {
            errors['title'] = t('Název pobočky je povinný.');
        }
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <TextFormfield name="title" label={t('Název pobočky')}></TextFormfield>
            </Grid>
		</FormContainer>
	);
}

export default CompanyBranchForm;
