export function isEmailValid(email: string) {
	if (email.length > 256) { return false; }
	const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

export function isPhoneValid(phone: string) {
	const re = /^\+(420|421)([0-9]{9})$/;
	if (re.test(phone)) {
		return true;
	}
	const reOthers = /^\+(39|33|34|44|49|43|48)([0-9]{1,15})$/;
	return reOthers.test(phone);
}