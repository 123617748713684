import {Card, CardActions, CardContent, Grid} from '@mui/material';
import * as React from 'react';
import {useCallback} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {useAppDispatch} from '../../store';
import {saveChangedPassword} from '../../store/auth';
import {Formik, FormikErrors} from 'formik';
import TextFormfield, {whiteStyle} from '../../components/form/TextFormfield';
import {
	ApiResultOfstringResultEnum,
	JsonUpdateUserRequest,
	JsonUpdateUserRequestTokenTypeEnum
} from '../../generated-api';
import {isEmpty} from '../../helpers/object';
import {addMessage} from '../../store/localApp';
import whiteLogo from '../../assets/images/atairu_logo_white.png';
import {isEmailValid} from '../../helpers/validators';
import {ActionLink} from "../../components/layout/ActionLink";
import {useNavigate, useParams} from "react-router";
import {Button} from '../../components/layout/Button';
import {AtairuEnv, getEnvironment} from "../../helpers/environment";
import stratairuLogo from "../../assets/images/stratairu/stratairu-logo.svg";

interface JsonUpdateUserRequestEx extends JsonUpdateUserRequest {
	verifyPassword: string;
}

interface Props {
	tokenType: JsonUpdateUserRequestTokenTypeEnum
}

const ChangePasswordPage = ({ tokenType }: Props) => {

	const t = useAppTranslation();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { token } = useParams();

	const env = getEnvironment();

	const validate = useCallback((values: JsonUpdateUserRequestEx) => {
		const errors = {} as FormikErrors<JsonUpdateUserRequestEx>;
		if (!values.email) {
			errors['email'] = t('Zadejte přihlašovací email');
		} else if (!isEmailValid(values.email)) {
			errors['email'] = t('Neplatný přihlašovací email');
		}
		if (!values.password) {
			errors['password'] = t('Nové heslo nesmí být prázdné');
		}
		if (values.verifyPassword !== values.password) {
			errors['verifyPassword'] = t('Heslo a jeho potvrzení se musí shodovat');
		}
		return isEmpty(errors) ? undefined : errors;
	}, [t]);

	const handleChangePassword = useCallback(async (values: JsonUpdateUserRequestEx) => {
		if (tokenType) {
			const result = await dispatch(saveChangedPassword({ ...values, token, tokenType }));
			if ('error' in result) {
				dispatch(addMessage({
					severity: 'error',
					title: t('apiResults.' + result.error.name),
				}));
				return;
			} else {
				if (result.payload.result === ApiResultOfstringResultEnum.Ok) {
					navigate('/');
					dispatch(addMessage({
						severity: 'success',
						title: t('apiResults.' + result.payload.data || ''),
					}));
				}
			}
		}
	}, [dispatch, token, tokenType, navigate, t])

	// https://mui.com/material-ui/getting-started/usage/
	return (
		<Formik
			initialValues={{
				email: '',
				password: '',
				verifyPassword: ''
				}}
			validate={validate}
			onSubmit={handleChangePassword}
			>
			{props => (
			<form onSubmit={props.handleSubmit}>
				<div style={{ paddingTop: '15%' }}>
					<Card sx={{ width: '100%', maxWidth: 400, margin: 'auto', background: 'transparent' }} elevation={0}>
						<CardContent>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<div style={{ display: 'flex', justifyContent: 'center' }}>
										<img alt={'Atairu'} src={env === AtairuEnv.Tv ? whiteLogo : stratairuLogo} style={{ height: '140px' }}/>
									</div>
								</Grid>
								<Grid item xs={12}>
									<TextFormfield name="email" label={t('E-mail')} sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
								</Grid>
								{ tokenType === JsonUpdateUserRequestTokenTypeEnum.Activate &&
									<>
										<Grid item xs={12}>
											<TextFormfield name="userUpdate.firstName" label={t('Jméno')} sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
										</Grid>
										<Grid item xs={12}>
											<TextFormfield name="userUpdate.lastName" label={t('Příjmení')} sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
										</Grid>
									</>
								}
								<Grid item xs={12}>
									<TextFormfield name="password" label={t('Nové heslo')} type="password" sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
								</Grid>
								<Grid item xs={12}>
									<TextFormfield name="verifyPassword" label={t('Potvrzení hesla')} type="password" sx={env === AtairuEnv.Tv ? whiteStyle : undefined}></TextFormfield>
								</Grid>
							</Grid>
						</CardContent>
						<CardActions sx={{ display: 'flex', justifyContent: 'center' }}>
							<Button variant="contained" color="success"  type="submit" fullWidth>{t('Nastavit nové heslo')}</Button>
						</CardActions>
						<CardContent sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
							<ActionLink onClick={() => navigate('/')} sx={{ color: env === AtairuEnv.Tv ? 'white' : undefined  }}>{t('Přihlášení')}</ActionLink>
							<div style={{ marginTop: '50px', fontSize: '12px', color: '#aaa' }}>&copy; 2023 ATAIRU</div>
						</CardContent>
					</Card>
				</div>
			</form>
			)}
		</Formik>
	);
}

export default ChangePasswordPage;
