import {Breakpoint, Button, Dialog, DialogContent, DialogTitle, Grid} from '@mui/material';
import * as React from 'react';
import {PropsWithChildren, useCallback} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {StratairuButton, StratairuButtonEnum} from "../stratairu/components/StratairuButton";
export enum ModalButtonActionEnum {
    Close = 'CLOSE',
    Save = 'SAVE',
    Confirm = 'CONFIRM',
    Cancel = 'CANCEL'
}
export interface ModalButton {
    title?: string;
    action: ModalButtonActionEnum;
    color?: StratairuButtonEnum;
}

interface Props {
    open: boolean;
    title: string | undefined;
    buttons?: ModalButton[];
    onClose?: () => void;
    onAction?: (action: ModalButtonActionEnum) => void;
    closable?: boolean;
    buttonSize?: number;
    size?: Breakpoint;
}

const ClosableModal = ({ children, open, title, buttons, onClose, onAction, closable, buttonSize, size }: PropsWithChildren<Props>) => {

    const calculatedButtonSize = 8 / (buttons?.length || 1)

    const handleOnClick = useCallback((button: ModalButton) => {
        if (onAction) {
            onAction(button.action);
        }
        if (button.action === ModalButtonActionEnum.Close && onClose) {
            onClose();
        }
    }, [onAction, onClose]);

    return (
        <>
            { open &&
                <Dialog open={open} maxWidth={ size || 'sm' } fullWidth>
                    <DialogTitle>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
                            <CloseIcon style={{ cursor: 'pointer' }} onClick={() => handleOnClick({ action: ModalButtonActionEnum.Close })}/>
                        </div>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container style={{ fontSize: '25px', marginBottom: '20px', fontWeight: '600', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>{ title }</Grid>
                        <Grid container style={{ fontSize: '14px', marginBottom: '0px', display: 'flex', justifyContent: 'center', textAlign: 'center' }}>{ children }</Grid>
                        <Grid container style={{ marginTop: '0px', display: 'flex', justifyContent: 'center' }}>
                            { buttons?.map(button =>
                                <Grid item xs={12} md={buttonSize || calculatedButtonSize} key={button.action} style={{ padding: '10px' }}>
                                    <StratairuButton variant="contained" mycolor={ button.color || StratairuButtonEnum.Blue } fullWidth onClick={() => handleOnClick(button)}>{button.title}</StratairuButton>
                                </Grid>
                                )
                            }
                        </Grid>
                    </DialogContent>
                </Dialog>
            }
        </>
    );
};

export default ClosableModal;
