/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonSerieUserCountInfo
 */
export interface JsonSerieUserCountInfo {
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserCountInfo
     */
    serieId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserCountInfo
     */
    userCount?: number;
}

export function JsonSerieUserCountInfoFromJSON(json: any): JsonSerieUserCountInfo {
    return JsonSerieUserCountInfoFromJSONTyped(json, false);
}

export function JsonSerieUserCountInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieUserCountInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function JsonSerieUserCountInfoToJSON(value?: JsonSerieUserCountInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'serieId': value.serieId,
        'userCount': value.userCount,
    };
}

