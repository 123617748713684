import {createTheme} from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: '#210055',
        },
        secondary: {
            main: '#ffffff',
        },
        success: {
            main: '#a6f5b4'
        }
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: '15px'
                },
            },
        },
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    whiteSpace: 'break-spaces'
                }
            }
        }
    }
});

export default theme;
