import * as React from 'react';
import {PropsWithChildren, useCallback} from "react";
import styles from '../../assets/styles/live.module.css';
import {useAppTranslation} from "../../services/i18n";
import {Button} from "./Button";
import {useNavigate} from "react-router";
import {ReactComponent as DecoImage} from '../../assets/images/petal.svg';
import {useParams} from "react-router-dom";
import logoImage from '../../assets/images/atairu_logo_tv.png';
import logoPWC from '../../assets/images/pwc_logo.png';
import logoCS from '../../assets/images/cs_logo.png';

interface Props {
    title?: string;
    subtitle?: string;
    backUrl?: string;
    backButton?: boolean;
    hideFaqButton?: boolean;
}

const LiveLayout = ({ title, subtitle, backUrl, backButton, hideFaqButton, children }: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    const navigate = useNavigate();

    const { code } = useParams();

    const handleNavigateBack = useCallback(() => {
        navigate('/live/' + code + (backUrl || ''));
    }, [navigate, code, backUrl])

    const handleNavigateFaq = useCallback(() => {
        navigate('/live/' + code + '/faq');
    }, [navigate, code])

	return (
        <div className={styles.liveLayoutContainer}>
            <div>
                <img className={styles.logo} alt="Atairu" src={logoImage}/>
                { title && <div className={styles.title} dangerouslySetInnerHTML={{ __html: t(title) }}/> }
                { subtitle && <div className={styles.subtitle} dangerouslySetInnerHTML={{ __html: t(subtitle) }}/> }
                { backButton && <div className={styles.backButton}><Button variant="contained" color="secondary" fullWidth onClick={handleNavigateBack}>{t('Zpět')}</Button></div> }
                <div className={styles.decoContainer}><DecoImage className={styles.decoImage}/></div>
                <div className={styles.content}>{ children }</div>
            </div>
            <div className={styles.footer}>
                <div className={styles.copy}>&copy; { t('2023 ATAIRU') }</div>
                <div className={styles.partners}>
                    <div>{ t('Partneři:')}</div>
                    <img className={styles.logoPWC} alt="PWC" src={logoPWC}/>
                    <img className={styles.logoCS} alt="Česká spořitelna" src={logoCS}/>
                </div>
                { !hideFaqButton && <div className={styles.faqButton}><Button sx={{ width: '180px' }} variant="contained" color="secondary" fullWidth onClick={handleNavigateFaq}>{t('Podpora')}</Button></div> }
            </div>
        </div>
	);
}

export default LiveLayout;
