import {
    JsonStratOnepagerInfo,
    JsonStratOnepagerStatusEnum
} from '../../../generated-api';
import {Card, Container, Grid} from '@mui/material';
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../../../store/selectors";
import styles from '../../../assets/styles/dashboard.module.css';
import stylesOnepager from '../../../assets/styles/onepager.module.css';
import {calculateSubtreeStatuses} from "../../../helpers/strategy";
import {useAppTranslation} from "../../../services/i18n";

interface FillingCardProps {
    title: string;
    statuses: {
        [name: string]: number,
    }
}

const OnePagersFillingCard = ({ title, statuses }: FillingCardProps) => {

    const totalCount = (statuses[JsonStratOnepagerStatusEnum.New] || 0) + (statuses[JsonStratOnepagerStatusEnum.Draft] || 0) + (statuses[JsonStratOnepagerStatusEnum.Public] || 0) + (statuses[JsonStratOnepagerStatusEnum.Finished] || 0);
    const result = (((statuses[JsonStratOnepagerStatusEnum.Draft] || 0) * 0.25 +  (statuses[JsonStratOnepagerStatusEnum.Public] || 0) * 0.5 + (statuses[JsonStratOnepagerStatusEnum.Finished] || 0)) / totalCount) * 100;
    const formattedResult = (Math.round(result * 100) / 100).toFixed(2);

    return <>
            { !!totalCount &&
                <div>
                    <Typography component="div" variant="h6" color="black">{ title }</Typography>
                    <Card className={styles.fillingCard}>
                        <div>
                            <div className={stylesOnepager.NEW}>{ statuses[JsonStratOnepagerStatusEnum.New] }</div>
                            <div className={stylesOnepager.DRAFT}>{ statuses[JsonStratOnepagerStatusEnum.Draft] }</div>
                            <div className={stylesOnepager.PUBLIC}>{ statuses[JsonStratOnepagerStatusEnum.Public] }</div>
                            <div className={stylesOnepager.FINISHED}>{ statuses[JsonStratOnepagerStatusEnum.Finished] }</div>
                        </div>
                        <div>{formattedResult}&nbsp;%</div>
                    </Card>
                </div>
            }
        </>
}

interface Props {
    subtree: JsonStratOnepagerInfo[];
}
const SubtreeOnePagersStatus = ({ subtree }: Props) => {

  const t = useAppTranslation();
  const user = useSelector(selectAuthUser);
  const onepager = subtree.find((op: JsonStratOnepagerInfo) => op.userId === user?.userId);
  if (!onepager) {
      return <div>Onepager nenalezen</div>;
  }
  const directResult = calculateSubtreeStatuses(subtree, user.userId!, true);
  const allResult = calculateSubtreeStatuses(subtree, user.userId!, false);

  console.log({ directResult, allResult });

  return (
        <Container>
            <Grid container>
                <Grid item xs={12} style={{ marginTop: '20px' }}>
                    <Typography component="div" variant="h5" color="black">Stav vyplnění OnePagerů v mém týmu</Typography>
                    <div className={styles.statusesContainer}>
                        <OnePagersFillingCard statuses={directResult} title={t('Přímí podřízení')}></OnePagersFillingCard>
                        <OnePagersFillingCard statuses={allResult} title={t('Celý tým')}></OnePagersFillingCard>
                    </div>
                </Grid>
            </Grid>
        </Container>
	);
}

export default SubtreeOnePagersStatus;
