/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonSerie,
    JsonSerieFromJSON,
    JsonSerieFromJSONTyped,
    JsonSerieToJSON,
} from './JsonSerie';

/**
 * 
 * @export
 * @interface JsonEpisode
 */
export interface JsonEpisode {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonEpisode
     */
    allowedActions?: Array<JsonEpisodeAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonEpisode
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEpisode
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    dashboardUrl?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    episodeFrom?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEpisode
     */
    episodeId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    episodeTo?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    openFrom?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    openTo?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEpisode
     */
    orderNo?: number;
    /**
     * 
     * @type {JsonSerie}
     * @memberof JsonEpisode
     */
    serie?: JsonSerie;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    status?: JsonEpisodeStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonEpisode
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonEpisode
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonEpisodeAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonEpisodeStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonEpisodeFromJSON(json: any): JsonEpisode {
    return JsonEpisodeFromJSONTyped(json, false);
}

export function JsonEpisodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonEpisode {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'dashboardUrl': !exists(json, 'dashboardUrl') ? undefined : json['dashboardUrl'],
        'episodeFrom': !exists(json, 'episodeFrom') ? undefined : json['episodeFrom'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'episodeTo': !exists(json, 'episodeTo') ? undefined : json['episodeTo'],
        'openFrom': !exists(json, 'openFrom') ? undefined : json['openFrom'],
        'openTo': !exists(json, 'openTo') ? undefined : json['openTo'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'serie': !exists(json, 'serie') ? undefined : JsonSerieFromJSON(json['serie']),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonEpisodeToJSON(value?: JsonEpisode | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'dashboardUrl': value.dashboardUrl,
        'episodeFrom': value.episodeFrom,
        'episodeId': value.episodeId,
        'episodeTo': value.episodeTo,
        'openFrom': value.openFrom,
        'openTo': value.openTo,
        'orderNo': value.orderNo,
        'serie': JsonSerieToJSON(value.serie),
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

