import {Button, Container, Tooltip} from '@mui/material';
import {useCallback} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {Link, useNavigate} from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';

import PageHeader from '../../components/layout/PageHeader';
import PageableList from '../../components/layout/PagableList';
import { selectSeries, selectSeriesPaging } from '../../store/selectors';

import styles from '../../assets/styles/layout';
import { JsonSerieInfo } from '../../generated-api/models';
import { fetchSeries, fetchSeriesCount } from '../../store/series';
import PoplistValue from '../../components/form/PoplistValue';

const ListSeriesPage = () => {

  const navigate = useNavigate();
	const t = useAppTranslation();

  const handleNewSerie = useCallback(() => {
    navigate('/series/new');
  }, [navigate])

  const cols = [
    {
      title: t('Nazev seriálu'), 
      style: { width: '80%' },
      renderer: (row: JsonSerieInfo) => 
        <div>
          <div className={styles.listTitle}>
            <Link to={`/series/${row.serieId}`} className={styles.link}>{row.title}</Link>
          </div>
          <div>
            <div className={styles.listInfo}>{t('Episodes:')}: {row.episodeCount}</div>
          </div>
          { /* <div>{ JSON.stringify(row) }</div> */ }
        </div>
    },
    {
      title: t('Typ'), 
      style: { width: '10%' },
      renderer: (row: JsonSerieInfo) => 
        <div className={styles.listInfo}>
          <PoplistValue value={row.serieType} poplist="serieType"/>
        </div>
    },
    {
      title: t('Status'), 
      style: { width: '10%' },
      renderer: (row: JsonSerieInfo) => 
        <div className={styles.listInfo}>
          {row.status}
        </div>
    },
    {
      title: '',
      style: { width: '10%', display: 'flex', justifyContent: 'end' },
      renderer: (row: JsonSerieInfo) => 
        <div>
          <Tooltip title="Upravit seriál">
            <Link to={`/series/${row.serieId}`}><EditIcon color="primary"/></Link>
          </Tooltip>
        </div>
    }
  ]

  const handleAction = useCallback(async (user: JsonSerieInfo, action: string) => {
    switch (action) {
      /*case 'DELETE':
        console.log('Delete user', { user });
        await dispatch(purgeUser(user.userId!))
        await dispatch(fetchUsersCount());
        await dispatch(fetchUsers({ offset: 0 }));
        break;
      */
    }
    // no other action defined
  }, []);

  // https://mui.com/material-ui/getting-started/usage/
	return (
		<Container>
      <PageHeader title={t('Seznam seriálů')}>
          <div style={{ display: 'flex' }}>
            <Button variant="contained" fullWidth onClick={handleNewSerie} className={styles.yellowButton} sx={{ width: '200px', marginRight: '10px' }}>{t('Nový seriál')}</Button>
          </div>
      </PageHeader>

      <PageableList 
        cols={cols} 
        action={handleAction}
        config={{
          selectItems: selectSeries,
          selectItemsPaging: selectSeriesPaging,
          fetchItemsCount: fetchSeriesCount,
          fetchItems: fetchSeries
        }}
      ></PageableList>

    </Container>
	);
}

export default ListSeriesPage;
