import {
    JsonPoplistItem,
    JsonStratCvb,
    JsonStratCvbStatusEnum,
    JsonStratEnabler,
    JsonStratEnablerStatusEnum,
    JsonStratInitiative,
    JsonStratInitiativeStatusEnum,
    JsonStratOnepager,
    JsonStratPillar,
    JsonStratPillarStatusEnum,
    JsonStratSuccessLook,
    JsonStratSuccessLookStatusEnum,
    JsonStratTeam, JsonStratTeamStatusEnum
} from "../generated-api";
import {OnepagerEntityIndex, OnepagerEntityType} from "../model/onepager";
import {
    stratCvbFactory,
    stratEnablerFactory,
    stratInitiativeFactory,
    stratPillarFactory,
    stratSuccessLookFactory,
    stratTeamFactory
} from "../model/factories";

export const processAddOnePagerEntity = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex) => {
    const newOnepager = { ...onepager };

    if (entityIndex.entity === OnepagerEntityType.CVB) {
        const cvbEntity: JsonStratCvb = stratCvbFactory(newOnepager.onepagerId!);
        if (newOnepager.cvbs) {
            newOnepager.cvbs.push(cvbEntity);
        } else {
            newOnepager.cvbs = [cvbEntity];
        }
    }

    if ([OnepagerEntityType.Pillar, OnepagerEntityType.PillarCompany].includes(entityIndex.entity)) {
        const pillarEntity: JsonStratPillar = stratPillarFactory(newOnepager.onepagerId!);
        if (newOnepager.pillars) {
            newOnepager.pillars.push(pillarEntity);
        } else {
            newOnepager.pillars = [pillarEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.PillarSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const successLookEntity: JsonStratSuccessLook = stratSuccessLookFactory({ pillarId: parentPillar.pillarId });
        if (parentPillar.successLooks) {
            parentPillar.successLooks.push(successLookEntity);
        } else {
            parentPillar.successLooks = [successLookEntity];
        }
    }

    if ([OnepagerEntityType.Initiative, OnepagerEntityType.InitiativeHeader].includes(entityIndex.entity)) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = stratInitiativeFactory(parentPillar.pillarId);
        if (parentPillar.initiatives) {
            parentPillar.initiatives.push(initiativeEntity);
        } else {
            parentPillar.initiatives = [initiativeEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.InitiativeSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        const successLookEntity: JsonStratSuccessLook = stratSuccessLookFactory({ initiativeId: initiativeEntity.initiativeId });
        if (initiativeEntity.successLooks) {
            initiativeEntity.successLooks.push(successLookEntity);
        } else {
            initiativeEntity.successLooks = [successLookEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.InitiativeTeam) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        const teamEntity: JsonStratTeam = stratTeamFactory({ initiativeId: initiativeEntity.initiativeId });
        if (initiativeEntity.teams) {
            initiativeEntity.teams.push(teamEntity);
        } else {
            initiativeEntity.teams = [teamEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Enabler) {
        const enablerEntity: JsonStratEnabler = stratEnablerFactory(newOnepager.onepagerId!);
        if (newOnepager.enablers) {
            newOnepager.enablers.push(enablerEntity);
        } else {
            newOnepager.enablers = [enablerEntity];
        }
    }

    if (entityIndex.entity === OnepagerEntityType.EnablerTeam) {
        const enablerEntity: JsonStratEnabler = newOnepager.enablers?.find((val, index) => index === entityIndex.entityIndex)!;
        const teamEntity: JsonStratTeam = stratTeamFactory({ enablerId: enablerEntity.enablerId });
        if (enablerEntity.teams) {
            enablerEntity.teams.push(teamEntity);
        } else {
            enablerEntity.teams = [teamEntity];
        }
    }

    return newOnepager;
}

export const processChangeOnePagerEntity = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex, data: string | JsonStratTeam) => {

    const newOnepager = { ...onepager };

    console.log({ onepager, entityIndex, data });

    if (entityIndex.entity === OnepagerEntityType.Purpose) {
        newOnepager.purpose = data as string;
    }

    if (entityIndex.entity === OnepagerEntityType.Ambition) {
        newOnepager.ambition = data as string;
    }

    if (entityIndex.entity === OnepagerEntityType.Pillar) {
        const pillarEntity = newOnepager.pillars?.find((enabler, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            pillarEntity.description = data as string;
        }
    }

    if ([OnepagerEntityType.PillarCompany, OnepagerEntityType.PillarTeam].includes(entityIndex.entity)) {
        const pillarEntity = newOnepager.pillars?.find((enabler, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            let result = undefined;
            try {
                result = JSON.parse(pillarEntity.description || '');
            } catch (e) {
                result = undefined;
            }
            if (result?.length) {
                pillarEntity.description = JSON.stringify(entityIndex.entity === OnepagerEntityType.PillarCompany ? [data, result[1]] : [result[0], data]);
            } else {
                pillarEntity.description = JSON.stringify(entityIndex.entity === OnepagerEntityType.PillarCompany ? [data, ''] : ['', data]);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.PillarSuccessLook) {
        const parentPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex);
        const successLookEntity: JsonStratSuccessLook = parentPillar?.successLooks?.find((val, index) => index === entityIndex.entityIndex)!;
        if (successLookEntity) {
            const attribute: keyof JsonStratSuccessLook = (entityIndex.attribute || 'description') as keyof JsonStratSuccessLook;
            (successLookEntity as any)[attribute] = data as string;
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Initiative) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        if (initiativeEntity) {
            initiativeEntity.description = data as string;
        }
    }

    if (entityIndex.entity === OnepagerEntityType.InitiativeSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        if (initiativeEntity) {
            const successLookEntity = initiativeEntity.successLooks?.find((val, index) => index === entityIndex.subEntityIndex)!;
            if (successLookEntity) {
                const attribute: keyof JsonStratSuccessLook = (entityIndex.attribute || 'description') as keyof JsonStratSuccessLook;
                (successLookEntity as any)[attribute] = data as string;
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.InitiativeTeam) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        if (initiativeEntity) {
            const teamEntity = initiativeEntity.teams?.find((val, index) => index === entityIndex.subEntityIndex)!;
            if (teamEntity) {
                teamEntity.teamName = (data as JsonStratTeam).teamName || teamEntity.teamName;
                teamEntity.stratUserId = (data as JsonStratTeam).stratUserId || teamEntity.stratUserId;
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Enabler) {
        const enablerEntity = newOnepager.enablers?.find((enabler, index) => index === entityIndex.entityIndex);
        if (enablerEntity) {
            enablerEntity.description = data as string;
        }
    }

    if (entityIndex.entity === OnepagerEntityType.EnablerSuccessLook) {
        const enablerEntity = newOnepager.enablers?.find((enabler, index) => index === entityIndex.entityIndex);
        if (enablerEntity) {
            const successLookEntity = enablerEntity.successLooks?.find((val, index) => index === entityIndex.subEntityIndex)!;
            if (successLookEntity) {
                const attribute: keyof JsonStratSuccessLook = (entityIndex.attribute || 'description') as keyof JsonStratSuccessLook;
                (successLookEntity as any)[attribute] = data as string;
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.EnablerTeam) {
        const enablerEntity = newOnepager.enablers?.find((enabler, index) => index === entityIndex.entityIndex);
        if (enablerEntity) {
            const teamEntity = enablerEntity.teams?.find((val, index) => index === entityIndex.subEntityIndex)!;
            if (teamEntity) {
                teamEntity.teamName = (data as JsonStratTeam).teamName || teamEntity.teamName;
                teamEntity.stratUserId = (data as JsonStratTeam).stratUserId || teamEntity.stratUserId;
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.CVB) {
        const cvbEntity = newOnepager.cvbs?.find((cvb, index) => index === entityIndex.entityIndex);
        if (cvbEntity) {
            cvbEntity.description = data as string;
        }
    }

    // Workzone updates
    if ([OnepagerEntityType.AmbitionCompany, OnepagerEntityType.AmbitionTeam].includes(entityIndex.entity)) {
        let result = undefined;
        try {
            result = JSON.parse(newOnepager.ambition || '');
        } catch (e) {
            result = undefined;
        }
        if (result?.length) {
            newOnepager.ambition = JSON.stringify(entityIndex.entity === OnepagerEntityType.AmbitionCompany ? [data, result[1]] : [result[0], data]);
        } else {
            newOnepager.ambition = JSON.stringify(entityIndex.entity === OnepagerEntityType.AmbitionCompany ? [data, ''] : ['', data]);
        }
    }

    return newOnepager;
}

export const processDeleteOnePagerEntity = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex) => {
    const newOnepager = {...onepager};

    if ([OnepagerEntityType.Pillar, OnepagerEntityType.PillarCompany].includes(entityIndex.entity)) {
        const pillarEntity = newOnepager.pillars?.find((cvb, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            if (pillarEntity.pillarId) {
                newOnepager.pillars = newOnepager.pillars?.map(pillar => pillar !== pillarEntity ? pillar : {
                    ...pillar,
                    status: JsonStratPillarStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                newOnepager.pillars = newOnepager.pillars?.filter(pillar => pillar !== pillarEntity);
            }
        }
    }

    if ([OnepagerEntityType.Initiative, OnepagerEntityType.InitiativeHeader].includes(entityIndex.entity)) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        if (parentPillar && initiativeEntity) {
            if (initiativeEntity.initiativeId) {
                parentPillar.initiatives = parentPillar.initiatives?.map(initiative => initiative !== initiativeEntity ? initiative : {
                    ...initiativeEntity,
                    status: JsonStratInitiativeStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                parentPillar.initiatives = parentPillar.initiatives?.filter(initiative => initiative !== initiativeEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.InitiativeSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        const successLookEntity: JsonStratSuccessLook = initiativeEntity.successLooks?.find((val, index) => index === entityIndex.subEntityIndex)!;
        if (initiativeEntity && successLookEntity) {
            if (successLookEntity.successLookId) {
                initiativeEntity.successLooks = initiativeEntity.successLooks?.map(initiative => initiative !== successLookEntity ? initiative : {
                    ...successLookEntity,
                    status: JsonStratSuccessLookStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                initiativeEntity.successLooks = initiativeEntity.successLooks?.filter(initiative => initiative !== successLookEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.InitiativeTeam) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const initiativeEntity: JsonStratInitiative = parentPillar.initiatives?.find((val, index) => index === entityIndex.entityIndex)!;
        const teamEntity: JsonStratTeam = initiativeEntity.teams?.find((val, index) => index === entityIndex.subEntityIndex)!;
        if (initiativeEntity && teamEntity) {
            if (teamEntity.stratTeamId) {
                initiativeEntity.teams = initiativeEntity.teams?.map(initiative => initiative !== teamEntity ? initiative : {
                    ...teamEntity,
                    status: JsonStratTeamStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                initiativeEntity.teams = initiativeEntity.teams?.filter(initiative => initiative !== teamEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.PillarSuccessLook) {
        const parentPillar: JsonStratPillar = newOnepager.pillars?.find((val, index) => index === entityIndex.parentEntityIndex)!;
        const successLookEntity: JsonStratSuccessLook = parentPillar.successLooks?.find((val, index) => index === entityIndex.entityIndex)!;
        if (parentPillar && successLookEntity) {
            if (successLookEntity.successLookId) {
                parentPillar.successLooks = parentPillar.successLooks?.map(successLook => successLook !== successLookEntity ? successLook : {
                    ...successLookEntity,
                    status: JsonStratSuccessLookStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                parentPillar.successLooks = parentPillar.successLooks?.filter(successLook => successLook !== successLookEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.Enabler) {
        const enablerEntity = newOnepager.enablers?.find((cvb, index) => index === entityIndex.entityIndex);
        if (enablerEntity) {
            if (enablerEntity.enablerId) {
                newOnepager.enablers = newOnepager.enablers?.map(enabler => enabler !== enablerEntity ? enabler : {
                    ...enabler,
                    status: JsonStratEnablerStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                newOnepager.enablers = newOnepager.enablers?.filter(enabler => enabler !== enablerEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.EnablerTeam) {
        const enablerEntity = newOnepager.enablers?.find((cvb, index) => index === entityIndex.entityIndex);
        const teamEntity: JsonStratTeam = enablerEntity?.teams?.find((val, index) => index === entityIndex.subEntityIndex)!;
        if (enablerEntity && teamEntity) {
            if (teamEntity.stratTeamId) {
                enablerEntity.teams = enablerEntity.teams?.map(initiative => initiative !== teamEntity ? initiative : {
                    ...teamEntity,
                    status: JsonStratTeamStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                enablerEntity.teams = enablerEntity.teams?.filter(initiative => initiative !== teamEntity);
            }
        }
    }

    if (entityIndex.entity === OnepagerEntityType.CVB) {
        const cvbEntity = newOnepager.cvbs?.find((cvb, index) => index === entityIndex.entityIndex);
        if (cvbEntity) {
            if (cvbEntity.cvbId) {
                newOnepager.cvbs = newOnepager.cvbs?.map(cvb => cvb !== cvbEntity ? cvb : {
                    ...cvb,
                    status: JsonStratCvbStatusEnum.Deleted,
                    isRemoved: true
                })
            } else {
                newOnepager.cvbs = newOnepager.cvbs?.filter(cvb => cvb !== cvbEntity);
            }
        }
    }

    return newOnepager;
}

export const processUpdateOnePagerParentPillar = (onepager: JsonStratOnepager, entityIndex: OnepagerEntityIndex, data: Partial<JsonStratPillar>) => {
    const newOnepager = { ...onepager };

    if (entityIndex.entity === OnepagerEntityType.Pillar) {
        const pillarEntity = newOnepager.pillars?.find((enabler, index) => index === entityIndex.entityIndex);
        if (pillarEntity) {
            pillarEntity.parentPillarId = data.parentPillarId;
            pillarEntity.parentEnablerId = data.parentEnablerId;
            pillarEntity.parentInitiativeId = data.parentInitiativeId;
        }
    }

    return newOnepager;
}

export const fixOnePagerData = (onepager?: JsonStratOnepager) => {
    if (!onepager) {
        return undefined;
    }
    return {
        ...onepager,
        pillars: onepager.pillars?.map((pillar: JsonStratPillar, index: number) => ({
            ...pillar,
            initiatives: pillar.initiatives?.map((initiative: JsonStratInitiative, initiativeIndex: number) => ({
                ...initiative,
                successLooks: initiative.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => ({
                    ...successLook,
                    orderNo: successLookIndex
                })),
                teams: initiative.teams?.map((team: JsonStratTeam, teamIndex: number) => ({
                    ...team,
                    orderNo: teamIndex
                })),
                orderNo: initiativeIndex
            })),
            successLooks: pillar.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => ({ ...successLook, orderNo: successLookIndex})),
            orderNo: index
        })),
        enablers: onepager.enablers?.map((enabler: JsonStratEnabler, index: number) => ({
            ...enabler,
            successLooks: enabler.successLooks?.map((successLook: JsonStratSuccessLook, successLookIndex: number) => ({
                ...successLook,
                orderNo: successLookIndex
            })),
            teams: enabler.teams?.map((team: JsonStratTeam, teamIndex: number) => ({
                ...team,
                orderNo: teamIndex
            })),
            orderNo: index
        })),
        cvbs: onepager.cvbs?.map((cvb: JsonStratCvb, index: number) => ({ ...cvb, orderNo: index })),
        allowedActions: undefined,
        changes: undefined
    }
}

export const parseArray = (serializedArray?: string, index?: number) => {
    let description = '';
    if (serializedArray) {
        try {
            const result = JSON.parse(serializedArray || '');
            if (result?.length && result.length === 2) {
                description = result[index || 0];
            }
        } catch(e) {
        }
    }
    return description;
}

export interface ErrorMessage {
    text: string,
    params: any
}

const MAX_TEAMS_PER_ONEPAGER = 5;

export const checkOnePagerData = (onepager?: JsonStratOnepager, teams?: JsonPoplistItem[]) => {

    if (!onepager) {
        return { success: false }
    }

    const messages: ErrorMessage[] = [];

    // check max teams per OnePager
    let teamsCounts = onepager.pillars?.filter(pillar => pillar.status === JsonStratPillarStatusEnum.Active).reduce((acc: any, pillar: JsonStratPillar, index: number) => {
        pillar.initiatives?.filter(initiative => initiative.status === JsonStratInitiativeStatusEnum.Active).forEach(initiative => {
            initiative.teams?.filter(team => team.status === JsonStratTeamStatusEnum.Active).forEach(team => {
                if (team.stratUserId) {
                    if (!acc[team.stratUserId]) {
                        acc[team.stratUserId] = 1;
                    } else {
                        acc[team.stratUserId]++;
                    }
                }
            });
        });
        return acc;
    }, {});

    teamsCounts = onepager.enablers?.filter(enabler => enabler.status === JsonStratEnablerStatusEnum.Active).reduce((acc: any, enabler: JsonStratEnabler, index: number) => {
        enabler.teams?.filter(team => team.status === JsonStratTeamStatusEnum.Active).forEach(team => {
            if (team.stratUserId) {
                if (!acc[team.stratUserId]) {
                    acc[team.stratUserId] = 1;
                } else {
                    acc[team.stratUserId]++;
                }
            }
        });
        return acc;
    }, teamsCounts);

    for (const [key, value] of Object.entries(teamsCounts)) {
        if ((value as number) > MAX_TEAMS_PER_ONEPAGER) {
            const team = teams?.find((item) => item.value + '' === key);
            if (team) {
                messages.push({ text: 'stratairu.messages.assignedTeamsError', params: { team: team.label, max: MAX_TEAMS_PER_ONEPAGER }});
            }
        }
    }

    return {
        success: !messages.length,
        messages
    }

}
