import { createTheme, ThemeProvider } from '@mui/material';
import React, {useEffect, useState} from 'react';
import { useAppTranslation } from '../services/i18n';
import {useAppDispatch} from '../store';
import {fetchAuthPing, processLogin} from '../store/auth';
import { initStaticCodebooks, LocalCodebookItem } from '../store/codebooks';
import {AppRouter, MicroSiteRouter} from './AppRouter';
import {isLiveSite, isMicroSite, noHeader} from './config';
import {useInterval} from "../hooks/useInterval";
import theme from "../assets/styles/theme";

function App() {

  const dispatch = useAppDispatch();
  const t = useAppTranslation();
  const [loginChecked, setLoginChecked] = useState<boolean>(false);

  useEffect(() => {
    const enums = t('enums', { returnObjects: true });
    let initEnums = {} as {[name: string]: LocalCodebookItem[]};
    Object.keys(enums).forEach((item:string) => {
      initEnums[item] = [];
      Object.keys(enums[item as any]).forEach((value: string) => {
        initEnums[item].push({ value, label: enums[item as any][value as any]});
      });
    });
    dispatch(initStaticCodebooks(initEnums));
    if (!isMicroSite) {
      dispatch(processLogin())
        .then(() => setLoginChecked(true)); // try session login (eg. page refresh)
    }
  }, [dispatch, t]);

  // heartbeat
  useInterval(() => {
    if (!isMicroSite && !noHeader) {
      console.log('Heartbeat...');
      dispatch(fetchAuthPing());
    }
  }, 1800000);  // every 30 minut heartbeat

  // 	<React.StrictMode> renders app twice in dev!
  return (
    <ThemeProvider theme={theme}>
      { isMicroSite ? <MicroSiteRouter /> : <>{ loginChecked && <AppRouter /> }</> }
    </ThemeProvider>
  )
}

export default App;
