import * as React from 'react';
import {PropsWithChildren, useCallback, useState} from 'react';
import {useSelector} from 'react-redux';
import {
    selectAuthStrategies,
    selectAuthUser, selectAuthUserAliases,
    selectStrategyContext,
    selectStrategyDashboard
} from '../../../store/selectors';
import styles from '../../../assets/styles/stratairu.module.css';
import {useNavigate} from "react-router";
import {ReactComponent as StratairuLogo} from '../../../assets/images/stratairu/stratairu-logo.svg';
import {useAppTranslation} from "../../../services/i18n";
import {
    JsonPoplistItem,
    JsonStrategyInfo,
    JsonStratOnepagerInfo,
    JsonStratOnepagerInfoStatusEnum
} from "../../../generated-api";
import {ReactComponent as ProfileIcon} from '../../../assets/images/stratairu/profile.svg';
import classnames from "classnames";
import StratairuCombo, {StatairuComboDirection} from "./StratairuCombo";
import {useTranslation} from "react-i18next";
import {fetchAuthLogout, switchAuthAlias} from "../../../store/auth";
import {useAppDispatch} from "../../../store";
import StratairuHelpModal from "./StratairuHelpModal";
import {UserAlias} from "../../../store/strategy";

interface MenuItem {
    label: string;
    url?: string;
    check: string;
    action?: () => void;
}

const isSelected = (check: string) => {
    return document.location?.pathname?.includes(check);
}

interface Props {
}

const langItems: JsonPoplistItem[] = [
    { value: 'cs', label: 'CZ' },
    { value: 'en', label: 'ENG' }
]

const StratairuMenu = ({ children }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();
    const { i18n } = useTranslation();
    const dispatch = useAppDispatch();
    const [showHelp, setShowHelp] = useState<boolean>(false);

    const strategies = useSelector(selectAuthStrategies);
    const user = useSelector(selectAuthUser);
    const userAliases = useSelector(selectAuthUserAliases);
    const strategyContext = useSelector(selectStrategyContext);
    const dashboard = useSelector(selectStrategyDashboard);

    const currentUserAlias = userAliases?.find((alias: UserAlias) => alias.userId === user?.userId);

    const handleLogout = useCallback(() => {
        dispatch(fetchAuthLogout());
    }, [dispatch]);

    const handleChangeAlias = useCallback((value: string) => {
        // switch only when different user is set
        if (currentUserAlias?.userId !== +value) {
            dispatch(switchAuthAlias(+value));
        }
    }, [dispatch, currentUserAlias]);

    const navigate = useNavigate();

    const myOnePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => op.userId === user.userId);
    const companyOnePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => !op.parentUserId);

    const menuItems: MenuItem[] = [
        { label: 'stratairu.menu.home', url: '/stratairu/{{strategyId}}/home', check: '/home' },
        myOnePager?.onepagerId && { label: 'stratairu.menu.overview', url: '/stratairu/{{strategyId}}/overview', check: '/overview' },
        (myOnePager?.onepagerId || (companyOnePager?.onepagerId && [JsonStratOnepagerInfoStatusEnum.Public, JsonStratOnepagerInfoStatusEnum.Finished].includes(companyOnePager?.status))) && { label: myOnePager?.onepagerId ? 'stratairu.menu.my' : 'stratairu.menu.company', url: '/stratairu/{{strategyId}}/onepager/{{onePagerId}}', check: '/onepager' },
        { label: 'stratairu.menu.help', action: () => setShowHelp(true), check: '/help' },
    ].filter(item => !!item);

	return (
        <div className={styles.menuContainer}>
            <div className={styles.menuTopContainer}>
                <StratairuLogo style={{ marginTop: '50px', marginBottom: '20px' }}/>
                { !!strategyContext && <div className={styles.optionsContainer}>
                    { menuItems.map((item: MenuItem) => <div key={item.label} className={classnames(styles.option, isSelected(item.check) ? styles.selectedOption : undefined)} onClick={() => item.url ? navigate(t(item.url, { strategyId: strategyContext.strategy.strategyId, onePagerId: myOnePager?.onepagerId || companyOnePager?.onepagerId })!) : item.action && item.action() } dangerouslySetInnerHTML={{ __html: t(item.label) || '' }}></div>) }
                </div> }
            </div>
            <div className={styles.menuBottomContainer}>
                <div style={{ width: '40px', marginBottom: '20px' }}>
                    <ProfileIcon/>
                </div>
                <div>{ user?.firstName }</div>
                <div>{ user?.lastName }</div>
                <div>{ currentUserAlias?.teamName }</div>
                { userAliases?.length > 1 && <div style={{ width: '100%', paddingTop: '0px' }}>
                    <StratairuCombo
                        label={t('stratairu.menu.switchAlias') }
                        items={userAliases.map((alias: UserAlias) => ({ value: alias.userId, label: alias.teamName })) }
                        selected={currentUserAlias.userId}
                        onSelect={handleChangeAlias}
                        direction={StatairuComboDirection.Down}
                    />
                </div> }

                { strategyContext.roles?.map((role: string) => <div className={styles.roleBadge}>{ t('enums.strategyRoles.' + role) }</div>) }
                <div onClick={handleLogout} className={styles.logoutLink}>logout</div>
                <div style={{ width: '80px', paddingTop: '20px' }}>
                    <StratairuCombo
                        items={langItems}
                        selected={i18n.language}
                        onSelect={(value: string) => i18n.changeLanguage(value)}
                        direction={StatairuComboDirection.Down}
                    />
                </div>
            </div>
            <div>
                <div className={styles.currentStrategy}>{ strategyContext?.strategy?.title }</div>
                { strategies?.length > 1 && <StratairuCombo
                    label={t('stratairu.menu.switchStrategy') }
                    items={strategies?.map((strategy: JsonStrategyInfo) => ({ value: strategy.strategyId, label: strategy.title })) }
                    selected={strategyContext?.strategy?.strategyId}
                    onSelect={(value: string) => navigate('/stratairu/' + value + '/home')}
                    direction={ StatairuComboDirection.Up }
                /> }
            </div>
            { showHelp && <StratairuHelpModal onClose={() => setShowHelp(false)}/> }
        </div>
	);
}

export default StratairuMenu;
