import {useAppDispatch} from '../../../store';
import {useParams} from "react-router-dom";
import {useEffect} from 'react';
import {JsonStratOnepagerInfo} from '../../../generated-api';
import PageHeader from '../../../components/layout/PageHeader';
import {Container, Grid} from '@mui/material';
import {fetchDashboard, fetchStrategyContext} from "../../../store/strategy";
import OnePagerCard from "./OnePagerCard";
import {useSelector} from "react-redux";
import {selectAuthUser, selectStrategyContext, selectStrategyDashboard} from "../../../store/selectors";
import StrategyPillarSupport from "./StrategyPillarSupport";
import SubtreeOnePagersStatus from "./SubtreeOnePagersStatus";
import SearchOnePager from "./SearchOnePager";
import {getOnePagerLevel} from "../../../helpers/strategy";
import {useAppTranslation} from "../../../services/i18n";
import Typography from "@mui/material/Typography";

const StrategyDashboardPage = () => {

  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const dashboard = useSelector(selectStrategyDashboard);
  const strategyContext = useSelector(selectStrategyContext);
  const user = useSelector(selectAuthUser);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchStrategyContext(+id));
      dispatch(fetchDashboard({ strategyId: +id }));
    }
  }, [dispatch, id]);

  const myOnePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => op.userId === user.userId);
  let firstSubOnePager = true;

  return (
    <>
	{ !!dashboard && !!strategyContext.strategy && myOnePager &&
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <PageHeader title={`Strategie - ${strategyContext.strategy.title as string} - dashboard`} showBack></PageHeader>
          </Grid>
          <Grid item md={5} xs={12}>
            <SearchOnePager strategyId={strategyContext.strategy.strategyId} />
            { dashboard?.hierarchyOnePagers.map((op: JsonStratOnepagerInfo) => {
              const level = getOnePagerLevel(op);
              const titles = [];
              if (level === 1) {
                titles.push(t('Firemní strategie'));
              }
              if (op.userId === myOnePager.parentUserId) {
                titles.push(t('Můj přímý nadřízený'));
              }
              if (op.userId === user.userId) {
                titles.push(t('Moje strategie'));
              }
              if (op.parentUserId === user.userId && firstSubOnePager) {
                titles.push(t('Podřízení'));
                firstSubOnePager = false;
              }
              return <div key={op.onepagerId}>
                { !!titles.length && <Typography component="div" variant="h6" color="black">{ titles.join(' / ') }</Typography> }
                <OnePagerCard onepager={op}></OnePagerCard>
                </div>
            }) }
          </Grid>
          <Grid item md={7} xs={12}>
            <StrategyPillarSupport subtree={dashboard?.subtreeOnePagers}></StrategyPillarSupport>
            <SubtreeOnePagersStatus subtree={dashboard?.subtreeOnePagers}></SubtreeOnePagersStatus>
          </Grid>
        </Grid>
      </Container>    
    }
    </>
	);
}

export default StrategyDashboardPage;
