import {Container} from '@mui/material';
import {useAppTranslation} from '../../services/i18n';

const RelayInfoPage = () => {
    const t = useAppTranslation();

  // https://mui.com/material-ui/getting-started/usage/
	return (
		<Container>
            <div style={{ color: 'white', marginTop: '50px' }}>{t('Pro přístup k aktuálnímu vysílání použijte link doručený emailem.')}</div>
        </Container>
	);
}

export default RelayInfoPage;
