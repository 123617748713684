import {useAppDispatch} from '../../store';
import {useNavigate, useParams} from "react-router-dom";
import {useCallback, useEffect, useState} from 'react';
import {JsonUser} from '../../generated-api';
import PageHeader from '../../components/layout/PageHeader';
import {userFactory} from '../../model/factories';
import UserForm from '../../forms/UserForm';
import {fetchUser, saveUser} from '../../store/users';
import {Container, Grid} from '@mui/material';
import { addMessage } from '../../store/localApp';
import { useAppTranslation } from '../../services/i18n';

interface Props {
  createNew?: boolean;
}

const EditUserPage = ({ createNew }: Props) => {

  const t = useAppTranslation();

	const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [user, setUser] = useState<JsonUser | null>(null);

  const { id } = useParams();
	useEffect(() => {
    if (createNew) {
      setUser(userFactory());
      return;
    } 
    if (id) {
      dispatch(fetchUser(+id))
        .then((result) => setUser((result?.payload as any).data));
    }
	}, [dispatch, createNew, id]);

  const handleSave = useCallback(async (data: JsonUser) => {
    const result = await dispatch(saveUser(data));
    if ('error' in result) {
      dispatch(addMessage({
        severity: 'error',
        title: result.error.message === 'ENTITY_ALREADY_EXISTS' ?  t('Uživatel již existuje.') : t('Chyba při vytváření uživatele.'),
      }));
      return;
    }
    navigate('/users');
  }, [navigate, dispatch, t])

  const handleCancel = useCallback(() => {
    navigate('/users');
  }, [navigate])

  return (
    <>
		{ !!user &&
      <Container>
        <Grid container>
          <Grid item xs={12}>
            <PageHeader title={user.lastName as string} showBack></PageHeader>
          </Grid>
          <Grid item xs={12}>
            <UserForm data={user} onSave={handleSave} onCancel={handleCancel} cardLayout></UserForm>
            { /* <pre>{ JSON.stringify(user, null, 2) }</pre> */ }
          </Grid>
        </Grid>  
      </Container>    
    }
    </>
	);
}

export default EditUserPage;
