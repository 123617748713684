/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonCompanyUserInfo
 */
export interface JsonCompanyUserInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyUserInfo
     */
    allowedActions?: Array<JsonCompanyUserInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUserInfo
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUserInfo
     */
    companyUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUserInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUserInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUserInfo
     */
    phone?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUserInfo
     */
    roleCode?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUserInfo
     */
    roleId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUserInfo
     */
    roleTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUserInfo
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUserInfo
     */
    userStatus?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyUserInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonCompanyUserInfoFromJSON(json: any): JsonCompanyUserInfo {
    return JsonCompanyUserInfoFromJSONTyped(json, false);
}

export function JsonCompanyUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyUserId': !exists(json, 'companyUserId') ? undefined : json['companyUserId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'roleCode': !exists(json, 'roleCode') ? undefined : json['roleCode'],
        'roleId': !exists(json, 'roleId') ? undefined : json['roleId'],
        'roleTitle': !exists(json, 'roleTitle') ? undefined : json['roleTitle'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userStatus': !exists(json, 'userStatus') ? undefined : json['userStatus'],
    };
}

export function JsonCompanyUserInfoToJSON(value?: JsonCompanyUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyId': value.companyId,
        'companyUserId': value.companyUserId,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
        'roleCode': value.roleCode,
        'roleId': value.roleId,
        'roleTitle': value.roleTitle,
        'userId': value.userId,
        'userStatus': value.userStatus,
    };
}

