import { createSelector } from "@reduxjs/toolkit";
import {
    JsonPollInfo, JsonPoplistItem,
    JsonRelayItem,
    JsonRelayItemItemTypeEnum, JsonStratCommentInfo, JsonStratCommentInfoStatusEnum, JsonStrategyInfo,
    JsonStratOnepagerInfo,
    JsonStratOnepagerStatusEnum
} from "../generated-api";
import {RootState} from '../index';
import { RelayPollListItem } from "../pages/admin/relayPage/components/RelayPoolList";
import { CodebookState } from "./codebooks";
import {LocalAppState} from './localApp';

export const featureAuth = (state: RootState) => state.auth;
export const featureCodebooks = (state: RootState) => state.codebooks;
export const featureCompanies = (state: RootState) => state.companies;
export const featureSeries = (state: RootState) => state.series;
export const featureUsers = (state: RootState) => state.users;
export const featureRelay = (state: RootState) => state.relay;
export const featureRelays = (state: RootState) => state.relays;
export const featureStrategies = (state: RootState) => state.strategies;
export const featureStrategy = (state: RootState) => state.strategy;


export const selectCodebooks = createSelector([featureCodebooks], (codebooks: CodebookState) => codebooks);
export const selectAppMessages = createSelector([(state: RootState) => state.localApp], (localApp: LocalAppState) => localApp.messages);
export const selectAppLoading = createSelector([(state: RootState) => state.localApp], (localApp: LocalAppState) => localApp.showLoader);

export const selectAuthIsLogged = createSelector([featureAuth], (auth) => !!auth.user && !!auth.user.userId);

export const selectAuthIsAdmin = createSelector([featureAuth], (auth) => !!auth.user && auth.user.roleCode === 'ADMIN');

export const selectAuthStrategies = createSelector([featureAuth], (auth) => !!auth.user && auth.strategies && auth.strategies.filter((strategy: JsonStrategyInfo) => !strategy.serieId));

export const selectAuthFavourites = createSelector([featureAuth], (auth) => auth?.user?.userData?.favouriteOnepagerIds || [] );
export const selectAuthUser = createSelector([featureAuth], (auth) => auth.user);

export const selectAuthUserAliases = createSelector([featureStrategy], (strategy) => {
    return strategy.context.userAliases;
});

export const selectCompanies = createSelector([featureCompanies], (companies) => companies.items);

export const selectCompaniesFilter = createSelector([featureCompanies], (companies) => companies.filter);

export interface PagingData {
    currentPage: number,
    pages: number,
    count: number,
    rowsPerPage: number,
    startItem: number,
    finishItem: number,
}

const getPagingData = (result: any): PagingData => {
    return {
        currentPage: result.currentPage,
        pages: Math.floor(result.count / result.rowsPerPage) + ( result.count % result.rowsPerPage !== 0 ? 1 : 0 ),
        count: result.count,
        rowsPerPage: result.rowsPerPage,
        startItem: (result.currentPage - 1) * result.rowsPerPage + 1,
        finishItem: (result.currentPage - 1) * result.rowsPerPage + (result.items?.length || 0)
    }
}

export const selectCompaniesPaging = createSelector([featureCompanies], (companies) => getPagingData(companies));

export const selectUsers = createSelector([featureUsers], (users) => users.items);
export const selectUsersFilter = createSelector([featureUsers], (users) => users.filter);
export const selectUsersPaging = createSelector([featureUsers], (users) => getPagingData(users));

export const selectSeries = createSelector([featureSeries], (series) => series.items);
export const selectSeriesPaging = createSelector([featureSeries], (series) => getPagingData(series));

export const selectRelays = createSelector([featureRelays], (relays) => relays.items);
export const selectRelaysPaging = createSelector([featureRelays], (relays) => getPagingData(relays));
export const selectRelayIsActive = createSelector([featureRelays], (relays) => relays.isActive);
export const selectRelay = createSelector([featureRelay], (relay) => relay.info?.data);
export const selectRelayCurrent = createSelector([featureRelay], (relay) => relay.current?.items);
export const selectRelayGroups = createSelector([featureRelay], (relay) => relay.group?.items);
export const selectRelayPolls = createSelector([featureRelay], (relay) => relay.poll?.items);
export const selectRelayPollsDef = createSelector([featureRelay], (relay) => relay.pollDef?.items);
export const selectRelayPollsWithStatus = createSelector([selectRelayPolls, selectRelayCurrent], 
    (polls, current): RelayPollListItem[] => {
        return (polls || [])
            .map((poll: JsonPollInfo) => {
                const pollQuestionActiveItem = current?.find((item: JsonRelayItem) => item.poll?.pollId === poll.pollId && item.itemType === JsonRelayItemItemTypeEnum.PollQuestion && item.status === 'ACTIVE');
                const pollResultActiveItem = current?.find((item: JsonRelayItem) => item.poll?.pollId === poll.pollId && item.itemType === JsonRelayItemItemTypeEnum.PollResults && item.status === 'ACTIVE');
                const pollLastResultItem = pollResultActiveItem || current?.findLast((item: JsonRelayItem) => item.poll?.pollId === poll.pollId);
                const pollQuestionCount = current?.filter((item: JsonRelayItem) => item.poll?.pollId === poll.pollId && item.itemType === JsonRelayItemItemTypeEnum.PollQuestion && item.status === 'FINISHED')?.length;
                const pollResultCount = current?.filter((item: JsonRelayItem) => item.poll?.pollId === poll.pollId && item.itemType === JsonRelayItemItemTypeEnum.PollResults && item.status === 'FINISHED')?.length;

                return ({ 
                    ...poll, 
                    pollQuestionActiveItem,
                    pollResultActiveItem,
                    pollQuestionCount,
                    pollResultCount,
                    pollLastResultItem,
                    pollQuestionStatus: !!pollQuestionActiveItem ? 'ACTIVE' : 'AVAILABLE', // zatím takto, aby se dala otázka opakovaně zobrazit
                    pollResultStatus: !!pollResultActiveItem ? 'ACTIVE' : 'AVAILABLE', // odpovědi můžeme zobrazit kdykoliv
                });
            });
    }
);

export const selectStrategies = createSelector([featureStrategies], (strategies) => strategies.items);
export const selectStrategiesPaging = createSelector([featureStrategies], (strategies) => getPagingData(strategies));

export const selectStrategyContext = createSelector([featureStrategy, featureAuth], (strategy, auth) => {
    const strategyId = strategy.context.strategy?.strategyId;
    const roles = (auth.strategyRoles && auth.strategyRoles[strategyId]) || [];
    return {
        ...strategy.context,
        roles
    }
});

export const selectStrategyDashboard = createSelector([featureStrategy], (strategy) => strategy.dashboard);

export const selectStrategyOnePager= createSelector([featureStrategy], (strategy) => strategy.onepager.data);

export const selectStrategyParentOnePager= createSelector([featureStrategy], (strategy) => strategy.onepager.parent);

export const selectStrategySelfOnePager = createSelector([featureStrategy],
    (strategy) => strategy.onepager?.hierarchyOnePagers?.find((op: JsonStratOnepagerInfo) => op.onepagerId === strategy.onepager.data?.onepagerId));

export const selectStrategyRootOnePager = createSelector([featureStrategy],
    (strategy) => strategy.onepager?.hierarchyOnePagers?.find((op: JsonStratOnepagerInfo) => !op.parentUserId));

export const selectStrategyUserOnePager = createSelector([featureStrategy, selectStrategyOnePager],
    (strategy, onepager) => strategy.onepager?.hierarchyOnePagers?.find((op: JsonStratOnepagerInfo) => op.userId === onepager.userId));

/*
export const selectStrategyParentOnePager = createSelector([featureStrategy, selectStrategyOnePager],
    (strategy, onepager) => {
        const userOnePagerInfo = strategy.onepager?.hierarchyOnePagers?.find((op: JsonStratOnepagerInfo) => op.userId === onepager?.userId);
        if (userOnePagerInfo) {
            return strategy.onepager?.hierarchyOnePagers?.find((op: JsonStratOnepagerInfo) => op.userId === userOnePagerInfo.parentUserId);
        }
        return undefined;
    });
*/

export const selectStrategyOnePagerEditable= createSelector([featureStrategy, featureAuth],
    (strategy, auth) => strategy.onepager?.data?.userId === auth.user?.userId && strategy.onepager?.data?.status !== JsonStratOnepagerStatusEnum.Finished);

export const selectStrategyOnePagerActiveIndex= createSelector([featureStrategy], (strategy) => strategy.onepager.activeIndex);

export const selectStrategyOnePagerComments= createSelector([featureStrategy], (strategy) => strategy.onepager.comments.filter((c: JsonStratCommentInfo) => c.status === JsonStratCommentInfoStatusEnum.Active));

export const selectStrategyDashboardOnePagersType= createSelector([featureStrategy], (strategy) => strategy.dashboard.onePagersType + (strategy.dashboard.onePagersParam ? '_' + strategy.dashboard.onePagersParam : ''));

export const selectStrategyDashboardOnePagers= createSelector([featureStrategy], (strategy) => strategy.dashboard.onePagers);

export const selectStrategyDashboardFavouriteOnePagers= createSelector([featureStrategy], (strategy) => strategy.dashboard.favouriteOnePagers);

export const selectOnePagerSubTeamsCombo = createSelector([featureStrategy, selectStrategyOnePager],
    (strategy, onepager): JsonPoplistItem[] => {
        const teams = (strategy.onepager?.hierarchyOnePagers?.filter((op: JsonStratOnepagerInfo) => op.parentUserId === onepager.userId) || []).map((op: JsonStratOnepagerInfo) => ({ label: op.teamName, value: op.stratUserId }) as JsonPoplistItem);
        return teams;
    });
