/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonCompanyDepartment,
    JsonCompanyDepartmentFromJSON,
    JsonCompanyDepartmentFromJSONTyped,
    JsonCompanyDepartmentToJSON,
} from './JsonCompanyDepartment';

/**
 * 
 * @export
 * @interface ApiResultOfJsonCompanyDepartment
 */
export interface ApiResultOfJsonCompanyDepartment {
    /**
     * 
     * @type {JsonCompanyDepartment}
     * @memberof ApiResultOfJsonCompanyDepartment
     */
    data?: JsonCompanyDepartment;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonCompanyDepartment
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResultOfJsonCompanyDepartment
     */
    messageValues?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonCompanyDepartment
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonCompanyDepartment
     */
    result?: ApiResultOfJsonCompanyDepartmentResultEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApiResultOfJsonCompanyDepartmentResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

export function ApiResultOfJsonCompanyDepartmentFromJSON(json: any): ApiResultOfJsonCompanyDepartment {
    return ApiResultOfJsonCompanyDepartmentFromJSONTyped(json, false);
}

export function ApiResultOfJsonCompanyDepartmentFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResultOfJsonCompanyDepartment {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : JsonCompanyDepartmentFromJSON(json['data']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageValues': !exists(json, 'messageValues') ? undefined : json['messageValues'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function ApiResultOfJsonCompanyDepartmentToJSON(value?: ApiResultOfJsonCompanyDepartment | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': JsonCompanyDepartmentToJSON(value.data),
        'message': value.message,
        'messageValues': value.messageValues,
        'requestId': value.requestId,
        'result': value.result,
    };
}

