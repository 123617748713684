import {useCallback} from "react";
import {useParams} from "react-router-dom";
import LiveLayout from "../../components/layout/LiveLayout";
import {JsonEpisode} from "../../generated-api";
import LiveEpisodesList, {EpisodeListMode} from "./components/LiveEpisodesList";

const workbooks = [
    'workbook-1-clarity-atairu-leadership-tv.pdf',
    'workbook-2-resistance-atairu-leadership-tv.pdf',
    'workbook-3-availability-atairu-leadership-tv.pdf',
    'workbook-4-continuity-atairu-leadership-tv.pdf'
]

const LiveArchivesPage = () => {

    const { code } = useParams();

    const handleSelectEpisode = useCallback((episode: JsonEpisode, index: number) => {
        document.location = `https://live.atairuleadership.tv/${code}/archive-${index+1}`;
    }, [code])

    const handleWorkbook = useCallback((episode: JsonEpisode, index: number) => {
        console.log('PDF URL:' + process.env.PUBLIC_URL + '/docs/' + workbooks[index]);
        window.open(process.env.PUBLIC_URL + '/docs/' + workbooks[index], '_blank');
    }, [])

    return (
        <LiveLayout
            title="Archiv"
            subtitle="Záznamy předchozích epizod.<br/>Workbooky ke stažení."
            backButton
        >
            <LiveEpisodesList onClick={handleSelectEpisode} onClickWorkbook={handleWorkbook} mode={EpisodeListMode.Archives}/>
        </LiveLayout>);
}

export default LiveArchivesPage;
