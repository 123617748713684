import * as React from 'react';

interface Props {
    onClick: () => void;
}

const StratairuExportIcon = ({ onClick }: Props) => {
	
	return (
        <svg xmlns="http://www.w3.org/2000/svg" width="43.438" height="43.78" viewBox="0 0 60 59" onClick={onClick} style={{ cursor: 'pointer' }}>
            <defs>
                <clipPath id="clip-path-ei">
                    <rect width="35.879" height="33.099" fill="none"/>
                </clipPath>
            </defs>
            <circle cx={30} cy={30} r={30} fill="var(--strat-blue)"></circle>
            <g transform="translate(12,12) scale(1.1,1.1)">
                <g transform="translate(0)" clipPath="url(#clip-path-ei)">
                    <path id="Path_326" data-name="Path 326" d="M33.1,11.248v10.6a.276.276,0,0,0-.05.078,3.436,3.436,0,0,1-3.577,2.9H26.2v.4c0,1.411,0,2.822,0,4.232a4.439,4.439,0,0,1-.049.772A3.425,3.425,0,0,1,22.684,33.1q-6.074.008-12.148,0A3.444,3.444,0,0,1,6.9,29.472c0-1.432,0-2.865,0-4.3,0-.113-.009-.225-.015-.352-1.138,0-2.236,0-3.334,0A3.446,3.446,0,0,1,0,21.274Q0,16.557,0,11.84A3.447,3.447,0,0,1,3.568,8.275c.991,0,1.982,0,2.973,0H6.9c0-.177,0-.3,0-.432,0-1.519-.009-3.037.013-4.555A3.248,3.248,0,0,1,7.985.942,3.877,3.877,0,0,1,9.892,0H23.209c.017.013.032.033.051.037A3.445,3.445,0,0,1,26.2,3.772q0,2.068,0,4.137v.365h.4c1.034,0,2.069-.01,3.1,0a3.43,3.43,0,0,1,3.232,2.39c.062.191.113.386.17.579M26.2,23.432c.146.007.252.017.357.017q1.454,0,2.908,0a2.1,2.1,0,0,0,2.261-2.257q0-4.637,0-9.275a2.1,2.1,0,0,0-2.281-2.269q-2.148,0-4.3,0-10.777,0-21.553,0a2.087,2.087,0,0,0-2.218,2.233q0,4.67,0,9.339A2.091,2.091,0,0,0,3.6,23.449c.991,0,1.982,0,2.973,0,.1,0,.205-.012.328-.02v-.389c0-1.433,0-2.865,0-4.3A.7.7,0,0,1,7.3,18a1.457,1.457,0,0,1,.537-.076q8.709-.005,17.418,0c.086,0,.173,0,.258,0a.679.679,0,0,1,.678.714c.011.463,0,.926,0,1.39,0,1.119,0,2.239,0,3.4m-1.374-4.094H8.276v.377q0,4.863,0,9.727a2.1,2.1,0,0,0,2.3,2.281H22.53a2.1,2.1,0,0,0,2.295-2.283q0-4.863,0-9.727Zm0-11.08V7.993c0-1.487,0-2.974,0-4.46a2.067,2.067,0,0,0-2.16-2.156q-6.109-.01-12.217,0a2.07,2.07,0,0,0-2.169,2.18c0,1.465,0,2.93,0,4.4v.307Z" transform="translate(0)" fill="#fff"/>
                    <path id="Path_327" data-name="Path 327" d="M112.394,243.245c-1.345,0-2.69,0-4.036,0a.69.69,0,0,1-.755-.518.682.682,0,0,1,.681-.851c.527-.013,1.055,0,1.582,0h6.392c.086,0,.172,0,.258,0a.7.7,0,0,1,.713.683.68.68,0,0,1-.7.686c-.538.011-1.076,0-1.614,0h-2.518" transform="translate(-96.543 -217.048)" fill="#fff"/>
                    <path id="Path_328" data-name="Path 328" d="M112.449,268.819h3.842c.108,0,.216,0,.323.005a.68.68,0,0,1,.655.667.694.694,0,0,1-.631.7,2.038,2.038,0,0,1-.226.005H108.47c-.065,0-.13,0-.194,0a.679.679,0,0,1-.648-.706.645.645,0,0,1,.656-.656c.818-.014,1.636-.007,2.454-.008h1.711" transform="translate(-96.583 -241.233)" fill="#fff"/>
                    <path id="Path_329" data-name="Path 329" d="M109.692,214.953c.42,0,.84,0,1.26,0,.489.007.805.288.8.7s-.319.685-.812.688q-1.26.008-2.521,0c-.5,0-.8-.274-.793-.7s.3-.682.8-.688c.42-.005.84,0,1.26,0" transform="translate(-96.583 -192.893)" fill="#fff"/>
                </g>
            </g>
        </svg>
	);
}

export default StratairuExportIcon;
