import {PropsWithChildren, useCallback} from 'react';
import {Grid} from '@mui/material';
import {Button} from '../../../components/layout/Button';
import {useAppTranslation} from '../../../services/i18n';
import {JsonStratOnepagerInfo, JsonStratOnepagerStatusEnum} from "../../../generated-api";
import {useAppDispatch} from "../../../store";
import {setOnepagerStatus} from "../../../store/strategy";
import {addMessage} from "../../../store/localApp";
import {OnePagerMode} from "./OnePagerPage";

interface Props {
    onePager?: JsonStratOnepagerInfo;
    onSave?: (status: JsonStratOnepagerStatusEnum) => Promise<void>;
    mode?: OnePagerMode;
}

const OnePagerButtons = ({ onePager, onSave, mode }: PropsWithChildren<Props>) => {

  const t = useAppTranslation();

  const dispatch = useAppDispatch();

  const handleSave = useCallback(async (status: JsonStratOnepagerStatusEnum) => {
      console.log({ status });
      if (status === JsonStratOnepagerStatusEnum.Finished && onePager?.parentUserId) {
          dispatch(addMessage({
              severity: 'error',
              title: t('Pilíře nejsou navázané na pilíře nebo enablery nadřízeného.'),
          }));
          return;
      }
      await dispatch(setOnepagerStatus(status));
      onSave && await onSave(status);
  }, [onePager, onSave, dispatch, t])

    // TODO: uncomment
    // TODO: remove finished at first button
    // const status = (onePager?.status || JsonStratOnepagerStatusEnum.Draft) as JsonStratOnepagerStatusEnum;
    // const buttonsCount = status === JsonStratOnepagerStatusEnum.Public ? 2 : 3;

    const status = JsonStratOnepagerStatusEnum.Draft;
    const buttonsCount = 3;

  return (
      <Grid container sx={{ paddingBottom: '20px' }}>
          { mode === OnePagerMode.Strategy && /*[JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft].includes(status) &&*/ <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
              <Button variant="contained" color="secondary" fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Draft)}>{t('onePager.buttons.save')}</Button>
          </Grid> }
          { mode === OnePagerMode.Strategy && [JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.Public].includes(status) && <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
              <Button variant="contained" color="secondary" fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Public)}>{t('onePager.buttons.publish')}</Button>
          </Grid> }
          { mode === OnePagerMode.Strategy && [JsonStratOnepagerStatusEnum.New, JsonStratOnepagerStatusEnum.Draft, JsonStratOnepagerStatusEnum.Public].includes(status) && <Grid item xs={12} md={12/buttonsCount} style={{ padding: '20px 20px 0px 20px' }}>
              <Button variant="contained" color="success" fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Finished)}>{t('onePager.buttons.confirm')}</Button>
          </Grid> }
          { mode === OnePagerMode.Workzone && <Grid item xs={12} md={12} style={{ padding: '20px 20px 0px 20px' }}>
              <Button variant="contained" color="success" fullWidth onClick={() => handleSave(JsonStratOnepagerStatusEnum.Draft)}>{t('onePager.buttons.save')}</Button>
          </Grid> }
      </Grid>
	);
}

export default OnePagerButtons;
