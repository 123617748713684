import {createAsyncThunk, createSlice, PayloadAction,} from '@reduxjs/toolkit'
import { RootState } from '..';
import {API_CONFIG} from '../app/api-config';
import {GetRelayCountUsingGETRequest, GetRelayListUsingGETRelayStatusEnum, GetUserListUsingGETRequest, JsonRelay, JsonRelayInfo, JsonRelayStatusEnum, RelayControllerApi} from '../generated-api';

const relayApi = new RelayControllerApi(API_CONFIG);

export const fetchRelays = createAsyncThunk('relays/list', async (arg: { page?: number, offset?: number }, thunkApi) => {
	
	const { currentPage, rowsPerPage, filter } = (thunkApi.getState() as RootState).relays;

	const params = {
		start: arg.page ? (arg.page - 1) * rowsPerPage : ((currentPage - 1) + (arg?.offset || 0)) * rowsPerPage,
		rows: rowsPerPage,
		...filter
	} as GetUserListUsingGETRequest;
	
	return await relayApi.getRelayListUsingGET(params);
});

export const fetchRelaysCount = createAsyncThunk('relays/count', async (_: void, thunkApi) => {

	const { filter } = (thunkApi.getState() as RootState).relays;

	const params = {
		...filter
	} as GetUserListUsingGETRequest;

	return await relayApi.getRelayCountUsingGET(params);
});

export const fetchRelaysCombo = createAsyncThunk('relays/fetchCombo', async () => {
	return await relayApi.getRelayListUsingGET({});
});

export const fetchActiveRelay = createAsyncThunk('relays/fetchCombo', async () => {
	return await relayApi.getRelayListUsingGET({ relayStatus: GetRelayListUsingGETRelayStatusEnum.Active });
});

export const saveRelay = createAsyncThunk('relays/save', async (relay: JsonRelay) => {
	return await relayApi.saveRelayUsingPOST({ json: relay });
});

export const startRelay = createAsyncThunk('relays/start', async (relay: JsonRelay) => {
	const { finishedAt, startedAt, ...restRelay } = relay;
	return await relayApi.saveRelayUsingPOST({ json: { ...restRelay, status: JsonRelayStatusEnum.Active }});
}); 

export const stopRelay = createAsyncThunk('relays/stop', async (relay: JsonRelay) => {
	const { finishedAt, startedAt, ...restRelay } = relay;
	return await relayApi.saveRelayUsingPOST({ json: { ...restRelay, status: JsonRelayStatusEnum.Finished }});
}); 

export type RelaysState = {
	loading: boolean;
	rowsPerPage: number;
	currentPage: number;
	count: number;
	isActive: boolean;
	items: JsonRelayInfo[];
	filter: GetRelayCountUsingGETRequest;
}

const initialState: RelaysState = {
	loading: false,
	rowsPerPage: 10,
	currentPage: 1,
	count: 0,
	isActive: false,
	items: [],
	filter: {}
};

export const relaysSlice = createSlice({
	name: 'relays',
	initialState,
	reducers: {
		setRelaysFilter: (state, action: PayloadAction<GetRelayCountUsingGETRequest>) => {
			state.filter = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchRelaysCount.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchRelaysCount.fulfilled, (state, action) => ({ ...state, count: action.payload.data || 0, loading: false }));
		builder.addCase(fetchRelaysCount.rejected, (state, action) => ({ ...state, count: 0, loading: false }));

		builder.addCase(fetchRelays.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchRelays.fulfilled, (state, action) => ({ ...state, items: action.payload.data || [], currentPage: action.meta.arg.page || state.currentPage, loading: false }));
		builder.addCase(fetchRelays.rejected, (state, action) => ({ ...state, items: [], loading: false }));
	
		builder.addCase(fetchActiveRelay.fulfilled, (state, action) => ({ ...state, isActive: !!action.payload.data?.length }));
	}
});

export const relaysReducer = relaysSlice.reducer;
export const { setRelaysFilter } = relaysSlice.actions;
