import {Button, Container, Tooltip} from '@mui/material';
import {useCallback} from 'react';
import {getLocale, useAppTranslation} from '../../services/i18n';
import {Link, useNavigate} from "react-router-dom";

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

import PageHeader from '../../components/layout/PageHeader';
import {fetchUsers, fetchUsersCount} from '../../store/users';
import PageableList from '../../components/layout/PagableList';
import { selectUsers, selectUsersFilter, selectUsersPaging } from '../../store/selectors';

import styles from '../../assets/styles/layout';
import { useAppDispatch } from '../../store';
import UserListFilter from '../../forms/UserListFilter';
import {apiBasePath, isProdEnv} from "../../app/config";
import { useSelector } from 'react-redux';
import { JsonUserInfo } from '../../generated-api/models';
import { purgeUser } from '../../store/services';

const ListUsersPage = () => {
	const t = useAppTranslation();
	const dispatch = useAppDispatch();

  const navigate = useNavigate();
  const filter = useSelector(selectUsersFilter);

  const handleNewUsers = useCallback(() => {
    navigate('/users/new');
  }, [navigate])

  const handleExportUsers = useCallback(async () => {
    // console.log({ url: `${apiBasePath(getLocale())}/rest-service/user/export` });
    const queryFilter = [];
    if (filter.companyId) {
        queryFilter.push('companyId=' + filter.companyId);
    }
    if (filter.serieId) {
        queryFilter.push('serieId=' + filter.serieId);
    }
    const url = `${apiBasePath(getLocale())}/rest-service/user/export${queryFilter.length ? '?' + queryFilter.join('&') : ''}`;
    window.open(url, '_blank');
  }, [filter]);

  const cols = [
    {
      title: t('Jméno / Email'), 
      style: { width: '45%' },
      renderer: (row: JsonUserInfo) => 
        <div>
          <div className={styles.listTitle}>
            <Link to={`/users/${row.userId}`} className={styles.link}>{row.firstName} {row.lastName}</Link>
          </div>
          <div>
            <div className={styles.listInfo}>{row.email}</div>
          </div>
        </div>
    },
    {
      title: t('Společnosti'), 
      style: { width: '45%' },
      renderer: (row: JsonUserInfo) => 
        <div className={styles.listInfo}>
          {row.companyName}
        </div>
    },
    {
      title: '',
      style: { width: '10%', display: 'flex', justifyContent: 'end' },
      renderer: (row: JsonUserInfo) => 
        <div>
          { !isProdEnv && <Tooltip title="Odstranit uživatele">
            <DeleteIcon color="primary" onClick={() => handleAction(row, 'DELETE')}/>
          </Tooltip> }
          <Tooltip title="Upravit uživatele">
            <Link to={`/users/${row.userId}`}><EditIcon color="primary"/></Link>
          </Tooltip>
        </div>
    }
  ]

  const handleAction = useCallback(async (user: JsonUserInfo, action: string) => {
    switch (action) {
      case 'DELETE':
        // console.log('Delete user', { user });
        await dispatch(purgeUser(user.userId!))
        await dispatch(fetchUsersCount());
        await dispatch(fetchUsers({ offset: 0 }));
        break;
    }
    // no other action defined
  }, [dispatch]);

  // https://mui.com/material-ui/getting-started/usage/
	return (
		<Container>
      <PageHeader title={t('Seznam uživatelů')}>
          <div style={{ display: 'flex' }}>
            <Button variant="contained" fullWidth onClick={handleNewUsers} className={styles.yellowButton} sx={{ width: '200px', marginRight: '10px' }}>{t('Nový uživatel')}</Button>
            <Button variant="contained" fullWidth onClick={handleExportUsers} className={styles.yellowButton} sx={{ width: '200px' }}>{t('Export')}</Button>
          </div>
      </PageHeader>

      <PageableList 
        cols={cols} 
        action={handleAction}
        filter={<UserListFilter/>} 
        config={{
          selectItems: selectUsers,
          selectItemsPaging: selectUsersPaging,
          fetchItemsCount: fetchUsersCount,
          fetchItems: fetchUsers
        }}
      ></PageableList>

      { /* 
			<div style={{ height: 600, width: '100%' }}>
			<DataGrid
				rows={rows}
				columns={getColumns(t)}
				pageSize={5}
				rowsPerPageOptions={[10]}
			/>
      </div> */ }
    </Container>
	);
}

export default ListUsersPage;
