/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonGroup,
    ApiResultOfJsonGroupFromJSON,
    ApiResultOfJsonGroupToJSON,
    ApiResultOfJsonGroupInfo,
    ApiResultOfJsonGroupInfoFromJSON,
    ApiResultOfJsonGroupInfoToJSON,
    ApiResultOfListOfJsonGroupInfo,
    ApiResultOfListOfJsonGroupInfoFromJSON,
    ApiResultOfListOfJsonGroupInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonGroup,
    JsonGroupFromJSON,
    JsonGroupToJSON,
} from '../models';

export interface GetGroupCountUsingGETRequest {
    episodeId?: number;
    relayId?: number;
    status?: GetGroupCountUsingGETStatusEnum;
    search?: string;
}

export interface GetGroupInfoUsingGETRequest {
    groupId: number;
}

export interface GetGroupListUsingGETRequest {
    episodeId?: number;
    relayId?: number;
    status?: GetGroupListUsingGETStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetGroupUsingGETRequest {
    groupId: number;
}

export interface SaveGroupUsingPOSTRequest {
    json: JsonGroup;
    testOnly?: boolean;
}

/**
 * 
 */
export class GroupControllerApi extends runtime.BaseAPI {

    /**
     * getGroupCount
     */
    async getGroupCountUsingGETRaw(requestParameters: GetGroupCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.episodeId !== undefined) {
            queryParameters['episodeId'] = requestParameters.episodeId;
        }

        if (requestParameters.relayId !== undefined) {
            queryParameters['relayId'] = requestParameters.relayId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/group/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getGroupCount
     */
    async getGroupCountUsingGET(requestParameters: GetGroupCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getGroupCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGroupInfo
     */
    async getGroupInfoUsingGETRaw(requestParameters: GetGroupInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonGroupInfo>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getGroupInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/group/info/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonGroupInfoFromJSON(jsonValue));
    }

    /**
     * getGroupInfo
     */
    async getGroupInfoUsingGET(requestParameters: GetGroupInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonGroupInfo> {
        const response = await this.getGroupInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGroupList
     */
    async getGroupListUsingGETRaw(requestParameters: GetGroupListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonGroupInfo>> {
        const queryParameters: any = {};

        if (requestParameters.episodeId !== undefined) {
            queryParameters['episodeId'] = requestParameters.episodeId;
        }

        if (requestParameters.relayId !== undefined) {
            queryParameters['relayId'] = requestParameters.relayId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/group/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonGroupInfoFromJSON(jsonValue));
    }

    /**
     * getGroupList
     */
    async getGroupListUsingGET(requestParameters: GetGroupListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonGroupInfo> {
        const response = await this.getGroupListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getGroup
     */
    async getGroupUsingGETRaw(requestParameters: GetGroupUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonGroup>> {
        if (requestParameters.groupId === null || requestParameters.groupId === undefined) {
            throw new runtime.RequiredError('groupId','Required parameter requestParameters.groupId was null or undefined when calling getGroupUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/group/{groupId}`.replace(`{${"groupId"}}`, encodeURIComponent(String(requestParameters.groupId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonGroupFromJSON(jsonValue));
    }

    /**
     * getGroup
     */
    async getGroupUsingGET(requestParameters: GetGroupUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonGroup> {
        const response = await this.getGroupUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveGroup
     */
    async saveGroupUsingPOSTRaw(requestParameters: SaveGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonGroup>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveGroupUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/group`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonGroupToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonGroupFromJSON(jsonValue));
    }

    /**
     * saveGroup
     */
    async saveGroupUsingPOST(requestParameters: SaveGroupUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonGroup> {
        const response = await this.saveGroupUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetGroupCountUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetGroupListUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
