/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonErrorInfo
 */
export interface JsonErrorInfo {
    /**
     * 
     * @type {string}
     * @memberof JsonErrorInfo
     */
    errorCode?: JsonErrorInfoErrorCodeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonErrorInfo
     */
    errorGuid?: string;
    /**
     * 
     * @type {object}
     * @memberof JsonErrorInfo
     */
    values?: object;
}

/**
* @export
* @enum {string}
*/
export enum JsonErrorInfoErrorCodeEnum {
    LoginNeeded = 'LOGIN_NEEDED',
    Unauthorized = 'UNAUTHORIZED',
    MissingParameter = 'MISSING_PARAMETER',
    InvalidParameter = 'INVALID_PARAMETER',
    DataIntegrity = 'DATA_INTEGRITY',
    GeneralError = 'GENERAL_ERROR'
}

export function JsonErrorInfoFromJSON(json: any): JsonErrorInfo {
    return JsonErrorInfoFromJSONTyped(json, false);
}

export function JsonErrorInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonErrorInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'errorCode': !exists(json, 'errorCode') ? undefined : json['errorCode'],
        'errorGuid': !exists(json, 'errorGuid') ? undefined : json['errorGuid'],
        'values': !exists(json, 'values') ? undefined : json['values'],
    };
}

export function JsonErrorInfoToJSON(value?: JsonErrorInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'errorCode': value.errorCode,
        'errorGuid': value.errorGuid,
        'values': value.values,
    };
}

