import { useAppDispatch } from "../store";
import { showLoader } from "../store/localApp";

export function useWithLoader() {

	const dispatch = useAppDispatch();

	return async (fn: Function) => {
		// console.log('Start Loader');
		dispatch(showLoader(true));
		await fn();
		// console.log('Stop Loader');
		dispatch(showLoader(false));
	}
	
}