/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonStratInitiative,
    JsonStratInitiativeFromJSON,
    JsonStratInitiativeFromJSONTyped,
    JsonStratInitiativeToJSON,
} from './JsonStratInitiative';
import {
    JsonStratSuccessLook,
    JsonStratSuccessLookFromJSON,
    JsonStratSuccessLookFromJSONTyped,
    JsonStratSuccessLookToJSON,
} from './JsonStratSuccessLook';

/**
 * 
 * @export
 * @interface JsonStratPillar
 */
export interface JsonStratPillar {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonStratPillar
     */
    allowedActions?: Array<JsonStratPillarAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonStratPillar
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillar
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillar
     */
    description?: string;
    /**
     * 
     * @type {Array<JsonStratInitiative>}
     * @memberof JsonStratPillar
     */
    initiatives?: Array<JsonStratInitiative>;
    /**
     * 
     * @type {boolean}
     * @memberof JsonStratPillar
     */
    isRemoved?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    onepagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    orderNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    parentEnablerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    parentInitiativeId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    parentPillarId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    pillarId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillar
     */
    status?: JsonStratPillarStatusEnum;
    /**
     * 
     * @type {Array<JsonStratSuccessLook>}
     * @memberof JsonStratPillar
     */
    successLooks?: Array<JsonStratSuccessLook>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillar
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillar
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratPillarAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonStratPillarStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonStratPillarFromJSON(json: any): JsonStratPillar {
    return JsonStratPillarFromJSONTyped(json, false);
}

export function JsonStratPillarFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratPillar {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'initiatives': !exists(json, 'initiatives') ? undefined : ((json['initiatives'] as Array<any>).map(JsonStratInitiativeFromJSON)),
        'isRemoved': !exists(json, 'isRemoved') ? undefined : json['isRemoved'],
        'onepagerId': !exists(json, 'onepagerId') ? undefined : json['onepagerId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'parentEnablerId': !exists(json, 'parentEnablerId') ? undefined : json['parentEnablerId'],
        'parentInitiativeId': !exists(json, 'parentInitiativeId') ? undefined : json['parentInitiativeId'],
        'parentPillarId': !exists(json, 'parentPillarId') ? undefined : json['parentPillarId'],
        'pillarId': !exists(json, 'pillarId') ? undefined : json['pillarId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'successLooks': !exists(json, 'successLooks') ? undefined : ((json['successLooks'] as Array<any>).map(JsonStratSuccessLookFromJSON)),
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonStratPillarToJSON(value?: JsonStratPillar | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'description': value.description,
        'initiatives': value.initiatives === undefined ? undefined : ((value.initiatives as Array<any>).map(JsonStratInitiativeToJSON)),
        'isRemoved': value.isRemoved,
        'onepagerId': value.onepagerId,
        'orderNo': value.orderNo,
        'parentEnablerId': value.parentEnablerId,
        'parentInitiativeId': value.parentInitiativeId,
        'parentPillarId': value.parentPillarId,
        'pillarId': value.pillarId,
        'status': value.status,
        'successLooks': value.successLooks === undefined ? undefined : ((value.successLooks as Array<any>).map(JsonStratSuccessLookToJSON)),
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

