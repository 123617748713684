/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompanyUser,
    ApiResultOfJsonCompanyUserFromJSON,
    ApiResultOfJsonCompanyUserToJSON,
    ApiResultOfJsonCompanyUserInfo,
    ApiResultOfJsonCompanyUserInfoFromJSON,
    ApiResultOfJsonCompanyUserInfoToJSON,
    ApiResultOfListOfJsonCompanyUserInfo,
    ApiResultOfListOfJsonCompanyUserInfoFromJSON,
    ApiResultOfListOfJsonCompanyUserInfoToJSON,
    ApiResultOfVoid,
    ApiResultOfVoidFromJSON,
    ApiResultOfVoidToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompanyUser,
    JsonCompanyUserFromJSON,
    JsonCompanyUserToJSON,
} from '../models';

export interface DeleteCompanyUserUsingDELETERequest {
    companyUserId: number;
}

export interface GetCompanyUserCountUsingGETRequest {
    companyId?: number;
    userId?: number;
    roleCodes?: Array<string>;
    search?: string;
}

export interface GetCompanyUserInfoUsingGETRequest {
    companyUserId: number;
}

export interface GetCompanyUserListUsingGETRequest {
    companyId?: number;
    userId?: number;
    roleCodes?: Array<string>;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanyUserUsingGETRequest {
    companyUserId: number;
}

export interface SaveCompanyUserUsingPOSTRequest {
    json: JsonCompanyUser;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanyUserControllerApi extends runtime.BaseAPI {

    /**
     * deleteCompanyUser
     */
    async deleteCompanyUserUsingDELETERaw(requestParameters: DeleteCompanyUserUsingDELETERequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfVoid>> {
        if (requestParameters.companyUserId === null || requestParameters.companyUserId === undefined) {
            throw new runtime.RequiredError('companyUserId','Required parameter requestParameters.companyUserId was null or undefined when calling deleteCompanyUserUsingDELETE.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-user/{companyUserId}`.replace(`{${"companyUserId"}}`, encodeURIComponent(String(requestParameters.companyUserId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfVoidFromJSON(jsonValue));
    }

    /**
     * deleteCompanyUser
     */
    async deleteCompanyUserUsingDELETE(requestParameters: DeleteCompanyUserUsingDELETERequest, initOverrides?: RequestInit): Promise<ApiResultOfVoid> {
        const response = await this.deleteCompanyUserUsingDELETERaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyUserCount
     */
    async getCompanyUserCountUsingGETRaw(requestParameters: GetCompanyUserCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.roleCodes) {
            queryParameters['roleCodes'] = requestParameters.roleCodes;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-user/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanyUserCount
     */
    async getCompanyUserCountUsingGET(requestParameters: GetCompanyUserCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanyUserCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyUserInfo
     */
    async getCompanyUserInfoUsingGETRaw(requestParameters: GetCompanyUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyUserInfo>> {
        if (requestParameters.companyUserId === null || requestParameters.companyUserId === undefined) {
            throw new runtime.RequiredError('companyUserId','Required parameter requestParameters.companyUserId was null or undefined when calling getCompanyUserInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-user/info/{companyUserId}`.replace(`{${"companyUserId"}}`, encodeURIComponent(String(requestParameters.companyUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyUserInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyUserInfo
     */
    async getCompanyUserInfoUsingGET(requestParameters: GetCompanyUserInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyUserInfo> {
        const response = await this.getCompanyUserInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyUserList
     */
    async getCompanyUserListUsingGETRaw(requestParameters: GetCompanyUserListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanyUserInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.userId !== undefined) {
            queryParameters['userId'] = requestParameters.userId;
        }

        if (requestParameters.roleCodes) {
            queryParameters['roleCodes'] = requestParameters.roleCodes;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-user/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanyUserInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyUserList
     */
    async getCompanyUserListUsingGET(requestParameters: GetCompanyUserListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanyUserInfo> {
        const response = await this.getCompanyUserListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyUser
     */
    async getCompanyUserUsingGETRaw(requestParameters: GetCompanyUserUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyUser>> {
        if (requestParameters.companyUserId === null || requestParameters.companyUserId === undefined) {
            throw new runtime.RequiredError('companyUserId','Required parameter requestParameters.companyUserId was null or undefined when calling getCompanyUserUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-user/{companyUserId}`.replace(`{${"companyUserId"}}`, encodeURIComponent(String(requestParameters.companyUserId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyUserFromJSON(jsonValue));
    }

    /**
     * getCompanyUser
     */
    async getCompanyUserUsingGET(requestParameters: GetCompanyUserUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyUser> {
        const response = await this.getCompanyUserUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompanyUser
     */
    async saveCompanyUserUsingPOSTRaw(requestParameters: SaveCompanyUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyUser>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanyUserUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company-user`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanyUserToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyUserFromJSON(jsonValue));
    }

    /**
     * saveCompanyUser
     */
    async saveCompanyUserUsingPOST(requestParameters: SaveCompanyUserUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyUser> {
        const response = await this.saveCompanyUserUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
