import {createAsyncThunk, createSlice, PayloadAction,} from '@reduxjs/toolkit'
import { RootState } from '..';
import {API_CONFIG} from '../app/api-config';
import {GetUserCountUsingGETRequest, GetUserListUsingGETRequest, JsonUser, JsonUserInfo, UserControllerApi} from '../generated-api';

const usersApi = new UserControllerApi(API_CONFIG);

/*
export const fetchUsers = createAsyncThunk('users/list', async (params: GetUserListUsingGETRequest) => {
	return await usersApi.getUserListUsingGET(params);
});
*/

export const fetchUsers = createAsyncThunk('users/list', async (arg: { page?: number, offset?: number }, thunkApi) => {
	
	const { currentPage, rowsPerPage, filter } = (thunkApi.getState() as RootState).users; 

	const params = {
		start: arg.page ? (arg.page - 1) * rowsPerPage : ((currentPage - 1) + (arg?.offset || 0)) * rowsPerPage,
		rows: rowsPerPage,
		orderCol: 'lastName',
		orderDir: 'asc',
		hasEmail: true,
		...filter
	} as GetUserListUsingGETRequest;
	
	return await usersApi.getUserListUsingGET(params);
});

export const fetchUsersCount = createAsyncThunk('users/count', async (_: void, thunkApi) => {

	const { filter } = (thunkApi.getState() as RootState).users; 

	const params = {
		hasEmail: true,
		...filter,
	} as GetUserListUsingGETRequest;

	return await usersApi.getUserCountUsingGET(params);
});

export const exportUsers = createAsyncThunk('users/export', async (_: void, thunkApi) => {

	const { filter } = (thunkApi.getState() as RootState).users; 

	const params = {
		hasEmail: true,
		...filter
	} as GetUserListUsingGETRequest;

	return await usersApi.getUserExportUsingGET(params);
});

export const fetchUser = createAsyncThunk('user/fetch', async (userId: number) => {
	return await usersApi.getUserUsingGET({ userId });
});

export const saveUser = createAsyncThunk('user/save', async (user: JsonUser) => {
	delete user['createdAt'];
	delete user['createdBy'];
	delete user['updatedAt'];
	delete user['updatedBy'];
	return await usersApi.saveUserUsingPOST({ json: user });
});

export const deleteUser = createAsyncThunk('user/delete', async (user: JsonUser) => {
	// TODO
});

export type UsersState = {
	loading: boolean;
	rowsPerPage: number;
	currentPage: number;
	count: number;
	items: JsonUserInfo[];
	filter: GetUserCountUsingGETRequest;
}

const initialState: UsersState = {
	loading: false,
	rowsPerPage: 10,
	currentPage: 1,
	count: 0,
	items: [],
	filter: {}
};

export const usersSlice = createSlice({
	name: 'users',
	initialState,
	reducers: {
		setUsersFilter: (state, action: PayloadAction<GetUserCountUsingGETRequest>) => {
			state.filter = action.payload;
		},
	},
	extraReducers: builder => {
		builder.addCase(fetchUsersCount.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchUsersCount.fulfilled, (state, action) => ({ ...state, count: action.payload.data || 0, loading: false }));
		builder.addCase(fetchUsersCount.rejected, (state, action) => ({ ...state, count: 0, loading: false }));

		builder.addCase(fetchUsers.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchUsers.fulfilled, (state, action) => ({ ...state, items: action.payload.data || [], currentPage: action.meta.arg.page || state.currentPage, loading: false }));
		builder.addCase(fetchUsers.rejected, (state, action) => ({ ...state, items: [], loading: false }));
	}
});

export const usersReducer = usersSlice.reducer;
export const { setUsersFilter } = usersSlice.actions;
