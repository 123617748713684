import * as React from 'react';
import {useMemo} from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {JsonPoplistItem, JsonStratTeam, JsonStratTeamStatusEnum} from "../../../generated-api";
import FormModal from "../../form/FormModal";
import {FormProps} from "../../../model/form";
import StrategyTeamForm from "../../../forms/StrategyTeamForm";
import {useSelector} from "react-redux";
import {selectOnePagerSubTeamsCombo} from "../../../store/selectors";

interface Props extends FormProps<JsonStratTeam> {
    teams: JsonStratTeam[];
}

const StratairuOnePagerTeamModal = (props: Props) => {

    const t = useAppTranslation();

    const allTeams = useSelector(selectOnePagerSubTeamsCombo);
    const teams = useMemo(() => {
        return allTeams.filter((item: JsonPoplistItem) => {
            const filter = props.teams.filter((team) => team !== props.data && team.status === JsonStratTeamStatusEnum.Active).map((team) => team.stratUserId);
            return !filter.includes(+item.value!);
        });
    }, [props.teams, props.data, allTeams])

    return (
        <FormModal title={t('Přidání kritického týmu')} open={!!props?.data}>
            <StrategyTeamForm { ...props } teams={teams}></StrategyTeamForm>
        </FormModal>
    );

}

export default StratairuOnePagerTeamModal;
