import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import TextFormfield from '../components/form/TextFormfield';
import DateTimeFormfield from '../components/form/DateTimeFormfield';
import { JsonEpisode } from '../generated-api';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';

interface Props extends FormProps<JsonEpisode> {}

const EpisodeForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonEpisode) => {
        let errors = {} as FormikErrors<JsonEpisode>;
        if (!values.title) {
            errors['title'] = t('Název episody je povinný.');
        }
        if (!values.orderNo) {
            errors['orderNo'] = t('Pořadí je povinné.');
        }
        if (!values.episodeFrom) {
            errors['episodeFrom'] = t('Čas zahájení episody je povinný.');
        }
        if (!values.episodeTo) {
            errors['episodeTo'] = t('Čas ukončení episody je povinný.');
        }
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={8}>
                <TextFormfield name="title" label={t('Název')}></TextFormfield>
            </Grid>
            <Grid item xs={4}>
                <TextFormfield name="orderNo" label={t('Pořadí')}></TextFormfield>
            </Grid>
            <Grid item xs={6}>
                <DateTimeFormfield name="episodeFrom" label={t('Zahájení episody')}></DateTimeFormfield>
            </Grid>
            <Grid item xs={6}>
                <DateTimeFormfield name="episodeTo" label={t('Ukončení episody')}></DateTimeFormfield>
            </Grid>
		</FormContainer>
	);
}

export default EpisodeForm;
