import {Button, Container} from '@mui/material';
import {useCallback, useMemo} from 'react';
import {useAppTranslation} from '../../services/i18n';
import {fetchCompanies, fetchCompaniesCount} from '../../store/companies';
import {selectCompanies, selectCompaniesFilter, selectCompaniesPaging} from '../../store/selectors';
import {Link, useNavigate} from "react-router-dom";

import PageHeader from '../../components/layout/PageHeader';
import {JsonCompanyInfo, JsonSerieUserCountInfo} from '../../generated-api';
import PoplistValue from '../../components/form/PoplistValue';
import PageableList from '../../components/layout/PagableList';
import EditIcon from '@mui/icons-material/Edit';

import styles from '../../assets/styles/layout';
import CompanyListFilter from '../../forms/ComapnyListFilter';
import {useSelector} from "react-redux";
import {ColumnDefinition} from "../../components/layout/SimpleList";

const ListCompaniesPage = () => {
    const t = useAppTranslation();

    const filter = useSelector(selectCompaniesFilter)

  const navigate = useNavigate();

  const cols = useMemo(() => {
      return [
          {
              title: t('Název / Odvětví'),
              style: { width: '30%' },
              renderer: (row: JsonCompanyInfo) =>
                  <div>
                      <div className={styles.listTitle}>
                          <Link to={`/companies/${row.companyId}`} className={styles.link}>{row.companyName}</Link>
                      </div>
                      <div className={styles.listInfo}>
                          <PoplistValue value={row.sectorId} poplist="companySector"/>
                      </div>
                  </div>
          },
          {
              title: t('Lokalita / Velikost'),
              style: { width: '30%' },
              renderer: (row: JsonCompanyInfo) =>
                  <div>
                      <div className={styles.listInfo}>
                          <PoplistValue value={row.countryCode} poplist="countryCode"/>&nbsp;/&nbsp;
                          <PoplistValue value={row.regionCode} poplist="regionCode"/>
                      </div>
                      <div className={styles.listInfo}>
                          <PoplistValue value={row.sizeId} poplist="companySize"/>
                      </div>
                  </div>
          },
          filter?.serieId ? {
              title: t('Počet účastníků'),
              style: { width: '30%' },
              renderer: (row: JsonCompanyInfo) => {
                  const usersCount = row.serieUserCounts?.find((sc: JsonSerieUserCountInfo) => sc.serieId === filter.serieId)?.userCount || 0;
                  return (<div>
                      <div className={styles.listInfo}>
                          { usersCount }
                      </div>
                  </div>)
              }
          } : undefined,
          {
              title: '',
              style: { width: '40%', display: 'flex', justifyContent: 'end' },
              renderer: (row: JsonCompanyInfo) =>
                  <div>
                      <Link to={`/companies/${row.companyId}`}><EditIcon color="primary"/></Link>
                  </div>
          }
      ].filter(x => !!x) as ColumnDefinition<JsonCompanyInfo>[];
  }, [filter, t]);

  const handleNewCompany = useCallback(() => {
    navigate('/companies/new');
  }, [navigate])


  const handleAction = useCallback(async (admin: JsonCompanyInfo, action: string) => {
    // console.log({ action, admin })
    // no other action defined
  }, []);

	return (
		<Container>
      <PageHeader title={t('Seznam společností')}>
        <Button variant="contained" className={styles.yellowButton} fullWidth onClick={handleNewCompany}>{t('Nová společnost')}</Button>
      </PageHeader>

      <PageableList 
        cols={cols} 
        action={handleAction} 
        filter={<CompanyListFilter/>}
        config={{
          selectItems: selectCompanies,
          selectItemsPaging: selectCompaniesPaging,
          fetchItemsCount: fetchCompaniesCount,
          fetchItems: fetchCompanies
      }}></PageableList>
    </Container>
	);
}

export default ListCompaniesPage;
