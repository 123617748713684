import { PropsWithChildren, useCallback } from 'react';

import { Link } from 'react-router-dom';
import { Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';

import SimpleList, { ColumnDefinition, SimpleListAction } from "../../../../components/layout/SimpleList";
import { JsonEpisodeInfo, JsonRelayInfo } from "../../../../generated-api";
import { useAppTranslation } from "../../../../services/i18n";
import EditActionButtons from '../../../../components/editor/EditActionButtons';
interface Props {
    rows: JsonEpisodeInfo[] | null;
    action: SimpleListAction<JsonEpisodeInfo>;
    onAdd: () => void;
    relays: JsonRelayInfo[];
}

const SerieEpisodesList = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();

    const hasRelay = useCallback((row: JsonEpisodeInfo) => {
        return !!(props.relays || []).find((relay: JsonRelayInfo) => relay.episodeId === row.episodeId);
    }, [props.relays])

    const cols = [
        { 
            title: 'name', 
            renderer: (row: JsonEpisodeInfo) => (
                <>
                    <div><strong>{ row.title }</strong></div>
                </>
            )
        },
        {
            renderer: (row: JsonEpisodeInfo) => (
                <>
                    { !hasRelay(row) && <Tooltip title="Vytvořit vysílání" style={{ marginRight: '10px' }}>
                        <OndemandVideoIcon color="primary" onClick={() => props.action(row, 'CREATE_RELAY')}/>
                    </Tooltip> }
                    <Tooltip title="Upravit epizodu">
                        <Link to={`/episodes/${row.episodeId}`}><EditIcon color="primary"/></Link>
                    </Tooltip>
                    { /*
                    <Tooltip title="Odstranit epizodu">
                        <DeleteIcon color="primary" onClick={() => props.action(row, 'DELETE')}/>
                    </Tooltip>
                    */ }
                </>
            )
        }
    ] as ColumnDefinition<JsonEpisodeInfo>[];

	return (
		<SimpleList cols={cols} config={{hideHeader: true, title: t('Episody')}} 
            headerAction={<EditActionButtons onAdd={() => props.onAdd() }/>} { ...props }></SimpleList>
	);
}

export default SerieEpisodesList;