/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonRelayResetRequest
 */
export interface JsonRelayResetRequest {
    /**
     * 
     * @type {number}
     * @memberof JsonRelayResetRequest
     */
    confirmedResponseCount?: number;
}

export function JsonRelayResetRequestFromJSON(json: any): JsonRelayResetRequest {
    return JsonRelayResetRequestFromJSONTyped(json, false);
}

export function JsonRelayResetRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRelayResetRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'confirmedResponseCount': !exists(json, 'confirmedResponseCount') ? undefined : json['confirmedResponseCount'],
    };
}

export function JsonRelayResetRequestToJSON(value?: JsonRelayResetRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'confirmedResponseCount': value.confirmedResponseCount,
    };
}

