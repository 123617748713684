import {useAppTranslation} from '../../services/i18n';
import styles from '../../assets/styles/live.module.css';
import {useCallback, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {Button} from "../../components/layout/Button";
import LiveLayout from "../../components/layout/LiveLayout";

import {ReactComponent as LiveIcon} from '../../assets/images/live/icons/live.svg';
import {ReactComponent as WorkzoneIcon} from '../../assets/images/live/icons/workzone.svg';
import {ReactComponent as ChartsIcon} from '../../assets/images/live/icons/charts.svg';
import {ReactComponent as ArchiveIcon} from '../../assets/images/live/icons/archive.svg';
import classnames from "classnames";
import * as React from "react";
import {useLiveContext} from "../../hooks/useLiveContext";
import {fetchCurrentEpisode} from "../../store/auth";
import {useAppDispatch} from "../../store";
import {JsonEpisodeInfo} from "../../generated-api";
import {stringDateToGui} from "../../helpers/date";

const links = [/*'live',*/ 'workzone', 'charts', 'archive'];

const LiveDashboardTile = ({ name, code, episodeInfo }: { name: string, code: string, episodeInfo?: JsonEpisodeInfo }) => {

    const t = useAppTranslation();

    const navigate = useNavigate();

    const handleNavigate = useCallback(() => {
        if (code && name) {
            let link = '';
            switch (name) {
                case 'live':
                    document.location = `https://live.atairuleadership.tv/c/${code}`;
                    return;
                case 'workzone':
                    link = `/live/${code}/workzone/onepager`;
                    break;
                case 'charts':
                    link = `/live/${code}/reports`;
                    break;
                case 'archive':
                    link = `/live/${code}/archives`;
                    break;
            }
            navigate(link);
        }
    }, [navigate, name, code]);

    const showLive = episodeInfo?.isOpen;

    return (
        <div className={classnames(styles.dashboardTile, name === 'live' ? styles.disabled : undefined)} >
            <div>
                <div className={styles.icon}>
                    { name === 'live' && <LiveIcon /> }
                    { name === 'workzone' && <WorkzoneIcon /> }
                    { name === 'charts' && <ChartsIcon /> }
                    { name === 'archive' && <ArchiveIcon /> }
                </div>
                <div className={styles.title} dangerouslySetInnerHTML={{ __html: t('live.dashboard.links.' + name + '.title') }} />
                { name === 'live' && !showLive && <div className={styles.desc}  dangerouslySetInnerHTML={{ __html: t('live.dashboard.links.' + name + '.descNext', { date: stringDateToGui(episodeInfo?.episodeFrom) } ) }} /> }
                { (name !== 'live' || !!showLive) && <div className={styles.desc}  dangerouslySetInnerHTML={{ __html: t('live.dashboard.links.' + name + '.desc') }} /> }
            </div>
            <div className={styles.button}>
                { (name !== 'live' || !!showLive) && <Button variant="contained" color="success" onClick={handleNavigate}>{ t('Vstoupit') }</Button> }
            </div>
        </div>
    )
}

const LiveDashboardPage = () => {
    const t = useAppTranslation();
    const dispatch = useAppDispatch();
    const [episodeInfo, setEpisodeInfo] = useState<JsonEpisodeInfo|undefined>(undefined);
    const { code } = useParams();

    const loadCurrentEpisode = useCallback(async () => {
        if (code) {
            const result = await dispatch(fetchCurrentEpisode(code));
            if (!('error' in result)) {
                setEpisodeInfo(result.payload.data);
            }
        }
    }, [code, dispatch])

    useEffect(() => {
        if (code) {
            loadCurrentEpisode();
            const interval = setInterval(loadCurrentEpisode, 300000);
            return () => clearInterval(interval);
        }
    }, [code, loadCurrentEpisode]);

    const [context] = useLiveContext(false);

    return context ? <LiveLayout
            title="Vítejte na <span style='color: var(--live-green)'>své cestě</span>."
            subtitle="Připravili jsme pro vás čtyři moduly,<br/>které Vám pomohou s rychlejší realizací změn."
        >
            <div className={styles.smallsubtitle} dangerouslySetInnerHTML={{ __html: t('Sledujte, hlasujte, vyzkoušejte si práci s One-pagerem a získejte navíc <span style=\'color: var(--live-green)\'>certifikát o absolvování*</span>.') }}></div>
            <div className={styles.dashboardTiles}>
                { links?.map((link: string) => (<LiveDashboardTile key={link} name={link} code={code!} episodeInfo={episodeInfo}/>))}
            </div>
            <div className={styles.tinysubtitle} dangerouslySetInnerHTML={{ __html: t('*Pro získání certifikátu je třeba splnit následující podmínky: účast na všech dílech seriálu, aktivní hlasování a práce s interaktivním One-pagerem.') }}></div>
        </LiveLayout> : <></>;
}

export default LiveDashboardPage;
