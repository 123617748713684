import PageHeader from '../../../components/layout/PageHeader';
import {Card, CardContent, Container, Grid} from '@mui/material';
import {useSelector} from "react-redux";
import {
  selectAuthStrategies
} from "../../../store/selectors";
import {useAppTranslation} from "../../../services/i18n";
import {JsonStrategyInfo} from "../../../generated-api";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {useNavigate} from "react-router-dom";
import stylesOnepager from "../../../assets/styles/onepager.module.css";

const StrategySelectionPage = () => {

  const t = useAppTranslation();
  const strategies = useSelector(selectAuthStrategies);
  const navigate = useNavigate();

  return (
    <>
	{ true &&
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <PageHeader title={t(`Moje strategie`)}></PageHeader>
          </Grid>
          { strategies.map((strategy: JsonStrategyInfo) =>
              <Grid key={strategy.strategyId} item xs={12} md={6}>
                <Card sx={{ display: 'flex' }} className={stylesOnepager.onePagerCard} onClick={() => navigate(`/strategy/${strategy.strategyId}/dashboard`)}>
                  <Box className={ stylesOnepager.onePagerIcon }>
                    { /* <AutoGraphIcon style={{ fontSize: '60px' }}/> */ }
                  </Box>
                  <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1' }}>
                    <CardContent sx={{ flex: '1', position: 'relative' }}>
                      <Typography component="div" variant="h6">
                        { strategy.title }
                      </Typography>
                    </CardContent>
                  </Box>
                </Card>
              </Grid>
           )}
          {!strategies?.length && <Typography component="div" variant="h6">{t('Nemáte přiřazeny žádné strategie.')}</Typography>}
        </Grid>
      </Container>    
    }
    </>
	);
}

export default StrategySelectionPage;
