import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {JsonStrategy} from '../generated-api';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';

interface Props extends FormProps<JsonStrategy> {}

/*
        companyId: undefined,
        status: undefined,
        strategyId: undefined,
        title: undefined,
*/

const StrategyForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonStrategy) => {
        let errors = {} as FormikErrors<JsonStrategy>;
        /*
        if (!values.email) {
            errors['email'] = t('Email je povinný.');
        } else if (!isEmailValid(values.email)) {
            errors['email'] = t('Zadejte platnou emailovou adresu.');
        }
        if (!values.firstName) {
            errors['firstName'] = t('Křestní jméno je povinné.');
        }
        if (!values.lastName) {
            errors['lastName'] = t('Příjmení je povinné.');
        }
        if (!values.sex) {
            errors['sex'] = t('Pohlaví je povinné.');
        }
         */
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <TextFormfield name="title" label={t('Název strategie')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="companyId" label={t('Společnost')} poplist="companyId"></SelectFormfield>
            </Grid>

            { /* <Grid item xs={12}>
                <TextFormfield name="lastName" label={t('Příjmení')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="sex" label={t('Pohlaví')} poplist="sex"></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <TextFormfield name="email" label={t('E-mail')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <TextFormfield name="phone" label={t('Telefon')} formatValue={formatPhone} normalizeValue={createNormalizePhone(false)}></TextFormfield>
            </Grid> */ }
		</FormContainer>
	);
}

export default StrategyForm;
