export const apiBasePath = (_lang: string) => {
	switch (window.location.hostname) {
		case 'localhost':
			return `https://atairu.codito.cz`;
		default:
			return 'https://' + window.location.hostname;
	}
}

export const isProdEnv = !['localhost', 'atairu.codito.cz', 'test-app.atairuleadership.tv'].includes(window.location.hostname);

export const isMicroSite = window.location.pathname.startsWith('/microsite');

export const isLiveSite = window.location.pathname.startsWith('/live');

export const noHeader = window.location.pathname.startsWith('/registration')
	|| window.location.pathname.startsWith('/live')
	|| window.location.pathname.startsWith('/finalize')
	|| window.location.pathname.startsWith('/confirmation')
	|| window.location.pathname.startsWith('/audit')
	|| window.location.pathname.startsWith('/forgotten-password')
	|| window.location.pathname.startsWith('/recover')
	|| window.location.pathname.startsWith('/activate')

export const baseUrl = '/';
