import {Alert, Snackbar, SnackbarCloseReason} from '@mui/material';
import React, {useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useAppDispatch} from '../../store';
import {FlashMessageWithId, removeMessage} from '../../store/localApp';
import {selectAppMessages} from '../../store/selectors';

interface Props {

}

export const FlashMessages: React.FC<Props> = () => {
	const messages = useSelector(selectAppMessages);
	const dispatch = useAppDispatch();
	const handleClose = useCallback((msg: FlashMessageWithId, reason?: SnackbarCloseReason) => dispatch(removeMessage(msg)), [dispatch]);
	return (<>
		{messages.map((msg) => (
			<Snackbar key={msg.id} open autoHideDuration={6000} onClose={(e, reason) => handleClose(msg, reason)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
				<Alert onClose={() => handleClose(msg)} severity={msg.severity}>
					{msg.title}
				</Alert>
			</Snackbar>))}
	</>);
};
