import React from 'react'
import styles from '../../../assets/styles/dropzone.module.css';
import { FileUploadStatus } from './Dropzopne';
import {CircularProgress, Icon} from "@mui/material";

export interface DropzopneFileListProps {
  	uploadStatus?: FileUploadStatus[];
}

export const DropzoneFileList = ({ uploadStatus }: DropzopneFileListProps) => {

	return (
	    <>
		  { uploadStatus &&
			<>
			<div className={styles.uploadedFileTitle}>Nahrávané soubory</div>
			{uploadStatus.map((fileStatus: FileUploadStatus, index: number) =>
			<div key={index} className={styles.uploadedFile}>
			  <div>{ fileStatus.file.name }</div>
			  { fileStatus.status === 'PENDING' && <Icon children="timer" color="disabled" /> }
			  { fileStatus.status === 'PROCESSING' && <CircularProgress size={20}/> }
			  { fileStatus.status === 'FINISHED' && <div style={{ color: '#006600' }}><Icon children="check-circle"/></div> }
			  { fileStatus.status === 'ERROR' && <Icon children="error" color="error"/> }
			</div>
			) }
			</>
		  }
    	</>
	)
}
