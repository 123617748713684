import styles from '../../assets/styles/layout';
import * as React from 'react';
import {useAppTranslation} from "../../services/i18n";
import ClosableModal from "./ClosableModal";
import {useState} from "react";

interface Props {
    help: string | null;
    onClose: () => void;
}

const HelpModal = ({ help, onClose }: Props) => {

    const t = useAppTranslation();
    const [showDetail, setShowDetail] = useState<boolean>(false);

    const hasDetail = t(help + '.detail') !== help + '.detail';

    return (
        <>
            { help &&
                <ClosableModal open={!!help} title={t('helpModal.title')} onClose={() => { setShowDetail(false); onClose && onClose(); }} buttons={[]}>
                    <div>
                        <div style={{ fontWeight: 'bold', marginBottom: '15px' }} dangerouslySetInnerHTML={{ __html: t(help + '.title') }}></div>
                        <div style={{ textAlign: 'left', lineHeight: '1.2rem' }} dangerouslySetInnerHTML={{ __html: t(help + '.main') }}></div>
                        { hasDetail && !showDetail && <div style={{ marginTop: '20px' }} className={styles.link} onClick={() => setShowDetail(true)}>{t('helpModal.showDetail')}</div> }
                        { hasDetail && showDetail && <>
                                <div style={{ textAlign: 'left', lineHeight: '1.2rem', marginTop: '20px' }} dangerouslySetInnerHTML={{ __html: t(help + '.detail') }}></div>
                                <div className={styles.link} onClick={() => setShowDetail(false)}>{t('helpModal.hideDetail')}</div>
                            </>
                        }
                    </div>
                </ClosableModal>
            }
        </>
    );

}

export default HelpModal;
