import * as React from 'react';
import styles from '../../../assets/styles/stratairu.module.css';
import {PropsWithChildren} from "react";
import {useAppTranslation} from "../../../services/i18n";

interface Props {
    text?: string;
}

const tempReplacementSrc = ['<b>', '</b>', '<i>', '</i>', '<u>', '</u>', '<br/>', '<br>'];
const tempReplacementDest = ['__#B_S#__', '__#B_E#__', '__#I_S#__', '__#I_E#__','__#U_S#__', '__#U_E#__', '__#BR#__', '__#BR2#__'];

export const sanitizeText = (text?: string): string => {
    let result = text || '';
    tempReplacementSrc?.forEach((src: string, index: number) => {
        result = result.replaceAll(src, tempReplacementDest[index]);
    });
    // console.log({ result });
    result = result?.replace(/<[^>]*>?/gm, '');
    tempReplacementDest?.forEach((src: string, index: number) => {
        result = result.replaceAll(src, tempReplacementSrc[index]);
    });
    return result.replaceAll('\n', '<br/>') || '';
}

const StratairuSanitizedText = ({ text }: Props) => {
	
    const t = useAppTranslation();

	return (
        <div dangerouslySetInnerHTML={{ __html: sanitizeText(text) || '' }}></div>
	);
}

export default StratairuSanitizedText;
