import {createAsyncThunk,} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import {
	CopyPollsIntoEpisodeUsingPOSTRequest,
	EpisodeControllerApi,
	GetGroupListUsingGETStatusEnum,
	GetPollListUsingGETStatusEnum,
	GroupControllerApi,
	JsonEpisode,
	JsonGroup,
	JsonPoll,
	PollControllerApi
} from '../generated-api';

const episodesApi = new EpisodeControllerApi(API_CONFIG);
const groupApi = new GroupControllerApi(API_CONFIG);
const pollApi = new PollControllerApi(API_CONFIG);

export const fetchSerieEpisodes = createAsyncThunk('serie/episodes', async (serieId: number) => {
	return await episodesApi.getEpisodeListUsingGET({ serieId });
});

export const fetchEpisode = createAsyncThunk('episode/fetch', async (episodeId: number) => {
	return await episodesApi.getEpisodeUsingGET({ episodeId });
});

export const saveEpisode = createAsyncThunk('user/save', async (episode: JsonEpisode) => {
	const { createdAt, createdBy, updatedAt, updatedBy, ...restEpisode } = episode;
	return await episodesApi.saveEpisodeUsingPOST({ json: restEpisode });
});

export const fetchEpisodeGroups = createAsyncThunk('episode/groups/fetch', async (episodeId: number) => {
	return await groupApi.getGroupListUsingGET({ episodeId, status: GetGroupListUsingGETStatusEnum.Active });
});

export const fetchEpisodePolls = createAsyncThunk('episode/polls/fetch', async (episodeId: number) => {
	return await pollApi.getPollListUsingGET({ episodeId, orderCol: 'orderNo', orderDir: 'asc', status: GetPollListUsingGETStatusEnum.Active });
});

export const fetchPoll = createAsyncThunk('episode/poll/fetch', async (pollId: number) => {
	return await pollApi.getPollUsingGET({ pollId });
});

export const savePoll = createAsyncThunk('episode/poll/save', async (poll: JsonPoll) => {
	delete poll['createdAt'];
	delete poll['createdBy'];
	delete poll['updatedAt'];
	delete poll['updatedBy'];
	return await pollApi.savePollUsingPOST({ json: poll });
});

export const fetchGroup = createAsyncThunk('episode/group/fetch', async (groupId: number) => {
	return await groupApi.getGroupUsingGET({ groupId });
});

export const saveGroup = createAsyncThunk('episode/group/save', async (group: JsonGroup) => {
	delete group['createdAt'];
	delete group['createdBy'];
	delete group['updatedAt'];
	delete group['updatedBy'];
	return await groupApi.saveGroupUsingPOST({ json: group });
});

export const searchPolls = createAsyncThunk('episode/polls/search', async (search: string) => {
	return await pollApi.getPollListUsingGET({ search, status: GetPollListUsingGETStatusEnum.Active });
});

export const copyPollsToEpisode = createAsyncThunk('episode/polls/copy', async (params: CopyPollsIntoEpisodeUsingPOSTRequest) => {
	return await pollApi.copyPollsIntoEpisodeUsingPOST(params);
});

