/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfListOfJsonPoplistItem,
    ApiResultOfListOfJsonPoplistItemFromJSON,
    ApiResultOfListOfJsonPoplistItemToJSON,
    ApiResultOfMapOfstringAndListOfJsonPoplistItem,
    ApiResultOfMapOfstringAndListOfJsonPoplistItemFromJSON,
    ApiResultOfMapOfstringAndListOfJsonPoplistItemToJSON,
    ApiResultOfMapOfstringAndint,
    ApiResultOfMapOfstringAndintFromJSON,
    ApiResultOfMapOfstringAndintToJSON,
} from '../models';

export interface GetPoplistUsingGETRequest {
    name: string;
}

export interface GetPoplistsUsingGETRequest {
    names: Array<string>;
}

/**
 * 
 */
export class PoplistControllerApi extends runtime.BaseAPI {

    /**
     * getPoplistList
     */
    async getPoplistListUsingGETRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfMapOfstringAndint>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poplist/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfMapOfstringAndintFromJSON(jsonValue));
    }

    /**
     * getPoplistList
     */
    async getPoplistListUsingGET(initOverrides?: RequestInit): Promise<ApiResultOfMapOfstringAndint> {
        const response = await this.getPoplistListUsingGETRaw(initOverrides);
        return await response.value();
    }

    /**
     * getPoplist
     */
    async getPoplistUsingGETRaw(requestParameters: GetPoplistUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonPoplistItem>> {
        if (requestParameters.name === null || requestParameters.name === undefined) {
            throw new runtime.RequiredError('name','Required parameter requestParameters.name was null or undefined when calling getPoplistUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poplist/{name}`.replace(`{${"name"}}`, encodeURIComponent(String(requestParameters.name))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonPoplistItemFromJSON(jsonValue));
    }

    /**
     * getPoplist
     */
    async getPoplistUsingGET(requestParameters: GetPoplistUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonPoplistItem> {
        const response = await this.getPoplistUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getPoplists
     */
    async getPoplistsUsingGETRaw(requestParameters: GetPoplistsUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfMapOfstringAndListOfJsonPoplistItem>> {
        if (requestParameters.names === null || requestParameters.names === undefined) {
            throw new runtime.RequiredError('names','Required parameter requestParameters.names was null or undefined when calling getPoplistsUsingGET.');
        }

        const queryParameters: any = {};

        if (requestParameters.names) {
            queryParameters['names'] = requestParameters.names;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/poplist`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfMapOfstringAndListOfJsonPoplistItemFromJSON(jsonValue));
    }

    /**
     * getPoplists
     */
    async getPoplistsUsingGET(requestParameters: GetPoplistsUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfMapOfstringAndListOfJsonPoplistItem> {
        const response = await this.getPoplistsUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
