/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonRelayResetResponse
 */
export interface JsonRelayResetResponse {
    /**
     * 
     * @type {boolean}
     * @memberof JsonRelayResetResponse
     */
    isSuccess?: boolean;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayResetResponse
     */
    itemCount?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayResetResponse
     */
    responseCount?: number;
}

export function JsonRelayResetResponseFromJSON(json: any): JsonRelayResetResponse {
    return JsonRelayResetResponseFromJSONTyped(json, false);
}

export function JsonRelayResetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRelayResetResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'isSuccess': !exists(json, 'isSuccess') ? undefined : json['isSuccess'],
        'itemCount': !exists(json, 'itemCount') ? undefined : json['itemCount'],
        'responseCount': !exists(json, 'responseCount') ? undefined : json['responseCount'],
    };
}

export function JsonRelayResetResponseToJSON(value?: JsonRelayResetResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'isSuccess': value.isSuccess,
        'itemCount': value.itemCount,
        'responseCount': value.responseCount,
    };
}

