import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import MultiCheckboxFormfield from '../components/form/MultiCheckboxFormfield';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import { JsonSerie } from '../generated-api';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';

interface Props extends FormProps<JsonSerie> {}

const SerieForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonSerie) => {
        let errors = {} as FormikErrors<JsonSerie>;
        if (!values.title) {
            errors['title'] = t('Název seriálu je povinný.');
        }
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <TextFormfield name="title" label={t('Název')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="serieType" label={t('Typ vysílání')} poplist="serieType"></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <MultiCheckboxFormfield name="serieFlags" label={t('Nastavení registrace')} poplist="serieFlag"></MultiCheckboxFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="lang" label={t('Jazyk')} poplist="lang"></SelectFormfield>
            </Grid>
		</FormContainer>
	);
}

export default SerieForm;
