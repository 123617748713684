/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonSerieUserInfo
 */
export interface JsonSerieUserInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonSerieUserInfo
     */
    allowedActions?: Array<JsonSerieUserInfoAllowedActionsEnum>;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    clientGuid?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    companyBranchId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    departmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    durationId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    phone?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    positionId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    serieId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    serieStatus?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    serieTitle?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    serieUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    sex?: JsonSerieUserInfoSexEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonSerieUserInfo
     */
    status?: JsonSerieUserInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    userId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonSerieUserInfo
     */
    userStatus?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonSerieUserInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieUserInfoSexEnum {
    M = 'M',
    F = 'F',
    X = 'X'
}/**
* @export
* @enum {string}
*/
export enum JsonSerieUserInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE',
    New = 'NEW'
}

export function JsonSerieUserInfoFromJSON(json: any): JsonSerieUserInfo {
    return JsonSerieUserInfoFromJSONTyped(json, false);
}

export function JsonSerieUserInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonSerieUserInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'clientGuid': !exists(json, 'clientGuid') ? undefined : json['clientGuid'],
        'companyBranchId': !exists(json, 'companyBranchId') ? undefined : json['companyBranchId'],
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'durationId': !exists(json, 'durationId') ? undefined : json['durationId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'serieId': !exists(json, 'serieId') ? undefined : json['serieId'],
        'serieStatus': !exists(json, 'serieStatus') ? undefined : json['serieStatus'],
        'serieTitle': !exists(json, 'serieTitle') ? undefined : json['serieTitle'],
        'serieUserId': !exists(json, 'serieUserId') ? undefined : json['serieUserId'],
        'sex': !exists(json, 'sex') ? undefined : json['sex'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
        'userStatus': !exists(json, 'userStatus') ? undefined : json['userStatus'],
    };
}

export function JsonSerieUserInfoToJSON(value?: JsonSerieUserInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'clientGuid': value.clientGuid,
        'companyBranchId': value.companyBranchId,
        'companyId': value.companyId,
        'departmentId': value.departmentId,
        'durationId': value.durationId,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'phone': value.phone,
        'positionId': value.positionId,
        'serieId': value.serieId,
        'serieStatus': value.serieStatus,
        'serieTitle': value.serieTitle,
        'serieUserId': value.serieUserId,
        'sex': value.sex,
        'status': value.status,
        'userId': value.userId,
        'userStatus': value.userStatus,
    };
}

