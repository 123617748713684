/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompanyPosition,
    ApiResultOfJsonCompanyPositionFromJSON,
    ApiResultOfJsonCompanyPositionToJSON,
    ApiResultOfJsonCompanyPositionInfo,
    ApiResultOfJsonCompanyPositionInfoFromJSON,
    ApiResultOfJsonCompanyPositionInfoToJSON,
    ApiResultOfListOfJsonCompanyPositionInfo,
    ApiResultOfListOfJsonCompanyPositionInfoFromJSON,
    ApiResultOfListOfJsonCompanyPositionInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompanyPosition,
    JsonCompanyPositionFromJSON,
    JsonCompanyPositionToJSON,
} from '../models';

export interface GetCompanyPositionCountUsingGETRequest {
    companyId?: number;
    status?: GetCompanyPositionCountUsingGETStatusEnum;
    search?: string;
}

export interface GetCompanyPositionInfoUsingGETRequest {
    companyPositionId: number;
}

export interface GetCompanyPositionListUsingGETRequest {
    companyId?: number;
    status?: GetCompanyPositionListUsingGETStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanyPositionUsingGETRequest {
    companyPositionId: number;
}

export interface SaveCompanyPositionUsingPOSTRequest {
    json: JsonCompanyPosition;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanyPositionControllerApi extends runtime.BaseAPI {

    /**
     * getCompanyPositionCount
     */
    async getCompanyPositionCountUsingGETRaw(requestParameters: GetCompanyPositionCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-position/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanyPositionCount
     */
    async getCompanyPositionCountUsingGET(requestParameters: GetCompanyPositionCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanyPositionCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyPositionInfo
     */
    async getCompanyPositionInfoUsingGETRaw(requestParameters: GetCompanyPositionInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyPositionInfo>> {
        if (requestParameters.companyPositionId === null || requestParameters.companyPositionId === undefined) {
            throw new runtime.RequiredError('companyPositionId','Required parameter requestParameters.companyPositionId was null or undefined when calling getCompanyPositionInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-position/info/{companyPositionId}`.replace(`{${"companyPositionId"}}`, encodeURIComponent(String(requestParameters.companyPositionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyPositionInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyPositionInfo
     */
    async getCompanyPositionInfoUsingGET(requestParameters: GetCompanyPositionInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyPositionInfo> {
        const response = await this.getCompanyPositionInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyPositionList
     */
    async getCompanyPositionListUsingGETRaw(requestParameters: GetCompanyPositionListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanyPositionInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-position/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanyPositionInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyPositionList
     */
    async getCompanyPositionListUsingGET(requestParameters: GetCompanyPositionListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanyPositionInfo> {
        const response = await this.getCompanyPositionListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyPosition
     */
    async getCompanyPositionUsingGETRaw(requestParameters: GetCompanyPositionUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyPosition>> {
        if (requestParameters.companyPositionId === null || requestParameters.companyPositionId === undefined) {
            throw new runtime.RequiredError('companyPositionId','Required parameter requestParameters.companyPositionId was null or undefined when calling getCompanyPositionUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-position/{companyPositionId}`.replace(`{${"companyPositionId"}}`, encodeURIComponent(String(requestParameters.companyPositionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyPositionFromJSON(jsonValue));
    }

    /**
     * getCompanyPosition
     */
    async getCompanyPositionUsingGET(requestParameters: GetCompanyPositionUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyPosition> {
        const response = await this.getCompanyPositionUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompanyPosition
     */
    async saveCompanyPositionUsingPOSTRaw(requestParameters: SaveCompanyPositionUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyPosition>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanyPositionUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company-position`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanyPositionToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyPositionFromJSON(jsonValue));
    }

    /**
     * saveCompanyPosition
     */
    async saveCompanyPositionUsingPOST(requestParameters: SaveCompanyPositionUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyPosition> {
        const response = await this.saveCompanyPositionUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetCompanyPositionCountUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCompanyPositionListUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
