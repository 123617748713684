import {useCallback} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import LiveLayout from "../../components/layout/LiveLayout";
import {JsonEpisode} from "../../generated-api";
import LiveEpisodesList, {EpisodeListMode} from "./components/LiveEpisodesList";

const LiveReportsPage = () => {

    const navigate = useNavigate();

    const { code } = useParams();

    const handleSelectEpisode = useCallback((episode: JsonEpisode, index: number) => {
        navigate(`/live/${code}/report/${episode.episodeId}`);
    }, [navigate, code])

    return (
        <LiveLayout
            title="Datové <span style='color: var(--live-green)'>výstupy</span>"
            subtitle="Výsledky hlasování z jednotlivých epizod."
            backButton
        >
            <LiveEpisodesList onClick={handleSelectEpisode} mode={EpisodeListMode.Dashboards}/>
        </LiveLayout>);
}

export default LiveReportsPage;
