import {useSelector} from 'react-redux';
import {JsonPoplistItem} from '../../generated-api';
import {selectCodebooks} from '../../store/selectors';

interface Props {
	value: any;
	poplist: string;
}

const PoplistValue = ({ value, poplist }: Props) => {
	
	const codebooks = useSelector(selectCodebooks);
	const label = codebooks[poplist]?.find((item: JsonPoplistItem) => item.value === value || item.value === value + '' )?.label;

	return (
		<span>{label}</span>
	);
}

export default PoplistValue;
