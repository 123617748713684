/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonCompanyBranch,
    ApiResultOfJsonCompanyBranchFromJSON,
    ApiResultOfJsonCompanyBranchToJSON,
    ApiResultOfJsonCompanyBranchInfo,
    ApiResultOfJsonCompanyBranchInfoFromJSON,
    ApiResultOfJsonCompanyBranchInfoToJSON,
    ApiResultOfListOfJsonCompanyBranchInfo,
    ApiResultOfListOfJsonCompanyBranchInfoFromJSON,
    ApiResultOfListOfJsonCompanyBranchInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonCompanyBranch,
    JsonCompanyBranchFromJSON,
    JsonCompanyBranchToJSON,
} from '../models';

export interface GetCompanyBranchCountUsingGETRequest {
    companyId?: number;
    status?: GetCompanyBranchCountUsingGETStatusEnum;
    search?: string;
}

export interface GetCompanyBranchInfoUsingGETRequest {
    companyBranchId: number;
}

export interface GetCompanyBranchListUsingGETRequest {
    companyId?: number;
    status?: GetCompanyBranchListUsingGETStatusEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetCompanyBranchUsingGETRequest {
    companyBranchId: number;
}

export interface SaveCompanyBranchUsingPOSTRequest {
    json: JsonCompanyBranch;
    testOnly?: boolean;
}

/**
 * 
 */
export class CompanyBranchControllerApi extends runtime.BaseAPI {

    /**
     * getCompanyBranchCount
     */
    async getCompanyBranchCountUsingGETRaw(requestParameters: GetCompanyBranchCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-branch/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getCompanyBranchCount
     */
    async getCompanyBranchCountUsingGET(requestParameters: GetCompanyBranchCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getCompanyBranchCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyBranchInfo
     */
    async getCompanyBranchInfoUsingGETRaw(requestParameters: GetCompanyBranchInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyBranchInfo>> {
        if (requestParameters.companyBranchId === null || requestParameters.companyBranchId === undefined) {
            throw new runtime.RequiredError('companyBranchId','Required parameter requestParameters.companyBranchId was null or undefined when calling getCompanyBranchInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-branch/info/{companyBranchId}`.replace(`{${"companyBranchId"}}`, encodeURIComponent(String(requestParameters.companyBranchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyBranchInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyBranchInfo
     */
    async getCompanyBranchInfoUsingGET(requestParameters: GetCompanyBranchInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyBranchInfo> {
        const response = await this.getCompanyBranchInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyBranchList
     */
    async getCompanyBranchListUsingGETRaw(requestParameters: GetCompanyBranchListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonCompanyBranchInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.status !== undefined) {
            queryParameters['status'] = requestParameters.status;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-branch/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonCompanyBranchInfoFromJSON(jsonValue));
    }

    /**
     * getCompanyBranchList
     */
    async getCompanyBranchListUsingGET(requestParameters: GetCompanyBranchListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonCompanyBranchInfo> {
        const response = await this.getCompanyBranchListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getCompanyBranch
     */
    async getCompanyBranchUsingGETRaw(requestParameters: GetCompanyBranchUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyBranch>> {
        if (requestParameters.companyBranchId === null || requestParameters.companyBranchId === undefined) {
            throw new runtime.RequiredError('companyBranchId','Required parameter requestParameters.companyBranchId was null or undefined when calling getCompanyBranchUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/company-branch/{companyBranchId}`.replace(`{${"companyBranchId"}}`, encodeURIComponent(String(requestParameters.companyBranchId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyBranchFromJSON(jsonValue));
    }

    /**
     * getCompanyBranch
     */
    async getCompanyBranchUsingGET(requestParameters: GetCompanyBranchUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyBranch> {
        const response = await this.getCompanyBranchUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveCompanyBranch
     */
    async saveCompanyBranchUsingPOSTRaw(requestParameters: SaveCompanyBranchUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonCompanyBranch>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveCompanyBranchUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/company-branch`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonCompanyBranchToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonCompanyBranchFromJSON(jsonValue));
    }

    /**
     * saveCompanyBranch
     */
    async saveCompanyBranchUsingPOST(requestParameters: SaveCompanyBranchUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonCompanyBranch> {
        const response = await this.saveCompanyBranchUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetCompanyBranchCountUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
/**
    * @export
    * @enum {string}
    */
export enum GetCompanyBranchListUsingGETStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}
