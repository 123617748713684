import FormModal from "../../../../components/form/FormModal";
import CompanyPoplistItemForm, { CompanyPoplistItemFormData } from "../../../../forms/CompanyPoplistItemForm";
import { FormProps } from "../../../../model/form";
import { useAppTranslation } from "../../../../services/i18n";

const CompanyPoplistItemModal = (props: FormProps<CompanyPoplistItemFormData>) => {

  const t = useAppTranslation();

  return (
    <FormModal title={t( 'CompanyPoplist.' + props.data?.type + '.title' )} open={!!props?.data}>
      <CompanyPoplistItemForm { ...props }></CompanyPoplistItemForm>
    </FormModal>
	);
}

export default CompanyPoplistItemModal;
