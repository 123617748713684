import {Grid} from '@mui/material';
import { FormikErrors } from 'formik';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {JsonUser} from '../generated-api';
import { createNormalizePhone, formatPhone } from '../helpers/formfield';
import { isEmailValid, isPhoneValid } from '../helpers/validators';
import {FormProps} from '../model/form';
import {useAppTranslation} from '../services/i18n';

interface Props extends FormProps<JsonUser> {}

const CompanyAdminForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonUser) => {
        let errors = {} as FormikErrors<JsonUser>;
        if (!values.email) {
            errors['email'] = t('Email je povinný.');
        } else if (!isEmailValid(values.email)) {
            errors['email'] = t('Zadejte platnou emailovou adresu.');
        }
        if (!values.firstName) {
            errors['firstName'] = t('Křestní jméno je povinné.');
        }
        if (!values.lastName) {
            errors['lastName'] = t('Příjmení je povinné.');
        }
        if (!values.sex) {
            errors['sex'] = t('Pohlaví je povinné.');
        }
        if (!values.phone) {
            errors['phone'] = t('Telefon je povinný.');
        } else if (!isPhoneValid(values.phone)) {
            errors['phone'] = t('Zadejte platné telefonní číslo.');
        }
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <TextFormfield name="firstName" label={t('Jméno')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <TextFormfield name="lastName" label={t('Příjmení')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="sex" label={t('Pohlaví')} poplist="sex"></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <TextFormfield name="email" label={t('E-mail')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <TextFormfield name="phone" label={t('Telefon')} formatValue={formatPhone} normalizeValue={createNormalizePhone(false)}></TextFormfield>
            </Grid>
		</FormContainer>
	);
}

export default CompanyAdminForm;
