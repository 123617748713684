/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonStratComment,
    ApiResultOfJsonStratCommentFromJSON,
    ApiResultOfJsonStratCommentToJSON,
    ApiResultOfJsonStratCommentInfo,
    ApiResultOfJsonStratCommentInfoFromJSON,
    ApiResultOfJsonStratCommentInfoToJSON,
    ApiResultOfListOfJsonStratCommentInfo,
    ApiResultOfListOfJsonStratCommentInfoFromJSON,
    ApiResultOfListOfJsonStratCommentInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonStratComment,
    JsonStratCommentFromJSON,
    JsonStratCommentToJSON,
} from '../models';

export interface GetStratCommentCountUsingGETRequest {
    onepagerId?: number;
    search?: string;
}

export interface GetStratCommentInfoUsingGETRequest {
    commentId: number;
}

export interface GetStratCommentListUsingGETRequest {
    onepagerId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetStratCommentUsingGETRequest {
    commentId: number;
}

export interface SaveStratCommentUsingPOSTRequest {
    json: JsonStratComment;
    testOnly?: boolean;
}

/**
 * 
 */
export class StratCommentControllerApi extends runtime.BaseAPI {

    /**
     * getStratCommentCount
     */
    async getStratCommentCountUsingGETRaw(requestParameters: GetStratCommentCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.onepagerId !== undefined) {
            queryParameters['onepagerId'] = requestParameters.onepagerId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-comment/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getStratCommentCount
     */
    async getStratCommentCountUsingGET(requestParameters: GetStratCommentCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getStratCommentCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratCommentInfo
     */
    async getStratCommentInfoUsingGETRaw(requestParameters: GetStratCommentInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratCommentInfo>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling getStratCommentInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-comment/info/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratCommentInfoFromJSON(jsonValue));
    }

    /**
     * getStratCommentInfo
     */
    async getStratCommentInfoUsingGET(requestParameters: GetStratCommentInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratCommentInfo> {
        const response = await this.getStratCommentInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratCommentList
     */
    async getStratCommentListUsingGETRaw(requestParameters: GetStratCommentListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratCommentInfo>> {
        const queryParameters: any = {};

        if (requestParameters.onepagerId !== undefined) {
            queryParameters['onepagerId'] = requestParameters.onepagerId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-comment/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratCommentInfoFromJSON(jsonValue));
    }

    /**
     * getStratCommentList
     */
    async getStratCommentListUsingGET(requestParameters: GetStratCommentListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratCommentInfo> {
        const response = await this.getStratCommentListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratComment
     */
    async getStratCommentUsingGETRaw(requestParameters: GetStratCommentUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratComment>> {
        if (requestParameters.commentId === null || requestParameters.commentId === undefined) {
            throw new runtime.RequiredError('commentId','Required parameter requestParameters.commentId was null or undefined when calling getStratCommentUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-comment/{commentId}`.replace(`{${"commentId"}}`, encodeURIComponent(String(requestParameters.commentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratCommentFromJSON(jsonValue));
    }

    /**
     * getStratComment
     */
    async getStratCommentUsingGET(requestParameters: GetStratCommentUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratComment> {
        const response = await this.getStratCommentUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveStratComment
     */
    async saveStratCommentUsingPOSTRaw(requestParameters: SaveStratCommentUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratComment>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveStratCommentUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/strat-comment`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonStratCommentToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratCommentFromJSON(jsonValue));
    }

    /**
     * saveStratComment
     */
    async saveStratCommentUsingPOST(requestParameters: SaveStratCommentUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratComment> {
        const response = await this.saveStratCommentUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
