import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import BarChartIcon from '@mui/icons-material/BarChart';
import {PropsWithChildren, useCallback} from 'react';
import { SimpleListAction } from "../../components/layout/SimpleList";
import {JsonGroup, JsonGroupInfo, JsonPoll, JsonPollInfo, JsonRelayItem, JsonRelayItemItemTypeEnum} from "../../generated-api";
import { useAppTranslation } from "../../services/i18n";
import RelayItemPollQuestion from '../../components/relay/RelayItemPollQuestion';
import { useSelector } from 'react-redux';
import { selectRelayPollsDef } from '../../store/selectors';
import RelayItemPollResults from '../../components/relay/RelayItemPollResults';
import layoutStyles from '../../assets/styles/layout';
import style from "../../assets/styles/layout";
import * as React from "react";
import classnames from "classnames";

export type RelayItemStatus = 'PENDING' | 'AVAILABLE' | 'ACTIVE' | 'FINISHED';

export interface RelayPollListItem extends JsonPollInfo {
    pollQuestionActiveItem: JsonRelayItem,
    pollResultActiveItem: JsonRelayItem,
    pollQuestionCount: number,
    pollResultCount: number,
    pollLastResultItem: JsonRelayItem,
    pollQuestionStatus: RelayItemStatus,
    pollResultStatus: RelayItemStatus,
}


interface ActionsProps {
    statusQuestion: RelayItemStatus | null;
    statusResults: RelayItemStatus | null;
    countQuestion: number;
    countResults: number;
    onStartQuestion?: () => void;
    onStopQuestion?: () => void;
    onStartResults?: () => void;
    onStopResults?: () => void;
    onRefresh?: () => void;
}

const RelayItemActions = ({ statusQuestion, statusResults, countQuestion, countResults, onStartQuestion, onStopQuestion, onStartResults, onStopResults, onRefresh }: PropsWithChildren<ActionsProps>) => {
    return (
        <div className={style.buttons}>
            { onStartQuestion && statusQuestion === 'AVAILABLE' && <div className={style.button} onClick={onStartQuestion}><FormatListBulletedIcon/>&nbsp;&nbsp;Zobrazit otázku</div> }
            { onStopQuestion && statusQuestion === 'ACTIVE' && <div className={classnames(style.button, style.turnOn)} onClick={onStopQuestion}><FormatListBulletedIcon/>&nbsp;&nbsp;Vypnout otázku</div> }
            { onStartResults && statusResults === 'AVAILABLE' && <div className={style.button} onClick={onStartResults}><BarChartIcon/>&nbsp;&nbsp;Zobrazit graf</div> }
            { onStopResults && statusResults === 'ACTIVE' && <div className={classnames(style.button, style.turnOn)} onClick={onStopResults}><BarChartIcon/>&nbsp;&nbsp;Vypnout graf</div> }
        </div>
    )
}


const renderRow = (row: RelayPollListItem, props: PropsWithChildren<Props>, t: any, showVoters?: boolean, group?: JsonGroupInfo, poll?: JsonPoll, groupIndex?: number) => {

    const handleOnStartQuestion = !!group ? undefined : () => props.action && props.action(row, 'START_' + JsonRelayItemItemTypeEnum.PollQuestion);
    const handleOnStopQuestion = !!group ? undefined : () => props.action && props.action(row, 'STOP_' + JsonRelayItemItemTypeEnum.PollQuestion);

    const handleOnStartResults = !!group ? undefined : () => props.action && props.action(row, 'START_' + JsonRelayItemItemTypeEnum.PollResults);
    const handleOnStopResults = !!group ? undefined : () => props.action && props.action(row, 'STOP_' + JsonRelayItemItemTypeEnum.PollResults);

    const handleOnRefresh = !!group ? undefined : () => props.action && props.action(row, 'REFRESH_POLL');

    return <>
        { !group &&
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0px' }}>
            <div>
                { !group &&
                    <div className={layoutStyles.remotePageTitle}>
                        <div className={layoutStyles.questionOrder}>Otázka {props.itemIndex + 1}</div>
                        <div>{row.title}</div>
                    </div>
                }
                { /* <div className={layoutStyles.listInfo}>{ row.pollType } / { row.chartType }</div> */ }
                { /* !group && row.pollQuestionStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Je vysílána otázka')}</div> */ }
                { /* !group && row.pollResultStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Jsou vysílány výsledky')}</div> */ }
            </div>
            { /* !group && <div style={{ width: '75px' }}>
                { renderRowButtons(props.action, row, false, row?.chartType !== JsonPollInfoChartTypeEnum.None) }
            </div> */ }
        </div>
        }
        { poll?.pollId &&
            <div>
                    { !poll.groupId &&
                        <>
                            <RelayItemActions
                                statusQuestion={row.pollQuestionStatus}
                                statusResults={row.pollResultStatus}
                                countQuestion={row.pollQuestionCount}
                                countResults={row.pollResultCount}
                                onStartQuestion={handleOnStartQuestion}
                                onStopQuestion={handleOnStopQuestion}
                                onStartResults={handleOnStartResults}
                                onStopResults={handleOnStopResults}
                                onRefresh={handleOnRefresh}
                            />
                        </>
                    }
                    <RelayItemPollQuestion
                        poll={poll}
                        group={group as JsonGroup}
                        results={{}}
                        responses={[]}
                    />
                    <RelayItemPollResults
                        poll={poll}
                        group={group as JsonGroup}
                        groupIndex={groupIndex}
                        results={row.pollLastResultItem?.pollResults || {}}
                        responses={[]}
                    />
                    {row.pollLastResultItem?.pollResults && showVoters && <div className={style.votersCount}>{t('Počet odpovědí')}: <b>{row.pollLastResultItem.pollResults.userCount}</b></div>}
            </div>
        }
    </>
}

interface Props {
    itemIndex: number;
    item: RelayPollListItem;
    items: RelayPollListItem[];
    groups: JsonGroupInfo[] | null;
    action: SimpleListAction<RelayPollListItem>;
}

const RemoteControllerItem = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();
    const polls = useSelector(selectRelayPollsDef);

    const renderItem = useCallback(() => {

        const row = props.item;
        const groupsDone: number[] = [];
        const groupId = row.groupId;
        const poll = polls?.find((poll: JsonPoll) => poll.pollId === row.pollId);

        if (groupId) {
            const group = props.groups?.find((g) => g.groupId === groupId);
            const groupRows = props.items?.filter((r) => r.groupId === groupId);
            const poll = polls?.find((poll: JsonPoll) => poll.pollId === row.pollId);
            if (group && groupRows) {
                if (groupsDone.indexOf(groupId) >= 0) {
                    return null;
                }
                groupsDone.push(groupId);
                return <div>
                    <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '5px'}}>
                        <div>
                            <div className={layoutStyles.listTitle}><span>Otázka {props.itemIndex + 1}</span><br/> {group.title || t('(skupina bez názvu)')}</div>
                            { /* row.pollQuestionStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Je vysílána skupina otázek')}</div> */}
                            { /* row.pollResultStatus === 'ACTIVE' && <div className={relayStyles.onAirInfo}>{t('Je vysílána skupina výsledků')}</div> */}
                        </div>
                        { /* <div style={{ width: '75px' }}>
                            { renderRowButtons(props.action, row, true, true) }
                        </div> */ }
                    </div>
                    <div  style={{display: 'flex', flexFlow: 'column'}}>
                        {
                            poll ? <RelayItemActions
                                statusQuestion={row.pollQuestionStatus}
                                statusResults={row.pollResultStatus}
                                countQuestion={row.pollQuestionCount}
                                countResults={row.pollResultCount}
                                onStartQuestion={() => props.action && props.action(row, 'START_GROUP_QUESTION')}
                                onStopQuestion={() => props.action && props.action(row, 'STOP_GROUP_QUESTION')}
                                onStartResults={() => props.action && props.action(row, 'START_GROUP_RESULTS')}
                                onStopResults={() => props.action && props.action(row, 'STOP_GROUP_RESULTS')}
                                onRefresh={() => props.action && props.action(row, 'REFRESH_GROUP')}
                            /> : <></>
                        }
                        {groupRows.map((row, i) => <div key={i}>{renderRow(row, props, t, i === groupRows.length - 1, group,  polls?.find((poll: JsonPoll) => poll.pollId === row.pollId), i)}</div>)}
                    </div>
                </div>
            }
        }
        return renderRow(row, props, t, true, undefined, poll);
    }, [props, polls, t]);

    return (
        <div>
            { renderItem() }
        </div>
	);
}

export default RemoteControllerItem;
