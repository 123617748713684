import * as React from 'react';
import styles from '../../../assets/styles/stratairu.module.css';
import {PropsWithChildren} from "react";
import {useAppTranslation} from "../../../services/i18n";
import {API_CONFIG} from "../../../app/api-config";
import {useSelector} from "react-redux";
import {selectStrategyContext} from "../../../store/selectors";

interface Props {
}

const StratairuPageHeader = ({ children }: PropsWithChildren<Props>) => {
	
    const t = useAppTranslation();

    const strategyContext = useSelector(selectStrategyContext);

	return (
        <div className={styles.pageHeader}>
            <div>
                { strategyContext.company?.logoFileGuid && <img alt={`logo`} style={{ height: '100px' }} src={`${API_CONFIG.basePath}/rest-service/download/?guid=${strategyContext.company.logoFileGuid}`} /> }
            </div>
            <div style={{ flex: 1 }}>{ children }</div>
        </div>
	);
}

export default StratairuPageHeader;
