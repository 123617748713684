import {Button, Container, Grid} from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import PageHeader from "../../../components/layout/PageHeader";
import {
  JsonPoll,
  JsonPollInfo,
  JsonRelayItemItemTypeEnum,
  StartRelayItemGroupUsingPOSTItemTypeEnum,
  StartRelayItemUsingPOSTItemTypeEnum,
  StopRelayItemGroupUsingPOSTItemTypeEnum,
  
} from "../../../generated-api";
import { useAppTranslation } from "../../../services/i18n";
import { useAppDispatch } from "../../../store";
import {fetchPoll, fetchRelay, fetchRelayGroups, fetchRelayItems, fetchRelayPolls, startRelayGroup, startRelayItem, stopRelayGroup, stopRelayItem} from "../../../store/relay";
import {selectRelay, selectRelayGroups, selectRelayPolls, selectRelayPollsDef, selectRelayPollsWithStatus} from "../../../store/selectors";
import MicroSiteMainPage from "../../microsite/MicroSiteMainPage";
import RelayPoolList, { RelayPollListItem } from "./components/RelayPoolList";
import styles from '../../../assets/styles/relay';
import layoutStyles from '../../../assets/styles/layout';
import CleanResultsModal from "./components/CleanResultsModal";


const RelayPage = () => {

  const t = useAppTranslation();
  const dispatch = useAppDispatch();
  const [previewQuestions, setPreviewQuestions] = useState<number[]>([]);
  const [previewResults, setPreviewResults] = useState<number[]>([]);
  const [showCleanResult, setShowCleanResult] = useState<boolean>(false);

  const relay = useSelector(selectRelay);
  const items = useSelector(selectRelayPollsWithStatus);
  const groups = useSelector(selectRelayGroups);
  const polls = useSelector(selectRelayPollsDef);
  const relayPolls = useSelector(selectRelayPolls);

  const { id } = useParams();

  useEffect(() => {
    if (id) {
      dispatch(fetchRelay(+id));
      dispatch(fetchRelayGroups(+id));
      dispatch(fetchRelayPolls(+id));
      dispatch(fetchRelayItems(+id));
    }
	}, [dispatch, id]);

  const handleAction = useCallback(async (poll: RelayPollListItem, action: string) => {

    if (!poll.pollId) {
      return;
    }

    // load poll deifinition if needed
    if (poll.groupId) {
      const pollToLoad: Promise<any>[] = [];
      const pollIds = relayPolls.filter((tempPoll: JsonPollInfo) => tempPoll.groupId === poll.groupId).map((tempPoll: JsonPollInfo) => tempPoll.pollId);
      pollIds.forEach((tempPollId: number) => {
        if (!polls?.find((pollDef: JsonPoll) => tempPollId === pollDef.pollId)) {
          pollToLoad.push(dispatch(fetchPoll(tempPollId)));
        }
      });
      await Promise.all(pollToLoad);
    } else {
      if (!polls?.find((pollDef: JsonPoll) => pollDef.pollId === poll.pollId)) {
        await dispatch(fetchPoll(poll.pollId));
      }
    }

    const groupPollIds = relayPolls.filter((tempPoll: JsonPollInfo) => tempPoll.groupId === poll.groupId).map((tempPoll: JsonPollInfo) => tempPoll.pollId) || [];

    switch (action) {
      case 'GROUP_QUESTION':
        // console.log({ polls, relayPolls });
        setPreviewResults((state: number[]) => state.filter((id: number) => !groupPollIds.includes(id)));
        if (!previewQuestions.includes(poll.pollId)) {
          setPreviewQuestions((state: number[]) => ([ ...state, ...groupPollIds ]));
        } else {
          setPreviewQuestions((state: number[]) => state.filter((id: number) => !groupPollIds.includes(id)));
        }
        break;
      case 'GROUP_RESULTS':
        // console.log({ polls, relayPolls });
        setPreviewQuestions((state: number[]) => state.filter((id: number) => !groupPollIds.includes(id)));
        if (!previewResults.includes(poll.pollId)) {
          setPreviewResults((state: number[]) => ([ ...state, ...groupPollIds ]));
        } else {
          setPreviewResults((state: number[]) => state.filter((id: number) => !groupPollIds.includes(id)));
        }
        break;      
      case JsonRelayItemItemTypeEnum.PollQuestion:
        setPreviewResults((state: number[]) => state.filter((id: number) => id !== poll.pollId));
        if (!previewQuestions.includes(poll.pollId)) {
          setPreviewQuestions((state: number[]) => ([ ...state, poll.pollId || 0 ]));
        } else {
          setPreviewQuestions((state: number[]) => state.filter((id: number) => id !== poll.pollId));
        }
        break;
      case JsonRelayItemItemTypeEnum.PollResults:
        setPreviewQuestions((state: number[]) => state.filter((id: number) => id !== poll.pollId));
        if (!previewResults.includes(poll.pollId)) {
          setPreviewResults((state: number[]) => ([ ...state, poll.pollId || 0 ]));
        } else {
          setPreviewResults((state: number[]) => state.filter((id: number) => id !== poll.pollId));
        }
        break;
      case 'START_POLL_QUESTION': 
      case 'START_POLL_RESULTS':
        // console.log('START', action);
        if (id) {
          await dispatch(startRelayItem({
            relayId: +id,
            itemType: action === 'START_POLL_QUESTION' ? StartRelayItemUsingPOSTItemTypeEnum.PollQuestion : StartRelayItemUsingPOSTItemTypeEnum.PollResults, 
            componentId: poll.pollId 
          }));
          await dispatch(fetchRelayItems(+id));
        }
        break;
      case 'STOP_POLL_QUESTION':
      case 'STOP_POLL_RESULTS':
        // console.log('STOP', action, poll);
        if (id) {
          await dispatch(stopRelayItem({
            relayId: +id,
            itemId: (action === 'STOP_POLL_QUESTION' ? poll.pollQuestionActiveItem.itemId : poll.pollResultActiveItem.itemId) as number,
          }));
          await dispatch(fetchRelayItems(+id));
        }
        break;
      case 'START_GROUP_QUESTION': 
      case 'START_GROUP_RESULTS':
        // console.log({ start: poll });
        // console.log('START_GROUP', action);
        if (id && poll.groupId) {
          await dispatch(startRelayGroup({
            relayId: +id,
            itemType: action === 'START_GROUP_QUESTION' ? StartRelayItemGroupUsingPOSTItemTypeEnum.PollQuestion : StartRelayItemGroupUsingPOSTItemTypeEnum.PollResults, 
            groupId: poll.groupId
          }));
          await dispatch(fetchRelayItems(+id));
        }
        break;
      case 'STOP_GROUP_QUESTION': 
      case 'STOP_GROUP_RESULTS':
        // console.log({ start: poll });
        // console.log('START_GROUP', action);
        if (id && poll.groupId) {
          await dispatch(stopRelayGroup({
            relayId: +id,
            itemType: action === 'STOP_GROUP_QUESTION' ? StopRelayItemGroupUsingPOSTItemTypeEnum.PollQuestion : StopRelayItemGroupUsingPOSTItemTypeEnum.PollResults,
            groupId: poll.groupId
          }));
          await dispatch(fetchRelayItems(+id));
        }
        break;
      case 'REFRESH_POLL':
      case 'REFRESH_GROUP':
        if (id) {
          await dispatch(fetchRelayItems(+id));
        }
        break;
      default:
        break;
    }
  }, [dispatch, id, previewQuestions, previewResults, relayPolls, polls])

  const handleOpenFullscreen = useCallback(() => {
    console.log('open fullscreen');
    window.open(`/microsite?relayGuid=${relay?.relayGuid}&clientGuid=results`);
  }, [relay]);

  const handleOpenSimpleFullscreen = useCallback((type: string, id: number) => {
    console.log('open simple fullscreen');
    window.open(`/microsite/simple-fullscreen/${relay?.relayId}/${type}/${id}`);
  }, [relay])

	return (
		<Container>
          <PageHeader title={t('Vysílání')}>
            <div style={{ display: 'flex', gap: '10px', width: '400px' }}>
            <Button variant="contained" className={layoutStyles.yellowButton} fullWidth onClick={() => setShowCleanResult(true)}>{t('Vymazat výsledky')}</Button>
            <Button variant="contained" className={layoutStyles.yellowButton} fullWidth onClick={handleOpenFullscreen}>{t('Fullscreen grafy')}</Button>
            </div>
          </PageHeader>

          <Grid container>
            <Grid item xs={12} md={6}>
              { /*
                <span style={{color: 'white'}}>{ JSON.stringify(polls, null, 2) }</span>
                <span style={{color: 'white'}}>{ JSON.stringify(previewQuestions, null, 2) }</span>
                <span style={{color: 'white'}}>{ JSON.stringify(items, null, 2) }</span> }
                */
              }
              <RelayPoolList rows={items} groups={groups} action={handleAction} previewQuestions={previewQuestions} previewResults={previewResults} fullscreenResults={handleOpenSimpleFullscreen}></RelayPoolList>
            </Grid>
            <Grid item xs={12} md={6}>
              {relay &&
                <>
                <div className={styles.onAir}>{t('ON AIR')}</div>
                <MicroSiteMainPage relayGuid={relay.relayGuid} clientGuid="admin"></MicroSiteMainPage>
                </>
              }
            </Grid>
          </Grid>
          <CleanResultsModal open={showCleanResult} relayId={relay?.relayId} onClose={() => setShowCleanResult(false)}/>
		</Container>
	);
}

export default RelayPage;
