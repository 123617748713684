import logoBasic from '../../assets/images/atairu_logo.png';
import logoTV from '../../assets/images/atairu_logo_tv.png';

interface Props {
      type: 'basic' | 'tv';
}

const AtairuHeader = ({ type }: Props) => {

	return (
            <div style={{ display: 'flex', justifyContent: 'center', margin: '0px', alignItems: 'center' }}>
                  <img alt="logo" src={type === 'basic' ? logoBasic : logoTV} style={{ height: '150px' }}/>
            </div>
	);
}

export default AtairuHeader;
