/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonStratPillar,
    ApiResultOfJsonStratPillarFromJSON,
    ApiResultOfJsonStratPillarToJSON,
    ApiResultOfJsonStratPillarInfo,
    ApiResultOfJsonStratPillarInfoFromJSON,
    ApiResultOfJsonStratPillarInfoToJSON,
    ApiResultOfListOfJsonStratPillarInfo,
    ApiResultOfListOfJsonStratPillarInfoFromJSON,
    ApiResultOfListOfJsonStratPillarInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonStratPillar,
    JsonStratPillarFromJSON,
    JsonStratPillarToJSON,
} from '../models';

export interface GetStratPillarCountUsingGETRequest {
    pivotEnablerId?: number;
    pivotInitiativeId?: number;
    pivotPillarId?: number;
    pivotMode?: GetStratPillarCountUsingGETPivotModeEnum;
    search?: string;
}

export interface GetStratPillarInfoUsingGETRequest {
    pillarId: number;
}

export interface GetStratPillarListUsingGETRequest {
    pivotEnablerId?: number;
    pivotInitiativeId?: number;
    pivotPillarId?: number;
    pivotMode?: GetStratPillarListUsingGETPivotModeEnum;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetStratPillarUsingGETRequest {
    pillarId: number;
}

export interface SaveStratPillarUsingPOSTRequest {
    json: JsonStratPillar;
    testOnly?: boolean;
}

/**
 * 
 */
export class StratPillarControllerApi extends runtime.BaseAPI {

    /**
     * getStratPillarCount
     */
    async getStratPillarCountUsingGETRaw(requestParameters: GetStratPillarCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.pivotEnablerId !== undefined) {
            queryParameters['pivotEnablerId'] = requestParameters.pivotEnablerId;
        }

        if (requestParameters.pivotInitiativeId !== undefined) {
            queryParameters['pivotInitiativeId'] = requestParameters.pivotInitiativeId;
        }

        if (requestParameters.pivotPillarId !== undefined) {
            queryParameters['pivotPillarId'] = requestParameters.pivotPillarId;
        }

        if (requestParameters.pivotMode !== undefined) {
            queryParameters['pivotMode'] = requestParameters.pivotMode;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-pillar/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getStratPillarCount
     */
    async getStratPillarCountUsingGET(requestParameters: GetStratPillarCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getStratPillarCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratPillarInfo
     */
    async getStratPillarInfoUsingGETRaw(requestParameters: GetStratPillarInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratPillarInfo>> {
        if (requestParameters.pillarId === null || requestParameters.pillarId === undefined) {
            throw new runtime.RequiredError('pillarId','Required parameter requestParameters.pillarId was null or undefined when calling getStratPillarInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-pillar/info/{pillarId}`.replace(`{${"pillarId"}}`, encodeURIComponent(String(requestParameters.pillarId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratPillarInfoFromJSON(jsonValue));
    }

    /**
     * getStratPillarInfo
     */
    async getStratPillarInfoUsingGET(requestParameters: GetStratPillarInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratPillarInfo> {
        const response = await this.getStratPillarInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratPillarList
     */
    async getStratPillarListUsingGETRaw(requestParameters: GetStratPillarListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStratPillarInfo>> {
        const queryParameters: any = {};

        if (requestParameters.pivotEnablerId !== undefined) {
            queryParameters['pivotEnablerId'] = requestParameters.pivotEnablerId;
        }

        if (requestParameters.pivotInitiativeId !== undefined) {
            queryParameters['pivotInitiativeId'] = requestParameters.pivotInitiativeId;
        }

        if (requestParameters.pivotPillarId !== undefined) {
            queryParameters['pivotPillarId'] = requestParameters.pivotPillarId;
        }

        if (requestParameters.pivotMode !== undefined) {
            queryParameters['pivotMode'] = requestParameters.pivotMode;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-pillar/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStratPillarInfoFromJSON(jsonValue));
    }

    /**
     * getStratPillarList
     */
    async getStratPillarListUsingGET(requestParameters: GetStratPillarListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStratPillarInfo> {
        const response = await this.getStratPillarListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStratPillar
     */
    async getStratPillarUsingGETRaw(requestParameters: GetStratPillarUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratPillar>> {
        if (requestParameters.pillarId === null || requestParameters.pillarId === undefined) {
            throw new runtime.RequiredError('pillarId','Required parameter requestParameters.pillarId was null or undefined when calling getStratPillarUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strat-pillar/{pillarId}`.replace(`{${"pillarId"}}`, encodeURIComponent(String(requestParameters.pillarId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratPillarFromJSON(jsonValue));
    }

    /**
     * getStratPillar
     */
    async getStratPillarUsingGET(requestParameters: GetStratPillarUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratPillar> {
        const response = await this.getStratPillarUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveStratPillar
     */
    async saveStratPillarUsingPOSTRaw(requestParameters: SaveStratPillarUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStratPillar>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveStratPillarUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/strat-pillar`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonStratPillarToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStratPillarFromJSON(jsonValue));
    }

    /**
     * saveStratPillar
     */
    async saveStratPillarUsingPOST(requestParameters: SaveStratPillarUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStratPillar> {
        const response = await this.saveStratPillarUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum GetStratPillarCountUsingGETPivotModeEnum {
    Up = 'DRILL_UP',
    Down = 'DRILL_DOWN'
}
/**
    * @export
    * @enum {string}
    */
export enum GetStratPillarListUsingGETPivotModeEnum {
    Up = 'DRILL_UP',
    Down = 'DRILL_DOWN'
}
