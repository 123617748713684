import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import { RootState } from '..';
import {API_CONFIG} from '../app/api-config';
import {
	AuthControllerApi,
	GetUserCountUsingGETRequest,
	GetUserListUsingGETRequest,
	JsonSerie,
	JsonSerieInfo,
	SerieControllerApi
} from '../generated-api';
import {LiveContextInfo} from "../model/live";

const authApi = new AuthControllerApi(API_CONFIG);
const seriesApi = new SerieControllerApi(API_CONFIG);

export const fetchSeries = createAsyncThunk('series/list', async (arg: { page?: number, offset?: number }, thunkApi) => {
	
	const { currentPage, rowsPerPage, filter } = (thunkApi.getState() as RootState).series;

	const params = {
		start: arg.page ? (arg.page - 1) * rowsPerPage : ((currentPage - 1) + (arg?.offset || 0)) * rowsPerPage,
		rows: rowsPerPage,
		...filter
	} as GetUserListUsingGETRequest;
	
	return await seriesApi.getSerieListUsingGET(params);
});

export const fetchSeriesCount = createAsyncThunk('series/count', async (_: void, thunkApi) => {

	const { filter } = (thunkApi.getState() as RootState).users; 

	const params = {
		...filter
	} as GetUserListUsingGETRequest;

	return await seriesApi.getSerieCountUsingGET(params);
});

export const fetchSerie = createAsyncThunk('serie/fetch', async (serieId: number) => {
	return await seriesApi.getSerieUsingGET({ serieId });
});

export const saveSerie = createAsyncThunk('serie/save', async (serie: JsonSerie) => {
	delete serie['createdAt'];
	delete serie['createdBy'];
	delete serie['updatedAt'];
	delete serie['updatedBy'];
	return await seriesApi.saveSerieUsingPOST({ json: serie });
});

export const fetchSerieUserContextByCode = createAsyncThunk('serie/fetch-user-context-by-code', async (clientGuid: string): Promise<LiveContextInfo|undefined> => {
	const userSerieResult = await authApi.getSerieUserByClientGuidUsingGET({ clientGuid });
	if ('error' in userSerieResult) {
		return undefined;
	}
	return {
		context: userSerieResult?.data?.context,
		user: userSerieResult?.data?.user
	};
});

export const deleteUser = createAsyncThunk('serie/delete', async (serie: JsonSerie) => {
	// TODO
});

export type SerieState = {
	loading: boolean;
	rowsPerPage: number;
	currentPage: number;
	count: number;
	items: JsonSerieInfo[];
	filter: GetUserCountUsingGETRequest;
}

const initialState: SerieState = {
	loading: false,
	rowsPerPage: 10,
	currentPage: 1,
	count: 0,
	items: [],
	filter: {}
};

export const seriesSlice = createSlice({
	name: 'series',
	initialState,
	reducers: {
	},
	extraReducers: builder => {
		builder.addCase(fetchSeriesCount.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchSeriesCount.fulfilled, (state, action) => ({ ...state, count: action.payload.data || 0, loading: false }));
		builder.addCase(fetchSeriesCount.rejected, (state, action) => ({ ...state, count: 0, loading: false }));

		builder.addCase(fetchSeries.pending, (state, action) => ({ ...state, loading: true }));
		builder.addCase(fetchSeries.fulfilled, (state, action) => ({ ...state, items: action.payload.data || [], currentPage: action.meta.arg.page || state.currentPage, loading: false }));
		builder.addCase(fetchSeries.rejected, (state, action) => ({ ...state, items: [], loading: false }));
	}
});

export const seriesReducer = seriesSlice.reducer;
