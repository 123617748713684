/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';

/**
 * 
 * @export
 * @interface JsonCompanyUser
 */
export interface JsonCompanyUser {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonCompanyUser
     */
    allowedActions?: Array<JsonCompanyUserAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonCompanyUser
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUser
     */
    companyId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUser
     */
    companyUserId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUser
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUser
     */
    createdBy?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUser
     */
    roleCode?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonCompanyUser
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUser
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonCompanyUser
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonCompanyUserAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}

export function JsonCompanyUserFromJSON(json: any): JsonCompanyUser {
    return JsonCompanyUserFromJSONTyped(json, false);
}

export function JsonCompanyUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonCompanyUser {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'companyId': !exists(json, 'companyId') ? undefined : json['companyId'],
        'companyUserId': !exists(json, 'companyUserId') ? undefined : json['companyUserId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'roleCode': !exists(json, 'roleCode') ? undefined : json['roleCode'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonCompanyUserToJSON(value?: JsonCompanyUser | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'companyId': value.companyId,
        'companyUserId': value.companyUserId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'roleCode': value.roleCode,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

