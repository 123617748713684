import React, {PropsWithChildren, useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../../../assets/styles/stratairu-onepager.module.css';
import {OnepagerEntityIndex} from "../../../model/onepager";
import {useAppDispatch} from "../../../store";
import {setOnepagerActiveIndex, updateOnepagerEntity} from "../../../store/strategy";
import {selectStrategyOnePagerActiveIndex} from "../../../store/selectors";
import {useSelector} from "react-redux";
import {BtnBold, BtnItalic, Editor, EditorProvider, Toolbar} from "react-simple-wysiwyg";
import StratairuSanitizedText, {sanitizeText} from "./StratairuSanitizedText";

const addBR = (value: string) => {
    console.log({ value });
    let addTwo = true;
    if (value.lastIndexOf('<br/>') && value.lastIndexOf('<br/>') === value.length - 5) {
        addTwo = false;
    }
    if (value.lastIndexOf('<br>') && value.lastIndexOf('<br>') === value.length - 4) {
        addTwo = false;
    }
    return value + (addTwo ? '<br/><br/>' : '<br/>');
}

interface Props {
  index: OnepagerEntityIndex;
  readonly?: boolean;
  value?: string;
  onConfirm?: (index: OnepagerEntityIndex, data: string) => void;
  editableDraft?: boolean;
  sx?: any;
}

const StratairuOnePagerEditableText = ({ index, value, readonly, onConfirm, editableDraft, sx }: PropsWithChildren<Props>) => {

    const dispatch = useAppDispatch();

    const activeIndex = useSelector(selectStrategyOnePagerActiveIndex);

    const [textValue, setTextValue] = useState<string>(value||'');
    const [isChanged, setIsChanged] = useState<boolean>(false);

    const isActive = useMemo(() => {
        return (JSON.stringify(activeIndex) === JSON.stringify(index)) && !readonly;
    }, [activeIndex, index, readonly]);

    const handleClick = useCallback(() => {
        if (!readonly) {
            dispatch(setOnepagerActiveIndex(index));
        }
    }, [dispatch, index, readonly])

    const handleEnter = useCallback((unsetIndex: boolean = true) => {
        // console.log('handleEnter', textValue, { activeIndex, index }, JSON.stringify(activeIndex) === JSON.stringify(index));
        if (!readonly) {
            if (onConfirm) {
                onConfirm(index, sanitizeText(textValue));
            } else {
                dispatch(updateOnepagerEntity({index: index, data: sanitizeText(textValue) }));
            }
            if (unsetIndex) {
                dispatch(setOnepagerActiveIndex(undefined));
            }
        }
    }, [index, textValue, dispatch, readonly, onConfirm])

    const handleEscape = useCallback(() => {
        if (isActive) {
            dispatch(setOnepagerActiveIndex(undefined));
        }
    }, [isActive, dispatch])

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (!isActive) {
                return false;
            }
            if (event.key === 'Enter') {
                // console.log({ ctrlKey: event.ctrlKey });
                if (event.ctrlKey) {
                    // get current cursor position;
                    /*
                    const editor = document.getElementsByClassName('rsw-ce');
                    const selection = window.getSelection();
                    let position: number | undefined = undefined;
                    if (selection && selection.rangeCount > 0) {
                        const range = selection.getRangeAt(0);
                        const preCaretRange = range.cloneRange();
                        preCaretRange?.selectNodeContents(editor[0]);
                        preCaretRange?.setEnd(range.endContainer, range.endOffset);
                        console.log(preCaretRange.toString().length);
                        position = preCaretRange.toString().length;
                    }
                    console.log({ innerHTML: editor[0].innerHTML })
                    if (position) {
                        editor[0].innerHTML = editor[0].innerHTML.substring(0, position) + '<br/>' + editor[0].innerHTML.substring(position + 1, editor[0].innerHTML.length);
                    }*/


                    setTextValue((value: string) => {
                        /*
                        if (position) {
                            setTimeout(() => {
                                const editor = document.getElementsByClassName('rsw-ce');
                                const selection = window.getSelection();
                                const range = document.createRange();
                                const textNode = editor[0].firstChild; // there are multiple children
                                range.setStart(textNode as Node, 10);
                                range.collapse(true);
                                selection?.removeAllRanges();
                                selection?.addRange(range);
                                (editor[0] as any).focus();
                            });
                            return value.substring(0, position) + '<br/>' + value.substring(position + 1, value.length);
                        } else {
                            return value;
                        }
                         */
                        return addBR(value);
                    });
                } else {
                    event.preventDefault();
                    handleEnter();
                }
            }
            if (event.key === 'Escape') {
                event.preventDefault();
                handleEscape();
            }
            setIsChanged(true);
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [handleEnter, handleEscape, textValue, setTextValue, isActive, value]);

    useEffect(() => {
        setTextValue(value||'');
    }, [value]);

    useEffect(() => {
        if (!isActive && isChanged && !onConfirm) {
            console.log({ isActive, isChanged });
            setIsChanged(false);
            setTimeout(() => handleEnter(false), 0);
        }
    }, [isActive, isChanged, handleEnter, onConfirm])

    return (
          <div onClick={() => handleClick()} className={`${styles.editableText} ${editableDraft ? styles.editableText : styles.readonlyEditableText} ${isActive ? styles.activeEditableText : undefined}`} style={sx}>
              { !isActive && <StratairuSanitizedText text={ textValue || '' } /> }
              { isActive && !readonly &&
                  <EditorProvider>
                      <Editor value={textValue} onChange={(event) => setTextValue(event.target.value)}>
                          <Toolbar>
                              <BtnBold />
                              <BtnItalic />
                          </Toolbar>
                      </Editor>
                  </EditorProvider>
              }
          </div>
	);
}

export default StratairuOnePagerEditableText;
