import {Tooltip} from '@mui/material';
import * as React from 'react';
import {useAppTranslation} from "../../../services/i18n";
import {OnepagerEntityIndex} from "../../../model/onepager";
import {
    JsonStratEnabler, JsonStratEnablerInfo, JsonStratInitiative,
    JsonStratOnepagerInfo,
    JsonStratPillar,
    JsonStratPillarInfo
} from "../../../generated-api";
import ClosableModal, {ModalButtonActionEnum} from "../../../components/layout/ClosableModal";
import {PillarStructureItem} from "./StratairuOnePagerPillarStructureModal";
import StratairuLabel from "./StratairuLabel";

interface Props {
    parentOnePager: JsonStratOnepagerInfo | undefined;
    index: OnepagerEntityIndex | undefined;
    onSelect: (index: OnepagerEntityIndex, pillar: Partial<JsonStratPillar>) => void;
    onClose: () => void;
}

const StratairuOnePagerPillarParentModal = ({ parentOnePager, index, onClose, onSelect }: Props) => {

    const t = useAppTranslation();
    const isParentRoot = !!parentOnePager?.parentUserId;

    return (
        <>
            { index &&
                <ClosableModal open={!!index} title={t('onePager.messages.selectParent')} onClose={onClose} buttons={[]} size="md">
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '20px', width: '100%', padding: '20px' }}>
                    { !!parentOnePager?.pillars && <div style={{ flex: 1 }}>
                            <StratairuLabel label={t(isParentRoot ? 'stratairu.titles.parentStrategicPillars' : 'stratairu.titles.parentInitiatives')}/>
                            <div>
                            {
                                parentOnePager.pillars.map((pillar: JsonStratPillarInfo) => {
                                    return pillar?.initiatives?.map((initiative: JsonStratInitiative) => {
                                        return <PillarStructureItem item={pillar} initiative={initiative} onSelect={() => onSelect(index, { parentInitiativeId: initiative.initiativeId, parentPillarId: pillar.pillarId })}/>
                                    });
                                })
                            }
                            </div>
                        </div>
                    }
                    { !!parentOnePager?.enablers && <div style={{ flex: 1 }}>
                        <StratairuLabel label={t('stratairu.titles.parentEnablers')}/>
                        <div>
                            {
                                parentOnePager.enablers.map((enabler: JsonStratEnabler) => {
                                    return <PillarStructureItem item={enabler as JsonStratEnablerInfo} onSelect={() => onSelect(index, { parentEnablerId: enabler.enablerId })}/>
                                })
                            }
                        </div>
                    </div>
                    }
                    </div>
                </ClosableModal>
            }
        </>
    );

}

export default StratairuOnePagerPillarParentModal;
