import { useAppTranslation } from "../services/i18n";
import {Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import * as React from "react";
import {JsonCompany, JsonSerie} from "../generated-api";
import SimpleDropzone from "../components/form/dropzone/SimpleDropzone";
import {useCallback, useEffect, useState} from "react";
import {importSerieUsers} from "../store/companies";
import {useAppDispatch} from "../store";
import styles from "../assets/styles/layout";
import dropzoneStyles from "../assets/styles/dropzone.module.css";
import {addMessage} from "../store/localApp";
import {checkImportResult, ImportUserError} from "../helpers/serie";
import CloseIcon from "@mui/icons-material/Close";

export interface ImportUsersParams {
    serieId: JsonSerie['serieId'];
    companyId: JsonCompany['companyId'];
}

interface Props {
    data: ImportUsersParams | null;
    onClose: () => void;
}
const ImportCompanySerieUsersModal = ({ data, onClose }: Props) => {

    const dispatch = useAppDispatch();
    const t = useAppTranslation();

    const [importFile, setImportFile] = useState<File | null>(null);
    const [importErrors, setImportErrors] = useState<ImportUserError[] | null>(null);

    useEffect(() => {
        setImportErrors(null);
        setImportFile(null);
    }, [data])

    const onDropImportFile = useCallback(async (file: File): Promise<boolean> => {
        try {
            const res = await dispatch(importSerieUsers({
                file,
                serieId: data?.serieId,
                companyId: data?.companyId,
                testOnly: true
            }));
            if (!('error' in res)) {
                const errors = checkImportResult(res.payload.data);
                setImportErrors(errors);
                if (errors) {
                    /*
                    dispatch(addMessage({
                        severity: 'error',
                        title: 'Importní soubor obsahuje chyby a nelze ho naimportovat',
                    }));
                     */
                    setImportFile(null);
                } else {
                    /*
                    dispatch(addMessage({
                        severity: 'success',
                        title: 'Importní soubor je v pořádku a je možné jej naimportovat',
                    }));
                     */
                    setImportFile(file);
                }
                return true;
            } else {
                dispatch(addMessage({
                    severity: 'error',
                    title: 'Importní soubor obsahuje chyby a nelze ho naimportovat',
                }));
            }
        } catch(e) {
        }
        setImportFile(null);
        return false;
    }, [dispatch, data])

    const handleImportFile = useCallback(async () => {
        if (!importFile || !data?.serieId || !data?.companyId) {
            dispatch(addMessage({
                severity: 'error',
                title: t('Není dostupný validní soubor pro import.'),
            }));
            return false;
        }
        if (importFile && data?.serieId && data?.companyId) {
            try {
                const res = await dispatch(importSerieUsers({
                    file: importFile,
                    serieId: data?.serieId,
                    companyId: data?.companyId,
                    testOnly: false
                }));
                if (!('error' in res)) {
                    dispatch(addMessage({
                        severity: 'success',
                        title: t('Import uživatelů proběhl v pořádku.'),
                    }));
                    onClose();
                    return true;
                }
            } catch(e) {
            }
        }
    }, [dispatch, data, importFile, onClose, t])

    return (
      <Dialog open={!!data}>
          <DialogTitle style={{ display: 'flex', justifyContent: 'space-between' }}>{ t('Hromadný import uživatelů k seriálu') }
              <CloseIcon style={{ cursor: 'pointer' }} onClick={onClose}/>
          </DialogTitle>
          <DialogContent>
              <div style={{ width: '100%', padding: '20px', boxSizing: 'border-box' }}>
                  <div style={{ marginBottom: '20px' }}>{ t('Použijte importní soubor v definovaném formátu pro import účastníku. Po nahrání souboru se provede jeho validace. V případě, že bude soubor v pořádku, bude jej možné naimportovat')}</div>
                  <SimpleDropzone onUploadFile={onDropImportFile} displayTimeout={0} hideMessages></SimpleDropzone>
                  { importFile && <>
                      <div className={dropzoneStyles.uploadedFileTitle}>Soubor připravený k importu</div>
                      <div>{ importFile.name }</div>
                      <Alert style={{ marginTop: '20px' }} severity="success">{ t('Importní soubor je v pořádku, stisknutím tlačítka importovat, provedete import do systému.') }</Alert>
                  </> }
                  { !!importErrors?.length &&
                      <>
                      <Alert style={{ marginTop: '20px' }} severity="error">{ t('Importní soubor obsahuje níže uvedené chyby a proto jej není možné použít.') }</Alert>
                      <div style={{ maxHeight: '300px' }}>
                        <div className={dropzoneStyles.uploadedFileTitle}>{ t('Chyby v importu:') }</div>
                        { importErrors.map((error) => {
                                return (<div><b>{t('Řádek #')}{error.row}:</b> { t('ImportErrors.' + error.error) === 'importErrors.' + error.error ? error.error : t('importErrors.' + error.error) }</div>)
                        })}
                      </div>
                      </>
                  }
              </div>
          </DialogContent>
          <DialogActions>
              <Button variant="contained" type="submit" fullWidth className={styles.yellowButton} onClick={handleImportFile}>{t('Importovat')}</Button>
              <Button variant="contained" onClick={() => onClose()} fullWidth>{t('Zrušit')}</Button>
          </DialogActions>
      </Dialog>
    );
}

export default ImportCompanySerieUsersModal;
