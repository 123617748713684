import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import InsertInvitationIcon from '@mui/icons-material/InsertInvitation';
import GroupAddIcon from '@mui/icons-material/GroupAdd';

import { Tooltip } from '@mui/material';

import {PropsWithChildren, useCallback} from 'react';
import SimpleList, {ColumnDefinition, SimpleListAction} from "../../../../components/layout/SimpleList";
import {JsonCompanySerieInfo, NotifyCompanySerieUsingPOSTNotificationTypeEnum} from "../../../../generated-api";
import {useAppTranslation} from "../../../../services/i18n";
import {useAppDispatch} from '../../../../store';
import {addMessage} from '../../../../store/localApp';
import styles from '../../../../assets/styles/layout';

interface Props {
    rows: JsonCompanySerieInfo[] | null;
    action: SimpleListAction<JsonCompanySerieInfo>;
}

const registrationBaseUrl = window.location.origin + '/registration/'

export enum CompanySeriesListActions {
    ImportUsers = 'IMPORT_USERS'
}


const CompanySeriesList = (props: PropsWithChildren<Props>) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const openRegistration = useCallback((link: string) => {
        window.open(link);
    }, [])

    const copyToClipboard = useCallback((link: string) => {
        let success = false;
        if (navigator?.clipboard) {
            navigator.clipboard.writeText(link);
            success = true;
        }
        if ((window as any)?.clipboardData) {
            (window as any)?.clipboardData.setData("Text", 'Copy this text to clipboard')
            success = true;
        }
        dispatch(addMessage({
            severity: success ? 'success' : 'error',
            title: success ? t('Link byl uložen do clipboardu.') : t('Nastala chyba při ukládání linku do clipboardu.'),
        }));
    }, [dispatch, t])

    const cols = [
        { 
            title: 'name', 
            renderer: (row: JsonCompanySerieInfo) => { 
                const registrationLink = registrationBaseUrl + row.serieGuid;
                return (
                    <>
                        <div><strong>{ row.serieTitle }</strong></div>
                        <div onClick={() => openRegistration(registrationLink)} className={styles.link}>{registrationLink}</div>
                        <div>{t('Počet registrovaných studentů')}: {row.companySerieUserCount}</div>
                    </>
                );
            }
        },
        {
            renderer: (row: JsonCompanySerieInfo) => {
                const registrationLink = registrationBaseUrl + row.serieGuid;
                return (
                    <>
                        <Tooltip title="Importovat účastníky">
                            <GroupAddIcon color="primary" onClick={() => props.action(row, CompanySeriesListActions.ImportUsers)} />
                        </Tooltip>
                        <Tooltip title="Kopírovat link do clipboardu">
                            <ContentCopyIcon color="primary" onClick={() => copyToClipboard(registrationLink)} />
                        </Tooltip>
                        <Tooltip title="Odeslat kód školení administrátorovi společnosti">
                            <ForwardToInboxIcon color="primary" onClick={() => props.action(row, NotifyCompanySerieUsingPOSTNotificationTypeEnum.SerieAdminLink)} />
                        </Tooltip>
                        <Tooltip title="Odeslat pozvánku zaregistrovaným studentům">
                            <InsertInvitationIcon color="primary" onClick={() => props.action(row, NotifyCompanySerieUsingPOSTNotificationTypeEnum.SerieUserLink)} />
                        </Tooltip>
                    </>
                );
            }
        }
    ] as ColumnDefinition<JsonCompanySerieInfo>[];

	return (
		<SimpleList cols={cols} config={{hideHeader: true, title: t('Přiřazené seriály')}} { ...props }></SimpleList>
	);
}

export default CompanySeriesList;
