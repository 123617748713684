/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonEpisode,
    ApiResultOfJsonEpisodeFromJSON,
    ApiResultOfJsonEpisodeToJSON,
    ApiResultOfJsonEpisodeInfo,
    ApiResultOfJsonEpisodeInfoFromJSON,
    ApiResultOfJsonEpisodeInfoToJSON,
    ApiResultOfListOfJsonEpisodeInfo,
    ApiResultOfListOfJsonEpisodeInfoFromJSON,
    ApiResultOfListOfJsonEpisodeInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonEpisode,
    JsonEpisodeFromJSON,
    JsonEpisodeToJSON,
} from '../models';

export interface GetEpisodeCountUsingGETRequest {
    serieId?: number;
    search?: string;
}

export interface GetEpisodeInfoUsingGETRequest {
    episodeId: number;
}

export interface GetEpisodeListUsingGETRequest {
    serieId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetEpisodeUsingGETRequest {
    episodeId: number;
}

export interface SaveEpisodeUsingPOSTRequest {
    json: JsonEpisode;
    testOnly?: boolean;
}

/**
 * 
 */
export class EpisodeControllerApi extends runtime.BaseAPI {

    /**
     * getEpisodeCount
     */
    async getEpisodeCountUsingGETRaw(requestParameters: GetEpisodeCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/episode/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getEpisodeCount
     */
    async getEpisodeCountUsingGET(requestParameters: GetEpisodeCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getEpisodeCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEpisodeInfo
     */
    async getEpisodeInfoUsingGETRaw(requestParameters: GetEpisodeInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEpisodeInfo>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getEpisodeInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/episode/info/{episodeId}`.replace(`{${"episodeId"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEpisodeInfoFromJSON(jsonValue));
    }

    /**
     * getEpisodeInfo
     */
    async getEpisodeInfoUsingGET(requestParameters: GetEpisodeInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEpisodeInfo> {
        const response = await this.getEpisodeInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEpisodeList
     */
    async getEpisodeListUsingGETRaw(requestParameters: GetEpisodeListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonEpisodeInfo>> {
        const queryParameters: any = {};

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/episode/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonEpisodeInfoFromJSON(jsonValue));
    }

    /**
     * getEpisodeList
     */
    async getEpisodeListUsingGET(requestParameters: GetEpisodeListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonEpisodeInfo> {
        const response = await this.getEpisodeListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getEpisode
     */
    async getEpisodeUsingGETRaw(requestParameters: GetEpisodeUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEpisode>> {
        if (requestParameters.episodeId === null || requestParameters.episodeId === undefined) {
            throw new runtime.RequiredError('episodeId','Required parameter requestParameters.episodeId was null or undefined when calling getEpisodeUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/episode/{episodeId}`.replace(`{${"episodeId"}}`, encodeURIComponent(String(requestParameters.episodeId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEpisodeFromJSON(jsonValue));
    }

    /**
     * getEpisode
     */
    async getEpisodeUsingGET(requestParameters: GetEpisodeUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEpisode> {
        const response = await this.getEpisodeUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveEpisode
     */
    async saveEpisodeUsingPOSTRaw(requestParameters: SaveEpisodeUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonEpisode>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveEpisodeUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/episode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonEpisodeToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonEpisodeFromJSON(jsonValue));
    }

    /**
     * saveEpisode
     */
    async saveEpisodeUsingPOST(requestParameters: SaveEpisodeUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonEpisode> {
        const response = await this.saveEpisodeUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
