import {useAppDispatch} from '../../store';
import {useParams} from "react-router-dom";
import {useEffect, useState} from 'react';
import {JsonStratEnablerInfo, JsonStratOnepagerInfo, JsonStratPillarInfo} from '../../generated-api';
import {Container, Grid} from '@mui/material';
import {fetchDashboard, fetchOverview, fetchStrategyContext} from "../../store/strategy";
import {useSelector} from "react-redux";
import {selectAuthUser, selectStrategyContext, selectStrategyDashboard} from "../../store/selectors";
import {useAppTranslation} from "../../services/i18n";
import StratairuPageHeader from "../../components/stratairu/components/StratairuPageHeader";
import {calculateSubtreeStatuses, getOnePagerLevel} from "../../helpers/strategy";
import StratairuElementCard from "../../components/stratairu/components/StratairuElementCard";
import styles from '../../assets/styles/stratairu.module.css';
import StratairuLabel from "../../components/stratairu/components/StratairuLabel";
import StratairuFillingCard from "../../components/stratairu/components/StratairuFillingCard";
import StratairuPage from "../../components/stratairu/components/StratairuPage";

const StratairuOverviewPage = () => {

  const t = useAppTranslation();
  const dispatch = useAppDispatch();

  const dashboard = useSelector(selectStrategyDashboard);
  const strategyContext = useSelector(selectStrategyContext);
  const user = useSelector(selectAuthUser);

  const [minPillarHeight, setMinPillarHeight] = useState<number>(0);
  const [minEnablerHeight, setMinEnablerHeight] = useState<number>(0);

  const { strategyId } = useParams();

  useEffect(() => {
    if (strategyId) {
      dispatch(fetchStrategyContext(+strategyId));
      dispatch(fetchDashboard({ strategyId: +strategyId }));
      dispatch(fetchOverview({ strategyId: +strategyId }));
    }
  }, [dispatch, strategyId]);

  const onePager = dashboard?.hierarchyOnePagers.find((op: JsonStratOnepagerInfo) => op.userId === user.userId);
  const onePagerLevel = getOnePagerLevel(onePager!);
  const subtree = dashboard?.subtreeOnePagers;

  const directResult = calculateSubtreeStatuses(subtree, user.userId!, true);
  const myTeamResult = calculateSubtreeStatuses(subtree, user.userId!, false);
  const myLevelResult = calculateSubtreeStatuses(dashboard?.myLevelOnePagers, user.userId!, false);
  const myFavouriteResult = calculateSubtreeStatuses(dashboard?.favouriteOnePagers, user.userId!, false);
  const allResult = calculateSubtreeStatuses(dashboard?.allOnePagers, user.userId!, false);

  return (
    <>
	{ !!dashboard && !!strategyContext.strategy && onePager &&
        <StratairuPage>
          <Container>
          <Grid container>
            <Grid item xs={12} style={{ paddingRight: '10px' }}>
              <StratairuPageHeader>
              </StratairuPageHeader>
            </Grid>

            <Grid item xs={12}>
              <StratairuLabel label={t('stratairu.common.pillars')} />
            </Grid>
            <Grid item xs={12}>
              <div className={styles.cardList}>
                { onePager.pillars?.map((pillar: JsonStratPillarInfo, index: number) => <StratairuElementCard minHeight={minPillarHeight} setMinHeight={(min) => setMinPillarHeight((curr) => curr < min ? min : curr)} key={'pillar-' + pillar.pillarId} element={pillar} index={index} rootLevel={onePagerLevel} level={onePagerLevel+1} subtree={subtree}></StratairuElementCard> ) }
              </div>
            </Grid>

            <Grid item xs={12}>
              <StratairuLabel label={t('stratairu.titles.successConditions')} />
            </Grid>
            <Grid item xs={12}>
              <div className={styles.cardList}>
                { onePager.enablers?.map((enabler: JsonStratEnablerInfo, index: number) => <StratairuElementCard minHeight={minEnablerHeight} setMinHeight={(min) => setMinEnablerHeight((curr) => curr < min ? min : curr)} key={'enabler-' + enabler.enablerId} element={enabler} index={index} rootLevel={onePagerLevel} level={onePagerLevel+1} subtree={subtree}></StratairuElementCard> ) }
              </div>
            </Grid>

            <Grid item xs={12}>
              <StratairuLabel label={t('stratairu.titles.fillingStatuses')} />
            </Grid>
            <Grid item xs={12}>
              <div className={styles.cardList}>
                <StratairuFillingCard label={t('stratairu.tabs.subordinate')} statuses={directResult}></StratairuFillingCard>
                { /* <StratairuFillingCard label={t('Celý tým')} statuses={allResult}></StratairuFillingCard> */ }
                <StratairuFillingCard label={t('stratairu.tabs.myLevel')} statuses={myLevelResult}></StratairuFillingCard>
                <StratairuFillingCard label={t('stratairu.tabs.favourites')} statuses={myFavouriteResult}></StratairuFillingCard>
                <StratairuFillingCard label={t('stratairu.tabs.wholeCompany')} statuses={allResult}></StratairuFillingCard>
              </div>
            </Grid>
          </Grid>
        </Container>
      </StratairuPage>
    }
    </>
	);
}

export default StratairuOverviewPage;
