import {useAppTranslation} from '../../../services/i18n';
import {
  JsonStratOnepagerInfo,
  JsonStratOnepagerInfoStatusEnum,
  JsonStratPillar,
  JsonStratPillarStatusEnum
} from "../../../generated-api";
import OnePagerEditableText from "./OnePagerEditableText";
import {OnepagerEntityIndex, OnepagerEntityType} from "../../../model/onepager";
import {Info, ReportProblem} from "@mui/icons-material";
import styles from "../../../assets/styles/onepager.module.css";
import {CommonActionsType} from "../../../model/form";
import OnePagerActionsContainer from "./OnePagerActionsContainer";
import {useCallback, useState} from "react";
import {setOnepagerParentPillar} from "../../../store/strategy";
import {useAppDispatch} from "../../../store";
import PillarParentModal from "./PillarParentModal";

interface Props {
  parentOnePager?: JsonStratOnepagerInfo;
  pillar: JsonStratPillar;
  pillarIndex?: number;
  isEditable: boolean;
  onAction?: (action: CommonActionsType, index: OnepagerEntityIndex) => void;
}

const ParentPillarInfo = ({ parentOnePager, pillar, pillarIndex, isEditable, onAction }: Props) => {

  const t = useAppTranslation();

  const dispatch = useAppDispatch();

  const [selectedPillarParentIndex, setSelectedPillarParentIndex] = useState<OnepagerEntityIndex | undefined>(undefined);

  const handleParentPillarAction = useCallback((action: CommonActionsType, index: OnepagerEntityIndex) => {
    if (action === CommonActionsType.Edit) {
      setSelectedPillarParentIndex(index);
      return;
    }
    onAction && onAction(action, index);
  }, [onAction])

  const handleSelectParentPillar = useCallback((index: OnepagerEntityIndex, pillar: Partial<JsonStratPillar>) => {
    console.log({ pillar });
    dispatch(setOnepagerParentPillar({ index, data: pillar }));
    setSelectedPillarParentIndex(undefined);
  }, [dispatch]);

  const isSet = !!(pillar.parentPillarId || pillar.parentEnablerId);

  const element = isSet && (pillar.parentPillarId ?
      parentOnePager?.pillars?.find(p => p.pillarId === pillar.parentPillarId) : parentOnePager?.enablers?.find(e => e.enablerId === pillar.parentEnablerId));
  const description = (element && element?.description) || '';
  const index = { entity: OnepagerEntityType.Pillar, entityIndex: pillarIndex };
  const indexText = { entity: OnepagerEntityType.PillarParent, entityIndex: pillarIndex };

  const actions: CommonActionsType[] = [];
  if (parentOnePager?.status === JsonStratOnepagerInfoStatusEnum.Finished) {
    // drill up only for pillar parent
    if (isEditable) {
      actions.push(CommonActionsType.Edit)
    }
    if (pillar.parentPillarId) {
      const parentPillar = parentOnePager.pillars?.find(p => p.pillarId === pillar.parentPillarId);
      if (parentPillar && (parentPillar.parentPillarId || parentPillar.parentEnablerId)) {
        actions.push(CommonActionsType.Up);
      }
    }
  }

  return pillar.status === JsonStratPillarStatusEnum.Active ?
      <>
      <OnePagerActionsContainer index={index} actions={ actions } onAction={handleParentPillarAction}>
        <div>
            { parentOnePager?.status !== JsonStratOnepagerInfoStatusEnum.Finished && <div className={styles.parentOnePagerNotFinished}><Info style={{ marginRight: '5px' }}/>{ t('onePager.messages.notConfirmed') }</div> }
            { parentOnePager?.status === JsonStratOnepagerInfoStatusEnum.Finished && !isSet && <div className={styles.parentPillarNotDefined}><ReportProblem style={{ marginRight: '5px' }}/>{ t('onePager.messages.notConnected') }</div> }
            { parentOnePager?.status === JsonStratOnepagerInfoStatusEnum.Finished && isSet && <OnePagerEditableText index={indexText} value={ description } readonly={true}/> }
        </div>
      </OnePagerActionsContainer>
      <PillarParentModal
          index={selectedPillarParentIndex}
          parentOnePager={parentOnePager}
          onSelect={handleSelectParentPillar}
          onClose={() => setSelectedPillarParentIndex(undefined)}/>
      </> : <></>
	;
}

export default ParentPillarInfo;
