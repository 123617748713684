import { useEffect, useRef } from 'react';

import styles from '../../../assets/styles/relay';
import { JsonGroup, JsonPoll, JsonPollResults, JsonRelayResponse } from '../../../generated-api';
import {ChartOptions, COLORS, SliderHeight} from '../../../helpers/relay';

const RelaySliderChart = (props: { group?: JsonGroup, poll: JsonPoll, results: JsonPollResults, responses: JsonRelayResponse[], groupIndex?: number, options?: ChartOptions }) => {
    const {poll, results} = props;

    const canvasRef = useRef(null);

    const weightedAvgSource: { top: number, bottom: number } | undefined = results?.optionTotals
        ? poll.options?.reduce((acc: { top: number, bottom: number }, option) => {
            /*let current = (results.optionTotals && results.optionTotals[option.optionId as number]) || 0;
            return current > max ? current : max;
            */
           const current = (results?.optionTotals && results.optionTotals[option.optionId as number]) || 0;
           acc.top += current * (+(option?.title || 0));
           acc.bottom += current;
           return acc;
        } ,  { top: 0, bottom: 0 }) : { top: 0, bottom: 0 };
    
    const weightedAvg = weightedAvgSource?.bottom ? Math.round(weightedAvgSource.top / weightedAvgSource.bottom * 10) / 10 : 0;
    const percentAvg = poll.options?.length ? Math.round((weightedAvg - 1) / (poll.options?.length - 1) * 100) : 0;
    const lineColor = props.options?.lineColor  || COLORS[1];
    const textColor = props.options?.textColor  || 'white';
    const sliderHeight = props.options?.height || SliderHeight;
    const sliderFontSize = props.options?.sliderScaleFontSize;
    const sliderCircleSize = props.options?.sliderCircleSize || '30px';
    const sliderCircleFontSize = props.options?.sliderCircleFontSize || '12px';
    const sliderCircleHalfSize = 'calc(' + sliderCircleSize + '/2)';
    const sliderCircleOffset = 'calc(-1 * calc(' + sliderCircleSize + ' - 30px) / 2)';
  
    useEffect(() => {
      const canvas = canvasRef.current as any;
      if (canvas) {
        const context = canvas.getContext('2d');
        context.canvas.width = canvas.clientWidth;
        context.canvas.height = canvas.clientHeight;
        const paddingH = 15;
        if (context && poll.options?.length && poll.options?.length > 1) {
            const width = canvas.width;
            const height = canvas.height - 10;
            const max = results?.optionTotals
                ? poll.options?.reduce((max: number, option) => {
                    let current = (results?.optionTotals && results.optionTotals[option.optionId as number]) || 0;
                    return current > max ? current : max;
                } , 0) : 0;
            const diff = (width - 2 * paddingH)  / (poll.options.length - 1);
            context.clearRect(0,0,width,height);
            context.beginPath()
            context.moveTo(0 + paddingH, height);
            poll.options.forEach((option, index) => {
                let current = (results?.optionTotals && results.optionTotals[option.optionId as number]) || 0;
                context.lineTo(index * diff + paddingH, height - (current / max * height));    
            });
            context.lineTo(width - paddingH, height);    
            context.lineTo(0 + paddingH, height);
            context.fillStyle = '#D4AFEE';
            context.fill()
            context.closePath()
        }
      }
    }, [poll, results])
    
    return <table style={{width: "100%", tableLayout: "fixed", fontFamily: "Roboto,Helvetica,Arial,sans-serif", paddingTop: "5px"}}>
                <tbody>
                    <tr>
                        <td colSpan={2} style={{ position: 'relative' /*, backgroundColor: 'green'*/ }}>
                            <canvas ref={canvasRef} style={{ width: '100%', height: sliderHeight, border: '0px' /*, backgroundColor: 'blue' */ }}>
                            </canvas>
                            <div style={{ width: 'calc(100% - ' + sliderCircleSize + ')', position: 'relative', /*border: '1px solid red',*/ margin: 'auto' }}>
                                <div style={{ position: 'absolute', bottom: '10px', width: percentAvg + '%', height: '5px', backgroundColor: lineColor }}></div>
                                <div style={{ position: 'absolute', bottom: sliderCircleOffset, left: 'calc(' + percentAvg + '% - ' + sliderCircleHalfSize + ')', width: sliderCircleSize, height: sliderCircleSize, borderRadius: sliderCircleHalfSize, backgroundColor: lineColor, color: textColor, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: sliderCircleFontSize }}>{ weightedAvg }</div>
                            </div>
                        </td>
                    </tr>
                        {poll.pollParams && <tr className={styles.scaleLabels}>
						<td style={{ fontSize: sliderFontSize || undefined }}>{poll.pollParams?.minLabel}</td>
						<td style={{ fontSize: sliderFontSize || undefined }}>{poll.pollParams?.maxLabel}</td>
					</tr>}
                    </tbody>
             </table>
};

export default RelaySliderChart;
