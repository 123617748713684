import {HTTPQuery} from '../generated-api';
import {ApiSerializedError} from '../model/api';
import {TAppFunction} from '../services/i18n';

export function getApiErrorData(action: any, t: TAppFunction) {
	let message = null;
	let code = null;
	if ((action.payload as any)?.message) {
		message = (action.payload as any)?.message;
	} else if ('error' in action) {
		message = (action.error as ApiSerializedError)?.message;
	}

	if ((action.payload as any)?.code) {
		code = (action.payload as any)?.code;
	} else if ('error' in action) {
		code = (action.error as ApiSerializedError)?.code;
	}

	switch (message) {
		case 'Network request failed':
			message = t('Pripojenie zlyhalo');
			break;
		case 'Timeout':
			message = t('Vypršal časový limit komunikácie');
			break;
		case 'Rejected':
			message =
				t('Chyba komunikácie.') +
				`\n(${action.error}, ${JSON.stringify(action.payload)})`;
			break;
	}

	return {message, code};
}

export function getApiErrorMessage(action: any, t: TAppFunction) {
	return getApiErrorData(action, t)?.message;
}

const reservedWordsForQuery: any = {
	_in: 'in',
};

function patchQueryParamName(param: string) {
	return reservedWordsForQuery[param] || param;
}

function appQueryParamValue(val: any) {
	if (val === undefined || val === null) {
		return '';
	}
	return encodeURIComponent(String(val));
}

export function appQueryParamsStringify(
	params: HTTPQuery,
	prefix = '',
): string {
	return Object.keys(params)
		.map(key => {
			const patchedKey = patchQueryParamName(key);
			const fullKey =
				prefix +
				(prefix.length
					? `[${encodeURIComponent(patchedKey)}]`
					: encodeURIComponent(patchedKey));

			const value = params[key];
			if (value instanceof Array) {
				return `${fullKey}=${value.map(v => appQueryParamValue(v)).join(',')}`;
				// return value
				// 	.map(
				// 		singleValue =>
				// 			`${fullKey}[]=${encodeURIComponent(String(singleValue))}`,
				// 	)
				// 	.join('&');
			}
			if (value instanceof Date) {
				return `${fullKey}=${appQueryParamValue(value.toISOString())}`;
			}
			if (typeof value === 'boolean') {
				return `${fullKey}=${value ? 1 : 0}`;
			}
			if (value instanceof Object) {
				return appQueryParamsStringify(value as HTTPQuery, fullKey);
			}
			return `${fullKey}=${appQueryParamValue(value)}`;
		})
		.filter(part => part.length > 0)
		.join('&');
}
