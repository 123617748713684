import {Link} from '@mui/material';
import {PropsWithChildren} from "react";

interface Props {
	onClick: () => void;
	sx?: any;
}
export const ActionLink = ({ onClick, sx, children }: PropsWithChildren<Props>) => {
	return <Link
			sx={{ cursor: 'pointer', textDecoration: 'underline', ...(sx || {}) }}
			onClick={onClick}>{ children }
	</Link>
};
