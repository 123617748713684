import React, {useCallback, useState} from 'react';
import { Subject } from 'rxjs';
import ClosableModal, {ModalButton, ModalButtonActionEnum} from "../components/layout/ClosableModal";
import {useAppTranslation} from "./i18n";
import {StratairuButtonEnum} from "../components/stratairu/components/StratairuButton";

interface Props {};

export type ModalButtons = 'CONFIRM' | 'CANCEL';
export type ModalMode = 'INFO' | 'CONFIRM';

export type ModalState = {
	isOpen: boolean;
	mode: ModalMode;
	title: string;
	message: string;
	confirmButton?: string;
	cancelButton?: string;
}

const style = {
	fontFamily: 'ProximaNovaWebRegular',
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	maxWidth: '100%',
	bgcolor: 'background.paper',
	border: '1px solid #666',
	borderRadius: 2,
	boxShadow: 24,
	pt: 2,
	px: 4,
	pb: 3,
  };

const { createContext, useContext } = React;

export const ModalContext = createContext<any>(null);

export const ModalProvider: React.FC<Props> = (props: any) => {

	const t = useAppTranslation();
	// const button = new Subject<ModalButtons>();

	const [modal, setModal] = useState({
		isOpen: false,
		mode: 'INFO',
		title: 'Informace',
		message: 'Potvrzovací informace'
	} as ModalState);
	const [button, setButton] = useState(new Subject<ModalButtons>());

	const open = ({ title, message, mode, confirmButton, cancelButton }: { title: string, message: string, mode: ModalMode, confirmButton?: string, cancelButton?: string }) => {
		setModal({ title, message, mode, confirmButton, cancelButton, isOpen: true });
	}

	const close = () => {
		setModal({ ...modal, isOpen: false });
	}	

	const value = {
		info: ({ title, message }: { title: string, message: string }) => {
			open({ title, message, mode: 'INFO' });
			const myButton = new Subject<ModalButtons>();
			setButton(myButton);
			return new Promise((resolve, reject) => {
				const subscription = myButton && myButton.subscribe((value: ModalButtons) => {
					close();
					subscription.unsubscribe();
					resolve(value);
				});
			});
		},
		confirm: ({ title, message, confirmButton, cancelButton }: { title: string, message: string, confirmButton?: string, cancelButton?: string }) => {
			open({ title, message, confirmButton, cancelButton, mode: 'CONFIRM' });
			const myButton = new Subject<ModalButtons>();
			setButton(myButton);
			return new Promise((resolve, reject) => {
				const subscription = myButton && myButton.subscribe((value: ModalButtons) => {
					close();
					subscription.unsubscribe();
					resolve(value);
				});
			});
		}
	};

	const handleModalAction = useCallback((action: ModalButtonActionEnum) => {
		console.log({ action });
		switch (action) {
			case ModalButtonActionEnum.Confirm:
				button.next('CONFIRM');
				break;
			case ModalButtonActionEnum.Cancel:
				button.next('CANCEL');
				break;

			default:
				break;
		}
	}, [button]);

	const buttons = [
		{ action: ModalButtonActionEnum.Close, title: t(modal?.cancelButton || 'Zrušit'), color: StratairuButtonEnum.Yellow },
		modal?.mode === 'CONFIRM' && { action: ModalButtonActionEnum.Confirm, title: t(modal?.confirmButton || 'Potvrdit'), color: StratairuButtonEnum.Blue }
	].filter(b => b !== false) as ModalButton[];

	return (
		<>
			<ModalContext.Provider value={ value }>
				{ props.children }
			</ModalContext.Provider>

			<ClosableModal
				title={ modal.title }
				open={modal.isOpen}
				onClose={() => { button && button.next('CANCEL'); }}
				buttons={buttons}
				onAction={handleModalAction}
				aria-labelledby="child-modal-title"
				aria-describedby="child-modal-description"
			>
				<div>
					<p style={{ marginBottom: '30px' }} id="child-modal-description">
						{ modal.message }
					</p>
				</div>
			</ClosableModal>
		</>
	);
};

export const useModal = () => {
	return useContext(ModalContext);
}
