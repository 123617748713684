/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonPollInfo,
    JsonPollInfoFromJSON,
    JsonPollInfoFromJSONTyped,
    JsonPollInfoToJSON,
} from './JsonPollInfo';

/**
 * 
 * @export
 * @interface ApiResultOfJsonPollInfo
 */
export interface ApiResultOfJsonPollInfo {
    /**
     * 
     * @type {JsonPollInfo}
     * @memberof ApiResultOfJsonPollInfo
     */
    data?: JsonPollInfo;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonPollInfo
     */
    message?: string;
    /**
     * 
     * @type {object}
     * @memberof ApiResultOfJsonPollInfo
     */
    messageValues?: object;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonPollInfo
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof ApiResultOfJsonPollInfo
     */
    result?: ApiResultOfJsonPollInfoResultEnum;
}

/**
* @export
* @enum {string}
*/
export enum ApiResultOfJsonPollInfoResultEnum {
    Ok = 'OK',
    Error = 'ERROR'
}

export function ApiResultOfJsonPollInfoFromJSON(json: any): ApiResultOfJsonPollInfo {
    return ApiResultOfJsonPollInfoFromJSONTyped(json, false);
}

export function ApiResultOfJsonPollInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): ApiResultOfJsonPollInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'data': !exists(json, 'data') ? undefined : JsonPollInfoFromJSON(json['data']),
        'message': !exists(json, 'message') ? undefined : json['message'],
        'messageValues': !exists(json, 'messageValues') ? undefined : json['messageValues'],
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'result': !exists(json, 'result') ? undefined : json['result'],
    };
}

export function ApiResultOfJsonPollInfoToJSON(value?: ApiResultOfJsonPollInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'data': JsonPollInfoToJSON(value.data),
        'message': value.message,
        'messageValues': value.messageValues,
        'requestId': value.requestId,
        'result': value.result,
    };
}

