import { useFormikContext } from "formik";
import { JsonPollOption } from "../../generated-api";
import { generatePreviewData } from '../../helpers/relay';

import RelayItemPollQuestion from "../relay/RelayItemPollQuestion";
import RelayItemPollResults from "../relay/RelayItemPollResults";
import { getJsonPollValue, JsonPollForm } from "./EditPoll";

interface Props {
    options: JsonPollOption[];
}

const PreviewPoll = ({ options }: Props) => {

    const { values } = useFormikContext<JsonPollForm>();

    const poll = getJsonPollValue(values, options.filter(option => !option.isRemoved).map((option, index) => ({ ...option, optionId: option.optionId || -index })));
    
    const results = generatePreviewData(poll);
    
    return (
        <>
            { /* JSON.stringify(poll, null, 2) */ }
            <RelayItemPollQuestion poll={poll} results={results} responses={[]}/>
            <RelayItemPollResults poll={poll} results={results} responses={[]}/>
        </>
      );
}

export default PreviewPoll;
