import {JsonSerieUser} from "../generated-api";

export interface ImportUserError {
    row: number;
    errorCode?: string;
    errorGuid?: string;
    error: string;
}
export const checkImportResult = (data: JsonSerieUser[] | undefined): ImportUserError[] | null => {
    const errors: ImportUserError[] = [];
    data?.forEach((user: JsonSerieUser, index: number) => {
       if (user.saveErrors && user.saveErrors.length) {
           const error = user.saveErrors[0];
           errors.push({
               row: user.rowNo || 0,
               errorCode: error.errorCode,
               errorGuid: error.errorGuid,
               error: (error.values as any)?.error
           });
       }
    });
    return errors.length ? errors : null;
}
