import {FormikErrors} from "formik";
import {Grid} from '@mui/material';
import FormContainer from '../components/form/FormContainer';
import SelectFormfield from '../components/form/SelectFormfield';
import TextFormfield from '../components/form/TextFormfield';
import {JsonCompany} from '../generated-api';
import { FormProps } from '../model/form';
import { useAppTranslation } from '../services/i18n';
import CompanyUploadLogo from "../pages/admin/editCompanyPage/components/CompanyUploadLogo";
import ColorPickerFormfield from "../components/form/ColorPickerFormfield";

interface Props extends FormProps<JsonCompany> {}

const CompanyForm = (props: Props) => {

    const t = useAppTranslation();

    const validate = (values: JsonCompany) => {
        let errors = {} as FormikErrors<JsonCompany>;
        if (!values.companyName) {
            errors['companyName'] = t('Název společnosti je povinný.');
        }
        if (!values.companyName) {
            errors['companyNumber'] = t('IČO společnosti je povinné.');
        }
        if (!values.sizeId) {
            errors['sizeId'] = t('Velikost společnosti je povinná.');
        }
        if (!values.countryCode) {
            errors['countryCode'] = t('Země je povinná.');
        }
        if (!values.sectorId) {
            errors['sectorId'] = t('Sektor je povinný.');
        }
        return errors;
    }

	return (
		<FormContainer {...props} validate={validate}>
            <Grid item xs={12}>
                <TextFormfield name="companyName" label={t('Název společnosti')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <TextFormfield name="companyNumber" label={t('IČO')}></TextFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="sizeId" label={t('Velikost')} poplist="companySize" numberic></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="countryCode" label={t('Země')}></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="regionCode" label={t('Kraj')}></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="sectorId" label={t('Odvětví / Sektor')} poplist="companySector" numberic></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <SelectFormfield name="tariffId" label={t('Zakoupené pásmo')} poplist="tariff" numberic></SelectFormfield>
            </Grid>
            <Grid item xs={12}>
                <ColorPickerFormfield name="companyData.backgroundColor" label={t('Firemní barva pozadí')}></ColorPickerFormfield>
            </Grid>
            <Grid item xs={12}>
                <ColorPickerFormfield name="companyData.textColor" label={t('Firemní barva textu')}></ColorPickerFormfield>
            </Grid>
            <Grid item xs={12} marginBottom={2}>
                <CompanyUploadLogo />
            </Grid>
		</FormContainer>
	);
}

export default CompanyForm;
