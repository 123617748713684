/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonChangeOfobject,
    JsonChangeOfobjectFromJSON,
    JsonChangeOfobjectFromJSONTyped,
    JsonChangeOfobjectToJSON,
} from './JsonChangeOfobject';
import {
    JsonPollOption,
    JsonPollOptionFromJSON,
    JsonPollOptionFromJSONTyped,
    JsonPollOptionToJSON,
} from './JsonPollOption';
import {
    JsonPollParams,
    JsonPollParamsFromJSON,
    JsonPollParamsFromJSONTyped,
    JsonPollParamsToJSON,
} from './JsonPollParams';

/**
 * 
 * @export
 * @interface JsonPoll
 */
export interface JsonPoll {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonPoll
     */
    allowedActions?: Array<JsonPollAllowedActionsEnum>;
    /**
     * 
     * @type {Array<JsonChangeOfobject>}
     * @memberof JsonPoll
     */
    changes?: Array<JsonChangeOfobject>;
    /**
     * 
     * @type {string}
     * @memberof JsonPoll
     */
    chartType?: JsonPollChartTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPoll
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    episodeId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    groupId?: number;
    /**
     * 
     * @type {Array<JsonPollOption>}
     * @memberof JsonPoll
     */
    options?: Array<JsonPollOption>;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    orderNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    pollId?: number;
    /**
     * 
     * @type {JsonPollParams}
     * @memberof JsonPoll
     */
    pollParams?: JsonPollParams;
    /**
     * 
     * @type {string}
     * @memberof JsonPoll
     */
    pollType?: JsonPollPollTypeEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    rootPollId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonPoll
     */
    status?: JsonPollStatusEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonPoll
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonPoll
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonPoll
     */
    updatedBy?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonPollAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonPollChartTypeEnum {
    Barchart = 'BARCHART',
    BarchartH = 'BARCHART_H',
    Sliders = 'SLIDERS',
    Spiderchart = 'SPIDERCHART',
    Wordcloud = 'WORDCLOUD',
    Matrix = 'MATRIX',
    None = 'NONE'
}/**
* @export
* @enum {string}
*/
export enum JsonPollPollTypeEnum {
    Choice = 'CHOICE',
    Multichoice = 'MULTICHOICE',
    Scale = 'SCALE',
    Ranking = 'RANKING',
    Text = 'TEXT'
}/**
* @export
* @enum {string}
*/
export enum JsonPollStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonPollFromJSON(json: any): JsonPoll {
    return JsonPollFromJSONTyped(json, false);
}

export function JsonPollFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPoll {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'changes': !exists(json, 'changes') ? undefined : ((json['changes'] as Array<any>).map(JsonChangeOfobjectFromJSON)),
        'chartType': !exists(json, 'chartType') ? undefined : json['chartType'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'episodeId': !exists(json, 'episodeId') ? undefined : json['episodeId'],
        'groupId': !exists(json, 'groupId') ? undefined : json['groupId'],
        'options': !exists(json, 'options') ? undefined : ((json['options'] as Array<any>).map(JsonPollOptionFromJSON)),
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'pollId': !exists(json, 'pollId') ? undefined : json['pollId'],
        'pollParams': !exists(json, 'pollParams') ? undefined : JsonPollParamsFromJSON(json['pollParams']),
        'pollType': !exists(json, 'pollType') ? undefined : json['pollType'],
        'rootPollId': !exists(json, 'rootPollId') ? undefined : json['rootPollId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'title': !exists(json, 'title') ? undefined : json['title'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
    };
}

export function JsonPollToJSON(value?: JsonPoll | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'changes': value.changes === undefined ? undefined : ((value.changes as Array<any>).map(JsonChangeOfobjectToJSON)),
        'chartType': value.chartType,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'episodeId': value.episodeId,
        'groupId': value.groupId,
        'options': value.options === undefined ? undefined : ((value.options as Array<any>).map(JsonPollOptionToJSON)),
        'orderNo': value.orderNo,
        'pollId': value.pollId,
        'pollParams': JsonPollParamsToJSON(value.pollParams),
        'pollType': value.pollType,
        'rootPollId': value.rootPollId,
        'status': value.status,
        'title': value.title,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
    };
}

