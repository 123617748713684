/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonStratInitiative,
    JsonStratInitiativeFromJSON,
    JsonStratInitiativeFromJSONTyped,
    JsonStratInitiativeToJSON,
} from './JsonStratInitiative';
import {
    JsonStratSuccessLook,
    JsonStratSuccessLookFromJSON,
    JsonStratSuccessLookFromJSONTyped,
    JsonStratSuccessLookToJSON,
} from './JsonStratSuccessLook';

/**
 * 
 * @export
 * @interface JsonStratPillarInfo
 */
export interface JsonStratPillarInfo {
    /**
     * 
     * @type {Array<string>}
     * @memberof JsonStratPillarInfo
     */
    allowedActions?: Array<JsonStratPillarInfoAllowedActionsEnum>;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    companyDepartmentId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    companyPositionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    createdBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    departmentId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    firstName?: string;
    /**
     * 
     * @type {Array<JsonStratInitiative>}
     * @memberof JsonStratPillarInfo
     */
    initiatives?: Array<JsonStratInitiative>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    lastName?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    onepagerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    orderNo?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    parentEnablerId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    parentInitiativeId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    parentPillarId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    pillarId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    pillarPath?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    positionId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    status?: JsonStratPillarInfoStatusEnum;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    stratUserId?: number;
    /**
     * 
     * @type {Array<JsonStratSuccessLook>}
     * @memberof JsonStratPillarInfo
     */
    successLooks?: Array<JsonStratSuccessLook>;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    teamName?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonStratPillarInfo
     */
    updatedAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    updatedBy?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonStratPillarInfo
     */
    userId?: number;
}

/**
* @export
* @enum {string}
*/
export enum JsonStratPillarInfoAllowedActionsEnum {
    View = 'VIEW',
    Edit = 'EDIT'
}/**
* @export
* @enum {string}
*/
export enum JsonStratPillarInfoStatusEnum {
    Deleted = 'DELETED',
    Active = 'ACTIVE'
}

export function JsonStratPillarInfoFromJSON(json: any): JsonStratPillarInfo {
    return JsonStratPillarInfoFromJSONTyped(json, false);
}

export function JsonStratPillarInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonStratPillarInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'allowedActions': !exists(json, 'allowedActions') ? undefined : json['allowedActions'],
        'companyDepartmentId': !exists(json, 'companyDepartmentId') ? undefined : json['companyDepartmentId'],
        'companyPositionId': !exists(json, 'companyPositionId') ? undefined : json['companyPositionId'],
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'createdBy': !exists(json, 'createdBy') ? undefined : json['createdBy'],
        'departmentId': !exists(json, 'departmentId') ? undefined : json['departmentId'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'initiatives': !exists(json, 'initiatives') ? undefined : ((json['initiatives'] as Array<any>).map(JsonStratInitiativeFromJSON)),
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'onepagerId': !exists(json, 'onepagerId') ? undefined : json['onepagerId'],
        'orderNo': !exists(json, 'orderNo') ? undefined : json['orderNo'],
        'parentEnablerId': !exists(json, 'parentEnablerId') ? undefined : json['parentEnablerId'],
        'parentInitiativeId': !exists(json, 'parentInitiativeId') ? undefined : json['parentInitiativeId'],
        'parentPillarId': !exists(json, 'parentPillarId') ? undefined : json['parentPillarId'],
        'pillarId': !exists(json, 'pillarId') ? undefined : json['pillarId'],
        'pillarPath': !exists(json, 'pillarPath') ? undefined : json['pillarPath'],
        'positionId': !exists(json, 'positionId') ? undefined : json['positionId'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'stratUserId': !exists(json, 'stratUserId') ? undefined : json['stratUserId'],
        'successLooks': !exists(json, 'successLooks') ? undefined : ((json['successLooks'] as Array<any>).map(JsonStratSuccessLookFromJSON)),
        'teamName': !exists(json, 'teamName') ? undefined : json['teamName'],
        'updatedAt': !exists(json, 'updatedAt') ? undefined : json['updatedAt'],
        'updatedBy': !exists(json, 'updatedBy') ? undefined : json['updatedBy'],
        'userId': !exists(json, 'userId') ? undefined : json['userId'],
    };
}

export function JsonStratPillarInfoToJSON(value?: JsonStratPillarInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'allowedActions': value.allowedActions,
        'companyDepartmentId': value.companyDepartmentId,
        'companyPositionId': value.companyPositionId,
        'createdAt': value.createdAt,
        'createdBy': value.createdBy,
        'departmentId': value.departmentId,
        'description': value.description,
        'email': value.email,
        'firstName': value.firstName,
        'initiatives': value.initiatives === undefined ? undefined : ((value.initiatives as Array<any>).map(JsonStratInitiativeToJSON)),
        'lastName': value.lastName,
        'onepagerId': value.onepagerId,
        'orderNo': value.orderNo,
        'parentEnablerId': value.parentEnablerId,
        'parentInitiativeId': value.parentInitiativeId,
        'parentPillarId': value.parentPillarId,
        'pillarId': value.pillarId,
        'pillarPath': value.pillarPath,
        'positionId': value.positionId,
        'status': value.status,
        'stratUserId': value.stratUserId,
        'successLooks': value.successLooks === undefined ? undefined : ((value.successLooks as Array<any>).map(JsonStratSuccessLookToJSON)),
        'teamName': value.teamName,
        'updatedAt': value.updatedAt,
        'updatedBy': value.updatedBy,
        'userId': value.userId,
    };
}

