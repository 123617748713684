/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    ApiResultOfJsonStrategy,
    ApiResultOfJsonStrategyFromJSON,
    ApiResultOfJsonStrategyToJSON,
    ApiResultOfJsonStrategyInfo,
    ApiResultOfJsonStrategyInfoFromJSON,
    ApiResultOfJsonStrategyInfoToJSON,
    ApiResultOfListOfJsonStrategyInfo,
    ApiResultOfListOfJsonStrategyInfoFromJSON,
    ApiResultOfListOfJsonStrategyInfoToJSON,
    ApiResultOflong,
    ApiResultOflongFromJSON,
    ApiResultOflongToJSON,
    JsonStrategy,
    JsonStrategyFromJSON,
    JsonStrategyToJSON,
} from '../models';

export interface GetStrategyCountUsingGETRequest {
    companyId?: number;
    serieId?: number;
    search?: string;
}

export interface GetStrategyInfoUsingGETRequest {
    strategyId: number;
}

export interface GetStrategyListUsingGETRequest {
    companyId?: number;
    serieId?: number;
    search?: string;
    orderCol?: string;
    orderDir?: string;
    start?: number;
    rows?: number;
}

export interface GetStrategyUsingGETRequest {
    strategyId: number;
}

export interface SaveStrategyUsingPOSTRequest {
    json: JsonStrategy;
    testOnly?: boolean;
}

/**
 * 
 */
export class StrategyControllerApi extends runtime.BaseAPI {

    /**
     * getStrategyCount
     */
    async getStrategyCountUsingGETRaw(requestParameters: GetStrategyCountUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOflong>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strategy/count`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOflongFromJSON(jsonValue));
    }

    /**
     * getStrategyCount
     */
    async getStrategyCountUsingGET(requestParameters: GetStrategyCountUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOflong> {
        const response = await this.getStrategyCountUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStrategyInfo
     */
    async getStrategyInfoUsingGETRaw(requestParameters: GetStrategyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStrategyInfo>> {
        if (requestParameters.strategyId === null || requestParameters.strategyId === undefined) {
            throw new runtime.RequiredError('strategyId','Required parameter requestParameters.strategyId was null or undefined when calling getStrategyInfoUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strategy/info/{strategyId}`.replace(`{${"strategyId"}}`, encodeURIComponent(String(requestParameters.strategyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStrategyInfoFromJSON(jsonValue));
    }

    /**
     * getStrategyInfo
     */
    async getStrategyInfoUsingGET(requestParameters: GetStrategyInfoUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStrategyInfo> {
        const response = await this.getStrategyInfoUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStrategyList
     */
    async getStrategyListUsingGETRaw(requestParameters: GetStrategyListUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfListOfJsonStrategyInfo>> {
        const queryParameters: any = {};

        if (requestParameters.companyId !== undefined) {
            queryParameters['companyId'] = requestParameters.companyId;
        }

        if (requestParameters.serieId !== undefined) {
            queryParameters['serieId'] = requestParameters.serieId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.orderCol !== undefined) {
            queryParameters['orderCol'] = requestParameters.orderCol;
        }

        if (requestParameters.orderDir !== undefined) {
            queryParameters['orderDir'] = requestParameters.orderDir;
        }

        if (requestParameters.start !== undefined) {
            queryParameters['start'] = requestParameters.start;
        }

        if (requestParameters.rows !== undefined) {
            queryParameters['rows'] = requestParameters.rows;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strategy/list`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfListOfJsonStrategyInfoFromJSON(jsonValue));
    }

    /**
     * getStrategyList
     */
    async getStrategyListUsingGET(requestParameters: GetStrategyListUsingGETRequest = {}, initOverrides?: RequestInit): Promise<ApiResultOfListOfJsonStrategyInfo> {
        const response = await this.getStrategyListUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * getStrategy
     */
    async getStrategyUsingGETRaw(requestParameters: GetStrategyUsingGETRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStrategy>> {
        if (requestParameters.strategyId === null || requestParameters.strategyId === undefined) {
            throw new runtime.RequiredError('strategyId','Required parameter requestParameters.strategyId was null or undefined when calling getStrategyUsingGET.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/rest-service/strategy/{strategyId}`.replace(`{${"strategyId"}}`, encodeURIComponent(String(requestParameters.strategyId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStrategyFromJSON(jsonValue));
    }

    /**
     * getStrategy
     */
    async getStrategyUsingGET(requestParameters: GetStrategyUsingGETRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStrategy> {
        const response = await this.getStrategyUsingGETRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * saveStrategy
     */
    async saveStrategyUsingPOSTRaw(requestParameters: SaveStrategyUsingPOSTRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ApiResultOfJsonStrategy>> {
        if (requestParameters.json === null || requestParameters.json === undefined) {
            throw new runtime.RequiredError('json','Required parameter requestParameters.json was null or undefined when calling saveStrategyUsingPOST.');
        }

        const queryParameters: any = {};

        if (requestParameters.testOnly !== undefined) {
            queryParameters['testOnly'] = requestParameters.testOnly;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/rest-service/strategy`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JsonStrategyToJSON(requestParameters.json),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ApiResultOfJsonStrategyFromJSON(jsonValue));
    }

    /**
     * saveStrategy
     */
    async saveStrategyUsingPOST(requestParameters: SaveStrategyUsingPOSTRequest, initOverrides?: RequestInit): Promise<ApiResultOfJsonStrategy> {
        const response = await this.saveStrategyUsingPOSTRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
