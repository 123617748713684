import React, {PropsWithChildren} from 'react';
import {OnepagerEntityIndex} from "../../../model/onepager";
import styles from "../../../assets/styles/stratairu-onepager.module.css";
import classnames from "classnames";
import {JsonPoplistItem, JsonStratTeam} from "../../../generated-api";
import {CommonActionsType} from "../../../model/form";
import StratairuOnePagerActionsContainer from "./StratairuOnePagerActionsContainer";
import {useSelector} from "react-redux";
import {selectOnePagerSubTeamsCombo} from "../../../store/selectors";
import {getAllowedActions} from "./StratairuOnePagerPicos";

interface Props {
    index: OnepagerEntityIndex;
    readonly?: boolean;
    value?: JsonStratTeam;
    editableDraft?: boolean;
    order?: number;
    actions?: CommonActionsType[];
    onAction?: (action: CommonActionsType, index: OnepagerEntityIndex) => void;
    noOrder?: boolean;
}

// Picos = Picture Of Success
const StratairuOnePagerTeam = ({ index, value, readonly, editableDraft, order, actions, onAction, noOrder }: PropsWithChildren<Props>) => {

    const teams = useSelector(selectOnePagerSubTeamsCombo);
    const name = (!!value?.stratUserId && teams.find((item: JsonPoplistItem) => value.stratUserId === item.value)?.label) || value?.teamName;

    return (
        <div className={styles.teamContainer}>
            <div className={styles.teamRow}>
                { !noOrder && <div className={classnames(styles.editableText, styles.teamTitle)}>{ order || 0 }.</div> }
                <StratairuOnePagerActionsContainer index={{ ...index }} actions={!readonly ? getAllowedActions([CommonActionsType.Edit, CommonActionsType.Delete, CommonActionsType.Create], actions) : []} onAction={onAction}>
                    <div className={classnames(styles.editableText, styles.teamValue)}>{ name }</div>
                </StratairuOnePagerActionsContainer>
            </div>
        </div>
	);
}

export default StratairuOnePagerTeam;
