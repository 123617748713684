/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    JsonUser,
    JsonUserFromJSON,
    JsonUserFromJSONTyped,
    JsonUserToJSON,
} from './JsonUser';

/**
 * 
 * @export
 * @interface JsonUpdateUserRequest
 */
export interface JsonUpdateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof JsonUpdateUserRequest
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUpdateUserRequest
     */
    password?: string;
    /**
     * 
     * @type {boolean}
     * @memberof JsonUpdateUserRequest
     */
    rememberMe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof JsonUpdateUserRequest
     */
    token?: string;
    /**
     * 
     * @type {string}
     * @memberof JsonUpdateUserRequest
     */
    tokenType?: JsonUpdateUserRequestTokenTypeEnum;
    /**
     * 
     * @type {JsonUser}
     * @memberof JsonUpdateUserRequest
     */
    userUpdate?: JsonUser;
}

/**
* @export
* @enum {string}
*/
export enum JsonUpdateUserRequestTokenTypeEnum {
    Remember = 'REMEMBER',
    Recover = 'RECOVER',
    Activate = 'ACTIVATE'
}

export function JsonUpdateUserRequestFromJSON(json: any): JsonUpdateUserRequest {
    return JsonUpdateUserRequestFromJSONTyped(json, false);
}

export function JsonUpdateUserRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonUpdateUserRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'email': !exists(json, 'email') ? undefined : json['email'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'rememberMe': !exists(json, 'rememberMe') ? undefined : json['rememberMe'],
        'token': !exists(json, 'token') ? undefined : json['token'],
        'tokenType': !exists(json, 'tokenType') ? undefined : json['tokenType'],
        'userUpdate': !exists(json, 'userUpdate') ? undefined : JsonUserFromJSON(json['userUpdate']),
    };
}

export function JsonUpdateUserRequestToJSON(value?: JsonUpdateUserRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'email': value.email,
        'password': value.password,
        'rememberMe': value.rememberMe,
        'token': value.token,
        'tokenType': value.tokenType,
        'userUpdate': JsonUserToJSON(value.userUpdate),
    };
}

