import React from 'react';
import {Route} from 'react-router';
import StratairuHomePage from "../pages/stratairu/StratairuHomePage";
import StratairuOverviewPage from "../pages/stratairu/StratairuOverviewPage";
import StratairuOnePagerPage from "../pages/stratairu/StratairuOnePagerPage";
import StratairuHelpPage from "../pages/stratairu/StratairuHelpPage";


export const getStratairuRoutes = (isLogged: boolean) => {

	return [
		isLogged && <Route path='/stratairu/:strategyId/home' element={<StratairuHomePage />} />,
		isLogged && <Route path='/stratairu/:strategyId/overview' element={<StratairuOverviewPage />} />,
		isLogged && <Route path='/stratairu/:strategyId/onepager/:id' element={<StratairuOnePagerPage/>} />,
		isLogged && <Route path='/stratairu/:strategyId/help' element={<StratairuHelpPage/>} />
	].filter(route => !!route);

};
