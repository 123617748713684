import {
    JsonStratEnabler,
    JsonStratOnepagerInfo,
    JsonStratPillarInfo
} from '../../../generated-api';
import {Card, Container, Grid} from '@mui/material';
import Typography from "@mui/material/Typography";
import {useSelector} from "react-redux";
import {selectAuthUser} from "../../../store/selectors";
import styles from '../../../assets/styles/dashboard.module.css';
import {getOnePagerLevel} from "../../../helpers/strategy";
import {Nl2BrText} from "../../../components/layout/Nl2BrText";

const SupportEnablerPillarCount = ({ enablerId, pillarIds, subtree, level, rootLevel }: { enablerId?: number, pillarIds?: number[], subtree: JsonStratOnepagerInfo[], level: number, rootLevel: number }) => {

    const supportedPillarIds: number[] = [];
    let count = 0;
    let countLevel: number = 0;

    subtree?.filter(op => getOnePagerLevel(op) === level).forEach(
        (op) => {
            countLevel++;
            op.pillars?.filter(pillar => (pillar.parentEnablerId && pillar.parentEnablerId === enablerId) || (pillar.parentPillarId && pillarIds?.includes(pillar.parentPillarId)))
                .forEach(pillar => {
                    count++;
                    supportedPillarIds.push(pillar.pillarId!);
                })
        }
    )

    return <>
        { !!countLevel &&
            <>
                <Card className={styles.pillarSupportElement}>
                    <div><b>-{ level - rootLevel }</b></div>
                    <div>{ count }</div>
                </Card>
                { !!count && <SupportEnablerPillarCount pillarIds={supportedPillarIds} subtree={subtree} level={level+1} rootLevel={rootLevel} /> }
            </>
        }
    </>
}

const StrategyPillarCard = ({ element }: { element: JsonStratPillarInfo }) => {
    return <div>
        <Card className={styles.pillarElement}>
            <div><b>Pilíř</b></div>
            <Nl2BrText text={ element.description || '' } />
        </Card>
    </div>;
}

const StrategyEnablerCard = ({ element }: { element: JsonStratEnabler }) => {
    return <div>
        <Card className={styles.pillarElement}>
            <div><b>Enabler</b></div>
            <Nl2BrText text={ element.description || '' } />
        </Card>
    </div>;
}

interface Props {
    subtree: JsonStratOnepagerInfo[];
}
const StrategyPillarSupport = ({ subtree }: Props) => {

  const user = useSelector(selectAuthUser);

  const onePager = subtree.find((op: JsonStratOnepagerInfo) => op.userId === user?.userId);
  const onePagerLevel = getOnePagerLevel(onePager!);

  return (
    <>
		{ onePager &&
            <Container>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography component="div" variant="h5" color="black">Strategické pilíře</Typography>
                        <Typography component="div" variant="h6" color="black">Moje strategické pilíře</Typography>
                        <div className={styles.pillarContainer}>
                            { onePager.pillars?.map((pillar) => <StrategyPillarCard key={'pillar-' + pillar.pillarId} element={pillar}></StrategyPillarCard> ) }
                            { onePager.enablers?.map((enabler) => <StrategyEnablerCard key={'enabler-' + enabler.enablerId} element={enabler}></StrategyEnablerCard> ) }
                        </div>
                        <div className={styles.pillarContainer}>
                            { onePager.pillars?.map((pillar) => <div key={'pillar-count-' + pillar.pillarId}><SupportEnablerPillarCount pillarIds={[pillar.pillarId!]} subtree={subtree} level={onePagerLevel + 1} rootLevel={onePagerLevel}></SupportEnablerPillarCount></div> ) }
                            { onePager.enablers?.map((enabler) => <div key={'enabler-count-' + enabler.enablerId}><SupportEnablerPillarCount enablerId={enabler.enablerId} subtree={subtree} level={onePagerLevel + 1} rootLevel={onePagerLevel}></SupportEnablerPillarCount></div> ) }
                        </div>
                    </Grid>
                </Grid>
            </Container>
        }
    </>
	);
}

export default StrategyPillarSupport;
