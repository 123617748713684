/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonRelayResponse
 */
export interface JsonRelayResponse {
    /**
     * 
     * @type {string}
     * @memberof JsonRelayResponse
     */
    createdAt?: string;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayResponse
     */
    pollId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayResponse
     */
    pollOptionId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonRelayResponse
     */
    responseId?: number;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayResponse
     */
    responseType?: JsonRelayResponseResponseTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof JsonRelayResponse
     */
    value?: string;
}

/**
* @export
* @enum {string}
*/
export enum JsonRelayResponseResponseTypeEnum {
    Poll = 'POLL'
}

export function JsonRelayResponseFromJSON(json: any): JsonRelayResponse {
    return JsonRelayResponseFromJSONTyped(json, false);
}

export function JsonRelayResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonRelayResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'createdAt': !exists(json, 'createdAt') ? undefined : json['createdAt'],
        'pollId': !exists(json, 'pollId') ? undefined : json['pollId'],
        'pollOptionId': !exists(json, 'pollOptionId') ? undefined : json['pollOptionId'],
        'responseId': !exists(json, 'responseId') ? undefined : json['responseId'],
        'responseType': !exists(json, 'responseType') ? undefined : json['responseType'],
        'value': !exists(json, 'value') ? undefined : json['value'],
    };
}

export function JsonRelayResponseToJSON(value?: JsonRelayResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'createdAt': value.createdAt,
        'pollId': value.pollId,
        'pollOptionId': value.pollOptionId,
        'responseId': value.responseId,
        'responseType': value.responseType,
        'value': value.value,
    };
}

