import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import styles from "../../assets/styles/stratairu.module.css";
import {useAppTranslation} from "../../services/i18n";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {useEffect, useState} from "react";
import StratairuPage from "../../components/stratairu/components/StratairuPage";
import {Container, Grid} from "@mui/material";
import StratairuPageHeader from "../../components/stratairu/components/StratairuPageHeader";
import * as React from "react";
import {fetchDashboard, fetchStrategyContext} from "../../store/strategy";
import {useAppDispatch} from "../../store";

const StratairuHelpItemGroup = ({ name, items }: { name: string, items: string[] }) => {

    const t = useAppTranslation();
    const dispatch = useAppDispatch();

    const [expanded, setExpanded] = useState<boolean>(false);

    const title = 'stratairu.faq.' + name;

    const { strategyId } = useParams();

    useEffect(() => {
        if (strategyId) {
            dispatch(fetchStrategyContext(+strategyId))
                .then(() => dispatch(fetchDashboard({ strategyId: +strategyId })));
        }
    }, [dispatch, strategyId]);

    return <div className={styles.faqGroup}>
        <div className={styles.groupHeader} onClick={() => setExpanded((value) => !value)}>
            <div dangerouslySetInnerHTML={{ __html: t(title + '.title') }} />
            { expanded && <ExpandLessIcon style={{ fontSize: '2rem' }}/> }
            { !expanded && <ExpandMoreIcon style={{ fontSize: '2rem' }}/> }
        </div>
        { !!expanded && items?.map((item) => <StratairuHelpItem name={ title + '.items.' + item } />) }
    </div>
}

const StratairuHelpItem = ({ name }: { name: string }) => {

    const t = useAppTranslation();

    const [expanded, setExpanded] = useState<boolean>(false);

    return <div className={styles.faqItem}>
        <div className={styles.header} onClick={() => setExpanded((value) => !value)}>
            <div dangerouslySetInnerHTML={{ __html: t(name + '.title') }} />
            { expanded && <ExpandLessIcon style={{ fontSize: '2rem' }}/> }
            { !expanded && <ExpandMoreIcon style={{ fontSize: '2rem' }}/> }
        </div>
        { expanded && <div className={styles.content} dangerouslySetInnerHTML={{ __html: t(name + '.desc') }} /> }
    </div>

}

const LiveFAQPage = () => {

    return (
        <StratairuPage>
            <Container>
                <Grid container>
                    <Grid item xs={12} style={{ paddingRight: '10px' }}>
                        <StratairuPageHeader />
                    </Grid>
                    <Grid item xs={12} style={{ paddingRight: '10px' }}>
                        <StratairuHelpItemGroup name="about" items={["1", "2", "3", "4", "5", "6", "7"]} />
                        <StratairuHelpItemGroup name="technical" items={["1", "2", "3", "4", "5", "6", "7"]} />
                    </Grid>
                </Grid>
            </Container>
        </StratairuPage>);
}

export default LiveFAQPage;
