import {PropsWithChildren, useCallback} from 'react';
import {Container, Grid} from '@mui/material';
import styles from '../../../assets/styles/onepager.module.css';
import OnePagerActionsContainer from "./OnePagerActionsContainer";
import {OnepagerEntityIndex} from "../../../model/onepager";
import {CommonActionsType} from "../../../model/form";
import HelpIcon from "@mui/icons-material/Help";

interface Props {
  title?: string;
  index: OnepagerEntityIndex;
  actions?: CommonActionsType[];
  onAction?: (action: CommonActionsType, index: OnepagerEntityIndex) => void;
  sx?: any;
  classx?: any;
}

const OnePagerRow = ({ title, index, actions, children, sx, classx, onAction }: PropsWithChildren<Props>) => {

    const handleAction = useCallback((action: CommonActionsType) => {
        if (onAction) {
            onAction(action, index);
        }
    }, [index, onAction])

    const hasTooltip = !!actions?.find(item => item === CommonActionsType.Help);


    return (
      <Container>
        <Grid container style={{ marginBottom: '5px' }} className={classx}>
            <Grid item md={4} xs={12} className={title ? styles.rowHeader : styles.noRowHeader } style={sx}>
                <OnePagerActionsContainer index={index} actions={actions?.filter(item => item !== CommonActionsType.Help)} onAction={handleAction} alwaysVisible>
                    <div className={styles.title}>
                        { title }
                        { hasTooltip && <span style={{ position: 'relative' }}>
                            <HelpIcon style={{ cursor: 'pointer', paddingTop: '0px', position: 'absolute', top: '-3px', left: '5px' }} onClick={() => handleAction(CommonActionsType.Help)}/>
                        </span> }
                    </div>
                </OnePagerActionsContainer>
            </Grid>
          <Grid item md={8} xs={12} className={styles.rowContent} style={{ paddingLeft: '10px' }}>
              { children }
          </Grid>
        </Grid>
      </Container>
	);
}

export default OnePagerRow;
