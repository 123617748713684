import {EnhancedStore} from '@reduxjs/toolkit';
import React, {ReactNode} from 'react';
import {Provider as ReduxProvider} from 'react-redux';

interface Props<T extends EnhancedStore> {
	store: T;
	children?: ReactNode | undefined;
}

export function AppProviders<T extends EnhancedStore>({
	store,
	children,
}: Props<T>) {
	// const dispatch: any = store.dispatch;
	return (
		<ReduxProvider store={store}>
			{children}
		</ReduxProvider>
	);
}
