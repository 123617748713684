/* tslint:disable */
/* eslint-disable */
/**
 * Api Documentation
 * Api Documentation
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface JsonPollResults
 */
export interface JsonPollResults {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof JsonPollResults
     */
    optionTotals?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof JsonPollResults
     */
    pollId?: number;
    /**
     * 
     * @type {number}
     * @memberof JsonPollResults
     */
    userCount?: number;
}

export function JsonPollResultsFromJSON(json: any): JsonPollResults {
    return JsonPollResultsFromJSONTyped(json, false);
}

export function JsonPollResultsFromJSONTyped(json: any, ignoreDiscriminator: boolean): JsonPollResults {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'optionTotals': !exists(json, 'optionTotals') ? undefined : json['optionTotals'],
        'pollId': !exists(json, 'pollId') ? undefined : json['pollId'],
        'userCount': !exists(json, 'userCount') ? undefined : json['userCount'],
    };
}

export function JsonPollResultsToJSON(value?: JsonPollResults | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'optionTotals': value.optionTotals,
        'pollId': value.pollId,
        'userCount': value.userCount,
    };
}

