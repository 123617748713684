import {createAsyncThunk} from '@reduxjs/toolkit'
import {API_CONFIG} from '../app/api-config';
import { ServiceControllerApi } from '../generated-api/apis/ServiceControllerApi';

const sericesApi = new ServiceControllerApi(API_CONFIG);

/*
export const fetchUsers = createAsyncThunk('users/list', async (params: GetUserListUsingGETRequest) => {
	return await usersApi.getUserListUsingGET(params);
});
*/

export const purgeUser = createAsyncThunk('service/purge-user', async (userId: number, thunkApi) => {
	
	return await sericesApi.purgeUserUsingGET({ userId });

});